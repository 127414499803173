import Chip from "@material-ui/core/Chip";
import { Tooltip } from "@material-ui/core";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const FilterChips = (props) => {
  const handleChipClick = (chipToEdit) => () => {
    props.setShowModal(true);
    props.setChipToFocus(chipToEdit);
    props.setAttributeData(props.appliedFilterAttr);
    props.setChipClicked(true);
  };

  const handleDeleteAll = (chipToDelete) => {
    console.log("inside the handle delete", chipToDelete);
    props.setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    if (chipToDelete.key === props.chipToFocus?.key) {
      props.setChipToFocus("");
    }
    props.setBody((body) => body.filter((e) => e.attrId !== chipToDelete.key));
    props.setAppliedFilterAttr((attrs) =>
      attrs.filter((attr) => attr.attrId !== chipToDelete.key)
    );
  };

  const handleDelete = (chipToDelete) => () => {
    console.log("inside the handle delete", chipToDelete);
    props.setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    if (chipToDelete.key === props.chipToFocus?.key) {
      props.setChipToFocus("");
    }
    props.setBody((body) => body.filter((e) => e.attrId !== chipToDelete.key));
    props.setAppliedFilterAttr((attrs) =>
      attrs.filter((attr) => attr.attrId !== chipToDelete.key)
    );
  };

  console.log("Chip data 40",props.chipData)


  const handleRemoveAll = () => {
    for (let i = 0; i < props.chipData.length; i++) {
      handleDeleteAll(props.chipData[i]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "inherit",
        alignItems: "center",
      }}
    >
      {props.chipData.length > 0}
      {props.chipData.map((data, index) => {
        return (
          <div key={index} style={{ margin: "0px" }}>
            <Tooltip title={data.value}>
              <Chip
                style={{
                  margin: "10px 10px 10px 0px",
                  backgroundColor: "#E8E8E8",
                  color: "#3D3D3B",
                  fontSize: "small",
                  border:'none',
                  borderRadius: "4px",
                }}
                variant="outlined"
                // backgroundColor="#8A8A8A"
                size="medium"
                label={data.label}
                onDelete={handleDelete(data)}
                onClick={handleChipClick(data)}
                deleteIcon={
                  <CloseRoundedIcon
                    style={{
                      color: "#3D3D3D",
                      fontSize: "medium",
                      fontWeight: "700",
                      borderLeft: "1px solid",
                      marginLeft: "0px",
                      paddingLeft: "5px"
                    }}
                  />
                }
              />
            </Tooltip>
          </div>
        );
      })}
      {props.chipData.length > 0 && (
        <h4
          style={{ fontSize: "12px",
          color: "#3D3D3B",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          cursor: "pointer"
         }}
          onClick={() => handleRemoveAll()}
        >
          Remove All
        </h4>
      )}
    </div>
  );
};

export default FilterChips;
