export const CreateCardIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.6665 14.667V7.07726C4.6665 6.41444 4.90251 5.84703 5.37454 5.37503C5.84654 4.903 6.41395 4.66699 7.07677 4.66699H24.9228C25.5857 4.66699 26.1531 4.903 26.6251 5.37503C27.0971 5.84703 27.3331 6.41444 27.3331 7.07726V14.667H4.6665Z"
      fill="#2A6F2B"
    />
    <path d="M7.07677 27.3337C6.41395 27.3337 5.84654 27.0976 5.37454 26.6256C4.90251 26.1536 4.6665 25.5862 4.6665 24.9234V16.667H13.6665V27.3337H7.07677Z" fill="#2A6F2B" />
    <path d="M15.6665 27.3337V16.667H27.3332V24.9234C27.3332 25.5862 27.0972 26.1536 26.6251 26.6256C26.1531 27.0976 25.5857 27.3337 24.9229 27.3337H15.6665Z" fill="#78C51C" />
  </svg>
);

export const RowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 5.3077V10.5H4V5.3077C4 4.94425 4.12358 4.64557 4.38457 4.38459L4.38459 4.38457C4.64557 4.12358 4.94425 4 5.3077 4H18.6923C19.0557 4 19.3544 4.12358 19.6154 4.38457L19.6154 4.38459C19.8764 4.64557 20 4.94425 20 5.3077Z"
      stroke="black"
    />
    <path d="M4.38459 19.6154L4.38457 19.6154C4.12358 19.3544 4 19.0557 4 18.6923V13H9.75V20H5.3077C4.94425 20 4.64557 19.8764 4.38459 19.6154Z" stroke="black" />
    <path d="M19.6154 19.6154L19.6154 19.6154C19.3544 19.8764 19.0557 20 18.6923 20H12.25V13H20V18.6923C20 19.0557 19.8764 19.3544 19.6154 19.6154Z" stroke="black" />
  </svg>
);

export const BambooBoxIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#78C51C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1412 7.90858C16.6162 7.60339 15.9678 7.60339 15.4428 7.90858L9.58116 11.3162C9.06087 11.6187 8.74075 12.1751 8.74075 12.7769V13.5936H7.0511C6.9178 13.5936 6.80973 13.7017 6.80973 13.835V14.2574C6.80973 14.3907 6.9178 14.4987 7.0511 14.4987H8.19766V14.4986H10.3096V15.4037H8.19766V15.4023H6.02506C5.89176 15.4023 5.78369 15.5104 5.78369 15.6437V16.0661C5.78369 16.1994 5.89176 16.3074 6.02506 16.3074H8.19759V16.3074H11.3354V17.2125H8.74075V17.2129H7.23197C7.09867 17.2129 6.9906 17.321 6.9906 17.4543V17.8767C6.9906 18.01 7.09867 18.1181 7.23197 18.1181H8.74075V18.1199H10.3096V19.025H8.74075V19.6125C8.74075 20.2143 9.06087 20.7707 9.58116 21.0731L15.4428 24.4808C15.9678 24.786 16.6162 24.786 17.1412 24.4808L23.0028 21.0731C23.5231 20.7707 23.8432 20.2143 23.8432 19.6125V12.7769C23.8432 12.1751 23.5231 11.6187 23.0028 11.3162L17.1412 7.90858ZM16.5454 11.298C16.284 11.1471 15.9619 11.1471 15.7006 11.298L12.1404 13.3534C11.879 13.5044 11.718 13.7832 11.718 14.0851V18.196C11.718 18.4978 11.879 18.7767 12.1404 18.9276L15.7006 20.983C15.9619 21.1339 16.284 21.1339 16.5454 20.983L20.1055 18.9276C20.3669 18.7767 20.5279 18.4978 20.5279 18.196V14.0851C20.5279 13.7832 20.3669 13.5044 20.1055 13.3534L16.5454 11.298Z"
      fill="white"
    />
  </svg>
);

export const NoData = () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="66" height="66" rx="10" fill="#F5F5F5" />
    <path
      d="M17 48V46H39V48H17ZM41.0057 34C38.5173 34 36.3942 33.1231 34.6366 31.3692C32.8789 29.6153 32 27.4941 32 25.0057C32 22.5173 32.877 20.3942 34.6308 18.6366C36.3847 16.8789 38.5059 16 40.9943 16C43.4827 16 45.6058 16.877 47.3634 18.6309C49.1212 20.3847 50 22.5059 50 24.9943C50 27.4827 49.1231 29.6058 47.3692 31.3634C45.6153 33.1211 43.4941 34 41.0057 34ZM17 32V30H27.7538C27.8846 30.3744 28.0295 30.7218 28.1885 31.0423C28.3475 31.3628 28.5282 31.6821 28.7308 32H17ZM17 40V38H35.5308C36.0667 38.2359 36.6269 38.4417 37.2116 38.6173C37.7962 38.7929 38.3923 38.9218 39 39.0039V40H17ZM40.2307 30.7693H41.7693V23.6154H40.2307V30.7693ZM41 21.3846C41.2667 21.3846 41.5 21.2846 41.7 21.0846C41.9 20.8846 42 20.6513 42 20.3846C42 20.1179 41.9 19.8846 41.7 19.6846C41.5 19.4846 41.2667 19.3846 41 19.3846C40.7333 19.3846 40.5 19.4846 40.3 19.6846C40.1 19.8846 40 20.1179 40 20.3846C40 20.6513 40.1 20.8846 40.3 21.0846C40.5 21.2846 40.7333 21.3846 41 21.3846Z"
      fill="#3D3D3B"
    />
  </svg>
);

export const CategoryIcon = ({ category }) => {
  // ["Data Quality and Coverage", "Engagement", "Intent", "Potential Opportunity", "Deal", "Customer"]
  let color1 = "#5F41B7";
  let color2 = "#5F41B7";
  let color3 = "#9170EF";
  if (category === "Engagement") {
    color1 = "#E7C03A";
    color2 = "#E7C03A";
    color3 = "rgba(241, 211, 109, 1)";
  } else if (category === "Intent") {
    color1 = "rgba(61, 112, 212, 1)";
    color2 = "rgba(61, 112, 212, 1)";
    color3 = "rgba(117, 160, 244, 1)";
  } else if (category === "Potential Opportunity") {
    color1 = "rgba(205, 21, 21, 1)";
    color2 = "rgba(205, 21, 21, 1)";
    color3 = "rgba(246, 104, 104, 1)";
  } else if (category === "Deal") {
    color1 = "rgba(217, 144, 54, 1)";
    color2 = "rgba(217, 144, 54, 1)";
    color3 = "rgba(235, 180, 113, 1)";
  } else if (category === "Customer") {
    color1 = "rgba(234, 62, 165, 1)";
    color2 = "rgba(234, 62, 165, 1)";
    color3 = "rgba(250, 129, 201, 1)";
  }
  return (
    <svg width="20" height="24" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="23.5" height="19.5" rx="5.75" fill="white" />
      <rect x="0.25" y="0.25" width="23.5" height="19.5" rx="5.75" stroke="#EBEDE8" stroke-width="0.5" />
      <path
        d="M6.3335 9.33398V5.53912C6.3335 5.20771 6.4515 4.924 6.68751 4.688C6.92351 4.45199 7.20722 4.33398 7.53863 4.33398H16.4617C16.7931 4.33398 17.0768 4.45199 17.3128 4.688C17.5488 4.924 17.6668 5.20771 17.6668 5.53912V9.33398H6.3335Z"
        fill={color1}
      />
      <path d="M7.53863 15.6673C7.20722 15.6673 6.92351 15.5493 6.68751 15.3133C6.4515 15.0773 6.3335 14.7936 6.3335 14.4622V10.334H10.8335V15.6673H7.53863Z" fill={color2} />
      <path d="M11.8335 15.6673V10.334H17.6668V14.4622C17.6668 14.7936 17.5488 15.0773 17.3128 15.3133C17.0768 15.5493 16.7931 15.6673 16.4617 15.6673H11.8335Z" fill={color3} />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" fill="white" />
        <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" stroke="#E8E8E8" />
        <path
          d="M14 17.7121C13.8769 17.7121 13.7634 17.6919 13.6593 17.6517C13.5553 17.6114 13.4535 17.5414 13.3539 17.4418L10.3604 14.4483C10.2491 14.337 10.1908 14.2059 10.1857 14.0549C10.1806 13.904 10.2388 13.7656 10.3604 13.6395C10.4864 13.5135 10.6223 13.4494 10.7681 13.4472C10.9139 13.445 11.0498 13.507 11.1758 13.633L13.4286 15.8857V6.57143C13.4286 6.40878 13.4832 6.27289 13.5923 6.16374C13.7015 6.05458 13.8374 6 14 6C14.1626 6 14.2985 6.05458 14.4077 6.16374C14.5168 6.27289 14.5714 6.40878 14.5714 6.57143V15.8857L16.8242 13.633C16.9355 13.5216 17.0678 13.4634 17.2209 13.4582C17.374 13.4531 17.5136 13.5135 17.6396 13.6395C17.7612 13.7656 17.8231 13.9004 17.8253 14.0439C17.8275 14.1875 17.7656 14.3223 17.6396 14.4483L14.6461 17.4418C14.5465 17.5414 14.4447 17.6114 14.3407 17.6517C14.2366 17.6919 14.1231 17.7121 14 17.7121ZM7.84614 22C7.32014 22 6.88095 21.8238 6.52857 21.4714C6.17619 21.119 6 20.6799 6 20.1539V17.9561C6 17.7934 6.05458 17.6575 6.16374 17.5483C6.27289 17.4392 6.40878 17.3846 6.57143 17.3846C6.73406 17.3846 6.86995 17.4392 6.97911 17.5483C7.08828 17.6575 7.14286 17.7934 7.14286 17.9561V20.1539C7.14286 20.3297 7.21611 20.4908 7.36263 20.6374C7.50916 20.7839 7.67033 20.8571 7.84614 20.8571H20.1539C20.3297 20.8571 20.4908 20.7839 20.6374 20.6374C20.7839 20.4908 20.8571 20.3297 20.8571 20.1539V17.9561C20.8571 17.7934 20.9117 17.6575 21.0209 17.5483C21.13 17.4392 21.2659 17.3846 21.4286 17.3846C21.5912 17.3846 21.7271 17.4392 21.8363 17.5483C21.9454 17.6575 22 17.7934 22 17.9561V20.1539C22 20.6799 21.8238 21.119 21.4714 21.4714C21.119 21.8238 20.6799 22 20.1539 22H7.84614Z"
          fill="#3D3D3B"
        />
      </svg>
    </>
  );
};

export const BinIcon = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity=".9"
          d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
          fill="#686868"
        />
      </svg>
    </>
  );
};

export const AddIcon = () => {
  return (
    <>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.4375 9.56246H4.125V8.4375H8.4375V4.125H9.56246V8.4375H13.875V9.56246H9.56246V13.875H8.4375V9.56246Z" fill="black" fill-opacity="0.38" />
      </svg>
    </>
  );
};

export const BackIcon = () => {
  return (
    <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.8248 9.50078L8.7248 14.4008C8.9248 14.6008 9.02064 14.8341 9.01231 15.1008C9.00397 15.3674 8.89981 15.6008 8.69981 15.8008C8.49981 15.9841 8.26647 16.08 7.9998 16.0883C7.73314 16.0966 7.4998 16.0008 7.2998 15.8008C7.2998 15.8008 0.799805 9.30078 0.699805 9.20078C0.599805 9.10078 0.528971 8.99245 0.487305 8.87578C0.445638 8.75912 0.424805 8.63412 0.424805 8.50078C0.424805 8.36745 0.445638 8.24245 0.487305 8.12578C0.528971 8.00912 0.599805 7.90078 0.699805 7.80078L7.2998 1.20078C7.48314 1.01745 7.7123 0.925781 7.9873 0.925781C8.2623 0.925781 8.49981 1.01745 8.69981 1.20078C8.89981 1.40078 8.9998 1.63828 8.9998 1.91328C8.9998 2.18828 8.89981 2.42578 8.69981 2.62578L3.8248 7.50078H14.9998C15.2831 7.50078 15.5206 7.59662 15.7123 7.78828C15.904 7.97995 15.9998 8.21745 15.9998 8.50078C15.9998 8.78412 15.904 9.02162 15.7123 9.21328C15.5206 9.40495 15.2831 9.50078 14.9998 9.50078H3.8248Z"
        fill="#3D3D3B"
      />
    </svg>
  );
};

export const DefaultCardHeaderIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="12" fill="#F3F5F3" />
      <path
        d="M8.7692 24.5771C8.3474 24.5771 7.98875 24.4294 7.69325 24.1338C7.39775 23.8383 7.25 23.4797 7.25 23.0579V12.5963C7.25 12.1746 7.39775 11.8159 7.69325 11.5204C7.98875 11.2249 8.3474 11.0771 8.7692 11.0771H11.25V8.59635C11.25 8.17456 11.3977 7.81591 11.6932 7.5204C11.9887 7.2249 12.3474 7.07715 12.7692 7.07715H19.2308C19.6526 7.07715 20.0112 7.2249 20.3067 7.5204C20.6022 7.81591 20.75 8.17456 20.75 8.59635V15.0771H23.2308C23.6526 15.0771 24.0112 15.2249 24.3067 15.5204C24.6022 15.8159 24.75 16.1746 24.75 16.5963V23.0579C24.75 23.4797 24.6022 23.8383 24.3067 24.1338C24.0112 24.4294 23.6526 24.5771 23.2308 24.5771H17.25V20.5771H14.75V24.5771H8.7692ZM8.74995 23.0772H11.25V20.5771H8.74995V23.0772ZM8.74995 19.0771H11.25V16.5771H8.74995V19.0771ZM8.74995 15.0771H11.25V12.5771H8.74995V15.0771ZM12.7499 19.0771H15.25V16.5771H12.7499V19.0771ZM12.7499 15.0771H15.25V12.5771H12.7499V15.0771ZM12.7499 11.0771H15.25V8.57712H12.7499V11.0771ZM16.75 19.0771H19.25V16.5771H16.75V19.0771ZM16.75 15.0771H19.25V12.5771H16.75V15.0771ZM16.75 11.0771H19.25V8.57712H16.75V11.0771ZM20.75 23.0772H23.25V20.5771H20.75V23.0772ZM20.75 19.0771H23.25V16.5771H20.75V19.0771Z"
        fill="#3D3D3B"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M10.8924 15.4209C6.54476 15.4209 3.02026 11.9696 3.02026 7.71204C3.02026 3.45451 6.54472 0.00317383 10.8924 0.00317383C15.2401 0.00317383 18.7646 3.45455 18.7646 7.71208C18.7646 11.9696 15.2401 15.4209 10.8924 15.4209ZM10.8924 2.20573C7.78693 2.20573 5.26946 4.67099 5.26946 7.71208C5.26946 10.7532 7.78693 13.2184 10.8924 13.2184C13.9979 13.2184 16.5154 10.7532 16.5154 7.71208C16.5154 4.67099 13.9979 2.20573 10.8924 2.20573Z" fill="#595959"/>
                   <path d="M1.89576 17.6234C1.59683 17.6251 1.30953 17.5102 1.09731 17.304C0.656314 16.8757 0.653406 16.1784 1.0908 15.7466C1.09297 15.7445 1.09514 15.7423 1.09731 15.7402L5.32577 11.5994C5.78227 11.1676 6.50983 11.1799 6.95082 11.627C7.39181 12.074 7.37922 12.7865 6.92272 13.2183L2.69422 17.304C2.482 17.5102 2.1947 17.6251 1.89576 17.6234Z" fill="#595959"/>
                   </svg>
  )
}

export const GlobalWithInfo = ()=>{
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0026 19C11.0346 19 10.1246 18.8163 9.27241 18.449C8.42026 18.0816 7.67901 17.583 7.04865 16.9533C6.4183 16.3235 5.91927 15.5829 5.55156 14.7316C5.18385 13.8802 5 12.9706 5 12.0026C5 11.0346 5.18368 10.1246 5.55104 9.27241C5.91841 8.42026 6.41698 7.67901 7.04674 7.04865C7.67651 6.4183 8.41706 5.91927 9.26841 5.55156C10.1198 5.18385 11.0294 5 11.9974 5C12.9654 5 13.8754 5.18368 14.7276 5.55104C15.5797 5.91841 16.321 6.41697 16.9514 7.04674C17.5817 7.67651 18.0807 8.41706 18.4484 9.26841C18.8161 10.1198 19 11.0294 19 11.9974C19 12.9654 18.8163 13.8754 18.449 14.7276C18.0816 15.5797 17.583 16.321 16.9533 16.9514C16.3235 17.5817 15.5829 18.0807 14.7316 18.4484C13.8802 18.8161 12.9706 19 12.0026 19ZM11.2222 18.1833V16.6667C10.7944 16.6667 10.4282 16.5144 10.1236 16.2097C9.81898 15.9051 9.66667 15.5389 9.66667 15.1111V14.3333L5.93333 10.6C5.89444 10.8333 5.8588 11.0667 5.82639 11.3C5.79398 11.5333 5.77778 11.7667 5.77778 12C5.77778 13.5685 6.29306 14.9426 7.32361 16.1222C8.35417 17.3019 9.6537 17.9889 11.2222 18.1833ZM16.5889 16.2C16.8481 15.9148 17.0815 15.6069 17.2889 15.2764C17.4963 14.9458 17.6681 14.6023 17.8042 14.2458C17.9403 13.8894 18.044 13.5231 18.1153 13.1472C18.1866 12.7713 18.2222 12.3889 18.2222 12C18.2222 10.7221 17.8715 9.55506 17.17 8.49885C16.4685 7.44264 15.5229 6.68121 14.3333 6.21454V6.55556C14.3333 6.98333 14.181 7.34954 13.8764 7.65417C13.5718 7.9588 13.2056 8.11111 12.7778 8.11111H11.2222V9.66667C11.2222 9.88704 11.1477 10.0718 10.9986 10.2208C10.8495 10.3699 10.6648 10.4444 10.4444 10.4444H8.88889V12H13.5556C13.7759 12 13.9606 12.0745 14.1097 12.2236C14.2588 12.3727 14.3333 12.5574 14.3333 12.7778V15.1111H15.1111C15.4481 15.1111 15.7528 15.2116 16.025 15.4125C16.2972 15.6134 16.4852 15.8759 16.5889 16.2Z" fill="#3D3D3B"/>
      <path d="M17.0445 9.55897L17.0442 9.55923C17.0318 9.57145 17.0251 9.5742 17.0239 9.57467L17.0239 9.57469L17.0238 9.57471C17.0227 9.57517 17.0164 9.57775 17 9.57775C16.9833 9.57775 16.9771 9.57509 16.9761 9.57469L16.9761 9.57467C16.9749 9.5742 16.9682 9.57145 16.9558 9.55923L16.9555 9.55897C16.9492 9.55273 16.9459 9.54837 16.9443 9.54611C16.9428 9.54397 16.9424 9.54293 16.9423 9.54274L16.9423 9.54273L16.9423 9.54272C16.9422 9.54253 16.9418 9.54166 16.9414 9.53949C16.941 9.53719 16.9402 9.53225 16.9402 9.524C16.9402 9.50855 16.9427 9.50259 16.9434 9.50098C16.9441 9.49915 16.9477 9.4909 16.962 9.47635L16.962 9.47635C16.969 9.4692 16.9741 9.46524 16.977 9.46321C16.9798 9.46125 16.9814 9.46057 16.9819 9.46036C16.9823 9.46016 16.9833 9.45978 16.9854 9.45938C16.9875 9.45895 16.9922 9.45825 17 9.45825C17.0078 9.45825 17.0125 9.45895 17.0146 9.45938C17.0167 9.45978 17.0177 9.46016 17.0181 9.46036C17.0186 9.46057 17.0202 9.46125 17.023 9.46321C17.0259 9.46524 17.031 9.4692 17.038 9.47635L17.038 9.47635C17.0523 9.4909 17.0559 9.49915 17.0566 9.50098C17.0573 9.50259 17.0597 9.50855 17.0597 9.524C17.0597 9.53225 17.059 9.53719 17.0586 9.53949C17.0582 9.54166 17.0578 9.54253 17.0577 9.54272L17.0577 9.54273C17.0576 9.5429 17.0572 9.54394 17.0557 9.54611C17.0541 9.54837 17.0508 9.55273 17.0445 9.55897ZM13.1089 10.8911C14.1781 11.9602 15.4845 12.5 17 12.5C18.5155 12.5 19.8219 11.9602 20.8911 10.8911C21.9602 9.82194 22.5 8.51546 22.5 7C22.5 5.48454 21.9602 4.17806 20.8911 3.10895C19.8219 2.03984 18.5155 1.5 17 1.5C15.4721 1.5 14.1611 2.04292 13.0972 3.12075C12.0367 4.19503 11.5 5.49697 11.5 7C11.5 8.51546 12.0398 9.82194 13.1089 10.8911ZM17.0597 7.42825H16.9402V4.42825H17.0597V7.42825Z" fill="#FFB300" stroke="white"/>
    </svg>
  )
}

export const InformationIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.37565 13.9584H10.6257V9.16671H9.37565V13.9584ZM10.0007 7.74046C10.1913 7.74046 10.3512 7.67594 10.4802 7.54692C10.6093 7.41789 10.6738 7.25803 10.6738 7.06733C10.6738 6.87664 10.6093 6.71678 10.4802 6.58775C10.3512 6.45886 10.1913 6.39442 10.0007 6.39442C9.80996 6.39442 9.6501 6.45886 9.52107 6.58775C9.39204 6.71678 9.32753 6.87664 9.32753 7.06733C9.32753 7.25803 9.39204 7.41789 9.52107 7.54692C9.6501 7.67594 9.80996 7.74046 10.0007 7.74046ZM10.0021 17.9167C8.90711 17.9167 7.87787 17.7089 6.9144 17.2934C5.95093 16.8778 5.11287 16.3139 4.40023 15.6015C3.6876 14.8891 3.12336 14.0514 2.70753 13.0884C2.29183 12.1253 2.08398 11.0964 2.08398 10.0015C2.08398 8.9065 2.29176 7.87726 2.70732 6.91379C3.12287 5.95032 3.68683 5.11226 4.39919 4.39962C5.11155 3.68698 5.94926 3.12275 6.91232 2.70692C7.87537 2.29122 8.90433 2.08337 9.99919 2.08337C11.0942 2.08337 12.1234 2.29115 13.0869 2.70671C14.0504 3.12226 14.8884 3.68622 15.6011 4.39858C16.3137 5.11094 16.8779 5.94865 17.2938 6.91171C17.7095 7.87476 17.9173 8.90372 17.9173 9.99858C17.9173 11.0936 17.7095 12.1228 17.294 13.0863C16.8784 14.0498 16.3145 14.8878 15.6021 15.6005C14.8897 16.3131 14.052 16.8773 13.089 17.2932C12.1259 17.7089 11.097 17.9167 10.0021 17.9167ZM10.0007 16.6667C11.8618 16.6667 13.4382 16.0209 14.7298 14.7292C16.0215 13.4375 16.6673 11.8612 16.6673 10C16.6673 8.13893 16.0215 6.56254 14.7298 5.27087C13.4382 3.97921 11.8618 3.33337 10.0007 3.33337C8.13954 3.33337 6.56315 3.97921 5.27148 5.27087C3.97982 6.56254 3.33398 8.13893 3.33398 10C3.33398 11.8612 3.97982 13.4375 5.27148 14.7292C6.56315 16.0209 8.13954 16.6667 10.0007 16.6667Z" fill="#888888"/>
    </svg>
  )
}

export const ContactCardHeaderIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="12" fill="#F3F5F3"/>
      <path d="M16 15.6923C15.0375 15.6923 14.2135 15.3496 13.5281 14.6642C12.8427 13.9788 12.5 13.1548 12.5 12.1924C12.5 11.2299 12.8427 10.4059 13.5281 9.72051C14.2135 9.03509 15.0375 8.69238 16 8.69238C16.9625 8.69238 17.7864 9.03509 18.4718 9.72051C19.1572 10.4059 19.5 11.2299 19.5 12.1924C19.5 13.1548 19.1572 13.9788 18.4718 14.6642C17.7864 15.3496 16.9625 15.6923 16 15.6923ZM8.5 21.7885V21.0846C8.5 20.5949 8.63302 20.1414 8.89905 19.7241C9.16507 19.3068 9.52051 18.986 9.96537 18.7616C10.9538 18.277 11.951 17.9135 12.9567 17.6712C13.9625 17.4289 14.9769 17.3078 16 17.3078C17.023 17.3078 18.0375 17.4289 19.0432 17.6712C20.049 17.9135 21.0461 18.277 22.0346 18.7616C22.4794 18.986 22.8349 19.3068 23.1009 19.7241C23.3669 20.1414 23.5 20.5949 23.5 21.0846V21.7885C23.5 22.2103 23.3522 22.5689 23.0567 22.8644C22.7612 23.1599 22.4025 23.3077 21.9808 23.3077H10.0192C9.59742 23.3077 9.23877 23.1599 8.94327 22.8644C8.64776 22.5689 8.5 22.2103 8.5 21.7885ZM9.99997 21.8077H22V21.0846C22 20.8821 21.9413 20.6946 21.824 20.5221C21.7067 20.3497 21.5474 20.209 21.3461 20.1C20.4846 19.6757 19.6061 19.3542 18.7107 19.1356C17.8152 18.917 16.9117 18.8077 16 18.8077C15.0883 18.8077 14.1847 18.917 13.2893 19.1356C12.3938 19.3542 11.5154 19.6757 10.6538 20.1C10.4525 20.209 10.2932 20.3497 10.1759 20.5221C10.0586 20.6946 9.99997 20.8821 9.99997 21.0846V21.8077ZM16 14.1924C16.55 14.1924 17.0208 13.9965 17.4125 13.6049C17.8041 13.2132 18 12.7424 18 12.1924C18 11.6424 17.8041 11.1715 17.4125 10.7799C17.0208 10.3882 16.55 10.1924 16 10.1924C15.45 10.1924 14.9791 10.3882 14.5875 10.7799C14.1958 11.1715 14 11.6424 14 12.1924C14 12.7424 14.1958 13.2132 14.5875 13.6049C14.9791 13.9965 15.45 14.1924 16 14.1924Z" fill="#3D3D3B"/>
    </svg>
  )
}

export const TrendIcon = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66537 9.16108L0.875 8.37071L6.01393 3.1943L9.01393 6.1943L13.224 2.0361H11V0.911133H15.125V5.0361H14V2.82646L9.01393 7.81252L6.01393 4.81252L1.66537 9.16108Z" fill="#262626"/>
    </svg>
  )
}

export const RefreshIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.03906 15.5C5.94523 15.5 4.17181 14.7736 2.71881 13.3207C1.26565 11.8679 0.539062 10.0948 0.539062 8.0015C0.539062 5.90817 1.26565 4.13458 2.71881 2.68075C4.17181 1.22692 5.94523 0.5 8.03906 0.5C9.20823 0.5 10.3146 0.759917 11.3583 1.27975C12.4018 1.79975 13.2697 2.53342 13.9621 3.48075V0.5H15.4621V6.61525H9.34681V5.1155H13.2968C12.7698 4.15 12.039 3.38942 11.1043 2.83375C10.1698 2.27792 9.14806 2 8.03906 2C6.3724 2 4.95573 2.58333 3.78906 3.75C2.6224 4.91667 2.03906 6.33333 2.03906 8C2.03906 9.66667 2.6224 11.0833 3.78906 12.25C4.95573 13.4167 6.3724 14 8.03906 14C9.3224 14 10.4807 13.6333 11.5141 12.9C12.5474 12.1667 13.2724 11.2 13.6891 10H15.2698C14.816 11.632 13.9221 12.9567 12.5881 13.974C11.2541 14.9913 9.73773 15.5 8.03906 15.5Z" fill="#3D3D3B"/>
    </svg>
  )
}

export const MoreVertIcon = () => {
  return (
    <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 15.2692C1.5875 15.2692 1.23442 15.1223 0.94075 14.8285C0.646917 14.5348 0.5 14.1817 0.5 13.7692C0.5 13.3567 0.646917 13.0035 0.94075 12.7097C1.23442 12.416 1.5875 12.2692 2 12.2692C2.4125 12.2692 2.76558 12.416 3.05925 12.7097C3.35308 13.0035 3.5 13.3567 3.5 13.7692C3.5 14.1817 3.35308 14.5348 3.05925 14.8285C2.76558 15.1223 2.4125 15.2692 2 15.2692ZM2 9.49996C1.5875 9.49996 1.23442 9.35305 0.94075 9.05921C0.646917 8.76555 0.5 8.41246 0.5 7.99996C0.5 7.58746 0.646917 7.23438 0.94075 6.94071C1.23442 6.64688 1.5875 6.49996 2 6.49996C2.4125 6.49996 2.76558 6.64688 3.05925 6.94071C3.35308 7.23438 3.5 7.58746 3.5 7.99996C3.5 8.41246 3.35308 8.76555 3.05925 9.05921C2.76558 9.35305 2.4125 9.49996 2 9.49996ZM2 3.73071C1.5875 3.73071 1.23442 3.58388 0.94075 3.29021C0.646917 2.99638 0.5 2.64321 0.5 2.23071C0.5 1.81821 0.646917 1.46513 0.94075 1.17146C1.23442 0.87763 1.5875 0.730713 2 0.730713C2.4125 0.730713 2.76558 0.87763 3.05925 1.17146C3.35308 1.46513 3.5 1.81821 3.5 2.23071C3.5 2.64321 3.35308 2.99638 3.05925 3.29021C2.76558 3.58388 2.4125 3.73071 2 3.73071Z" fill="#3D3D3B"/>
    </svg>
  )
}

export const PublicIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00261 14C6.03462 14 5.12456 13.8163 4.27241 13.449C3.42026 13.0816 2.67901 12.583 2.04865 11.9533C1.4183 11.3235 0.919268 10.5829 0.551561 9.7316C0.183854 8.88024 0 7.97058 0 7.00261C0 6.03462 0.183679 5.12456 0.551036 4.27241C0.918407 3.42026 1.41698 2.67901 2.04674 2.04865C2.67651 1.4183 3.41706 0.919269 4.26841 0.551562C5.11976 0.183854 6.02942 0 6.99739 0C7.96538 0 8.87544 0.183679 9.72759 0.551036C10.5797 0.918406 11.321 1.41697 11.9514 2.04674C12.5817 2.67651 13.0807 3.41706 13.4484 4.26841C13.8161 5.11976 14 6.02942 14 6.99739C14 7.96538 13.8163 8.87544 13.449 9.72759C13.0816 10.5797 12.583 11.321 11.9533 11.9514C11.3235 12.5817 10.5829 13.0807 9.7316 13.4484C8.88024 13.8161 7.97058 14 7.00261 14ZM6.22222 13.1833V11.6667C5.79444 11.6667 5.42824 11.5144 5.12361 11.2097C4.81898 10.9051 4.66667 10.5389 4.66667 10.1111V9.33333L0.933333 5.6C0.894444 5.83333 0.858796 6.06667 0.826389 6.3C0.793981 6.53333 0.777778 6.76667 0.777778 7C0.777778 8.56852 1.29306 9.94259 2.32361 11.1222C3.35417 12.3019 4.6537 12.9889 6.22222 13.1833ZM11.5889 11.2C11.8481 10.9148 12.0815 10.6069 12.2889 10.2764C12.4963 9.94583 12.6681 9.60231 12.8042 9.24583C12.9403 8.88935 13.044 8.52315 13.1153 8.14722C13.1866 7.7713 13.2222 7.38889 13.2222 7C13.2222 5.72211 12.8715 4.55506 12.17 3.49885C11.4685 2.44264 10.5229 1.68121 9.33333 1.21454V1.55556C9.33333 1.98333 9.18102 2.34954 8.87639 2.65417C8.57176 2.9588 8.20556 3.11111 7.77778 3.11111H6.22222V4.66667C6.22222 4.88704 6.14769 5.07176 5.99861 5.22083C5.84954 5.36991 5.66481 5.44444 5.44444 5.44444H3.88889V7H8.55556C8.77593 7 8.96065 7.07454 9.10972 7.22361C9.2588 7.37269 9.33333 7.55741 9.33333 7.77778V10.1111H10.1111C10.4481 10.1111 10.7528 10.2116 11.025 10.4125C11.2972 10.6134 11.4852 10.8759 11.5889 11.2Z" fill="#3D3D3B"/>
    </svg>
  )
}

export const MoreHorizontalIcon = () => {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.49519 1.79326V0.668276H13.375V1.79326H3.49519ZM3.49519 4.56246V3.4375H13.375V4.56246H3.49519ZM3.49519 7.33169V6.20671H13.375V7.33169H3.49519ZM1.23078 1.83653C1.05914 1.83653 0.915269 1.77779 0.799169 1.6603C0.683056 1.54281 0.625 1.39723 0.625 1.22356C0.625 1.05397 0.683056 0.911813 0.799169 0.797088C0.915269 0.682363 1.05914 0.625 1.23078 0.625C1.4024 0.625 1.54626 0.682363 1.66236 0.797088C1.77847 0.911813 1.83653 1.05397 1.83653 1.22356C1.83653 1.39723 1.77847 1.54281 1.66236 1.6603C1.54626 1.77779 1.4024 1.83653 1.23078 1.83653ZM1.23078 4.59852C1.05914 4.59852 0.915269 4.54116 0.799169 4.42645C0.683056 4.31173 0.625 4.16957 0.625 3.99998C0.625 3.82114 0.683056 3.67427 0.799169 3.55936C0.915269 3.44446 1.05914 3.38701 1.23078 3.38701C1.4024 3.38701 1.54626 3.44446 1.66236 3.55936C1.77847 3.67427 1.83653 3.82114 1.83653 3.99998C1.83653 4.16957 1.77847 4.31173 1.66236 4.42645C1.54626 4.54116 1.4024 4.59852 1.23078 4.59852ZM1.23078 7.37496C1.05914 7.37496 0.915269 7.31622 0.799169 7.19873C0.683056 7.08124 0.625 6.93566 0.625 6.76199C0.625 6.5924 0.683056 6.45024 0.799169 6.33552C0.915269 6.22079 1.05914 6.16343 1.23078 6.16343C1.4024 6.16343 1.54626 6.22079 1.66236 6.33552C1.77847 6.45024 1.83653 6.5924 1.83653 6.76199C1.83653 6.93566 1.77847 7.08124 1.66236 7.19873C1.54626 7.31622 1.4024 7.37496 1.23078 7.37496Z" fill="black"/>
    </svg>
  )
}