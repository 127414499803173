import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactFusioncharts from 'react-fusioncharts';
import React, {useRef} from 'react';
import { Paper, Typography, withStyles, Tooltip } from '@material-ui/core';
import { useAuthState } from '@bamboobox/b2logincheck';
import FreqToggleTabMarketing from './FreqToggleTabMarketing';
import './TrendChart.css';
import TimeSpanComponent from './TimeSpanComponent';
import { changeFromToDate } from './dateTimeFunctionRepository';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box} from "@material-ui/core";
import UItheme from '@bamboobox/b2-theme';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Skeleton } from "@mui/material";
import {Loader} from '../../../assets/icons/FallBackComponents'

import _ from "lodash";
import { nanoid } from 'nanoid';
// Resolves charts dependancy
charts(FusionCharts);

const OpportunitiesTrend = (props) => {
  const controllerRef = useRef()
  var threeWeeksOldDate = new Date();
  threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 21);
  const [selectedFrequency, setSelectedFrequency] = React.useState('Monthly');
  const [fromDate, setFromDate] = React.useState(threeWeeksOldDate);
  const [toDate, setToDate] = React.useState(new Date());
  const [tabVal, setTabVal] = React.useState(0);
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const isOHRoutingPermissionPresent =  userDetails?.permissions?.filter(res => res?.resourceName === 'unified_account_profile')?.[0]?.['operations']?.some(ops => ops === 'opportunityHub');
  const [category, setCategory] = React.useState([]);
  const [dataset, setDataset] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [dataFromCdp, setDataFromCdp] = React.useState([]);
  const [dataToggle,setDataToggle]=React.useState(0)
  const [revenueFlag,setRevenueFlag]=React.useState(false)
  const [yaxis,setYaxis]=React.useState("NUMBER OF OPPORTUNITIES");
  const [revenueToggle,setRevenueToggle]=React.useState(false);


  // const labels = [];

  function weekAndMonth(date) {
    var month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return `${date.getDate()} ${month[date.getMonth()]}, ${date
      .getFullYear()
      .toString()
      .substring(2, 4)}`;
  }

  function monthAndYear(date) {
    var prefixes = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return `${prefixes[date.getMonth()]}, ${date
      .getFullYear()
      .toString()
      .substring(2, 4)}`;
  }

  function quaterAndYear(date) {
    var prefixes = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];
    return `${prefixes[Math.ceil((date.getMonth() + 1) / 3) - 1]} ${date.getFullYear().toString().substring(2, 4)}`;
}

  const getLabels = (toDate, fromDate) => {
    const datesArray = [];
    const labels=[]
    const no_of_days = (toDate - fromDate) / (1000 * 3600 * 24); 
    datesArray.push(new Date(fromDate).toISOString());
    console.log("freq-test",selectedFrequency)
    if (selectedFrequency === 'Weekly') {   
      const no_of_weeks = Math.ceil(no_of_days / 7);
      console.log('no of weeks', no_of_weeks);
      let i = 1;
      let nextdate = new Date(fromDate);
      while (i <= no_of_weeks) {
        if (i === no_of_weeks) {
          nextdate = new Date(toDate);
        } else {
          nextdate.setDate(nextdate.getDate() + 7);
        }
        if (nextdate > new Date(toDate)) {
          datesArray.push(new Date(nextdate).toISOString());
          labels.push({ label: weekAndMonth(new Date(datesArray[i - 1])) });
          i += 1;
          break;
        }
        datesArray.push(new Date(nextdate).toISOString());
        labels.push({ label: weekAndMonth(new Date(datesArray[i - 1])) });
        i += 1;
      }
      console.log('labels', labels);
    }else if (selectedFrequency === 'Quarterly') {
        let nextdate = new Date(fromDate);
        // datesArray.push(nextdate.toISOString());
           labels.push({ label: quaterAndYear(nextdate) });
        while(nextdate<new Date(toDate)){
            const month=nextdate.getMonth();
            const year=nextdate.getFullYear()
            console.log("checking month",month);
            const quarter=4-((month+1)%3);
            nextdate=new Date(year,month+quarter,1) 
            console.log("testing",nextdate)
            nextdate.setDate(nextdate.getDate() + 1);
            console.log("testing+1",nextdate)
            if(nextdate<=new Date(toDate)){
                datesArray.push(nextdate.toISOString());
              labels.push({ label: quaterAndYear(nextdate) });
            }else{
                let lastDate=new Date(toDate);
                lastDate.setDate(lastDate.getDate()+1)
                datesArray.push(new Date(lastDate).toISOString());
                //labels.push({ label: quaterAndYear(new Date(toDate)) });
            }  
        }
        console.log('labels', labels);
      } 
    else if (selectedFrequency === 'Monthly') {
        let nextdate = new Date(fromDate);
        // datesArray.push(nextdate.toISOString());
           labels.push({ label: monthAndYear(nextdate) });
        while(nextdate<new Date(toDate)){
            const month=nextdate.getMonth();
            const year=nextdate.getFullYear();
            nextdate=new Date(year,month+1,1) 
            console.log("testing",nextdate)
            nextdate.setDate(nextdate.getDate() + 1);
            console.log("testing+1",nextdate)
            if(nextdate<=new Date(toDate)){
                datesArray.push(nextdate.toISOString());
              labels.push({ label: monthAndYear(nextdate) });
            }else{
                let lastDate=new Date(toDate);
                lastDate.setDate(lastDate.getDate()+1)
                datesArray.push(new Date(lastDate).toISOString());
                //datesArray.push(new Date(toDate).toISOString());
              //labels.push({ label: monthAndYear(new Date(toDate)) });
            }  
        }
        console.log('labels', labels);
    }
    setCategory(labels);
    console.log('dates', datesArray);
    return datesArray;
  };

  const createFilter = (dataObj, channel) => {
    let filterArr = [];
    try{
      let dates = dataObj.dateField;
      filterArr = [{
        "attrId": nanoid(),
        "attribute":"opportunityCreatedAt",
        "relation":"between",
        "type":"OPPORTUNITY",
        "values":[dates[1],dates[0]]
        },
      ];
      let sourcedFilter = {
        "attrId": nanoid(),
          "attribute":"opportunitySourced",
          "relation":"includes",
          "type":"OPPORTUNITY",
          "values":["Marketing"]
      }
      if(channel?.toLowerCase() === 'non-marketing'){
        sourcedFilter.values = ["Non-Marketing"];
      }else{
        let channelFilter = {
          "attrId": nanoid(),
          "attribute":"opportunitySourcedChannel",
          "relation":"includes",
          "type":"OPPORTUNITY",
          "values":[channel]
        }

        if(channel == null || channel === 'null'){
          channelFilter.relation = 'is empty';
          channelFilter.values = [];
        }
        filterArr.push(channelFilter);
      }

      filterArr.push(sourcedFilter);
    }catch(e){
      
    }
    
    return filterArr;
  }

  const createChartData = (seriesArray,datesArray,colors,dataFromCdp) => {
    let newDataSet = [];
    for(let index=0; index<seriesArray.length; index++ ){
    // seriesArray.forEach((a, index) => {
      let a = seriesArray[index];
      const temp = [];
      for (let i = 1; i < datesArray.length; i++) {
        let dateField = [datesArray[i] , datesArray[i - 1]]
        let filtered_data=[]
        let count = 0;
        let revenue=0;
        dataFromCdp?.msg?.map((tem) => {
          if (
            (tem?.opportunity_prediction.sourced_channel === a &&
              tem?.opportunity_prediction.sourced === 'Marketing') ||
            (tem?.opportunity_prediction.sourced === 'Non-Marketing' &&
              a === 'Non-Marketing')
          ) {
            //console.log("yes",tem.opportunityCreatedAt,datesArray[i],new Date(tem.opportunityCreatedAt)<=datesArray[i],new Date(tem.opportunityCreatedAt)>=datesArray[i-1]);
            if (
                
              tem?.opportunityCreatedAt <=
                datesArray[i] &&
              tem?.opportunityCreatedAt >
                datesArray[i - 1]
            ) {
              count += 1;
              revenue+=parseInt(tem.opportunity_prediction.revenue)
            }
          }
        });
        if (count === 0) {
          temp.push({ showValue: '0' });
        } else {
            if(revenueFlag){
                temp.push({value:revenue, dateField})
            }else{
                temp.push({ value: count, dateField });
            }
        }
      }
      if (a === null || a === 'null') {
        console.log('Still getting null');
        const color = index > colors.length - 1 ? null : colors[index];
        newDataSet.push({
          seriesname: 'Others',
          color: color,
          data: temp?.map(obj => ({...obj,
          ...(isOHRoutingPermissionPresent && {link: `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
            JSON.stringify([...createFilter(obj, a), ...props.requestBody])
          ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.requestBody)))}/?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.mainTabVal}&tab=opportunity&category=${props?.category}`})}))
        });
      }else if (a==='Sdr Outbound'){
        const color = index > colors.length - 1 ? null : colors[index];
        newDataSet.push({
          seriesname: 'SDR Outbound',
          color: color,
          data: temp?.map(obj => ({...obj,
            ...(isOHRoutingPermissionPresent && {link: `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify([...createFilter(obj, a), ...props.requestBody])
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.requestBody)))}/?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.mainTabVal}&tab=opportunity&category=${props?.category}`})}))
        });
      }
       else {
        const color = index > colors.length - 1 ? null : colors[index];
        //const name = a.slice(0, 1).toUpperCase() + a.slice(1).toLowerCase();
        newDataSet.push({
          seriesname: a,
          color: color,
          data: temp?.map(obj => ({...obj,
            ...(isOHRoutingPermissionPresent && {link: `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify([...createFilter(obj, a), ...props.requestBody])
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.requestBody)))}/?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.mainTabVal}&tab=opportunity&category=${props?.category}`})}))

        });
      }
    }
    // });
    return newDataSet;
  }

  const createSeries = (data, fromDate, lastD) => {
    let series = new Set();
    data?.msg?.forEach((obj) => {
      if (
        typeof obj?.opportunity_prediction?.sourced_channel !==
          'undefined' &&
        obj?.opportunityCreatedAt >=
          new Date(fromDate).toISOString() &&
        obj?.opportunityCreatedAt <
          new Date(lastD).toISOString()
      ) {
        // filtered.push(obj);
        if (obj?.opportunity_prediction?.sourced === 'Non-Marketing') {
          series.add('Non-Marketing');
        } else {
          series.add(obj?.opportunity_prediction?.sourced_channel);
        }
      }
    });
    return series;
  }

  const createColorsArray = () => {
    let colors = [
      "#016B40",
      "#1ABF6E",
      "#92CC38",
      "#C2AE46",
      "#FDC731",
      "#FAA544",
      "#FC693E",
      "#FDA6A5",
      "#D83545",
      "#DE2268",
      "#FD57BA",
      "#F899EC",
      "#9247D3",
      "#6D41C6",
    ];
    if(process.env.REACT_APP_COLORS){
      const colFromEnv=process.env.REACT_APP_COLORS.split(',');
      const colorArray=colFromEnv.map((color)=>{
          color='#'+color;
          return color
      })
      console.log("colors",colorArray);
      if(colorArray?.length>0){
          colors=colorArray;
      }
    }
    return colors;
  }

  React.useEffect(() => {
    setIsLoading(true);
    setDataset([]);
    console.log('selected', selectedFrequency, fromDate, toDate, tabVal,new Date(fromDate).toISOString(),new Date(toDate).toISOString());
    const datesArray = getLabels(toDate, fromDate);
    let series = new Set();
    const filtered = [];
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    if(props.icpSelected !== null && props.segmentName !== '') {
      fetch(
      `${window._env_.UNIFIED_URL}/analytics/opportunities/${tenantId}/${props.icpSelected}/${props.segmentName}?filterName=${props.segmentName}`,
      {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"filters": props.requestBody}),
        signal:controllerRef.current?.signal,
        credentials: 'include'
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDataFromCdp(data);
        let lastD=new Date(toDate);
        lastD.setDate(lastD.getDate()+1);
        series = createSeries(data, fromDate, lastD);
        const seriesArray = [...series];
        let colors = createColorsArray();
        let newdataset = createChartData(seriesArray, datesArray, colors, data)
        setDataset(newdataset);
        setIsLoading(false);
      }).catch((error)=>{
        if(error?.message === "The user aborted a request.") return;
      })
    }
  }, [props.requestBody, props.segmentName, props.icpSelected,props.softRefresh,
    props.refresh]);

  React.useEffect(() => {
    setIsLoading(true);
    setDataset([]);
    const datesArray = getLabels(toDate, fromDate);
    let series = new Set();
    let lastD=new Date(toDate);
    lastD.setDate(lastD.getDate()+1);
    series = createSeries(dataFromCdp, fromDate, lastD);
    const seriesArray = Array.from(series);
    let colors = createColorsArray()
    let newDataSet = createChartData(seriesArray, datesArray, colors, dataFromCdp);
    setDataset(newDataSet);
    setIsLoading(false);
  }, [fromDate, toDate, selectedFrequency,dataToggle]);

  const handleChange=(e,newValue)=>{
    setDataToggle(newValue)
    if(newValue === 1){
        setRevenueFlag(true)
        setYaxis("REVENUE")
    }else{
        setRevenueFlag(false)
        setYaxis("NO OF OPPORTUNITIES")
    }
    
  }

  const dataSource = {
    chart: {
        showsum: '1',
      plottooltext: '$seriesName $dataValue',
      showPercentValues: '1',
      theme: 'fusion',
      plotSpacePercent: '65',
      canvasLeftPadding: 50,
      canvasRightPaddding: 40,
      canvasTopPadding:30,
    //   chartTopMargin:20,
      yAxisName: yaxis,
      xAxisName: 'TIME',
      legendItemFontBold: 1,
      drawCustomLegendIcon: 1,
      legendIconSides: 4,
      legendIconStartAngle: 45,
      legendIconScale: 0,
      divLineDashed: 1,
      showZeroPlaneValue: 1,
      xAxisNameFontColor : "#5E5454",
                    xAxisNameFontSize:"9px",
                    yAxisNameFontColor : "#5E5454",
                    yAxisNameFontSize:"9px", // Optional: Set font color
                    xAxisValueFontColor: '#5E5454', // Optional: Set font color
                    yAxisValueFontColor: '#5E5454', // Optional: Set font color 
                    xAxisNameFontBold:"0",
                    yAxisNameFontBold:"0",
                    "yAxisValueFont": "Open Sans",
                    "yAxisValueFontSize": "9px",
                    "yAxisValueFontColor": "#5F6B7A",
                    "xAxisFont": "Inter",
                    "xAxisFontSize": "10px",
                    "xAxisFontColor": "#ff0000",
                    "baseFont": "Open Sans",
                    "baseFontSize": "9px",
                    "baseFontColor": "#5F6B7A",
    //   showsum: '1',
    //   plottooltext: '$seriesName $dataValue',
    // //   showPercentValues: '1',
    //   theme: 'fusion',
    //  // theme: "fusion",
    //  drawcrossline: "1",
    // plotSpacePercent: '70',
    // //   canvasLeftPaddding:'100',
    // //   canvasRightPaddding: 40,
    // //   canvasTopPadding:30,
    // //   chartLeftMargin:30,
    // //   chartTopMargin:20,
    // canvasLeftPadding: 50,
    // //minPlotHeightForValue:'50px',
    //   canvasRightPaddding: 40,
    //   canvasTopPadding:30,
    //   yAxisName: yaxis,
    //   xAxisName: 'TIME',
    //   legendItemFontBold: 1,
    //   drawCustomLegendIcon: 1,
    //   legendIconSides: 4,
    //   legendIconStartAngle: 45,
    //   legendIconScale: 0,
    //   divLineDashed: 1,
    //   numvisibleplot: "8",
    //   showZeroPlaneValue: 1
    },
    categories: [
      {
        category: category
      }
    ],
    dataset: dataset
  };
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'rgb(236, 247, 254)',
      color: UItheme.palette.primary.dark,
      boxShadow: theme.shadows[1],
      fontSize: 11
    }
  }))(Tooltip);


  React.useEffect(async () => {
    const tool = 'marketing_sourced_scheduler';
    const res = await fetch(`${window._env_.TENANT_CONFIG}/tenant/get-tenant-config-json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        tenantId,
        tool,
        keys: ['details']
      })
    });
    const data = await res.json();
    console.log(
      '////////////////',
      data,
      data?.data?.configs[0]?.configVal?.toShow
    );
    if (data.success) {
      setRevenueToggle(data?.data?.configs[0]?.configVal?.showRevenue);
    }
  }, []);


  return (
    <div
      className="chart-container-trend"
      style={{margin:"10px"}}
    >
      <div
        className="functionality-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
            marginLeft: 'auto'
          }}
        >
          {/* <FunnelLvlSelector style={{marginRight:"10px"}}  tabVal={tabVal} setStagesToBeSent={props.setStagesToBeSent} setStagesToBeSentFrConversionTrend={setStagesToBeSentFrConversionTrend} /> */}
          {revenueToggle?<div>
              <Box sx={{ maxWidth: "70vw", borderBottomWidth:'0.2px', borderBottomColor: "rgb(211, 211, 211)"}}>
                <Tabs 
                style={{ borderBottom:'1px solid #D3D3D3', color:"#595959",}}
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#59CB59",
                    border: "2px solid #59CB59",
                    }
                  }}
                  //position
                  value={dataToggle}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                variant="standard"
                >
                     <Tab style={{ textTransform: 'none', fontSize: "12px", color: '#737373', fontWeight: 500 }} label="Opportunities" />
                     <Tab style={{ textTransform: 'none', fontSize: "12px", color: '#737373', fontWeight: 500 }} label="Revenue" />
                </Tabs>
                </Box>
              </div>:<></>}
          
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginLeft: 'auto'
            }}
          >
            <FreqToggleTabMarketing
              tabVal={tabVal}
              setTabVal={setTabVal}
              showQuarterly = {true} 
              showWeekly={'enable'}
              selectedFrequency={selectedFrequency}
              setSelectedFrequency={setSelectedFrequency}
              fromDate={
                changeFromToDate(fromDate, toDate, selectedFrequency)[0]
              }
              toDate={changeFromToDate(fromDate, toDate, selectedFrequency)[1]}
            ></FreqToggleTabMarketing>
            <TimeSpanComponent
              frequencySelected={selectedFrequency}
              setToDate={setToDate}
              setFromDate={setFromDate}
              enableToRange={true}
              fromDate={fromDate} toDate={toDate}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
       <Loader  />
       ) : (
        <ReactFusioncharts
          type="scrollstackedcolumn2d"
          width="98%"
          height="300"
          dataFormat="JSON"
          dataLoadStartMessage="Retrieving data Please wait"
          dataEmptyMessage="No data to display"
          dataSource={dataSource}
        />
      )}
    </div>
  );
};

export default OpportunitiesTrend;
