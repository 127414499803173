let ACCOUNT_CATEGORY = [
  {
    title: "Employee Count",
    value: "Employee Count",
    fieldName: "numOfEmployees",
    label: "",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Industry",
    value: "Industry",
    fieldName: "industry",
    label: "",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Sub Industry",
    value: "Sub Industry",
    fieldName: "subIndustry",
    label: "",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Account Classification",
    fieldName: "accountClassification",
    label: "",
    value: "Account Classification",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Geography",
    fieldName: "geography",
    label: "",
    value: "Geography",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Company Type",
    fieldName: "companyType",
    label: "",
    value: "Company Type",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Company Hierarchy",
    fieldName: "companyHierarchy",
    label: "",
    value: "Company Hierarchy",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Account Region",
    fieldName: "accountRegion",
    label: "",
    value: "Account Region",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Number of Contacts",
    fieldName: "noOfContact",
    label: "",
    value: "Number of Contacts",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Number of Stores",
    fieldName: "numOfStores",
    label: "",
    value: "Number of Stores",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  // { title: "Account Source",value: "Account Source", groupName: "ACCOUNT", type: "ACCOUNT" },
  {
    title: "Company Name",
    fieldName: "accountName",
    label: "",
    value: "Account Name",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Company Country",
    fieldName: "country",
    label: "",
    value: "Company Country",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "State",
    fieldName: "state",
    label: "",
    value: "State",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "City",
    fieldName: "city",
    label: "",
    value: "City",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Company Phone",
    fieldName: "phone",
    label: "",
    value: "Company Phone",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Brand Revenue Number",
    fieldName: "annualRevenue",
    label: "",
    value: "Annual Revenue",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Business Format",
    fieldName: "businessFormat",
    label: "",
    value: "Business Format",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Group Company Name",
    fieldName: "groupName",
    label: "",
    value: "Group Name",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Parent Company Name",
    fieldName: "parentAccountName",
    label: "",
    value: "Parent Account Name",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Parent Website",
    fieldName: "parentWebsite",
    label: "",
    value: "Parent Website",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Group Website",
    fieldName: "groupWebsite",
    label: "",
    value: "Group Website",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Account Website",
    fieldName: "accountWebsite",
    label: "",
    value: "Account Website",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Account Type",
    fieldName: "accountType",
    label: "",
    value: "Account Type",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "B2 Account Stage",
    fieldName: "b2AccountStage",
    label: "",
    value: "B2 Account Stage",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Crm Account Stage",
    fieldName: "crmAccountStage",
    label: "",
    value: "Crm Account Stage",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Facebook URL",
    fieldName: "fbURL",
    label: "",
    value: "Facebook URL",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "LinkedIn URL",
    fieldName: "linkedInURL",
    label: "",
    value: "LinkedIn URL",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Twitter URL",
    fieldName: "twitterURL",
    label: "",
    value: "Twitter URL",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Extra 1",
    fieldName: "extra1",
    label: "",
    value: "Extra 1",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Extra 2",
    fieldName: "extra2",
    label: "",
    value: "Extra 2",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },

  {
    title: "Activity Type",
    fieldName: "activityType",
    label: "",
    value: "Activity Type",
    groupName: "ACCOUNT",
    type: "ACTIVITY",
  },
  {
    title: "Campaign Name",
    fieldName: "programName",
    label: "",
    value: "Campaign Name",
    groupName: "ACCOUNT",
    type: "CAMPAIGN",
  },
  {
    title: "Event Date",
    fieldName: "eventDate",
    label: "",
    value: "Event Date",
    groupName: "ACCOUNT",
    type: "ACTIVITY",
  },
  {
    title: "Event Count",
    fieldName: "eventCount",
    label: "",
    value: "Event Count",
    groupName: "ACCOUNT",
    type: "ACTIVITY",
  },
  {
    title: "Account Engagement Level",
    fieldName: "accountEngagementLevel",
    label: "",
    groupName: "ACCOUNT",
    value: "Account Engagement Level",
    type: "ACCOUNT",
  },
  {
    title: "Icp Level",
    fieldName: "icpLevel",
    label: "",
    groupName: "ACCOUNT",
    value: "ICP Level",
    type: "ACCOUNT",
  },

  {
    title: "FS Executive",
    fieldName: "fieldSalesExec",
    label: "",
    value: "FS Executive",
    groupName: "ASSIGNED TO",
    type: "ACCOUNT",
  },
  {
    title: "IS Executive",
    fieldName: "ISExec",
    label: "",
    value: "IS Executive",
    groupName: "ASSIGNED TO",
    type: "ACCOUNT",
  },
  {
    title: "Account Owner",
    fieldName: "accountOwner",
    label: "",
    value: "Account Owner",
    groupName: "ASSIGNED TO",
    type: "ACCOUNT",
  },

  {
    title: "Account Created Date",
    fieldName: "accCreatedAt",
    label: "",
    groupName: "DATE",
    value: "Account Created Date",
    type: "ACCOUNT",
  },
  {
    title: "First Activity Date",
    fieldName: "firstActivityDate",
    label: "",
    groupName: "DATE",
    value: "First Activity Date",
    type: "ACTIVITY",
  },
  {
    title: "Last Activity Date",
    fieldName: "lastActivityDate",
    label: "",
    groupName: "DATE",
    value: "Last Activity Date",
    type: "ACTIVITY",
  },
  {
    title: "First Engagement Date",
    fieldName: "firstEngDate",
    label: "",
    groupName: "DATE",
    value: "First Engagement Date",
    type: "ACTIVITY",
  },
  {
    title: "Last Engagement Date",
    fieldName: "lastEngDate",
    label: "",
    groupName: "DATE",
    value: "Last Engagement Date",
    type: "ACTIVITY",
  },
  {
    title: "Account Updated Date",
    fieldName: "accUpdatdAt",
    label: "",
    groupName: "DATE",
    value: "Account Updated Date",
    type: "ACCOUNT",
  },

  {
    title: "Account Score",
    fieldName: "accountScore",
    label: "",
    value: "Account Score",
    groupName: "SCORE",
    type: "ACCOUNT",
  },
  {
    title: "Engagement Depth",
    fieldName: "engagementDepth",
    label: "",
    value: "Engagement Depth",
    groupName: "SCORE",
    type: "ACCOUNT",
  },
  {
    title: "Engagement Score",
    fieldName: "engagementScore",
    label: "",
    value: "Engagement Score",
    groupName: "SCORE",
    type: "ACCOUNT",
  },
  {
    title: "Engagement Width",
    fieldName: "engagementWidth",
    label: "",
    value: "Engagement Width",
    groupName: "SCORE",
    type: "ACCOUNT",
  },
  {
    title: "ICP Score",
    fieldName: "icpScores",
    label: "",
    value: "ICP Score",
    groupName: "SCORE",
    type: "ACCOUNT",
  },

  {
    title: "Last Intent Date",
    fieldName: "last_intent_date",
    label: "",
    value: "Intent Creation Date",
    groupName: "INTENT",
    type: "INTENT",
  },
  {
    title: "Intent Topics",
    fieldName: "topics_data",
    label: "",
    value: "Intent Topics",
    groupName: "INTENT",
    type: "INTENT",
  },
  {
    title: "Intent Level",
    fieldName: "b2_intent_level",
    label: "",
    value: "Intent level",
    groupName: "INTENT",
    type: "INTENT",
  },
  {
    title: "Buyer Group",
    fieldName: "buyerGroup",
    label: "",
    value: "BUYER GROUP",
    groupName: "BUYER",
    type: "multiple",
  },
  {
    title: "Target QTR",
    fieldName: "Target_QTR",
    label: "",
    value: "Target QTR",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Product Name",
    fieldName: "productName",
    label: "",
    value: "Product Name",
    groupName: "ACCOUNT",
    type: "PRODUCT",
  },
  {
    title: "Event Name",
    fieldName: "eventName",
    label: "",
    value: "Event Name",
    groupName: "ACCOUNT",
    type: "ACTIVITYATTRIBUTES",
  },

  {
    title: "Account opportunityName",
    fieldName: "Account opportunityName",
    label: "",
    value: "Account opportunityName",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account products",
    fieldName: "Account products",
    label: "",
    value: "Account products",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account services",
    fieldName: "Account services",
    label: "",
    value: "Account services",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityCreatedAt",
    fieldName: "Account opportunityCreatedAt",
    label: "",
    value: "Account opportunityCreatedAt",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityUpdatdAt",
    fieldName: "Account opportunityUpdatdAt",
    label: "",
    value: "Account opportunityUpdatdAt",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityCloseDate",
    fieldName: "Account opportunityCloseDate",
    label: "",
    value: "Account opportunityCloseDate",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityStage",
    fieldName: "Account opportunityStage",
    label: "",
    value: "Account opportunityStage",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account associatedContacts",
    fieldName: "Account associatedContacts",
    label: "",
    value: "Account associatedContacts",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account preSalesExec",
    fieldName: "Account preSalesExec",
    label: "",
    value: "Account preSalesExec",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account leadSource",
    fieldName: "Account leadSource",
    label: "",
    value: "Account leadSource",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account setupFee",
    fieldName: "Account setupFee",
    label: "",
    value: "Account setupFee",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account monthlyRecurringRevenue",
    fieldName: "Account monthlyRecurringRevenue",
    label: "",
    value: "Account monthlyRecurringRevenue",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account annualRecurringRevenue",
    fieldName: "Account annualRecurringRevenue",
    label: "",
    value: "Account annualRecurringRevenue",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account productARR",
    fieldName: "Account productARR",
    label: "",
    value: "Account productARR",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account servicesARR",
    fieldName: "Account servicesARR",
    label: "",
    value: "Account servicesARR",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account totalARR",
    fieldName: "Account totalARR",
    label: "",
    value: "Account totalARR",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  //TODO: more filters to add. need clarification first.
  /*
  { title: "opportunity_prediction", fieldName: 'Account opportunity_prediction', label: '', value: "Account opportunity_prediction", groupName: "OPPORTUNITIES", type: "OPPORTUNITIES" },
  { title: "opportunity_marketing_influenced", fieldName: 'Account opportunity_marketing_influenced', label: '', value: "Account opportunity_marketing_influenced", groupName: "OPPORTUNITIES", type: "OPPORTUNITIES" },
  */

  {
    title: "Account opportunityOwner",
    fieldName: "Account opportunityOwner",
    label: "",
    value: "Account opportunityOwner",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account nextStep",
    fieldName: "Account nextStep",
    label: "",
    value: "Account nextStep",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account amount",
    fieldName: "Account amount",
    label: "",
    value: "Account amount",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account probability",
    fieldName: "Account probability",
    label: "",
    value: "Account probability",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityType",
    fieldName: "Account opportunityType",
    label: "",
    value: "Account opportunityType",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityWeightedAmount",
    fieldName: "Account opportunityWeightedAmount",
    label: "",
    value: "Account opportunityWeightedAmount",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityAge",
    fieldName: "Account opportunityAge",
    label: "",
    value: "Account opportunityAge",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunityStatus",
    fieldName: "Account opportunityStatus",
    label: "",
    value: "Account opportunityStatus",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account opportunitySource",
    fieldName: "Account opportunitySource",
    label: "",
    value: "Account opportunitySource",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account closedWonReason",
    fieldName: "Account closedWonReason",
    label: "",
    value: "Account closedWonReason",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account closedLostReason",
    fieldName: "Account closedLostReason",
    label: "",
    value: "Account closedLostReason",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account pipeline",
    fieldName: "Account pipeline",
    label: "",
    value: "Account pipeline",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account pipelineAmount",
    fieldName: "Account pipelineAmount",
    label: "",
    value: "Account pipelineAmount",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account currency",
    fieldName: "Account currency",
    label: "",
    value: "Account currency",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  {
    title: "Account oppString1",
    fieldName: "Account oppString1",
    label: "",
    value: "Account oppString1",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString2",
    fieldName: "Account oppString2",
    label: "",
    value: "Account oppString2",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString3",
    fieldName: "Account oppString3",
    label: "",
    value: "Account oppString3",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString4",
    fieldName: "Account oppString4",
    label: "",
    value: "Account oppString4",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString5",
    fieldName: "Account oppString5",
    label: "",
    value: "Account oppString5",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString6",
    fieldName: "Account oppString6",
    label: "",
    value: "Account oppString6",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString7",
    fieldName: "Account oppString7",
    label: "",
    value: "Account oppString7",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString8",
    fieldName: "Account oppString8",
    label: "",
    value: "Account oppString8",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString9",
    fieldName: "Account oppString9",
    label: "",
    value: "Account oppString9",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString11",
    fieldName: "Account oppString11",
    label: "",
    value: "Account oppString11",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString12",
    fieldName: "Account oppString12",
    label: "",
    value: "Account oppString12",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString13",
    fieldName: "Account oppString13",
    label: "",
    value: "Account oppString13",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString14",
    fieldName: "Account oppString14",
    label: "",
    value: "Account oppString14",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppString15",
    fieldName: "Account oppString15",
    label: "",
    value: "Account oppString15",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  {
    title: "Account oppPicklist1",
    fieldName: "Account oppPicklist1",
    label: "",
    value: "Account oppPicklist1",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist2",
    fieldName: "Account oppPicklist2",
    label: "",
    value: "Account oppPicklist2",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist3",
    fieldName: "Account oppPicklist3",
    label: "",
    value: "Account oppPicklist3",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist4",
    fieldName: "Account oppPicklist4",
    label: "",
    value: "Account oppPicklist4",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist5",
    fieldName: "Account oppPicklist5",
    label: "",
    value: "Account oppPicklist5",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist6",
    fieldName: "Account oppPicklist6",
    label: "",
    value: "Account oppPicklist6",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist7",
    fieldName: "Account oppPicklist7",
    label: "",
    value: "Account oppPicklist7",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist8",
    fieldName: "Account oppPicklist8",
    label: "",
    value: "Account oppPicklist8",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist9",
    fieldName: "Account oppPicklist9",
    label: "",
    value: "Account oppPicklist9",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist10",
    fieldName: "Account oppPicklist10",
    label: "",
    value: "Account oppPicklist10",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist11",
    fieldName: "Account oppPicklist11",
    label: "",
    value: "Account oppPicklist11",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist12",
    fieldName: "Account oppPicklist12",
    label: "",
    value: "Account oppPicklist12",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist13",
    fieldName: "Account oppPicklist13",
    label: "",
    value: "Account oppPicklist13",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist14",
    fieldName: "Account oppPicklist14",
    label: "",
    value: "Account oppPicklist14",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppPicklist15",
    fieldName: "Account oppPicklist15",
    label: "",
    value: "Account oppPicklist15",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  {
    title: "Account oppDate1",
    fieldName: "Account oppDate1",
    label: "",
    value: "Account oppDate1",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate2",
    fieldName: "Account oppDate2",
    label: "",
    value: "Account oppDate2",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate3",
    fieldName: "Account oppDate3",
    label: "",
    value: "Account oppDate3",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate4",
    fieldName: "Account oppDate4",
    label: "",
    value: "Account oppDate4",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate5",
    fieldName: "Account oppDate5",
    label: "",
    value: "Account oppDate5",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate6",
    fieldName: "Account oppDate6",
    label: "",
    value: "Account oppDate6",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate7",
    fieldName: "Account oppDate7",
    label: "",
    value: "Account oppDate7",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate8",
    fieldName: "Account oppDate8",
    label: "",
    value: "Account oppDate8",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate9",
    fieldName: "Account oppDate9",
    label: "",
    value: "Account oppDate9",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate10",
    fieldName: "Account oppDate10",
    label: "",
    value: "Account oppDate10",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate11",
    fieldName: "Account oppDate11",
    label: "",
    value: "Account oppDate11",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate12",
    fieldName: "Account oppDate12",
    label: "",
    value: "Account oppDate12",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate13",
    fieldName: "Account oppDate13",
    label: "",
    value: "Account oppDate13",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate14",
    fieldName: "Account oppDate14",
    label: "",
    value: "Account oppDate14",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppDate15",
    fieldName: "Account oppDate15",
    label: "",
    value: "Account oppDate15",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  {
    title: "Account oppNumber1",
    fieldName: "Account oppNumber1",
    label: "",
    value: "Account oppNumber1",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber2",
    fieldName: "Account oppNumber2",
    label: "",
    value: "Account oppNumber2",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber3",
    fieldName: "Account oppNumber3",
    label: "",
    value: "Account oppNumber3",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber4",
    fieldName: "Account oppNumber4",
    label: "",
    value: "Account oppNumber4",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber5",
    fieldName: "Account oppNumber5",
    label: "",
    value: "Account oppNumber5",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber6",
    fieldName: "Account oppNumber6",
    label: "",
    value: "Account oppNumber6",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber7",
    fieldName: "Account oppNumber7",
    label: "",
    value: "Account oppNumber7",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber8",
    fieldName: "Account oppNumber8",
    label: "",
    value: "Account oppNumber8",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber9",
    fieldName: "Account oppNumber9",
    label: "",
    value: "Account oppNumber9",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber10",
    fieldName: "Account oppNumber10",
    label: "",
    value: "Account oppNumber10",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber11",
    fieldName: "Account oppNumber11",
    label: "",
    value: "Account oppNumber11",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber12",
    fieldName: "Account oppNumber12",
    label: "",
    value: "Account oppNumber12",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber13",
    fieldName: "Account oppNumber13",
    label: "",
    value: "Account oppNumber13",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber14",
    fieldName: "Account oppNumber14",
    label: "",
    value: "Account oppNumber14",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber15",
    fieldName: "Account oppNumber15",
    label: "",
    value: "Account oppNumber15",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber16",
    fieldName: "Account oppNumber16",
    label: "",
    value: "Account oppNumber16",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber17",
    fieldName: "Account oppNumber17",
    label: "",
    value: "Account oppNumber17",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber18",
    fieldName: "Account oppNumber18",
    label: "",
    value: "Account oppNumber18",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber19",
    fieldName: "Account oppNumber19",
    label: "",
    value: "Account oppNumber19",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber20",
    fieldName: "Account oppNumber20",
    label: "",
    value: "Account oppNumber20",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber21",
    fieldName: "Account oppNumber21",
    label: "",
    value: "Account oppNumber21",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber22",
    fieldName: "Account oppNumber22",
    label: "",
    value: "Account oppNumber22",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber23",
    fieldName: "Account oppNumber23",
    label: "",
    value: "Account oppNumber23",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber24",
    fieldName: "Account oppNumber24",
    label: "",
    value: "Account oppNumber24",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppNumber25",
    fieldName: "Account oppNumber25",
    label: "",
    value: "Account oppNumber25",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  {
    title: "Account oppBoolean1",
    fieldName: "Account oppBoolean1",
    label: "",
    value: "Account oppBoolean1",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppBoolean2",
    fieldName: "Account oppBoolean2",
    label: "",
    value: "Account oppBoolean2",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppBoolean3",
    fieldName: "Account oppBoolean3",
    label: "",
    value: "Account oppBoolean3",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppBoolean4",
    fieldName: "Account oppBoolean4",
    label: "",
    value: "Account oppBoolean4",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppBoolean5",
    fieldName: "Account oppBoolean5",
    label: "",
    value: "Account oppBoolean5",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  {
    title: "Account oppMoney1",
    fieldName: "Account oppMoney1",
    label: "",
    value: "Account oppMoney1",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney2",
    fieldName: "Account oppMoney2",
    label: "",
    value: "Account oppMoney2",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney3",
    fieldName: "Account oppMoney3",
    label: "",
    value: "Account oppMoney3",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney4",
    fieldName: "Account oppMoney4",
    label: "",
    value: "Account oppMoney4",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney5",
    fieldName: "Account oppMoney5",
    label: "",
    value: "Account oppMoney5",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney6",
    fieldName: "Account oppMoney6",
    label: "",
    value: "Account oppMoney6",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney7",
    fieldName: "Account oppMoney7",
    label: "",
    value: "Account oppMoney7",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney8",
    fieldName: "Account oppMoney8",
    label: "",
    value: "Account oppMoney8",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney9",
    fieldName: "Account oppMoney9",
    label: "",
    value: "Account oppMoney9",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account oppMoney10",
    fieldName: "Account oppMoney10",
    label: "",
    value: "Account oppMoney10",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },

  {
    title: "Account stageUpdateDate",
    fieldName: "Account stageUpdateDate",
    label: "",
    value: "Account stageUpdateDate",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account stageAge",
    fieldName: "Account stageAge",
    label: "",
    value: "Account stageAge",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account sourcedChannel",
    fieldName: "Account sourcedChannel",
    label: "",
    value: "Account sourcedChannel",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "Account influencedChannels",
    fieldName: "Account influencedChannels",
    label: "",
    value: "Account influencedChannels",
    groupName: "OPPORTUNITIES",
    type: "OPPORTUNITIES",
  },
  {
    title: "totalValue",
    fieldName: "totalValue",
    label: "",
    value: "totalValue",
    groupName: "OPPORTUNITIES",
    type: "ACCOUNT_OPPORTUNITY_AGGR",
  },
  {
    title: "productsOfLOpps",
    fieldName: "productsOfLOpps",
    label: "",
    value: "productsOfLOpps",
    groupName: "OPPORTUNITIES",
    type: "ACCOUNT_OPPORTUNITY_AGGR",
  },
  {
    title: "icpsOfClosedOppsCrossSell",
    fieldName: "icpsOfClosedOppsCrossSell",
    label: "",
    value: "icpsOfClosedOppsCrossSell",
    groupName: "OPPORTUNITIES",
    type: "ACCOUNT_OPPORTUNITY_AGGR",
  },
  {
    title: "stagesOfLOpps",
    fieldName: "stagesOfLOpps",
    label: "",
    value: "stagesOfLOpps",
    groupName: "OPPORTUNITIES",
    type: "ACCOUNT_OPPORTUNITY_AGGR",
  },

  {
    title: "extra3",
    fieldName: "extra3",
    label: "",
    value: "extra3",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "extra4",
    fieldName: "extra4",
    label: "",
    value: "extra4",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "extra5",
    fieldName: "extra5",
    label: "",
    value: "extra5",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "extra6",
    fieldName: "extra6",
    label: "",
    value: "extra6",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "extra7",
    fieldName: "extra7",
    label: "",
    value: "extra7",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "extra8",
    fieldName: "extra8",
    label: "",
    value: "extra8",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Omnichannel",
    fieldName: "omniChannel",
    label: "",
    value: "Omnichannel",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Top Target Account",
    fieldName: "topTargetAccount",
    label: "",
    value: "Top Target Account",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "ABM Account (Account)",
    fieldName: "abmaccount",
    label: "",
    value: "ABM Account",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "Intent Country",
    fieldName: "country_data_aggr",
    label: "",
    value: "Intent Country",
    groupName: "INTENT",
    type: "INTENT",
  },
  {
    title: "Account Labels",
    fieldName: "labels",
    label: "",
    value: "Account Labels",
    groupName: "LABEL",
    type: "LABEL",
  },
  {
    title: "Visited Page",
    fieldName: "visitedpage",
    label: "",
    value: "Visited Page",
    groupName: "WEB ACCOUNTS",
    type: "WEB ACCOUNTS",
  },
  {
    title: "accNumber1",
    fieldName: "accNumber1",
    label: "",
    value: "accNumber1",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "accNumber2",
    fieldName: "accNumber2",
    label: "",
    value: "accNumber2",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "accNumber3",
    fieldName: "accNumber3",
    label: "",
    value: "accNumber3",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "accNumber4",
    fieldName: "accNumber4",
    label: "",
    value: "accNumber4",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "accNumber5",
    fieldName: "accNumber5",
    label: "",
    value: "accNumber5",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "accNumber6",
    fieldName: "accNumber6",
    label: "",
    value: "accNumber6",
    groupName: "ACCOUNT",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist1",
    fieldName: "accPicklist1",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist1",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist2",
    fieldName: "accPicklist2",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist2",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist3",
    fieldName: "accPicklist3",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist3",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist4",
    fieldName: "accPicklist4",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist4",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist5",
    fieldName: "accPicklist5",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist5",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist6",
    fieldName: "accPicklist6",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist6",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist7",
    fieldName: "accPicklist7",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist7",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist8",
    fieldName: "accPicklist8",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist8",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist9",
    fieldName: "accPicklist9",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist9",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist10",
    fieldName: "accPicklist10",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist10",
    type: "ACCOUNT",
  },
  {
    title: "accPicklist11",
    fieldName: "accPicklist11",
    label: "",
    groupName: "ACCOUNT",
    value: "accPicklist11",
    type: "ACCOUNT",
  },
  {
    title: "accString1",
    fieldName: "accString1",
    label: "",
    groupName: "ACCOUNT",
    value: "accString1",
    type: "ACCOUNT",
  },
  {
    title: "accString2",
    fieldName: "accString2",
    label: "",
    groupName: "ACCOUNT",
    value: "accString2",
    type: "ACCOUNT",
  },
  {
    title: "accString3",
    fieldName: "accString3",
    label: "",
    groupName: "ACCOUNT",
    value: "accString3",
    type: "ACCOUNT",
  },
  {
    title: "accString4",
    fieldName: "accString4",
    label: "",
    groupName: "ACCOUNT",
    value: "accString4",
    type: "ACCOUNT",
  },
  {
    title: "accString5",
    fieldName: "accString5",
    label: "",
    groupName: "ACCOUNT",
    value: "accString5",
    type: "ACCOUNT",
  },
  {
    title: "accString6",
    fieldName: "accString6",
    label: "",
    groupName: "ACCOUNT",
    value: "accString6",
    type: "ACCOUNT",
  },
  {
    title: "accString7",
    fieldName: "accString7",
    label: "",
    groupName: "ACCOUNT",
    value: "accString7",
    type: "ACCOUNT",
  },
  {
    title: "accString8",
    fieldName: "accString8",
    label: "",
    groupName: "ACCOUNT",
    value: "accString8",
    type: "ACCOUNT",
  },
  {
    title: "accString9",
    fieldName: "accString9",
    label: "",
    groupName: "ACCOUNT",
    value: "accString9",
    type: "ACCOUNT",
  },
  {
    title: "accString10",
    fieldName: "accString10",
    label: "",
    groupName: "ACCOUNT",
    value: "accString10",
    type: "ACCOUNT",
  },
  {
    title: "accString11",
    fieldName: "accString11",
    label: "",
    groupName: "ACCOUNT",
    value: "accString11",
    type: "ACCOUNT",
  },
  {
    title: "accString12",
    fieldName: "accString12",
    label: "",
    groupName: "ACCOUNT",
    value: "accString12",
    type: "ACCOUNT",
  },
  {
    title: "accString13",
    fieldName: "accString13",
    label: "",
    groupName: "ACCOUNT",
    value: "accString13",
    type: "ACCOUNT",
  },
  {
    title: "accDate1",
    fieldName: "accDate1",
    label: "",
    groupName: "DATE",
    value: "accDate1",
    type: "ACCOUNT",
  },
  {
    title: "accDate2",
    fieldName: "accDate2",
    label: "",
    groupName: "DATE",
    value: "accDate2",
    type: "ACCOUNT",
  },
  {
    title: "accDate3",
    fieldName: "accDate3",
    label: "",
    groupName: "DATE",
    value: "accDate3",
    type: "ACCOUNT",
  },
  {
    title: "accDate4",
    fieldName: "accDate4",
    label: "",
    groupName: "DATE",
    value: "accDate4",
    type: "ACCOUNT",
  },
  {
    title: "accDate5",
    fieldName: "accDate5",
    label: "",
    groupName: "DATE",
    value: "accDate5",
    type: "ACCOUNT",
  },
  {
    title: "accDate6",
    fieldName: "accDate6",
    label: "",
    groupName: "DATE",
    value: "accDate6",
    type: "ACCOUNT",
  },
  {
    title: "accDate7",
    fieldName: "accDate7",
    label: "",
    groupName: "DATE",
    value: "accDate7",
    type: "ACCOUNT",
  },
  {
    title: "accDate8",
    fieldName: "accDate8",
    label: "",
    groupName: "DATE",
    value: "accDate8",
    type: "ACCOUNT",
  },
];

export const CATEGORIES = ACCOUNT_CATEGORY.sort((a, b) => {
  if (a.groupName === b.groupName) {
    return a.title < b.title ? -1 : 1;
  } else {
    return a.groupName < b.groupName ? -1 : 1;
  }
});

export const NUMERIC_FIELDS = [
  "Account Score",
  "Annual Revenue",
  "Company Revenue",
  "Engagement Depth",
  "Engagement Score",
  "Engagement Width",
  "ICP Score",
  "Individual Engagement Score",
  "Number of Contacts",
  "Number of Stores",
  "Brand Revenue Number",
  "Employee Count",
  "setupFee",
  "monthlyRecurringRevenue", //MRR
  "annualRecurringRevenue", //ARR
  "productARR",
  "servicesARR",
  "totalARR",
  "others_number",
  "amount",
  "probability",
  "weightedAmount",
  "age",
  "pipelineAmount",
  "oppNumber1",
  "oppNumber2",
  "oppNumber3",
  "oppNumber4",
  "oppNumber5",
  "oppNumber6",
  "oppNumber7",
  "oppNumber8",
  "oppNumber9",
  "oppNumber10",
  "oppNumber11",
  "oppNumber12",
  "oppNumber13",
  "oppNumber14",
  "oppNumber15",
  "oppNumber16",
  "oppNumber17",
  "oppNumber18",
  "oppNumber19",
  "oppNumber20",
  "oppNumber21",
  "oppNumber22",
  "oppNumber23",
  "oppNumber24",
  "oppNumber25",
  "accNumber1",
  "accNumber2",
  "accNumber3",
  "accNumber4",
  "accNumber5",
  "accNumber6",
];

export const DATE_FIELDS = [
  "Account Creation Date",
  "Account Created Date",
  "Account Modification Date",
  "Last Engagement Date",
  "Account Updated Date",
  "First Activity Date",
  "Last Activity Date",
  "Last Intent Date",
  "First Engagement Date",
  "Event Date",
  "opportunityCreatedAt",
  "opportunityUpdatdAt",
  "opportunityCloseDate",
  "others_date",
  "oppDate1",
  "oppDate2",
  "oppDate3",
  "oppDate4",
  "oppDate5",
  "oppDate6",
  "oppDate7",
  "oppDate8",
  "oppDate9",
  "oppDate10",
  "oppDate11",
  "oppDate12",
  "oppDate13",
  "oppDate14",
  "oppDate15",
  "extra5",
  "extra6",
  "accDate1",
  "accDate2",
  "accDate3",
  "accDate4",
  "accDate5",
  "accDate6",
  "accDate7",
  "accDate8",
];
export const PAST_FIELDS = [
  "Last Engagement Date",
  "Account Creation Date",
  "Account Created Date",
  "Account Modification Date",
  "Contact Created Date",
  "Contact Updated Date",
  "Account Updated Date",
  "First Activity Date",
  "Last Activity Date",
  "First Engagement Date",
  "Last Intent Date",
  "Event Date",
];
export const EVENT_D = ["Event Date"];

export const EVENT_C = ["Event Count", "Buyer Group"];

export const NAME_FIELDS = [
  "Account Name",
  "Group Name",
  "Parent Account Name",
  "Contact City",
  "Contact State",
  "Title",
  "Company Phone",
  "Company City",
  "Group Website",
  "Parent Website",
  "City",
  "State",
  "Account Website",
  "Company Name",
  "Group Company Name",
  "Parent Company Name",
  "Parent Company Website",
  "Website",
  "Company State",
  "Group Company Website",
  "LinkedIn URL",
  "Twitter URL",
  "Facebook URL",
  "opportunityName",
  "opportunityStage",
  "preSalesExec",
  "leadSource",
  "others_string",
  "nextStep",
  "closedWonReason",
  "closedLostReason",
  "currency",
  "oppString1",
  "oppString2",
  "oppString3",
  "oppString4",
  "oppString5",
  "oppString6",
  "oppString7",
  "oppString8",
  "oppString9",
  "oppString10",
  "oppString11",
  "oppString12",
  "oppString13",
  "oppString14",
  "oppString15",
  "extra3",
  "extra4",
  "accString1",
  "accString2",
  "accString3",
  "accString4",
  "accString5",
  "accString6",
  "accString7",
  "accString8",
  "accString9",
  "accString10",
  "accString11",
  "accString12",
  "accString13",
];

export const ACT_CAMP_FIELDS = [];

export const ACT_CAMP_FIELDS_LOGO = [
  "Activity Type",
  "Campaign Name",
  "Product Name",
  "Event Name",
];

export const In_Ex_Emp_NotEmpty_Fields = [
  "Account Owner",
  "Activity Type",
  "Campaign Name",
  "Product Name",
  "Event Name",
];

export const BOOLEAN_FIELDS = [
  "Top Target Account",
  "Omnichannel",
  "ABM Account (Account)",
  "Contact ABM Account",
  "boolean1",
  "others_boolean",
  "oppBoolean1",
  "oppBoolean2",
  "oppBoolean3",
  "oppBoolean4",
  "oppBoolean5",
];

export const WEBSITE_FIELDS = ["Visited Page"];
