import React, { useEffect, useState } from "react";
import axios from "axios";
import { BackIcon, SearchIcon } from "./../../../assets/svg";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { TextField, InputAdornment, Alert } from "@mui/material";
import { ListUser } from "./ListUser";
import _ from "lodash";
import "../../../assets/styles/toast.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: "24px"
    },
    '& .MuiPaper-root': {
        borderRadius: '8px',
        height: '500px'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

const SharePopUp = (props) => {
    const [filterSearch, setFilterSearch] = useState("");
    const [originResult, setOriginResult] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const toastConfig = {
      closeOnClick: true,
      autoClose: true,
      closeButton: <CloseIcon style={{ width:"18px", marginLeft:"8px"}}/>,
      icon: true,
      newestOnTop: true,
    };

    useEffect(() => {
        try {
          const body = {
            cardArray: Array.isArray(props.data) ? props.data : [props.data],
          };
          console.log(" props.cardDetails-->", body);
          axios.post(
            `${window._env_.CARD_SERVICE}/card/v2/usersinfo/${props.tenantId}/${props.cardUuid}`,
            body,
            {
              withCredentials: true,
            }
          )
          // axios(`${window._env_.CARD_SERVICE}/card/v2/usersinfo/${props.tenantId}/${props.cardUuid}`, { withCredentials: true })
          .then((e) => {
            const allUsers = [...e.data.data.internalUsers, ...e.data.data.externalUsers];
            setOriginResult(allUsers);
            setSearchResult(_.cloneDeep(allUsers));
            setIsLoading(false);
          })
          .catch((e) => {
            console.error("Error in useEffect while trying to fetch user details line 49", e, props.tenantId);
            setSearchResult([]);
            setIsLoading(false);
          });
        } catch (error) {
          console.log('Error in useEffect while trying to fetch user details line 53', error, props.tenantId);
          setSearchResult([]);
          setIsLoading(false);
        }
    }, []);

    const handleSharing = ()=>{

      console.log(" props.data ->", props.data);
      const body = {
        cardArray: props.data,
        sharedWithUser: [],
        unsharedUser: [],
      };
      for (let i = 0; i < originResult.length; i++) {
        const isShared = originResult[i].cardShared === searchResult[i].cardShared;
        if (!isShared) {
          if (searchResult[i].cardShared === false) {
            body.unsharedUser.push(searchResult[i].userUuid);
          } else {
            body.sharedWithUser.push(searchResult[i].userUuid);
          }
        }
      }
      // console.log('Body', body);
      axios.post(`${window._env_.CARD_SERVICE}/card/v2/share/${props.tenantId}`, body, { withCredentials: true }).then(e => {
        if (e.data.code === 200) {
          props.onClose();
          if(props.data.length === 1)
            toast.success(`Insight shared with ${body?.sharedWithUser?.length || 0} user(s) and unshared ${body?.unsharedUser?.length || 0} with user(s) successfully`, toastConfig);
          else 
            toast.success(` ${props.data.length} Insights shared with ${body?.sharedWithUser?.length || 0} user(s) and unshared ${body?.unsharedUser?.length || 0} with user(s) successfully`, toastConfig);
        } else {
          props.onClose()
          toast.error('Oops there was problem sharing the card. Try again in some time', toastConfig);
        } 
        setOriginResult(_.cloneDeep(searchResult))
      })
    }

    const handleFilter = (e) => {
      try {
        setFilterSearch(e.target.value)      
      } catch (error) {
        console.error("catch block error occurred in handleFilter",error);
      }
    }

    const handleSingListCheck = (e, userId)=>{
      try {
        setSearchResult(searchResult.map(val => {
        if (val.userUuid === userId) val['cardShared'] = e.target.checked
        return val;
        }))
        setSelectedUsers((prevSelected) => prevSelected.includes(userId) ? prevSelected.filter((id) => id !== userId) : [...prevSelected, userId]);
      } catch (error) {
        console.error(error)
      }
    }

    return (
        <>
            <BootstrapDialog aria-labelledby="customized-dialog-title" open={props.open} PaperProps={{
                sx: {
                    '&.MuiDialog-paper.MuiDialog-paperScrollPaper': {
                        width: '628px !important',
                        margin: "0",
                    },
                    "& .MuiList-root": {
                      padding: "0 !important",
                      "& .MuiListItem-root": {
                        paddingTop: "0",
                        paddingBottom: "0",
                      }
                    }
                },
            }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '24px' }}>
                    <span onClick={props.onClose} 
                    style={{ paddingTop: '5px', cursor: 'default' }}
                    onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                    onMouseLeave={(e) => (e.target.style.cursor = 'default')}>
                        <BackIcon style={{ width: '24px', height: '24px' }}/>
                    </span>
                    <DialogTitle sx={{ height: '72px', color: '#3D3D3B', fontWeight: 600, fontSize: '20px', paddingTop: '20px', paddingLeft: '8px' }} id="customized-dialog-title">
                        Share {props?.count || 1} insights
                    </DialogTitle>
                </div>
                <IconButton aria-label="close" onClick={props.onClose} sx={{ position: 'absolute', right: 8, top: 8, color: '#262626' }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                <div style={{ alignItems:"center", width:"92%", position:"relative", left:"15px", top:"" }}>
                    <TextField
                        {...props}
                        variant="outlined"
                        size = "small"
                        fullWidth
                        onChange={handleFilter}
                        InputProps={{
                            ...props.InputProps,
                            startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                            ),
                        placeholder: "Search people",
                        }}
                    />
                </div>
                <div style={{margin:"34px auto auto auto",paddingLeft:"13px"}}>
                    <ListUser listUsers={searchResult} setListUsers={setSearchResult} handleSingListCheck={handleSingListCheck} filterSearch={filterSearch} isLoading={isLoading}/>
                </div>
                </DialogContent>
                <DialogActions sx={{ height: '64px' }}>
                    <Button variant="outlined" sx={{ borderRadius: '12px', textTransform: 'none', border: '1px solid #DFE2DE', color: '#3D3D3B', fontWeight: 600, fontSize: '12px', marginRight: '14px', '&:hover': { borderColor: '#3D3D3B' } }} onClick={props.onClose}>Cancel</Button>
                    <Button variant="contained" sx={{ backgroundColor: '#016B40', borderRadius: '12px', textTransform: 'none', fontWeight: 600, fontSize: '12px', marginRight: '16px', '&:hover': { backgroundColor: '#016B40' } }} disabled={selectedUsers.length === 0} onClick={()=>handleSharing()}>Share</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}

export default SharePopUp;