import React, { useState, useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import _ from "lodash";
import { RemoveCircleOutline } from "@material-ui/icons";
import { useAuthState } from "@bamboobox/b2logincheck";

import "./attributeitem.css";

import getTenantConfigJSON from "./../getTenantConfigJson";
import { FilterContext } from "./../index";
import AttributeComponent from "./AttributeComponent";
import ValueComponent from "./ValueComponent";
import BuyerGroup from "./BuyerGroup";

const AccountTreeOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.25 20.75V17.75H11.25V7.74997H8.74995V10.75H2.25V3.25002H8.74995V6.25002H15.25V3.25002H21.75V10.75H15.25V7.74997H12.75V16.25H15.25V13.25H21.75V20.75H15.25ZM16.75 9.25002H20.25V4.74997H16.75V9.25002ZM16.75 19.25H20.25V14.75H16.75V19.25ZM3.74995 9.25002H7.25V4.74997H3.74995V9.25002Z"
        fill="#030303"
        fill-opacity="0.6"
      />
    </svg>
  );
};

const valuesLimit = window._env_.FILTER_VALUES_LIMIT;
const limitvalues = (values) => {
  try {
    return values.slice(0, valuesLimit || 5000);
  } catch (error) {
    return [];
  }
};
const countryNameList = ['Indonesia', 'Singapore', 'Saudi Arabia', 'France', 'Malaysia', 'Kuwait', 'Philippines', 'United States', 'Turkey', 'Nigeria', 'United Kingdom (Great Britain)', 'China', 'Qatar', 'Australia', 'Spain', 'United Arab Emirates', 'Thailand', 'Italy', 'Germany', 'Canada', 'India', 'South Africa', 'Japan', 'Switzerland', 'New Zealand', 'Austria', 'Haiti', 'Samoa', 'Antigua and Barbuda', 'Oman', 'Taiwan', 'Vietnam', 'Sweden', 'Poland', 'Netherlands', 'Ireland', 'Mexico', 'Argentina', 'Brazil']

function AttributeItem(props) {
  console.log("props inside MyAttributeItem", props);
  const {
    attributeList,
    onFilterChange,
    deleteFilter,
    onClearAttributes,
    // icp,
    tenantConfigMapper,
    contTenantConfigMapper,
    relationArrConfig,
    // subfiltersConfig,
    screen,
  } = useContext(FilterContext);
  const { attributeData, subfilter, elementIndex, fetchAttributeArr } = props;
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  const [attributeValues, setAttributeValues] = useState([]);
  const [typedValue, setTypedValue] = useState(attributeData ?? "");
  const [inputText, setInputText] = React.useState("");
  const [currentAttribute, setCurrentAttribute] = useState();
  const [relationArr, setRelationArr] = useState([]);

  const attributeOptions = fetchAttributeArr(elementIndex, subfilter) ?? [];

  // const getValue = () => {
  //   try {
  //     const objectValue = [
  //       "Activity Type",
  //       "Campaign Name",
  //       "Product Name",
  //       "Event Name",
  //     ];
  //     return (
  //       attributeData.attribute !== "Buyer Group" &&
  //       attributeValues.filter((option) =>
  //         getOPtionFilt(option)?.toLowerCase().includes(inputText.toLowerCase())
  //       )?.length ===
  //         attributeData?.values.filter((option) =>
  //           getOPtionFilt(option)
  //             .toLowerCase()
  //             .includes(inputText.toLowerCase())
  //         )?.length
  //     );
  //   } catch (error) {
  //     return false;
  //   }
  // };

  const getOPtionFilt = (option) =>
    attributeData.attribute.includes('eventName') 
      ? option.eventName
      : attributeData.attribute.includes('productName') 
      ? option.productName
      : attributeData.attribute.includes('campaignName') 
      ? option.programName
      : attributeData.attribute.includes('activityType') 
      ? option.activityType
      : attributeData.attribute.includes('label')
      ? option.labelName
      : option;
  const selectAllValues = (() => {
    try {
      // const objectValue = [
      //   "Activity Type",
      //   "Campaign Name",
      //   "Product Name",
      //   "Event Name",
      // ];
      // if(attributeData.attribute.includes(objectValue))
      return (
        attributeData.attribute !== "buyerGroup" &&
        attributeValues.filter((option) =>
          getOPtionFilt(option)?.toLowerCase().includes(inputText.toLowerCase())
        )?.length ===
          attributeData?.values.filter((option) =>
            getOPtionFilt(option)
              .toLowerCase()
              .includes(inputText.toLowerCase())
          )?.length
      );
    } catch (error) {
      return false;
    }
  })();

  const allSelected =
    attributeValues
      .filter((obj) => Object.keys(obj)[0] === "department")
      .map((obj) => obj.department)
      ?.filter((e) => e.toLowerCase().includes(inputText))?.length ===
      (attributeData?.values?.[0]?.department?.filter((e) =>
        e.toLowerCase().includes(inputText)
      )?.length || "") || false;
  const allPersonaSelected =
    attributeValues
      .filter((obj) => Object.keys(obj)[0] === "personalevel")
      .map((obj) => obj.personalevel)
      ?.filter((e) => e.toLowerCase().includes(inputText))?.length ===
      (attributeData?.values?.[0]?.personalevel?.filter((e) =>
        e.toLowerCase().includes(inputText)
      )?.length || "") || false;

  useEffect(() => {
    let attributeObject;
    if (screen === "isAccountHub" || screen === "isOpportunityHub") {
      attributeObject = tenantConfigMapper.get(typedValue?.attribute);
    } else if (screen === "isContactHub") {
      attributeObject = contTenantConfigMapper.get(typedValue?.attribute);
    }

    const filterType = attributeObject?.filterType;
    setCurrentAttribute(attributeObject);
    if (
      !(typedValue && (typedValue.attribute || typedValue.label)) ||
      relationArr.length > 0
    ) {
      return;
    }
    fetchRelationArr(filterType);
  }, [typedValue]);

  function validateData(data) {
    // let date_fields = [
    //   "Account Creation Date",
    //   "Account Created Date",
    //   "Account Modification Date",
    //   "Last Engagement Date",
    //   "Account Updated Date",
    //   "First Activity Date",
    //   "Last Activity Date",
    //   "First Engagement Date",
    //   "Intent Creation Date",
    //   "Last Intent Date",
    //   "Account Created At",
    //   "Account Updated At",
    //   "Opportunity Created At",
    //   "Opportunity Updated At",
    // ];
    const { attribute, relation, values, filterType } = data;
    const attr = { ...data };
    if (!attribute || !relation) {
      attr.isValid = false;
      return attr;
    }
    if (
      attribute &&
      (relation === "is empty" ||
        relation === "is not empty" ||
        relation === "true" ||
        relation === "false")
    ) {
      attr.isValid = true;
      return attr;
    }
    if (
      filterType === "DATE" &&
      (relation === "in the past" || relation === "is more than") &&
      values.length !== 0
    ) {
      let value = typeof values[0] === "string";
      attr.isValid = value;
      return attr;
    }
    if (filterType === "DATE" && values.length !== 0 && relation) {
      if (values[0] === null || values[1] === null) {
        attr.isValid = false;
        return attr;
      }
      let d1 = new Date(values[0]);
      let d2 = new Date(values[1]);
      if ((isNaN(d1) || isNaN(d2)) && relation === "between") {
        attr.isValid = false;
      } else if (isNaN(d1)) {
        attr.isValid = false;
      } else {
        attr.isValid = true;
      }
      return attr;
    }
    if (attribute && relation) {
      if (relation !== "between") {
        if (values.length) {
          attr.isValid = true;
        } else {
          attr.isValid = false;
        }
        if (attr.attribute === "buyerGroup") {
          attr.isValid = false;
          if (
            attr.values[0].department.length > 0 &&
            attr.values[0].personalevel.length > 0 &&
            attr.values[0].value.length > 0 &&
            attr.values[0].value[0] !== ""
          ) {
            attr.isValid = true;
          }
        }
      } else {
        if (
          values[0] === "" ||
          values[0] === undefined ||
          values[1] === "" ||
          values[1] === undefined
        ) {
          attr.isValid = false;
        } else if (parseInt(values[0]) >= parseInt(values[1])) {
          attr.isValid = false;
        } else {
          attr.isValid = true;
        }
        if (attr.attribute === "buyerGroup") {
          attr.isValid = false;
          if (
            attr.values[0].department.length > 0 &&
            attr.values[0].personalevel.length > 0 &&
            attr.values[0].value.length > 1
          ) {
            attr.isValid = true;
          }
        }
      }
    }

    return attr;
  }
  function shouldShowErrors(attributeData, key) {
    if (key === "relation") {
      return relationArr?.length === 0 || relationArr === undefined;
    }
    if (key === "value") {
      return (
        attributeData?.values[0]?.value?.length === 0 ||
        attributeData?.values[0]?.value === undefined
      );
    }
  }

  function fetchRelationArr(filterType) {
    setRelationArr(
      relationArrConfig?.[filterType] ?? relationArrConfig?.["DEFAULT"]
    );
  }
  const fetchAttributeValues = async (value) => {
    let label = "";
    console.log("attributeList->>",attributeList); 
    if (!value) {
      value = attributeData?.attribute;
      label = currentAttribute?.label || attributeData?.label;
    }
    const  body = {};
    for(let i=0; i<attributeList.length ;i++){
      console.log("attributeList[i]-.",i,attributeList[i]);
      if(attributeList?.[i]?.subfilter?.length){
        const fil = attributeList?.[i]?.subfilter;
        for(let j=0 ;j<fil.length ;j++){
          body.columnName=fil[j]?.attribute;
          body.source=fil[j]?.logo;
          body.activityType=fil[j]?.activityType;
          body.tableName= attributeList[i]?.type;
        }
      }else{
        body.tableName = props?.attributeData?.type;
        body.columnName = value;
      }
    }
    

    console.log("body for the new API",body);
    console.log("Value and label",value,label);
    if (value.includes("activityType") || label === "Activity Type" || value === "accounts|labelName" ||
    value === "contacts|labelName" ||
    label === "Account Labels" ||
    label === "Labels") {
      try {
        let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 3->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          setAttributeValues(limitvalues(val?.data));
        });
      } catch (error) {
        console.log(error);
      }
    }  else if (label === "Product Name" || label === "Contact Product Name" || value === "productName") {
      const attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
      await fetch(
        attributeValuesUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          credentials: "include",
        }
      ).then((result) => result.json())
      .then((val)=>{
        console.log("attData->",val);
        console.log(" programs in index", val?.data);
        let productNames = [];
        
        val?.data?.map((obj) =>{
          let pushObj = {
            productName: obj?.attributeValue,
            source : obj?.source
          };
          console.log("pushObj ", pushObj);
          productNames.push(pushObj);
        })
        setAttributeValues(limitvalues(productNames));
      });

     
    } 
    else if (value === "programName" || label.toLowerCase() === "Campaign Name".toLowerCase()) {
      const attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
      await fetch(
        attributeValuesUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          credentials: "include",
        }
      ).then((result) => result.json())
      .then((val)=>{
        console.log("attData->",val);
        console.log(" programs in index", val?.data);
        let campaignTypes = [];
        campaignTypes = val?.data?.map((obj) =>{
          let pushObj = {
                    programName: obj?.attributeValue || "",
                    source: obj?.source,
                    programId: obj?.programId,
                  };
                  return pushObj;
        })
        setAttributeValues(limitvalues(campaignTypes));
      });
    } else if (value.toLowerCase().includes('country')) {
      let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 1->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          const countryList = [];
          val?.data?.map((ele) => countryList.push(ele.attributeValue))
          setAttributeValues(limitvalues(countryList));
        });

      // fetch(window._env_.B2PROSPECT_URL + "/metadata/all?tenantId=" + tenantId, {
      //   credentials: "include",
      // })
      //   .then((result) => result.json())
      //   .then((json) => {
      //     var country = json.find((obj) => {
      //       return obj.name === "country";
      //     });
      //     let countryList = [];
      //     country.values.forEach((place) => {
      //       countryList.push(place.value.slice());
      //     });
      //     if (countryList?.length > 0) {
      //       setAttributeValues(limitvalues(countryList));
      //     } else {
      //     }
      //   });
    }  
    else if (label.toLowerCase() === "Sub Industry".toLowerCase()) {
      let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 1->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          const subIndustryList = [];
          val?.data?.map((ele) => subIndustryList.push(ele.attributeValue));
          setAttributeValues(limitvalues(subIndustryList.filter((label, index) => subIndustryList.indexOf(label) === index)));
        });
      // fetch(window._env_.META_BASE_URL + "/attribute-setup/get-all-child-attributes?tenantId=" + tenantId + "&&parent=Industry&&child=Sub Industry", { credentials: "include" })
      //   .then((result) => result.json())
      //   .then((json) => {
      //     let subIndustryList = [];
      //     json.forEach((subIndustry) => {
      //       subIndustryList.push(subIndustry.child_value.slice());
      //     });
      //     console.log("subIndustryList->",subIndustryList);
      //     if (subIndustryList?.length > 0) {
      //       setAttributeValues(limitvalues(subIndustryList.filter((label, index) => subIndustryList.indexOf(label) === index)));
      //     } else {
      //     }
      //   });
    }
    else {
      console.log("In else index");
      let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 1->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          const attrArr = [];
          val?.data?.map((ele) => attrArr.push(ele.attributeValue));
          setAttributeValues(limitvalues(attrArr));
        });
    }
  };
  function onAttributeChange(value, key) {
    console.log("cvbn", key, value);
    const attrData = { ...attributeData };

    if (key === "attribute") {
      attrData["attribute"] = value.attribute;
      attrData["label"] = value.label;
      attrData["type"] = value.tableName;
      attrData["filterType"] = value.filterType;
      attrData["subfilter"] = [];
      attrData["source"] = value.source;
      if (value?.logo) {
        attrData["logo"] = value.logo;
      }
      if(value?.activityType){
        attrData["activityType"] = value.activityType;
      }
      fetchRelationArr(value?.filterType);
      attrData["relation"] = "";
      attrData["values"] = [];
      if (value.attribute === "buyerGroup" || value.label === "Buyer Group") {
        attrData["values"] = [
          {
            department: [],
            personalevel: [],
            value: [],
          },
        ];
      } else if (
        value.attribute === "accountStageTrend" ||
        value.label === "Account Stage Trend"
      ) {
        attrData["relation"] = "is";
        attrData["values"] = [
          {
            trend: "upwards",
            trendRelation: "in the past",
            trendValues: ["Week", null],
          },
        ];
      } else if (
        value.attribute === "departmentPersonalevel" ||
        value.label === "Persona Department Level"
      ) {
        attrData["relationMain"] = "includes";
        attrData["relation"] = "greater than(>)";
        attrData["values"] = [4, [0, null]];
      }
      setAttributeValues([]);
    } else if (key === "relation") {
      attrData["relation"] = value;
      if (
        attrData.attribute === "buyerGroup" ||
        attrData.label === "Buyer Group"
      ) {
        if (attrData["values"][0]?.value) attrData["values"][0].value = [];
      } else {
        attrData["values"] = [];
      }
      if (attrData["subfilter"]?.length > 0) {
        attrData["subfilter"] = [];
      }
    } else {
      if (
        attrData["attribute"] === "buyerGroup" ||
        attrData.label === "Buyer Group"
      ) {
        let tempValObj = {
          department: [],
          personalevel: [],
          value: [],
        };
        if (Object.keys(value)[0] === "department") {
          if (attrData[key].length > 0)
            if (value?.department.find((option) => option === "Select All")) {
              if (!allSelected) {
                attrData[key][0]["department"] = attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "department")
                  .map((obj) => obj.department)
                  .filter(
                    (e) =>
                      e !== "Select All" &&
                      (e.toLowerCase().includes(inputText.toLowerCase()) ||
                        value.department.includes(e))
                  );
              } else {
                attrData[key][0]["department"] = [];
              }
            } else {
              if (inputText === "")
                attrData[key][0]["department"] = value?.department || [];
              else
                value?.department?.filter(
                  (e) =>
                    e !== "Select All" &&
                    !e.toLowerCase().includes(inputText.toLowerCase())
                );
              attrData[key][0]["department"] = value?.department || [];
            }
          else {
            if (value?.department.find((option) => option === "Select All")) {
              tempValObj.department =
                attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "department")
                  .map((obj) => obj.department)
                  .filter(
                    (e) =>
                      e !== "Select All" &&
                      (e.toLowerCase().includes(inputText.toLowerCase()) ||
                        value.personalevel.includes(e))
                  ) || [];
              attrData[key].push(tempValObj);
            } else {
              if (inputText === "")
                tempValObj.department = value?.department || [];
              else
                attrData[key][0]["department"] = value?.department?.filter(
                  (e) =>
                    e !== "Select All" &&
                    !e.toLowerCase().includes(inputText.toLowerCase())
                );
              attrData[key].push(tempValObj);
            }
          }
        } else if (Object.keys(value)[0] === "personalevel") {
          if (attrData[key].length > 0) {
            if (value?.personalevel.find((option) => option === "Select All")) {
              if (!allPersonaSelected) {
                attrData[key][0]["personalevel"] = attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "personalevel")
                  .map((obj) => obj.personalevel)
                  .filter(
                    (e) =>
                      e !== "Select All" &&
                      (e.toLowerCase().includes(inputText.toLowerCase()) ||
                        value.personalevel.includes(e))
                  );
              } else {
                if (inputText === "") attrData[key][0]["personalevel"] = [];
                else {
                  attrData[key][0]["personalevel"] = value.personalevel.filter(
                    (e) =>
                      e !== "Select All" &&
                      !e.toLowerCase().includes(inputText.toLowerCase())
                  );
                }
              }
            } else {
              attrData[key][0]["personalevel"] = value?.personalevel || [];
            }
          } else {
            if (value?.personalevel.find((option) => option === "Select All")) {
              tempValObj.personalevel = attributeValues
                .filter((obj) => Object.keys(obj)[0] === "personalevel")
                .map((obj) => obj.personalevel)
                .filter(
                  (e) =>
                    e !== "Select All" &&
                    (e.toLowerCase().includes(inputText.toLowerCase()) ||
                      value.personalevel.includes(e))
                );
              attrData[key].push(tempValObj);
            } else {
              if (inputText === "")
                tempValObj.personalevel = value?.personalevel || [];
              else
                tempValObj.personalevel = value.personalevel.filter(
                  (e) =>
                    e !== "Select All" &&
                    !e.toLowerCase().includes(inputText.toLowerCase())
                );
              attrData[key].push(tempValObj);
            }
          }
        } else if (Object.keys(value)[0] === "value") {
          attrData[key] = value?.value;
          console.log("storing data", attrData[key]);
        }
      } else {
        if (value.find((e) => e === "Select All")) {
          if (!selectAllValues) {
            if (inputText === "") attrData[key] = attributeValues;
            else
              attrData[key] = attributeValues.filter(
                (option) =>
                  getOPtionFilt(option)
                    .toLowerCase()
                    .includes(inputText.toLowerCase()) ||
                  value.some(
                    (el) =>
                      getOPtionFilt(el) === getOPtionFilt(option) &&
                      el?.source === option?.source
                  )
              );
          } else {
            if (inputText === "") attrData[key] = [];
            else
              attrData[key] = value.filter(
                (e) =>
                  e !== "Select All" &&
                  !getOPtionFilt(e)
                    .toLowerCase()
                    .includes(inputText.toLowerCase())
              );
          }
        } else {
          attrData[key] = value;
          if (attrData["subfilter"]?.length > 0) {
            attrData["subfilter"] = attrData["subfilter"].filter(
              (itr) => {
                const ret = value.find((val) => {
                  return (
                    getOPtionFilt(val) === itr.activityType &&
                    (val?.source ? val.source === itr.logo : true)
                  );
                })
                return ret;
              }
            );
          }
        }
      }
    }
    if (value === "true" || value === "false") {
      attrData["values"] = [value];
    }
    onFilterChange(
      { attrData: validateData(attrData), elementIndex },
      subfilter
    );
  }
  const onClickCheck = (value) => {
    for (const category of attributeOptions) {
      if (value === category.label) {
        onAttributeChange(category, "attribute");
        setTypedValue(category);
      }
    }
  };

  return (
    <div
      className="main-filter"
      key={attributeData?.attrId}
    >
      {subfilter && (
        <div
          style={{
            marginTop: "14px",
            width: "24px",
            height: "24px",
            color: "#03030399",
          }}
        >
          <AccountTreeOutlinedIcon />
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "180px 180px 1fr",
          columnGap: "10px",
        }}
      >
        <div>
          {
            <AttributeComponent
              elementIndex={elementIndex}
              subfilter={subfilter}
              attributeOptions={attributeOptions}
              typedValue={typedValue}
              attributeData={attributeData}
              setTypedValue={setTypedValue}
              onClickCheck={onClickCheck}
            />
          }
          {(attributeData.attribute === "buyerGroup" ||
            attributeData.label === "Buyer Group") && (
            <div style={{ marginTop: "1em" }}>
              <svg
                style={{
                  position: "static!important",
                  width: "39px",
                  height: "50px",
                  marginLeft: "85%",
                  left: "250px",
                  color: "#C4C4C4",
                }}
                width="39"
                height="50"
                viewBox="0 0 39 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39 11L33 17L31.58 15.58L35.17 12H0V0H2V10H35.17L31.58 6.42L33 5L39 11Z"
                  fill="#C4C4C4"
                />
              </svg>
            </div>
          )}
        </div>
        {attributeData.attribute !== "buyerGroup" && (
          <Autocomplete
            size="sm"
            options={relationArr}
            value={attributeData?.relation}
            style={{ width: "180px" }}
            error={shouldShowErrors(attributeData, "relation")}
            variant="outlined"
            popupIcon={
              <KeyboardArrowDownRoundedIcon
                style={{ color: "rgba(3, 3, 3, 0.6)" }}
              />
            }
            disableClearable={
              attributeData?.relation === "" || typedValue?.relation === ""
            }
            inputProps={{
              classes: {
                icon: "relation-icon",
                root: "root",
              },
            }}
            // open={true}
            className="select"
            renderInput={(params) => (
              <TextField
                {...params}
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "12px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "0.15px",
                  color: "rgba(3, 3, 3, 0.6)",
                  flex: "none",
                  order: 0,
                  alignSelf: "stretch",
                  paddingTop: "0.8px",
                  flexGrow: 0,
                }}
                placeholder="Relation"
                variant="outlined"
                className="input"
              />
            )}
            onChange={(event, newValue) => {
              if (newValue === "includes" || newValue === "excludes") {
                fetchAttributeValues();
              }
              onAttributeChange(newValue, "relation");
            }}
          />
        )}

        <div
          style={{
            display: "flex",
            width: subfilter ? "224px" : "254px",
          }}
        >
          {attributeData.attribute === "buyerGroup" ||
          attributeData.label === "Buyer Group" ? (
            <BuyerGroup
              attributeData={attributeData}
              currentAttribute={currentAttribute}
              onAttributeChange={onAttributeChange}
              shouldShowErrors={shouldShowErrors}
              relationArr={relationArr}
              attributeValues={attributeValues}
              fetchAttributeValues={fetchAttributeValues}
              inputText={inputText}
              setInputText={setInputText}
              allSelected={allSelected}
              allPersonaSelected={allPersonaSelected}
              isSubFilter={subfilter}
            />
          ) : (
            <>
              {attributeData?.filterType === "BOOLEAN" ||
              attributeData?.relation === "is empty" ||
              attributeData?.relation === "is not empty" ? (
                <></>
              ) : (
                <ValueComponent
                  attributeData={attributeData}
                  currentAttribute={currentAttribute}
                  onAttributeChange={onAttributeChange}
                  shouldShowErrors={shouldShowErrors}
                  relationArr={relationArr}
                  elementIndex={elementIndex}
                  fetchAttributeValues={fetchAttributeValues}
                  attributeValues={attributeValues}
                  selectAllValues={selectAllValues}
                  getOPtionFilt={getOPtionFilt}
                />
              )}
            </>
          )}
        </div>
      </div>
      {attributeList.length === 1 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "auto",
            alignItems: "center",
            marginTop: "-4px",
          }}
        >
          <Tooltip disableFocusListener title="Clear">
            <IconButton aria-label="clear" size="large" style={{}}>
              <RemoveCircleOutline
                style={{
                  color: "rgba(3, 3, 3, 0.6)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onClearAttributes(elementIndex);
                  setTypedValue("");
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "auto",
            alignItems: "center",
            marginTop: "-4px",
          }}
        >
          <Tooltip disableFocusListener title="Delete">
            <IconButton aria-label="delete">
              <RemoveCircleOutline
                onClick={(event) => {
                  deleteFilter(elementIndex);
                }}
                style={{
                  color: "rgba(3, 3, 3, 0.6)",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default AttributeItem;
