import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, withStyles} from "@material-ui/core";
import "./FreqToggleTab.css"
import { useLocation } from "react-router-dom";
import {changeFromToDate} from "./dateTimeFunctionRepository"
import moment from "moment"
import React from "react";





export default function FreqToggleTabMarketing({tabVal,requestBody,icpSelected,tenantId, ...props}){
    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    let GetTabVal = () => {
      return useQuery().get("tabVal");
    }
    const CustomTab = withStyles({
        root: {
          fontSize: "12px",
          textTransform: "none",
          color: '#737373',
          fontWeight: 500
        }
      })(Tab);
   
    console.log('tab val in FreqToggleTab is', GetTabVal());
    
    const [frequency, setFrequency] = React.useState("Weekly");
    const [position, setPosition] = React.useState(0);

    
    const [disableMonthly, setDisableMonthly] = React.useState(true)
    const [disableQuarterly, setDisableQuarterly] = React.useState(true)
    //const [disableWeekly,setDisableWeekly]=React.useState(false);

    function handleChange(e,newPosition) {
      console.log("changed..............",newPosition)
      if(props.showWeekly==='enable'){
        props.setTabVal(newPosition);
      }else{
        props.setTabVal(newPosition);
      }
      handleFrequency(newPosition)
    }

    const handleFrequencyToggleDisable = () => {

      console.log("handleFrequencyToggleDisable just checking useffect", props?.fromDate, props?.toDate);

      let [fromDateMonthly, toDateMonthly] = changeFromToDate(props.fromDate, props.toDate, "Monthly");
      let [fromDateQuarterly, toDateQuarterly] = changeFromToDate(props.fromDate, props.toDate, "Quarterly");

      console.log("handleFrequencyToggleDisable before momentizing", fromDateMonthly, toDateMonthly, fromDateQuarterly, toDateQuarterly, props?.fromDate, props?.toDate);

      fromDateMonthly = moment(new Date(fromDateMonthly))
      toDateMonthly = moment(new Date(toDateMonthly))
      fromDateQuarterly = moment(new Date(fromDateQuarterly))
      toDateQuarterly = moment(new Date(toDateQuarterly))

      console.log("handleFrequencyToggleDisable", fromDateMonthly.toISOString(), toDateMonthly.toISOString(), fromDateQuarterly.toISOString(), toDateQuarterly.toISOString());

      if (props && props?.fromDate && props?.toDate && fromDateMonthly && toDateMonthly && fromDateQuarterly && toDateQuarterly) {
          console.log("handleFrequencyToggleDisable booleans",toDateMonthly.diff(fromDateMonthly, 'months'),(toDateMonthly.diff(fromDateMonthly, 'months') >=1), Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3), toDateQuarterly.diff(fromDateQuarterly, 'months'), (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) > 2))
          if (toDateMonthly.diff(fromDateMonthly, 'months') >=0 ) {
              setDisableMonthly(false)
          } else {
              setDisableMonthly(true)
          }

          if (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) >0) {
              setDisableQuarterly(false)
          } else {
              setDisableQuarterly(true)
          }
      } else {
          setDisableMonthly(true)
          setDisableQuarterly(true)
      }
      // if (toDateMonthly.diff(fromDateMonthly, 'months') <= 1 && frequency !== "Weekly") {
      //     setPosition(0)
      //     setFrequency("Weekly")
      //     props.setSelectedFrequency("Weekly")
          

      // } else if (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) <= 1 && frequency === "Quarterly") {
      //     setPosition(1)
      //         setFrequency("Monthly")
      //         props.setSelectedFrequency("Monthly")
      // }


  }

    const handleFrequency = (newOptionSelected) => {

      if (newOptionSelected !== null && newOptionSelected !== undefined) {
        if(props.showWeekly==='enable'){
            console.log("inside",newOptionSelected)
            if (newOptionSelected === 0) {
                setPosition(0)
                setFrequency("Monthly")
                props.setSelectedFrequency("Monthly")
                
            }
            else if (newOptionSelected === 1) {
                setPosition(1)
                setFrequency("Quarterly")
                props.setSelectedFrequency("Quarterly")
            } 
        }else{
            if (newOptionSelected === 0) {
                setPosition(0)
                setFrequency("Weekly")
                 props.setSelectedFrequency("Weekly")
                
            }
            else if (newOptionSelected === 1) {
                setPosition(1)
                setFrequency("Monthly")
                props.setSelectedFrequency("Monthly")
            }
            else if (newOptionSelected === 2) {
                setPosition(2)
                setFrequency("Quarterly")
                props.setSelectedFrequency("Quarterly")
            }
        }
                      
      } 
      else {
          setFrequency("Weekly")
          setPosition(0)
      }
      
  };

    React.useEffect(() => {
      handleFrequency()
    }, [])

    React.useEffect(() => {
        handleFrequencyToggleDisable()
    }, [new Date(props.fromDate), new Date(props.toDate)])


    React.useEffect(()=>{
        console.log("tabVal FreqToggleTab", tabVal)
        setPosition(Number(tabVal)||0)
      },[tabVal])
      
    return(
      
             <div>
              <Box sx={{ maxWidth: "70vw", borderBottomWidth:'0.2px', borderBottomColor: "rgb(211, 211, 211)"}}>
                <Tabs 
                style={{ borderBottom:'1px solid #D3D3D3', color:"#595959",}}
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#59CB59",
                    border: "2px solid #59CB59",
                    }
                  }}
                  //position
                  value={(Number(tabVal) || 0)}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                variant="standard"
                >
                    {props.showWeekly!=='enable' &&
                      <CustomTab label="Weekly"></CustomTab>
                    }
                    {/* <CustomTab label="Weekly"></CustomTab> */}
                    <CustomTab label="Monthly" disabled={disableMonthly}></CustomTab>
                    {props.showQuarterly &&
                      <CustomTab label="Quarterly" disabled={disableQuarterly}></CustomTab>
                    }

                </Tabs>
                </Box>
              </div>
    )
}
