import { useState, useEffect, useRef } from "react";
import { Typography, Autocomplete, TextField, Checkbox, Avatar, Chip, createFilterOptions } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import _ from "lodash";

export const Share = (props) => {
  const { expType, tenantId, cardUuid, loading, setLoading, selectedSharedUsers, setSelectedSharedUsers, setSelectedOriginalSharedUsers, cardDetails } = props;
  const [inputText, setInputText] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const filters = createFilterOptions();
  const selectAll = useRef(false);
  selectAll.current =
    allUsers.filter((ele) => ele.name.toLowerCase().includes(inputText.toLowerCase())).length === selectedSharedUsers.filter((ele) => ele.name.toLowerCase().includes(inputText.toLowerCase())).length;

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0]?.[0] ?? ""}${name.split(" ")[1]?.[0] ?? ""}`,
    };
  }

  const handleInput = (value) => {
    try {
      if (value) setInputText(value?.target?.value || "");
      else setInputText("");
    } catch (error) {
      setInputText("");
    }
  };
  
  const handleCheck = (evt, option) => {
    evt.persist();
    console.log(option);
    const uniMap = new Map();
    for (const item of option) {
      if (!uniMap.has(item.userUuid)) {
        uniMap.set(item.userUuid, item);
      } else {
        uniMap.delete(item.userUuid);
      }
    }
    option = [...uniMap.values()];

    if (option.find((e) => e?.label === "Select All")) {
      if (!selectAll.current) {
        setAllUsers(
          allUsers.map((e) => {
            if (e.cardShared === true) return e;
            if (e?.name.toLowerCase().includes(inputText.toLowerCase())) {
              e.cardShared = true;
            }
            return e;
          })
        );
        const searchAll = allUsers
          .filter((e) => e.name.toLowerCase().includes(inputText.toLowerCase()) || e?.cardShared)
          .map((val) => {
            val["cardShared"] = true;
            return val;
          });
        setSelectedSharedUsers(searchAll);
      } else {
        if (inputText === "") {
          setAllUsers(
            allUsers.map((e) => {
              e.cardShared = false;
              return e;
            })
          );
          setSelectedSharedUsers([]);
        } else {
          setAllUsers(
            allUsers.map((e) => {
              if (e.name.toLowerCase().includes(inputText.toLowerCase())) {
                e.cardShared = false;
              }
              return e;
            })
          );
          setSelectedSharedUsers(option.filter((e) => !e.name.toLowerCase().includes(inputText.toLowerCase())));
        }
      }
    } else {
      setAllUsers(
        allUsers.map((val) => {
          if (option.findIndex((e) => e.userUuid === val.userUuid) !== -1) {
            val["cardShared"] = true;
          } else val["cardShared"] = false;
          return val;
        })
      );
      setSelectedSharedUsers(option);
    }
    // setInputText('');
  };

  useEffect(() => {
    console.log("cardDetails-->",cardDetails);
    const body = {
      cardArray:Array.isArray(cardDetails) ? cardDetails : [cardDetails],
    };
    axios.post(
      `${window._env_.CARD_SERVICE}/card/v2/usersinfo/${tenantId}/${cardUuid}`,
      body,
      {
        withCredentials: true,
      }
    )
    // axios(`${window._env_.CARD_SERVICE}/card/v2/usersinfo/${tenantId}/${cardUuid}`, { withCredentials: true })
      .then((e) => {
        const allUsers = [...e.data.data.internalUsers, ...e.data.data.externalUsers];
        // setoriginalResult(allUsers);
        
        if(!body?.cardArray[0]){
          allUsers?.map((ele) => ele.cardShared = false);
        }
        console.log("allUsers->",allUsers);

        setAllUsers(_.cloneDeep(allUsers));
        const checkedValue = allUsers.filter((e) => e.cardShared);   
        setSelectedOriginalSharedUsers(checkedValue);
        setSelectedSharedUsers(checkedValue);
        setLoading(false);
      })
      .catch((e) => {
        console.error("error in axios occurred", e);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Typography>Share with</Typography>
      <div className="clear-all" onClick={() => setSelectedSharedUsers([])}>
        Clear All
      </div>
      <Autocomplete
        multiple
        disablePortal
        size="small"
        id="combo-box-demo"
        popupIcon={<KeyboardArrowDownRoundedIcon />}
        value={selectedSharedUsers}
        options={allUsers}
        getOptionLabel={(option) => option.name}
        filterOptions={(options, params) => {
          const filtered = filters(options, params);
          return [{ label: "Select All", name: "Select All" }, ...filtered];
        }}
        sx={{ width: 300 }}
        onChange={handleCheck}
        renderOption={(props, option, { selected }) => {
          const selectAllProps = option?.label === "Select All" ? { checked: selectAll.current } : {};
          return (
            <li {...props} key={option.userUuid}>
              <Checkbox
                // icon={icon}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                color="success"
                size="small"
                style={{ marginRight: "10px", height: "16px", width: "16px" }}
                checked={option.cardShared || selected}
                {...selectAllProps}
              />
              {option.label !== "Select All" && <Avatar {...stringAvatar(option.name)} style={{ marginRight: "10px" }} />}
              {option.name}
            </li>
          );
        }}
        renderTags={(value, getTagProps) => (
          <div className="tag-root">
            {value.slice(0, 2).map((e, index) => {
              return (
                <>
                  {e.name && (
                    <div className="tag-item">
                      <Avatar {...stringAvatar(e.name)} />
                      <span>{e.name}</span>
                      <Chip {...getTagProps({ index })} deleteIcon={<CloseIcon />} />
                    </div>
                  )}
                </>
              );
            })}
            {value.length - 2 > 0 && <>+{value.length - 2}</>}{" "}
          </div>
        )}
        renderInput={(params) => <TextField {...params} fullWidth placeholder="Search People" onInput={handleInput} />}
      />
    </>
  );
};
