import React, { useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import './LegendWithMenu.css';

const LegendWithMenu = ({ AttributeList }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className="legendItems">
      {AttributeList.slice(0, 4).map((item, index) => (
        <div key={index} className="legendItem">
          <div className="colorBox" style={{ backgroundColor: item.color }} />
          <span className="labelText">{item.label}</span>
        </div>
      ))}
      
      {AttributeList.length > 4 && (
        <div onClick={handleMenuClick} className="menuIcon">
          <div className="menuIconInner">
            <MoreHorizIcon />
          </div>
        </div>
      )}
      
      <Menu id="legend-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {AttributeList.slice(4).map((item, index) => (
          <MenuItem key={index} onClick={handleCloseMenu}>
            <div className="colorBox" style={{ backgroundColor: item.color }} />
            <Typography variant="body2">{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LegendWithMenu;
