const TENANT_CONFIG = window._env_.TENANT_CONFIG;
const getTenantConfigJSON = async (tenantId, tool, tenantConfigKeys) => {
  try {
    // const tenantConfigResponse = await fetch(`${process.env.TENANT_CONFIG}/tenant/get-tenant-config-json`,{
    const tenantConfigResponse = await fetch(
      `${TENANT_CONFIG}/tenant/get-tenant-config-json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: tenantId,
          tool: tool,
          keys: tenantConfigKeys,
        }),
        credentials: "include",
      }
    );
    const tenantConfigResponseJson = await tenantConfigResponse.json();
    console.log(
      "tenantConfgResponseJson " + JSON.stringify(tenantConfigResponseJson)
    );
    if (!tenantConfigResponseJson.success) {
      throw "failed to fetch tenant config";
    }
    const tenantConfigResponseJsonArray = {};
    for (const tenantConfig of tenantConfigResponseJson.data.configs) {
      tenantConfigResponseJsonArray[tenantConfig.configKey] =
        tenantConfig.configVal;
    }
    const tenantConfigResponseArray = [];
    for (const key of tenantConfigKeys) {
      if (key in tenantConfigResponseJsonArray) {
        tenantConfigResponseArray.push({
          configKey: key,
          configVal: tenantConfigResponseJsonArray[key],
        });
      }
    }
    return tenantConfigResponseArray;
  } catch (err) {
    console.error(err);
    return [];
  }
};
export default getTenantConfigJSON;
