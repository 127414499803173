import { Button, Dialog, DialogTitle, TextField, DialogActions, ThemeProvider } from "@material-ui/core";
import { Alert, Autocomplete, Collapse, DialogContent, DialogContentText } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useState, useEffect } from "react";
import moment from "moment";
import { camalize } from "./../insightForm/components/title";
import b2theme from "@bamboobox/b2-theme";
import Box from "@material-ui/core/Box";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/styles";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Audience_DropDown from "@bamboobox/b2_audience_tags_component/dist/Audience_DropDown";
import Tags_DropDown from "@bamboobox/b2_audience_tags_component/dist/Tags_DropDown";
import AudienceandTagsDropDown from "@bamboobox/b2_audience_tags_component/dist/Audience_Tags_DropDown";
import AudienceAndFolder_DropDown from "@bamboobox/b2_audience_tags_component/dist/Audience_Folders";

const filter = createFilterOptions();
const CampaignClass = makeStyles((theme) => ({
  popUp: {
    "& .MuiDialog-paperWidthSm": {
      padding: "9px",
      borderRadius: "24px",
    },
  },
}));
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const AddCampaign = (props) => {
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
  });

  const className = CampaignClass();
  const [isOpen, setOpen] = useState(false);
  const [uiMAPData, setUiMAPData] = useState([]);
  const [selectedMAP, setSelectedMAP] = useState({ tool: "" });
  const [alertCount, setAlertCount] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  const [selectAllTags, setSelectAllTags] = useState(false);
  const [selectAllAudience, setSelectAllAudience] = useState(false);

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isSelectAllAudienceChecked, setIsSelectAllAudienceChecked] = useState(false);
  const [keyword_In_TagsDropdown, setKeyword_In_TagsDropDown] = useState("");
  const [keyword_In_AudienceDropdown, setKeyword_In_AudienceDropDown] = useState("");

  console.log("audienceListData", audiences);
  console.log("props.card", props.cardResult);
  console.log("selectedMap", selectedMAP);
  console.log("props in AddCampaign", props);
  console.log("setKeyword_In_TagsDropDown", keyword_In_TagsDropdown);
  const [audSelected, setAudSelected] = useState([]);

  let stateVariables = {
    audiences,
    setAudiences,
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    selectAllAudience,
    setSelectAllAudience,
    selectAllTags,
    setSelectAllTags,
    isSelectAllChecked,
    setIsSelectAllChecked,
    isSelectAllAudienceChecked,
    setIsSelectAllAudienceChecked,
    keyword_In_TagsDropdown,
    setKeyword_In_TagsDropDown,
    keyword_In_AudienceDropdown,
    setKeyword_In_AudienceDropDown,
    audSelected,
    setAudSelected,
  };

  console.log("uiMapData", uiMAPData);
  console.log("selectedMap", selectedMAP);

  const handleeClose = async () => {
    props.setOpen(false);
    setSelectedMAP({ tool: "" });
    setAudSelected([]);
    setSelectedTagsList([]);
    setSelectAllTags(false);
    setSelectAllAudience(false);
    setIsSelectAllChecked(false);
    setIsSelectAllAudienceChecked(false);
  };

  const handleSyncToList = async (cardId) => {
    console.log("cardId", cardId);

    let url = `${window._env_.LIST_SYNC_URL}/tenantPlatforms/addToAudienceList?tenantId=${props.tenantId}&platform=${selectedMAP?.tool}&cardId=${props?.cardResult.filterDetails.filterId}&icp=${props.icp}`;

    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        audience_list: Array.isArray(audSelected) ? audSelected : [audSelected],
        tags_list: selectedTagsList,
        selectAll: true, //for cards assuming everything is selected hence true
        screen: "Card",
        selected_map_config: selectedMAP,
        icp: props.icp,
        contSegId: props?.cardResult.filterDetails.filterId,
      }),
    };

    let response = await fetch(url, requestOptions);
    let resp = await response.json();

    console.log("resp from handleSyncToList", resp);
    if (!resp?.success) {
      toast.error(<Alert severity="error">Unable to add contacts to Audiences</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
    } else {
      toast.success(
        <Alert severity="success">
          Added {props?.cardResult?.resultCount} Contacts to {audSelected?.length} {audSelected?.length > 1 ? "Audiences" : "Audience"}. Sync to Platform will take upto 60 minutes.
        </Alert>,
        {
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        }
      );
    }

    handleeClose();
  };

  useEffect(() => {
    const tenantBody = {
      tenantId: props.tenantId,
      tool: "MAP",
      keys: ["Tools"],
    };

    fetch(`${window._env_.TENANT_CONFIG}/tenant/get-tenant-config-json`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tenantBody),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        try {
          if (props.Type === "ACCOUNT") {
            setUiMAPData(
              response.data.configs[0].configVal.filter((element) => (element.exportFlag === true || element.exportFlag === "true") && (element.accountSync === true || element.accountSync === "true"))
            );
          } else {
            setUiMAPData(
              response.data.configs[0].configVal.filter((element) => (element.exportFlag === true || element.exportFlag === "true") && (element.contactSync === true || element.contactSync === "true"))
            );
          }
        } catch (e) {
          console.log(e);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // if(selectedMAP?.tagOrAudience){
    //   console.log("inside if")
    //   getAudienceDataFromMap()
    // }else{
    fetch(`${window._env_.B2_CDPAPI}/program/get-all-active-programs?tenantId=${props.tenantId}&source=${selectedMAP.tool}`, { credentials: "include" })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          console.log("add to campaign result", response.data.programs);
          setCampaignData(response.data.programs);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  }, [selectedMAP?.tool]);

  return (
    <ThemeProvider theme={b2theme}>
      <Dialog
        PaperProps={{
          style: { borderRadius: 22, paddingBottom: 7 },
        }}
        open={props.open}
        onClose={handleeClose}
      >
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "562px",
          }}
        >
          <DialogTitle style={{ fontWeight: "bold" }}>Assign To Campaign</DialogTitle>
          <span style={{ marginTop: "20px", paddingRight: "20px" }}>
            <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={handleeClose} />
          </span>
        </div>
        {
          <DialogContent>
            <Autocomplete
              style={{ padding: 3 }}
              size="small"
              options={uiMAPData}
              getOptionLabel={(option) => {
                // let label = option.tool.slice(0, 1).toUpperCase() + option.tool.slice(1, option.tool.length).toLowerCase();
                // return label;
                if (option.labelForTools) {
                  //return option.tool.slice(0, 1).toUpperCase() + option.tool.slice(1,6).toLowerCase() +" "+option.tool.slice(6,7).toUpperCase()+ option.tool.slice(7,option.tool.length).toLowerCase()
                  return option.labelForTools;
                } else {
                  return option.tool.slice(0, 1).toUpperCase() + option.tool.slice(1, option.tool.length).toLowerCase();
                }
              }}
              value={selectedMAP}
              onChange={(event, value) => {
                setAudSelected([]);
                setTagsList([]);
                setAudiences([]);
                setSelectedTagsList([]);
                value === null ? setSelectedMAP({ tool: "" }) : setSelectedMAP(value);
              }}
              renderInput={(params) => (
                <TextField {...params} autoFocus margin="dense" size="small" id="name" placeholder="Platform Name" label="Marketing Platform" type="text" fullWidth variant="outlined"></TextField>
              )}
              renderOption={(props, option) => {
                console.log("value of option and props", props);
                console.log("option", option);
                return (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    <div style={{ lineHeight: "5px" }}>
                      <img width="20" height="20" src={`${window._env_.B2_INTENT_SETTING}/logos/${option.tool === "Google Ads" ? "googleads".toLowerCase() : option.tool.toLowerCase()}.png`}></img>
                    </div>
                    <div>
                      <span style={{ visibility: "hidden" }}>.</span>
                      {option.labelForTools ? option.labelForTools : option.tool.slice(0, 1).toUpperCase() + option.tool.slice(1, option.tool.length).toLowerCase()}
                    </div>
                  </Box>
                );
              }}
            />
            {selectedMAP?.tool === "GOOGLEADS" && (
              <div style={{ marginTop: "0px", marginBottom: "25px" }}>
                <div
                  style={{
                    backgroundColor: "#EBFAFB",
                    display: "flex",
                    width: "98%",
                    height: "50px",
                    borderRadius: "8px",
                    marginbottom: "8px",
                    padding: "4px",
                    gap: "8px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginleft: "3px",
                  }}
                >
                  <p
                    style={{
                      color: "#165154",
                      fontFamily: "Nunito Sans",
                      size: "12px",
                      width: "100%",
                      height: "32px",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "16.37px",
                      margin: "8px",
                    }}
                  >
                    To comply with Google EU user consent policy and GDPR, contact's consent is required to show them personalised ads.
                  </p>
                </div>
              </div>
            )}

            {
              selectedMAP?.folderAndAudience && selectedMAP?.folderAndAudience === true && selectedMAP?.tagOrAudience && selectedMAP?.tagOrAudience===true ?

            <>
              <AudienceAndFolder_DropDown tenantId={props.tenantId} selectedMAP={selectedMAP} stateVariables={stateVariables} ></AudienceAndFolder_DropDown>
            </>
              :selectedMAP?.tags && selectedMAP?.tags === true ? (
              <>
                <Tags_DropDown tenantId={props.tenantId} selectedMAP={selectedMAP} stateVariables={stateVariables}></Tags_DropDown>
              </>
            ) : selectedMAP?.tagOrAudience && selectedMAP?.tagOrAudience === true ? (
              <>
                <Audience_DropDown tenantId={props.tenantId} selectedMAP={selectedMAP} stateVariables={stateVariables}></Audience_DropDown>
              </>
            ) : selectedMAP?.tagAndAudience && selectedMAP?.tagAndAudience === true ? (
              <AudienceandTagsDropDown
                styleTags={{ audience_margin: "0px 0px 20px 0px" }}
                tenantId={props.tenantId}
                selectedMAP={selectedMAP}
                stateVariables={stateVariables}
              ></AudienceandTagsDropDown>
            ) : (
              <>
                <Autocomplete
                  style={{ padding: 3 }}
                  size="small"
                  options={campaignData.sort((a, b) => -b.programName?.localeCompare(a.programName))}
                  disabled={selectedMAP.tool === "" ? true : false}
                  value={selectedCampaign}
                  onChange={(event, value) => {
                    setSelectedCampaign(value);
                  }}
                  renderOption={(props, option) => (
                    <div>
                      <div {...props}>
                        <p2
                          style={{
                            position: "static",
                            color: "black",
                            paddingLeft: "8px",
                            fontSize: "14px",
                            lineHeight: "166%",
                            lineSpacing: "0.4px",
                          }}
                        >
                          {" "}
                          {option.programName}{" "}
                        </p2>
                        <p2
                          style={{
                            position: "static",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingLeft: "8px",
                            fontSize: "12px",
                            lineHeight: "166%",
                            lineSpacing: "0.4px",
                          }}
                        >{` | ${option.b2programType} | since ${moment(option.programCreatedAt.substring(0, option.programCreatedAt.indexOf("Z") + 1)).format("DD MMM YYYY")} `}</p2>
                      </div>
                    </div>
                  )}
                  getOptionLabel={(option) => option.programName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      // select
                      size="small"
                      margin="dense"
                      id="name"
                      label=""
                      type="text"
                      fullWidth
                      variant="outlined"
                    ></TextField>
                  )}
                />
                <Collapse in={isAlertOpen}>
                  <Alert id="campaign-alert" severity="warning">
                    {" "}
                    <span style={{ color: "#83551b" }}>{alertCount} Contacts are already assigned to the same campaign</span>
                  </Alert>
                </Collapse>
              </>
            )}
          </DialogContent>
        }
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 18px 0 28px" }}>
          {props.count > 1 ? (
            <DialogContentText
              style={{
                marginBottom: "6px",
                color: "black",
              }}
            >
              {props.count} {camalize(props.Type)}s are selected
            </DialogContentText>
          ) : (
            <DialogContentText
              style={{
                marginBottom: "6px",

                color: "black",
              }}
            >
              {props.count} {camalize(props.Type)} is selected
            </DialogContentText>
          )}

          <DialogActions sx={{ gap: "32px", textAlign: "center" }}>
            <Button style={{ alignItems: "center" }} variant="outlined" onClick={handleeClose}>
              <CloseOutlinedIcon style={{ color: "#A0A0A0", width: "20%" }} />
              CANCEL
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (selectedMAP?.tagOrAudience || selectedMAP?.tagAndAudience) {
                  handleSyncToList(props.cardId);

                  // props.setOpen(false)
                } else {
                  props.onConfirm(selectedCampaign, selectedMAP);
                }
                props.setOpen(false);
                setSelectedMAP({ tool: "" });
                setAudSelected([]);
                setSelectedTagsList([]);
                setIsSelectAllChecked(false);
                setIsSelectAllAudienceChecked(false);
              }}
              disabled={(selectedCampaign === null && selectedTagsList?.length === 0 && audSelected?.length === 0) || props.count === 0 || props.count === alertCount ? true : false}
            >
              <DoneRoundedIcon style={{ color: "#FFFFFF", width: "20%" }} />
              CONFIRM
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};
