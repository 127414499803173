import React from 'react';
import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


function SingleDatePickerComponent(props) {

    
    const [selectedDate, setSelectedDate] = React.useState(props.attributeData.values[0]);
    function convertUTCDateToLocalDate(date) {
        try{
        let offset = date.getTimezoneOffset()*60*1000
        if(offset>0){
            date.setHours(0)
            date.setMinutes(0)
            date = new Date(date)
        }
        else{
            date = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
        }        
        return date;
    }catch(e){
        console.log("catch block error convertUTCDateToLocalDate:",e);
    }
      }


    const handleDateChange = (value) => {
        console.log('value in handle function is',value);
        ;
        setSelectedDate(convertUTCDateToLocalDate(value));
        var tempDate = convertUTCDateToLocalDate(value);
        props.onAttributeChange([tempDate],"values")
        console.log("handleDateChange ", value, new Date(value),props.attributeData.values[0]);
    };


    React.useEffect(() => {
        setSelectedDate(props.attributeData.values[0]||null)
    }, [props]);
    
    return (
        <React.Fragment>
            
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                 <KeyboardDatePicker
                    disableToolbar
                    disabled={props?.disabled||false}
                    maxDate={
                        props.attributeData.attribute !== 'opportunityCloseDate'
                            ? new Date()
                            : undefined
                    }
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder='DD/MM/YYYY'
                    InputProps={{
                        style: {
                            fontSize: 16,
                            height: 40,
                            color:"rgba(3, 3, 3, 0.6)",
                        }
                    }}
                    style={{ width: '100%'}}
                    value={selectedDate||null}
                    autoOk={true}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
               
            </MuiPickersUtilsProvider>
        </React.Fragment>


    );
}

export default SingleDatePickerComponent;