import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Skeleton } from '@mui/material';
import {Loader} from '../../../../assets/icons/FallBackComponents'

import ReactFC from "react-fusioncharts";

import FusionCharts from "fusioncharts";

import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import { AttributeList } from "./totalAccount/AttributeList";

import CircularProgress from "@mui/material/CircularProgress";
import Divider from '@mui/material/Divider';
import CryptoJS from "crypto-js";
import {LICENSE_STRING} from "./Constant";
// import Alert from '@mui/material/Alert';
import _ from "lodash";

if (LICENSE_STRING != "") {
  var _0x49bc0e = _0x298e;
  function _0x298e(_0x7d727b, _0x38966f) {
    var _0x1b8773 = _0x1b87();
    return (
      (_0x298e = function (_0x298eb3, _0x1414f4) {
        _0x298eb3 = _0x298eb3 - 0x1ea;
        var _0x32f714 = _0x1b8773[_0x298eb3];
        return _0x32f714;
      }),
      _0x298e(_0x7d727b, _0x38966f)
    );
  }
  (function (_0x5c9ab4, _0x59221e) {
    var _0xe05717 = _0x298e,
      _0x550b93 = _0x5c9ab4();
    while (!![]) {
      try {
        var _0x3a14a4 =
          parseInt(_0xe05717(0x1f4)) / 0x1 +
          parseInt(_0xe05717(0x1f7)) / 0x2 +
          parseInt(_0xe05717(0x1ee)) / 0x3 +
          -parseInt(_0xe05717(0x1ea)) / 0x4 +
          (parseInt(_0xe05717(0x1eb)) / 0x5) *
            (parseInt(_0xe05717(0x1ec)) / 0x6) +
          (parseInt(_0xe05717(0x1f0)) / 0x7) *
            (-parseInt(_0xe05717(0x1ef)) / 0x8) +
          (-parseInt(_0xe05717(0x1f6)) / 0x9) *
            (-parseInt(_0xe05717(0x1f5)) / 0xa);
        if (_0x3a14a4 === _0x59221e) break;
        else _0x550b93["push"](_0x550b93["shift"]());
      } catch (_0x3a3581) {
        _0x550b93["push"](_0x550b93["shift"]());
      }
    }
  })(_0x1b87, 0xed863);
  function _0x1b87() {
    var _0x3cdb73 = [
      "Utf8",
      "b2\x20cdp\x20uses\x20fustion\x20chart",
      "169152xDHSeA",
      "30SRtDAJ",
      "2786688ScKRos",
      "1604846jQeLkv",
      "license",
      "options",
      "3060620rKUxON",
      "3982685QzwgIi",
      "6quDyMF",
      "enc",
      "1686396OqHHUo",
      "1738384mMIoTV",
      "49NMgycc",
      "AES",
    ];
    _0x1b87 = function () {
      return _0x3cdb73;
    };
    return _0x1b87();
  }
  var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]
    ["decrypt"](LICENSE_STRING, _0x49bc0e(0x1f3))
    ["toString"](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);
  FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({
    key: oooo0000,
    creditLabel: ![],
  });
}


const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    margin:'2px'
  },
  chart: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center'
  },
  heading: {
    display: "flex",
    justifyContent: "flex-start",
    paddingTop: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#172b4d"
  },
  caption: {
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "bold",
    width: "370px"
  },
  label:{
    height: "14px",
    width: "14px",
    display: "inline-flex",
    borderRadius: "4px"
  },
  labelMain: {
  display: 'flex',
  // justifyContent: 'space-evenly'
},
level:{
  // fontFamily: Proxima Nova;
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "14px",
  /* identical to box height */
  color: "#A0A0A0",
}
,value:
{
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "20px",
  /* identical to box height */
  color: "#595959"
}
}));

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

function IntentChart(props) {
//   toast.configure({
//     autoClose: 6000,
//     draggable: true,
//     hideProgressBar: false,
//     position: toast.POSITION.BOTTOM_LEFT,
//     icon: false,

//   });
  console.log("request Body", props.requestBody);

  const controllerRef = useRef();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [totalAccount,setTotalAccount]=useState(0);
  const [lowVal,setLowVal]=useState(0);
  const [mediumVal,setMediumVal]=useState(0);
  const [highVal,setHighVal]=useState(0);
  const [total,setTotal] = useState(0);
//   const [attributeValue1, setAttributeValue1] = React.useState(
//     AttributeList[0]
//   );
  const pieChartData = [];


  const getLabelData = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    fetch(
      `${window._env_.UNIFIED_URL}/analytics/intent/intent/intentLevelPie/${props.tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({"filters": props.requestBody}),
        signal:controllerRef.current?.signal,
        credentials: "include",
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let high=0,medium=0,low=0
        if(!data?.length){
          setHighVal(high);
          setMediumVal(medium)
          setLowVal(low)
          setTotal(parseInt(high)+parseInt(medium)+parseInt(low))
        } 
        data?.map((level,index) => {
          console.log("set date", level.intent_creation_date)
          let dynamicFilterObject = [
            {
              key:{index},
              attrId: "E63kyu2eapdehvn52J",
              attribute: "Intent Level",
              type: "INTENT",
              relation: "includes",
              values: [],
            }
          ];
          if (!(Array.isArray(props.requestBody) && !props.requestBody.length)) {
            props.requestBody.forEach(reqBody => {
              dynamicFilterObject.push(reqBody)
            })
          }
          let attributeRowValueObject = {
            label: "",
            value: "",
            color: "",
            link: `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&category=${props.category}`,
          };

          if (level.b2_intent_level === "VERY HIGH") {
            dynamicFilterObject[0]["values"].push(level.b2_intent_level);
            attributeRowValueObject["label"] = "VERY HIGH";
            attributeRowValueObject["value"] = level.num_of_acc;
            high=level.num_of_acc
            attributeRowValueObject["color"] = "#1ABF6E ,#FFFFFF";
            attributeRowValueObject[
              "link"
            ] = `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&category=${props.category}`;
            pieChartData.push(attributeRowValueObject);
          } else if (level.b2_intent_level === "HIGH") {
            dynamicFilterObject[0]["values"].push(level.b2_intent_level);
            attributeRowValueObject["label"] = "HIGH";
            attributeRowValueObject["value"] = level.num_of_acc;
            medium=level.num_of_acc
            attributeRowValueObject["color"] = "#016B40,#14DE95";
            attributeRowValueObject[
              "link"
            ] = `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&category=${props.category}`;
            pieChartData.push(attributeRowValueObject);
          } else if (level.b2_intent_level === "MEDIUM") {
            dynamicFilterObject[0]["values"].push(level.b2_intent_level);
            attributeRowValueObject["label"] = "MEDIUM";
            attributeRowValueObject["value"] = level.num_of_acc;
            low=level.num_of_acc
            attributeRowValueObject["color"] = "#FDC731,#08A9CC";
            attributeRowValueObject[
              "link"
            ] = `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&category=${props.category}`;
            pieChartData.push(attributeRowValueObject);
          }
          setHighVal(high);
          setMediumVal(medium)
          setLowVal(low)
          setTotal(parseInt(high)+parseInt(medium)+parseInt(low))
        });
        setLoading(false);
        setChartData(pieChartData);

        console.log("data is", pieChartData);
        // }
      }).catch((error) => {
    //       if(error?.message !== "The user aborted a request.") {
    //         toast.warn(<Alert severity="warn">No pending requests available</Alert>,{
    //         closeOnClick: true,
    //         autoClose: true,
    //         closeButton: true,
    //         newestOnTop: true
    //     });
    //   }
      })
  };

  useEffect(() => {
    console.log("intent piechart useffect", props.icpSelected, props.segmentName)
    setLoading(true)
    if (props.icpSelected !== null && props.segmentName !== '') {
      getLabelData();
    }

  }, [
    props.requestBody,
    props.icpSelected,
    // attributeValue1,
    props.segmentName,
    props.softRefresh,
    props.refresh
  ]);
 
  const chartConfigs = {
    type: "doughnut2d", // The chart type
    width: "262", // Width of the chart
    height: "262", // Height of the chart
    renderAt: "chart-container",
    dataFormat: "json", // Data type
    xAxisNameFontColor : "#5E5454",
                    xAxisNameFontSize:"9px",
                    yAxisNameFontColor : "#5E5454",
                    yAxisNameFontSize:"9px", // Optional: Set font color
                    xAxisValueFontColor: '#5E5454', // Optional: Set font color
                    yAxisValueFontColor: '#5E5454', // Optional: Set font color 
                    xAxisNameFontBold:"0",
                    yAxisNameFontBold:"0",
                    "yAxisValueFont": "Open Sans",
                    "yAxisValueFontSize": "9px",
                    "yAxisValueFontColor": "#5F6B7A",
                    "xAxisFont": "Inter",
                    "xAxisFontSize": "10px",
                    "xAxisFontColor": "#ff0000",
                    "baseFont": "Open Sans",
                    "baseFontSize": "9px",
                    "baseFontColor": "#5F6B7A",

    dataSource: {
      // Chart Configuration
      chart: {
        animation: "0",
        // captionFontFamily: "Proxima Nova",
        captionFontSize: "14px",
        captionFontColor: "#000000",
        showlegend: "0",
        showpercentvalues: "0",
        enableSlicing: "1",
        labelSepChar: "\n",
        showLabels:"0",
        showValues:"0",
        "defaultCenterLabel": `${total}`,
        labelFontSize: "22",
        captionOnTop: "0",
        pieRadius: "100",
        "doughnutRadius":"70",
        minAngleForLabel: "1",
        minAngleForValue: "1",
        // showShadow: "1",
        labelFontBold: "1",
        theme: "fusion",
        showPlotBorder: "1",
        plotBorderColor:"#FFFFFF",
        plotBorderThickness:"5",
        centerLabelFontSize: "22",
        centerLabelFont:"Inter",
        centerLabelColor:"#595959",
        centerLabelBold:"1",
        "toolTipBorderRadius": "10",
        "toolTipBgColor": "#FFFFFF",
        "toolTipBgAlpha": "100",
        "showToolTipShadow": "1"
      },
      data: chartData,
    },
  };

  const classes = useStyles();

  return (
    <div>
      {/* <div className={classes.heading}>Account Distribution</div> */}
      {loading ? (<Loader />) : (
        <div className={classes.container}>
          <div className={classes.chart}>
            <ReactFC {...chartConfigs} />
            <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',gap:"25px",marginLeft:"20px"}}>
              <div className={classes.labelMain}>
              <div style={{display: 'flex',flexDirection: 'column',paddingRight: "10px"}}>
              <Divider  className={classes.label} orientation="vertical" style={{ backgroundColor:"#1ABF6E",height: "14px",
                      width: "14px",
                      display: "inline-flex",
                      borderRadius: "2px"}}/>
              </div>
              <div style={{display: 'flex',flexDirection: 'column'}}>
                <span className={classes.level}>VERY HIGH</span>
                <span className={classes.value}>{highVal}</span>
              </div>
              </div>
              <div className={classes.labelMain}>
              <div style={{display: 'flex',flexDirection: 'column',paddingRight: "10px"}}>
              <Divider  className={classes.label} orientation="vertical" style={{ backgroundColor:"#016B40",height: "14px",
                width: "14px",
                display: "inline-flex",
                borderRadius: "2px"}}/>
              </div>
              <div style={{display: 'flex',flexDirection: 'column'}}>
                <span className={classes.level}>HIGH</span>
                <span className={classes.value}>{mediumVal}</span>
              </div>
              </div>
              <div className={classes.labelMain}>
              <div style={{display: 'flex',flexDirection: 'column',paddingRight: "10px"}}>
              <Divider className={classes.label}  orientation="vertical" style={{backgroundColor:"#FDC731",height: "14px",
              width: "14px",
              display: "inline-flex",
              borderRadius: "2px"}}/>
              </div>
              <div style={{display: 'flex',flexDirection: 'column'}}>
                <span className={classes.level}>MEDIUM</span>
                <span className={classes.value}>{lowVal}</span>
              </div>
              </div>
            </div>
          </div>
         
        </div>
      )}
    </div>
  );
}

export default IntentChart;
