import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useAuthState } from "@bamboobox/b2logincheck";
import "./Settings.css";
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { BackIcon } from "../../assets/svg";
import config from '../../config.json';
import { useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CreateCardIcon, BambooBoxIcon, NoData } from '../../assets/svg';
import { ToastContainer } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from '@mui/material/Checkbox';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Button from '@mui/material/Button';
import SharePopUp from "./ShareUserList";
import Tooltip from '@mui/material/Tooltip';
import { getTitle } from "../../helpers/common.helper";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  marginRight: "-8px",
  "& .MuiSwitch-track": {
    borderRadius: 8,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    borderRadius: 3,
    boxShadow: "none",
    color: "white",
    width: 13,
    height: 14,
    marginLeft: 4.2,
    marginTop: 3,
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#78C51C !important",
    opacity: "1 !important",
  },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Settings = (props) => {
  const [cardDetails, setCardDetails] = useState([]);
  const [shareableRowData, setShareableRowData] = useState([])
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [newInsightCardCount, setNewInsightCardCount] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const icp = query.get("icp") || "";
  const category = query.get("category" || "");

  const formatDate = useMemo(() => {
    return (dateString) => {
      if (!dateString || dateString.length === 0) {
        return '';
      }

      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      
      const date = new Date(dateString);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
    
      if (date.toDateString() === today.toDateString()) {
        return 'Today';
      } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
      } else {
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
    };
  }, []);

  const handleShareClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    const updatedRows = cardDetails.map((row) => ({ ...row, checked: false }));
    setCardDetails(updatedRows);
    setSelectedCount(0)
  };
  
  const fetchCardDetails = () => {
    try {
      axios.get(`${window._env_.CARD_SERVICE}/card/v2/listCards/${tenantId}`, { withCredentials: true })
      .then((e) => {
        setCardDetails(e.data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error in fetchCardDetails line 103", error);
        setCardDetails([]);
        setIsLoading(false);
      })
    } catch (error) {
      console.log("Error in fetchCardDetails line 109", error);
      setCardDetails([]);
      setIsLoading(false);
    }
  }

  const fetchNewCardCount = () => {
    try {
      axios.get(`${window._env_.CARD_SERVICE}/card/v2/getNewCounts/${tenantId}?icp=${icp}`, { withCredentials: true }).then(e => {
        setNewInsightCardCount(e.data.count)
      })
    } catch (error) {
      console.log('Error in fetchNewCardCount', error);
    }
  }

  const groupedData = cardDetails?.reduce((acc, item) => {
    const category = item.category;
    if (config.categories.includes(category)) {
      if (!acc[category]) {
        acc[category] = {
          count: 0,
          items: [],
        };
      }
      acc[category].count++;
      acc[category].items.push(item);
    }
    return acc;
  }, {});

  useEffect(()=>{
    fetchCardDetails();
    fetchNewCardCount();
  }, [])

  const handleDragEnd = (param) => {
    if (!param.destination) {
      // If the cards are dragged outside of droppable area
      return; 
    }

    const sourceId = param.source.droppableId;
    const destinationId = param.destination.droppableId;
  
    if (sourceId !== destinationId) {
      // If the cards are dragged to different category
      return;
    }
  
    const sourceCategoryData = groupedData[sourceId];
    const destinationCategoryData = groupedData[destinationId];

    const movedItemIndex = sourceCategoryData.items.findIndex(item => item.cardId === param.draggableId);
    const movedItem = sourceCategoryData.items[movedItemIndex];
    sourceCategoryData.items.splice(movedItemIndex, 1);
    const destinationIndex = param.destination.index;
    destinationCategoryData.items.splice(destinationIndex, 0, movedItem);
    destinationCategoryData.items.forEach((item, index) => {
      item.order = destinationCategoryData.items.length - index - 1;
    });

    const updatedCardDetails = cardDetails.map(item => {
      if (item.category === destinationId) {
        const updatedItem = destinationCategoryData.items.find(i => i.cardId === item.cardId);
        return { ...item, order: updatedItem.order };
      }
      return item;
    });
    // console.log('Card details', updatedCardDetails)
    handleSave(updatedCardDetails)
  }

  const handleSave = (updatedCardDetails) => {
    try {
      const body = updatedCardDetails.map((e) => ({
        cardUuid: e?.cardId,
        hidden: e?.hide || false,
        displayorder: e?.order || 0,
        notified: true
      }))
      console.log('body', body)
      axios.post(`${window._env_.CARD_SERVICE}/action/set/order/hide/${tenantId}`, body, { withCredentials: true })
    } catch (error) {
      console.log('Error inside handleSave', error);
    }
  }

  const handleVisibility = (cardId) => {
    try {
      setCardDetails(cardDetails.map(e => {
        if (e.cardId === cardId) {
          if (e.isNew === true) {
            setNewInsightCardCount(newInsightCardCount - 1)
          }
          e.hide = !e.hide
          e.isNew = false
         }
         return e
      }))
      const updatedCard = cardDetails.find(e => e.cardId === cardId);
      const body = [updatedCard].map((e) => ({
        cardUuid: e?.cardId,
        hidden: e?.hide || false,
        displayorder: e?.order || 0,
        notified: true,
        isNew: false
      }))
      console.log('Visibility body', body)
      axios.post(`${window._env_.CARD_SERVICE}/action/set/order/hide/${tenantId}`, body, { withCredentials: true })
      // handleSave([updatedCard]);
    } catch (error) {
      console.log("Inside handleVisibility error", error);
    }
  }

  const toggleExpand = (category) => {
    setExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [category]: !prevExpanded[category],
    }));
  };

  const handleCheckboxToggle = (id) => {
    const updatedRows = cardDetails.map((row) =>
      row.cardId === id ? { ...row, checked: !row.checked } : row
    );
    setCardDetails(updatedRows);
    setShareableRowData(updatedRows.filter((row) => row.checked))
    setSelectedCount(updatedRows.filter((row) => row.checked).length);
  };

  const resetCheckedState = () => {
    const updatedRows = cardDetails.map((row) => ({ ...row, checked: false }));
    setCardDetails(updatedRows);
    setSelectedCount(0)
  };

  /* Variable to check if data from API has "category" field or not and also the value of category should be part of the
  predefined categories in config file, which in turn will help in displaying no data SVG */
  const hasCategoryField = cardDetails.length > 0 && cardDetails.some((item) => {
    return item.hasOwnProperty('category') && config.categories.includes(item.category);
  });

  return (
    <>
      <ToastContainer position="bottom-left" />
      <div className="card-form-root">
        <div className='icon-div'>
          <a href={`${window._env_.NEW_DASHBOARD_VIEW}/#/?icp=${icp}&category=${category}`} className="link">
            <BackIcon style={{ height: "24px", width: "24px" }} />
          </a>
          <Typography style={{ fontSize: "24px", fontWeight: "600", color: '#3D3D3B' }} className="text">Create an insight</Typography>
        </div>
        <div className="card-form-body">
        <div className="create-card">
        <div className="create-card-second-div">
          <div className="icon-layout">
            <div className="icon"><CreateCardIcon /></div>
          </div>
          <div className="h1-container">
            <p className="first-p">Create your own insight</p>
            <p className="second-p">Make your own insights based on your own experience</p>
          </div>
        </div>
        <a href={`${window._env_.NEW_DASHBOARD_VIEW}/#/insightForm?icp=${icp}&category=${category}&type=Create`}>
          <button className="insight-button">Create</button>
        </a>
        </div>
        <div className="wrapper">
        <div className="insight-list-main-div">
        <div className="insight-list-left">
          Choose from a list of insights
          {newInsightCardCount > 0 && <div className="notification"> 
            <div className="notification-text">{`${newInsightCardCount} NEW INSIGHTS`}</div>
          </div>}
        </div>
        <div className="insight-list-right">
          {selectedCount > 0 && <div style={{fontWeight: 600, fontSize: '16px', color: '#29702B'}}>{selectedCount} items selected</div>}
          {selectedCount > 0 && <Button style={{ textTransform: 'none' }} variant="contained" color="success" sx={{ borderRadius: '8px', marginLeft: '30px', marginRight: '20px', heigh: '30px', width: '54px' }} onClick={handleShareClick}>Share</Button>}
          {selectedCount > 0 && <Button style={{ textTransform: 'none' }} variant="text" sx={{ color: '#3D3D3B', fontWeight: 600, fontSize: '12px' }} onClick={resetCheckedState}>Cancel</Button>}
        </div>
        {isPopupOpen && (<SharePopUp tenantId={tenantId} count={selectedCount} data={shareableRowData} open={isPopupOpen} onClose={handleClosePopup} />)}
        </div>
        <DragDropContext onDragEnd={(param) => {handleDragEnd(param)}}>
          <div className="card-details">
          {isLoading ? (
          <div className="circular-progress-div">
            <CircularProgress style = {{ color: "#016B40" }} />
          </div>
          ) : hasCategoryField ? (Object.entries(groupedData).sort(([categoryA], [categoryB]) => {
          const indexA = config.categories.indexOf(categoryA);
          const indexB = config.categories.indexOf(categoryB);
          return indexA - indexB;
          }).map(([category, data], index, array) => (
          <Droppable key={category} droppableId={category}>
            {(provided) => (
              <div className="card-categories" ref={provided.innerRef} {...provided.droppableProps} key={category}>
              <div className="card-detail-header"> {category} ({data?.count}) </div>
              <div className="card-category-div">
                {data.items.sort((a, b) => b.order - a.order).slice(0, expandedCategories[category] ? data.items.length : 4).map((item, index) => (
                  <Draggable key={item.cardId} draggableId={item.cardId} index={index}>
                    {(provided) => (
                      <>
                      {/* <div className="card-detail-row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={item?.cardId}> */}
                      <div className={`card-detail-row ${item?.checked ? 'selected' : ''}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={item?.cardId}>
                      <DragIndicatorIcon sx={{ width: '16px', height: '16px', color: '#3A3A3A' }}/>
                      <Checkbox style={{ marginLeft: '-10px'}} disabled={item?.cardType.toLowerCase() !== 'user'} {...label} checked={item.checked || false} onChange={() => handleCheckboxToggle(item.cardId)} color = "success"/>
                        <div className="card-title-div">{getTitle({cardDetails: {title: item?.label}}, true)}
                          {item?.isNew && <div className="category-notification">NEW</div>}
                        </div>
                        <div className="date-logo">
                          <div>Last Updated {formatDate(item?.updatedAt)}</div>
                          {item?.cardType.toLowerCase() !== 'user' ? 
                          (
                            <Tooltip title={
                              <React.Fragment>
                                <div style={{ lineHeight: '2' }}>
                                  {`Created by: ${item?.createdBy}`} <br />
                                  {`Created at: ${formatDate(item?.createdAt)}`}
                                </div>
                              </React.Fragment>
                            } arrow >
                            <div style={{ cursor: 'pointer' }}><BambooBoxIcon /></div>
                            </Tooltip>
                          ) : (
                            <Tooltip title={
                              <React.Fragment>
                                <div style={{ lineHeight: '2' }}>
                                  {`Created by: ${item?.createdBy}`} <br />
                                  {`Created at: ${formatDate(item?.createdAt)}`}
                                </div>
                              </React.Fragment>
                            } arrow >
                            <div style={{ cursor: 'pointer' }}><AccountCircleIcon fontSize="large"/></div>
                            </Tooltip>
                          )
                          }
                        </div>
                        <button disabled={item?.createdBy === "me" ? false : true} className="edit-button"
                                onClick={() => { window.location.href = `${window._env_.NEW_DASHBOARD_VIEW}/#/insightForm?icp=${icp}&cardUuid=${item?.cardId}&type=Edit` }}
                                style={{ cursor: item?.createdBy === "me" ? 'pointer' : 'not-allowed' }}
                        >Edit</button>
                        <Android12Switch checked={!item?.hide} onChange={() => handleVisibility(item?.cardId)}/>
                      </div>
                      {index !== data.items.length - 1 && ( 
                        <hr className="hr-after-each-card-detail" />
                      )}
                    </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {data.items.length > 4 && (
                  <div className="view-all-div">
                    <span className="view-all-text" onClick={() => toggleExpand(category)}>
                      {expandedCategories[category] ? 'View Less' : 'View All'}
                    </span>
                  </div>
                )}
              </div>
              {index !== array?.length - 1 && (<hr className="hr-after-each-category" />)}
            </div>
            )}
          </Droppable>
          ))) : <div className="no-data-svg">
                  <NoData />
                  <span className="no-data-text">No data found</span>
                </div>
          }
          </div>
        </DragDropContext>
        </div>
        </div>      
      </div>            
    </>
  );
};

export default Settings;
