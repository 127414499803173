import { BackIcon } from "../../assets/svg";
import { Typography, Alert } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { GlobalStyles } from "./InsightStyles";
import ExportFilterCriteria from "./segment/ExportFilterCriteria";
import { useLocation } from "react-router-dom";
import { useAuthState } from "@bamboobox/b2logincheck";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "../../assets/styles/toast.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Category } from "./components/category";
import { Title } from "./components/title";
import { Description } from "./components/description";
import { Share } from "./components/share";
import { Action } from "./components/action";
import { camalize, capitalize } from "./components/title";
import config from "../../config.json";

const InsightForm = (props) => {
  console.log("props in InsightForm", props);
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const icp = query.get("icp") || "";
  const category = query.get("category") || "";
  const type = query.get("type") || "";
  const copy = query.get("copy") === "true";
  const cardUuid = query.get("cardUuid") || undefined; //|| "10735eec-0923-5c45-86d0-5f30a63f5f60";
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  // const categories = ["Data Quality and Coverage", "Engagement", "Intent", "Potential Opportunity", "Deal", "Customer"];
  const titleOptions = ["Account", "Contact"];
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [expType, setExpType] = useState("Account");
  const [filterAttributesArray, setFilterAttributesArray] = useState([[{}]]);
  const [cardDetails, setCardDetails] = useState();
  const [selectedSharedUsers, setSelectedSharedUsers] = useState([]);
  const [selectedOriginalSharedUsers, setSelectedOriginalSharedUsers] = useState([]);
  const [selectedAction, setSelectedAction] = useState("None");
  const [loading, setLoading] = useState(true);
  const redirectTimeoutId = useRef(null);

  const toastConfig = {
    closeOnClick: true,
    autoClose: true,
    closeButton: <CloseIcon style={{ width: "18px", marginLeft: "8px" }} />,
    icon: true,
    newestOnTop: true,
  };

  const CustomToastWithLink = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {message}
      <a onClick={handleBackBtn} className="toast-view-anchor" href={`${window._env_.NEW_DASHBOARD_VIEW}/#/?icp=${icp}&category=${category}`}>View</a>
    </div>
  );

  const handleSubmit = async () => {
    try {
      setLoading(true);
      /* if card type is user then edit */
      if (cardUuid && cardDetails?.cardType !== "user") {
        toast.error("You don't have access to edit.", toastConfig);
      }
      const selectedSharedIds = selectedSharedUsers.map((itr) => itr.userUuid);
      const formObj = {
        title: `-- ${camalize(expType)}s ${title}`,
        description: description,
        category: selectedCategory,
        cardType: "user",
        uxComponent: "tabular",
        cardObject: expType.toUpperCase(),
        actionLabel: selectedAction,
        cardFilterType: expType.toLowerCase(),
        segmentBody: filterAttributesArray,
        sharedWith: {
          sharedWithUser: selectedSharedIds,
          unsharedUser: [],
        },
        backup_icp: icp,
      };
      let url = `${window._env_.CARD_SERVICE}/card/v2/saveCardToDB/${tenantId}`;
      if (cardUuid && !copy) {
        url += `?cardUuid=${cardUuid}`;
        formObj.cardUuid = cardUuid;
        formObj.filterId = cardDetails?.filterDetails?.filterId;
        formObj.displayorder = cardDetails?.displayorder;
        formObj.sharedWith = {
          sharedWithUser: selectedSharedIds,
          unsharedUser: selectedOriginalSharedUsers.filter((itr) => !selectedSharedIds.includes(itr.userUuid)).map((itr) => itr.userUuid),
        };
      }

      const response = await axios.post(url, formObj, { withCredentials: true });
      if (response.data.success) {
        setLoading(false);
        if (cardUuid && !copy) toast.success(<CustomToastWithLink message="Insight saved successfully" />, toastConfig);
        else if (copy) toast.success(<CustomToastWithLink message="Insight copied successfully" />, toastConfig);
        else toast.success(<CustomToastWithLink message="Insight created successfully" />, toastConfig);
        redirectTimeoutId.current = setTimeout(() => {
          window.location.href = `${window._env_.NEW_DASHBOARD_VIEW}/#/settings?icp=${icp}&category=${category}`;
        }, 6000);
      } else {
        setLoading(false);
        toast.error("An error occurred for the selected action. Please try again.", toastConfig);
      }
    } catch (e) {
      console.error("Error occurred while saving the card", e);
    }
  };

  const isDisabled = () => {
    try {
      if (loading || selectedCategory?.length === 0 || title?.length === 0 || filterAttributesArray[0].length === 0) return true;
      else {
        for (const filterAttributes of filterAttributesArray) {
          for (const filterAttribute of filterAttributes) {
            // if(filterAttribute.attribute === 'Buyer Group' && filterAttribute.values.length === '3' && filterAttribute.values['department']!==undefined &&filterAttribute.values['personalevel']!==undefined && filterAttribute.values['value']!==undefined) return false
            if (filterAttribute.attribute === "Buyer Group") {
              if (!filterAttribute.relation || filterAttribute.values || filterAttribute.values[0]?.value || filterAttribute.values[0].value[0]) {
                return true;
              }
            }
            if (!("values" in filterAttribute)) {
              return true;
            }
          }
        }
      }
      return false;
    } catch (e) {
      return true;
    }
  };

  const handleBackBtn = (event) => {
    clearTimeout(redirectTimeoutId.current);
  }

  if (loading) {
    document.body.style.cursor = "wait";
  } else {
    document.body.style.cursor = "auto";
  }

  useEffect(() => {
    if (cardUuid) {
      setLoading(true);
      axios
        .get(`${window._env_.CARD_SERVICE}/card/v2/getCardDetails/${tenantId}?cardUuid=${cardUuid}&icp=${icp}`, {
          withCredentials: true,
        })
        .then((response) => {
          const result = response.data?.data;
          console.log("cards details in update insight", result);
          if (result?.length > 0) {
            const cardData = result[0];
            setCardDetails(cardData);
            if (cardData?.filterDetails?.filterId !== "false") {
              setFilterAttributesArray(cardData?.filterDetails?.filterBody);
            } else {
              toast.error(cardData?.filterDetails?.filterBody?.message, toastConfig);
            }
            setSelectedCategory(cardData?.category);
            setExpType(capitalize(cardData?.cardObject));
            setTitle(cardData.title.split(" ").slice(2).join(" "));
            setDescription(cardData?.description);
            setSelectedAction(cardData?.actionLabel);
          }
        })
        .catch((e) => {
          console.error("Error occurred while fetching card-details in update insight", e);
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <div className="card-form-root">
        <div className="nav">
          <a onClick={handleBackBtn} href={`${window._env_.NEW_DASHBOARD_VIEW}/#/${query.get("copy") ? "" : "settings"}?icp=${icp}&category=${category}`}>
            <BackIcon />
          </a>
          <Typography className="">{type === 'Edit' ? 'Edit' : 'Create'} Insight</Typography>
          <ToastContainer position="bottom-left" />
        </div>
        <div className="card-form-body">
          <div className="category header-2">
            <Category categories={config.categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
          </div>
          <div className="title header-2">
            <Title expType={expType} setExpType={setExpType} titleOptions={titleOptions} title={title} setTitle={setTitle} />
          </div>
          <div className="insight-description header-2">
            <Description description={description} setDescription={setDescription} />
          </div>
          <div className="share header-2">
            <Share
              expType={expType}
              tenantId={tenantId}
              cardUuid={cardUuid}
              cardDetails={cardDetails}
              loading={loading}
              setLoading={setLoading}
              selectedSharedUsers={selectedSharedUsers}
              setSelectedSharedUsers={setSelectedSharedUsers}
              setSelectedOriginalSharedUsers={setSelectedOriginalSharedUsers}
            />
          </div>
          <div className="segment header-2">
            <Typography>Segment Conditions</Typography>
            <div className="clear-all" onClick={() => setFilterAttributesArray([[{}]])}>
              Clear All
            </div>
            <ExportFilterCriteria icp={icp} expType={expType} setExpType={setExpType} filterAttributesArray={filterAttributesArray} setFilterAttributesArray={setFilterAttributesArray} />
          </div>
          <div className="header-2">
            <Action expType={expType} selectedAction={selectedAction} setSelectedAction={setSelectedAction} />
          </div>
          <div className="insight-action-root">
            <button className="create-insight" onClick={handleSubmit} disabled={isDisabled()}>
              {cardUuid && !copy ? "Update Insight" : "Create Insight"}
            </button>
            <a href={`${window._env_.NEW_DASHBOARD_VIEW}/#/settings?icp=${icp}&category=${category}`}>
              <button className="cancel">Cancel</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightForm;
