const numeral = require("numeral");
// Function to format numbers using numeral
export function formatNumber(num) {
  // Using '0a' for automatic abbreviation
  // Uppercase to match your output (e.g., "K", "M")
  return numeral(num).format("0a").toUpperCase();
}

export function up() {
  return (
    <svg
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166504 3.33333L3.49984 0L6.83317 3.33333H0.166504Z"
        fill="#01AF45"
      />
    </svg>
  );
}

export function down() {
  return (
    <svg
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83447 0.000163078L3.50114 3.3335L0.167807 0.000163078L6.83447 0.000163078Z"
        fill="#CD1515"
      />
    </svg>
  );
}

export function newUp() {
  return (
    <svg
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166504 3.33333L3.49984 0L6.83317 3.33333H0.166504Z"
        fill="#9C9C9C"
      />
    </svg>
  );
}
export function newDown() {
  return (
    <svg
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83447 0.000163078L3.50114 3.3335L0.167807 0.000163078L6.83447 0.000163078Z"
        fill="#9C9C9C"
      />
    </svg>
  );
}

export const getTitle = (props, titleOnly=false) => {
  try{
    const count = props?.count;
    const title = props.cardDetails?.title;
    if(!title) return count;
    const index = title.indexOf("--");
    if(titleOnly){
      if(index === 0){
        return title.substring(index + 2);
      }
      return title;
    }
    if(index === 0){
      return count + title.substring(index + 2);
    }
    return count + " " + title;
  }catch(e){
    console.error("Error in get title", e?.message);
    return "";
  }
}
