import { createTheme, IconButton, TextField, ThemeProvider, Tooltip } from "@material-ui/core";
// import { Autocomplete } from "@material-ui/lab";
import { Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import "./ExportFilterCriteriaSectionField.css";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useAuthState } from "@bamboobox/b2logincheck";
import getTenantConfigJSON from "./getTenantConfigJson";
import _ from "lodash";
import Skeleton from "@mui/material/Skeleton";
import AccStageTrend from "./newAttributes/AccStageTrend";
import PersonaLevel from "./newAttributes/PersonaLevel";
import BuyerGroup from "./newAttributes/BuyerGroup";

import AttributeComponent from "./AttributeComponent";
import ValueComponent from "./ValueComponent";
import { BinIcon } from "../../../assets/svg";
import { height, width } from "@mui/system";

const countryNameList = [
  "Indonesia",
  "Singapore",
  "Saudi Arabia",
  "France",
  "Malaysia",
  "Kuwait",
  "Philippines",
  "United States",
  "Turkey",
  "Nigeria",
  "United Kingdom (Great Britain)",
  "China",
  "Qatar",
  "Australia",
  "Spain",
  "United Arab Emirates",
  "Thailand",
  "Italy",
  "Germany",
  "Canada",
  "India",
  "South Africa",
  "Japan",
  "Switzerland",
  "New Zealand",
  "Austria",
  "Haiti",
  "Samoa",
  "Antigua and Barbuda",
  "Oman",
  "Taiwan",
  "Vietnam",
  "Sweden",
  "Poland",
  "Netherlands",
  "Ireland",
  "Mexico",
  "Argentina",
  "Brazil",
];

const AccountTreeOutlinedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.25 20.75V17.75H11.25V7.74997H8.74995V10.75H2.25V3.25002H8.74995V6.25002H15.25V3.25002H21.75V10.75H15.25V7.74997H12.75V16.25H15.25V13.25H21.75V20.75H15.25ZM16.75 9.25002H20.25V4.74997H16.75V9.25002ZM16.75 19.25H20.25V14.75H16.75V19.25ZM3.74995 9.25002H7.25V4.74997H3.74995V9.25002Z"
        fill="#030303"
        fill-opacity="0.6"
      />
    </svg>
  );
};

const filterValuesLimit = window._env_.FILTER_VALUES_LIMIT;

const limitvalues = (values) => {
  try {
    return values.slice(0, filterValuesLimit || 5000);
  } catch (error) {
    return [];
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#21DA8C", // This is an orange looking color
    },
  },
});
const ExportFilterCriteriaSectionField = (props) => {
  console.log("props in section field", props);

  const { subfilter, subfiltersConfig, filterAttribute, filterAttributeIndex, fetchAttributeArr, accTenantConfigMapper, contTenantConfigMapper, relationArrConfig } = props;
  const [attributeRelations, setAttibuteRelations] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [typedValue, setTypedValue] = useState(filterAttribute ?? "");
  const [currentAttribute, setCurrentAttribute] = useState({});
  const [inputText, setInputText] = useState("");
  const tenantId = useAuthState().user.tenantId;
  const META_BASE_URL = window._env_.META_BASE_URL;
  const B2PROSPECT_URL = window._env_.B2PROSPECT_URL;
  const B2_CDPAPI = window._env_.B2_CDPAPI;
  const INTENT_TOPICS_URL = window._env_.INTENT_BACKEND;
  const AGGREGATOR_API = window._env_.AGGREGATOR_API;

  const allSelected =
    attributeValues
      .filter((obj) => Object.keys(obj)[0] === "department")
      .map((obj) => obj.department)
      ?.filter((e) => e.toLowerCase().includes(inputText))?.length === (filterAttribute?.values?.[0]?.department?.filter((e) => e.toLowerCase().includes(inputText))?.length || "") || false;
  const allPersonaSelected =
    attributeValues
      .filter((obj) => Object.keys(obj)[0] === "personalevel")
      .map((obj) => obj.personalevel)
      ?.filter((e) => e.toLowerCase().includes(inputText))?.length === (filterAttribute?.values?.[0]?.personalevel?.filter((e) => e.toLowerCase().includes(inputText))?.length || "") || false;

  function shouldShowErrors(attributeData, key) {
    if (key === "relation") {
      return attributeRelations?.length === 0 || attributeRelations === undefined;
    }
    if (key === "value") {
      return attributeData?.values[0]?.value?.length === 0 || attributeData?.values[0]?.value === undefined;
    }
  }

  const getOPtionFilt = (option) =>
    filterAttribute.attribute.includes('eventName') 
      ? option.eventName
      : filterAttribute.attribute.includes('productName') 
      ? option.productName
      : filterAttribute.attribute.includes('campaignName') 
      ? option.programName ?? option
      : filterAttribute.attribute.includes('activityType') 
      ? option.activityType
      : filterAttribute.attribute.includes('label')
      ? option.labelName
      : option;

   console.log("getOPtionFilt",getOPtionFilt)
  const selectAllValues = (() => {
    try {
      return (
        filterAttribute.attribute !== "Buyer Group" &&
        attributeValues.filter((option) => getOPtionFilt(option)?.toLowerCase().includes(inputText.toLowerCase()))?.length ===
          filterAttribute?.values.filter((option) => getOPtionFilt(option).toLowerCase().includes(inputText.toLowerCase()))?.length
      );
    } catch (error) {
      return false;
    }
  })();

  const fetchAttributeValues = async (value) => {
    console.log("inside fetchAttributeValues",filterAttribute);
    let label = "";
    let allVals = [];
    if (!value) {
      value = filterAttribute?.attribute;
      label = currentAttribute?.label || filterAttribute?.label;
    }
    const  body = {};
    console.log("sunfilters in fetchattr->",subfilter);
    if(subfilter){
        body.columnName=value;
        body.source=filterAttribute?.logo;
        body.activityType=filterAttribute?.activityType;
        body.tableName= filterAttribute?.type;
    }else{
      body.tableName = filterAttribute?.type;
      body.columnName = value;
    }


    console.log("Value and label->",value,label)
    if (value.includes("activityType") || label === "Activity Type" || value === "accounts|labelName" ||
    value === "contacts|labelName" ||
    label === "Account Labels" ||
    label === "Labels") {
      try {
        let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 3->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          setAttributeValues(limitvalues(val?.data));
        });
      } catch (error) {
        console.log(error);
      }
    }  else if (label === "Product Name" || label === "Contact Product Name" || value === "productName") {
      const attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
      await fetch(
        attributeValuesUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          credentials: "include",
        }
      ).then((result) => result.json())
      .then((val)=>{
        console.log("attData->",val);
        console.log(" programs in index", val?.data);
        let productNames = [];
        
        val?.data?.map((obj) =>{
          let pushObj = {
            productName: obj?.attributeValue,
            source : obj?.source
          };
          console.log("pushObj ", pushObj);
          productNames.push(pushObj);
        })
        setAttributeValues(limitvalues(productNames));
      });

    } 
    else if (value === "programName" || label.toLowerCase() === "Campaign Name".toLowerCase()) {
      const attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
      await fetch(
        attributeValuesUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          credentials: "include",
        }
      ).then((result) => result.json())
      .then((val)=>{
        console.log("attData->",val);
        console.log(" programs in index", val?.data);
        let campaignTypes = [];
        campaignTypes = val?.data?.map((obj) =>{
          let pushObj = {
                    programName: obj?.attributeValue || "",
                    source: obj?.source,
                    programId: obj?.programId,
                  };
                  return pushObj;
        })
        setAttributeValues(limitvalues(campaignTypes));
      });
    } else if (value.toLowerCase().includes('country')) {
      let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 1->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          const countryList = [];
          val?.data?.map((ele) => countryList.push(ele.attributeValue))
          setAttributeValues(limitvalues(countryList));
        });
    }  
    else if (label.toLowerCase() === "Sub Industry".toLowerCase()) {
      let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 1->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          const subIndustryList = [];
          val?.data?.map((ele) => subIndustryList.push(ele.attributeValue));
          setAttributeValues(limitvalues(subIndustryList.filter((label, index) => subIndustryList.indexOf(label) === index)));
        });
    }
    else {
      console.log("In else index");
      let attributeValuesUrl = `${window._env_.META_BASE_URL}/attribute-setup/v2/getAttributeData/${tenantId}`;
        await fetch(
          attributeValuesUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
          }
        ).then((result) => result.json())
        .then((val)=>{
          console.log("attData 1->",val);
          console.log("Activity type uniqueActivitie in indexs", val?.data);
          const attrArr = [];
          val?.data?.map((ele) => attrArr.push(ele.attributeValue));
          setAttributeValues(limitvalues(attrArr));
        });
    }
  };

  const fetchRelationArr = (value) => {
    const relationArr = relationArrConfig[value] || relationArrConfig.DEFAULT;
    setAttibuteRelations(relationArr);
  };

  function validateData(data) {
    let date_fields = [
      "Account Creation Date",
      "Account Created Date",
      "Account Modification Date",
      "Last Engagement Date",
      "Account Updated Date",
      "First Activity Date",
      "Last Activity Date",
      "First Engagement Date",
      "Intent Creation Date",
      "Last Intent Date",
      "Account Created At",
      "Account Updated At",
      "Opportunity Created At",
      "Opportunity Updated At",
    ];
    const { attribute, relation, values } = data;
    const attr = { ...data };
    if (!attribute || !relation) {
      attr.isValid = false;
      return attr;
    }
    if (attribute && (relation === "is empty" || relation === "is not empty" || relation === "true" || relation === "false")) {
      attr.isValid = true;
      return attr;
    }
    if (date_fields.includes(attribute) && (relation === "in the past" || relation === "is more than") && values.length !== 0) {
      let value = typeof values[0] === "string";
      attr.isValid = value;
      return attr;
    }
    if (date_fields.includes(attribute) && values.length !== 0 && relation) {
      if (values[0] === null || values[1] === null) {
        attr.isValid = false;
        return attr;
      }
      let d1 = new Date(values[0]);
      let d2 = new Date(values[1]);
      if ((isNaN(d1) || isNaN(d2)) && relation === "between") {
        attr.isValid = false;
      } else if (isNaN(d1)) {
        attr.isValid = false;
      } else {
        attr.isValid = true;
      }
      return attr;
    }
    if (attribute && relation) {
      if (relation !== "between") {
        if (values.length) {
          attr.isValid = true;
        } else {
          attr.isValid = false;
        }
        if (attr.attribute === "Buyer Group") {
          attr.isValid = false;
          if (attr.values[0].department.length > 0 && attr.values[0].personalevel.length > 0 && attr.values[0].value.length > 0 && attr.values[0].value[0] !== "") {
            attr.isValid = true;
          }
        }
      } else {
        if (values[0] === "" || values[0] === undefined || values[1] === "" || values[1] === undefined) {
          attr.isValid = false;
        } else if (parseInt(values[0]) >= parseInt(values[1])) {
          attr.isValid = false;
        } else {
          attr.isValid = true;
        }
        if (attr.attribute === "Buyer Group") {
          attr.isValid = false;
          if (attr.values[0].department.length > 0 && attr.values[0].personalevel.length > 0 && attr.values[0].value.length > 1) {
            attr.isValid = true;
          }
        }
      }
    }

    return attr;
  }

  function onAttributeChange(value, key) {
    console.log("cvbn", key, value);
    let attrData = { ...filterAttribute };

    if (key === "attribute") {
      attrData["attribute"] = value.attribute;
      attrData["label"] = value.label;
      attrData["type"] = value.tableName;
      attrData["filterType"] = value.filterType;
      attrData["source"] = value.source;
      attrData["subfilter"] = [];
      if (value?.logo) {
        attrData["logo"] = value.logo;
      }
      if (value?.activityType) {
        attrData["activityType"] = value.activityType;
      }
      fetchRelationArr(value?.filterType);
      attrData["relation"] = "";
      attrData["values"] = [];

      if (value.attribute === "buyerGroup" || value.label === "Buyer Group") {
        attrData["values"] = [
          {
            department: [],
            personalevel: [],
            value: [],
          },
        ];
      } else if (value.attribute === "accountStageTrend" || value.label === "Account Stage Trend") {
        attrData["relation"] = "is";
        attrData["values"] = [
          {
            trend: "upwards",
            trendRelation: "in the past",
            trendValues: ["Week", null],
          },
        ];
      } else if (value.attribute === "departmentPersonalevel" || value.label === "Persona Department Level") {
        attrData["relationMain"] = "includes";
        attrData["relation"] = "greater than(>)";
        attrData["values"] = [4, [0, null]];
      }
      setAttributeValues([]);
    } else if (key === "relation") {
      attrData["relation"] = value;
      if (attrData.attribute === "buyerGroup" || attrData.label === "Buyer Group") {
        if (attrData["values"][0]?.value) attrData["values"][0].value = [];
      } else {
        attrData["values"] = [];
      }
    } else if (key === "others") {
      attrData = value;
    } else {
      if (attrData["attribute"] === "buyerGroup" || attrData.label === "Buyer Group") {
        let tempValObj = {
          department: [],
          personalevel: [],
          value: [],
        };
        if (Object.keys(value)[0] === "department") {
          if (attrData[key].length > 0)
            if (value?.department.find((option) => option === "Select All")) {
              if (!allSelected) {
                attrData[key][0]["department"] = attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "department")
                  .map((obj) => obj.department)
                  .filter((e) => e !== "Select All" && (e.toLowerCase().includes(inputText.toLowerCase()) || value.department.includes(e)));
              } else {
                attrData[key][0]["department"] = [];
              }
            } else {
              if (inputText === "") attrData[key][0]["department"] = value?.department || [];
              else value?.department?.filter((e) => e !== "Select All" && !e.toLowerCase().includes(inputText.toLowerCase()));
              attrData[key][0]["department"] = value?.department || [];
            }
          else {
            if (value?.department.find((option) => option === "Select All")) {
              tempValObj.department =
                attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "department")
                  .map((obj) => obj.department)
                  .filter((e) => e !== "Select All" && (e.toLowerCase().includes(inputText.toLowerCase()) || value.personalevel.includes(e))) || [];
              attrData[key].push(tempValObj);
            } else {
              if (inputText === "") tempValObj.department = value?.department || [];
              else attrData[key][0]["department"] = value?.department?.filter((e) => e !== "Select All" && !e.toLowerCase().includes(inputText.toLowerCase()));
              attrData[key].push(tempValObj);
            }
          }
        } else if (Object.keys(value)[0] === "personalevel") {
          if (attrData[key].length > 0) {
            if (value?.personalevel.find((option) => option === "Select All")) {
              if (!allPersonaSelected) {
                attrData[key][0]["personalevel"] = attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "personalevel")
                  .map((obj) => obj.personalevel)
                  .filter((e) => e !== "Select All" && (e.toLowerCase().includes(inputText.toLowerCase()) || value.personalevel.includes(e)));
              } else {
                if (inputText === "") attrData[key][0]["personalevel"] = [];
                else {
                  attrData[key][0]["personalevel"] = value.personalevel.filter((e) => e !== "Select All" && !e.toLowerCase().includes(inputText.toLowerCase()));
                }
              }
            } else {
              attrData[key][0]["personalevel"] = value?.personalevel || [];
            }
          } else {
            if (value?.personalevel.find((option) => option === "Select All")) {
              tempValObj.personalevel = attributeValues
                .filter((obj) => Object.keys(obj)[0] === "personalevel")
                .map((obj) => obj.personalevel)
                .filter((e) => e !== "Select All" && (e.toLowerCase().includes(inputText.toLowerCase()) || value.personalevel.includes(e)));
              attrData[key].push(tempValObj);
            } else {
              if (inputText === "") tempValObj.personalevel = value?.personalevel || [];
              else tempValObj.personalevel = value.personalevel.filter((e) => e !== "Select All" && !e.toLowerCase().includes(inputText.toLowerCase()));
              attrData[key].push(tempValObj);
            }
          }
        } else if (Object.keys(value)[0] === "value") {
          attrData[key] = value?.value;
          console.log("storing data", attrData[key]);
        }
      } else {
        if (value.find((e) => e === "Select All")) {
          if (!selectAllValues) {
            if (inputText === "") attrData[key] = attributeValues;
            else
              attrData[key] = attributeValues.filter(
                (option) => getOPtionFilt(option).toLowerCase().includes(inputText.toLowerCase()) || value.some((el) => getOPtionFilt(el) === getOPtionFilt(option) && el?.source === option?.source)
              );
          } else {
            if (inputText === "") attrData[key] = [];
            else attrData[key] = value.filter((e) => e !== "Select All" && !getOPtionFilt(e).toLowerCase().includes(inputText.toLowerCase()));
          }
        } else {
          attrData[key] = value;
          if (attrData["subfilter"]?.length > 0) {
            attrData["subfilter"] = attrData["subfilter"].filter((itr) => {
              const ret = value.find((val) => {
                return getOPtionFilt(val) === itr.activityType && (val?.source ? val.source === itr.logo : true);
              });
              return ret;
            });
          }
        }
      }
    }
    if (value === "true" || value === "false") {
      attrData["values"] = [value];
    }
    const validatedObject = validateData(attrData);
    const newFilterAttributeArray = [...props.filterAttributes];
    if (subfilter) {
      const indexes = filterAttributeIndex.split("-");
      const parentFilterIndex = +indexes[0];
      const subFilterIndex = +indexes[1];

      newFilterAttributeArray[parentFilterIndex].subfilter[subFilterIndex] = validatedObject;
    } else {
      newFilterAttributeArray[props.filterAttributeIndex] = validatedObject;
    }
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray[props.filterAttributesIndex] = [...newFilterAttributeArray];
    props.setFilterAttributesArray(newFilterAttributesArray);
  }

  const onClickClose = (subfilter = false) => {
    if (subfilter) {
      console.log(props);
      if (filterAttributeIndex.toString().includes("-")) {
        const indexes = filterAttributeIndex.split("-");
        const parentFilterIndex = +indexes[0];
        const subFilterIndex = +indexes[1];

        const newFilterAttributeArray = [...props.filterAttributes];
        newFilterAttributeArray[parentFilterIndex].subfilter.splice(subFilterIndex, 1);
        const newFilterAttributesArray = [...props.filterAttributesArray];
        newFilterAttributesArray[props.filterAttributesIndex] = [...newFilterAttributeArray];
        props.setFilterAttributesArray(newFilterAttributesArray);
      }
      return;
    }
    const newFilterAtributes = [...props.filterAttributes];
    newFilterAtributes.splice(props.filterAttributeIndex, 1);
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray[props.filterAttributesIndex] = [...newFilterAtributes];
    props.setFilterAttributesArray(newFilterAttributesArray);
  };

  const onClearAttributes = () => {
    if (subfilter) {
      console.log(props);
      if (filterAttributeIndex.toString().includes("-")) {
        const indexes = filterAttributeIndex.split("-");
        const parentFilterIndex = +indexes[0];
        const subFilterIndex = +indexes[1];

        const newFilterAttributeArray = [...props.filterAttributes];
        newFilterAttributeArray[parentFilterIndex].subfilter[subFilterIndex] = {
          label: "",
          relation: "",
          type: "",
          attribute: "",
          isValid: false,
          filterType: "",
          subfilter: [],
          values: [],
        };
        const newFilterAttributesArray = [...props.filterAttributesArray];
        newFilterAttributesArray[props.filterAttributesIndex] = [...newFilterAttributeArray];
        props.setFilterAttributesArray(newFilterAttributesArray);
      }
      return;
    }
    const newFilterAtributes = [...props.filterAttributes];
    newFilterAtributes[filterAttributeIndex] = {
      label: "",
      relation: "",
      type: "",
      attribute: "",
      isValid: false,
      filterType: "",
      subfilter: [],
      values: [],
    };
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray[props.filterAttributesIndex] = [...newFilterAtributes];
    props.setFilterAttributesArray(newFilterAttributesArray);
  };

  const attributeOptions = fetchAttributeArr(filterAttributeIndex, subfilter) ?? [];

  const onClickCheck = (value) => {
    /* subfilter attribute will come as an object */
    if (typeof value === "object") {
      onAttributeChange(value, "attribute");
      setTypedValue(value);
      return;
    }
    for (const category of attributeOptions) {
      if (value === category.label) {
        onAttributeChange(category, "attribute");
        setTypedValue(category);
        break;
      }
    }
  };

  useEffect(() => {
    if (!accTenantConfigMapper || !contTenantConfigMapper) return;
    let attributeObject;

    if (props.exportType === "Account") {
      attributeObject = accTenantConfigMapper.get(typedValue?.attribute);
    } else if (props.exportType === "Contact") {
      attributeObject = contTenantConfigMapper.get(typedValue?.attribute);
    }

    const filterType = attributeObject?.filterType;
    setCurrentAttribute(attributeObject);
    if (!(typedValue && (typedValue.attribute || typedValue.label)) || attributeRelations.length > 0) {
      return;
    }
    fetchRelationArr(filterType);
    if (typedValue?.relation === "includes" || typedValue?.relation === "excludes") {
      fetchAttributeValues();
    }
  }, [typedValue, accTenantConfigMapper, contTenantConfigMapper]);

  if (!accTenantConfigMapper || !contTenantConfigMapper) {
    return (
      <div style={{ display: "grid", gap: "10px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "140px 140px 1fr",
            gap: "10px",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        </div>
        <Skeleton variant="rounded" sx={{ height: "32px", width: "64px", borderRadius: "18px" }} />
      </div>
    );
  }

  return accTenantConfigMapper?.size > 0 || contTenantConfigMapper?.size > 0 ? (
    <ThemeProvider theme={theme}>
      <div className="export-filter-crieria-section-and-field">
        {subfilter && (
          <div
            style={{
              width: "24px",
              height: "24px",
              color: "#03030399",
              marginBottom: "auto",
              marginTop: "8px",
            }}
          >
            <AccountTreeOutlinedIcon />
          </div>
        )}

        <div className="export-filter-crieria-section-and-field-elements">
          <div>
            <AttributeComponent
              subfilter={subfilter}
              attributeOptions={attributeOptions}
              typedValue={typedValue}
              setTypedValue={setTypedValue}
              filterAttribute={filterAttribute}
              currentAttribute={currentAttribute}
              onClearAttributes={onClearAttributes}
              onClickCheck={onClickCheck}
            />
            {(filterAttribute?.attribute === "buyerGroup" || filterAttribute?.attribute === "accountStageTrend" || filterAttribute?.attribute === "departmentPersonalevel") && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div style={{}}>
                  <svg
                    style={{
                      color: "#C4C4C4",
                    }}
                    width="39"
                    height="50"
                    viewBox="0 0 39 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M39 11L33 17L31.58 15.58L35.17 12H0V0H2V10H35.17L31.58 6.42L33 5L39 11Z" fill="#C4C4C4" />
                  </svg>
                </div>

                {props.filterAttribute.attribute === "departmentPersonalevel" && <p style={{ minWidth: "50px", marginLeft: "8px" }}>with count</p>}
              </div>
            )}
            {filterAttribute?.relation !== "is empty" && filterAttribute?.relation !== "is not empty" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginRight: "16px",
                }}
              ></div>
            ) : (
              ""
            )}
          </div>
          {filterAttribute?.attribute === "buyerGroup" || filterAttribute?.label === "Buyer Group" ? (
            <BuyerGroup
              attributeData={filterAttribute}
              currentAttribute={currentAttribute}
              onAttributeChange={onAttributeChange}
              shouldShowErrors={shouldShowErrors}
              relationArr={attributeRelations}
              attributeValues={attributeValues}
              fetchAttributeValues={fetchAttributeValues}
              inputText={inputText}
              setInputText={setInputText}
              allSelected={allSelected}
              allPersonaSelected={allPersonaSelected}
              isSubFilter={subfilter}
            />
          ) : (
            <>
              {filterAttribute?.label === "Account Stage Trend" || filterAttribute?.attribute === "accountStageTrend" ? (
                <AccStageTrend
                  attributeRelations={attributeRelations}
                  filterAttribute={props.filterAttribute}
                  filterAttributes={props.filterAttributes}
                  filterAttributeIndex={props.filterAttributeIndex}
                  filterAttributesIndex={props.filterAttributesIndex}
                  filterAttributesArray={props.filterAttributesArray}
                  setFilterAttributesArray={props.setFilterAttributesArray}
                  onAttributeChange={onAttributeChange}
                  isSubFilter={subfilter}
                />
              ) : (
                <>
                  {filterAttribute?.attribute === "departmentPersonalevel" || filterAttribute?.label === "Persona Department Level" ? (
                    <PersonaLevel
                      attributeRelations={attributeRelations}
                      filterAttribute={props.filterAttribute}
                      filterAttributes={props.filterAttributes}
                      filterAttributeIndex={props.filterAttributeIndex}
                      filterAttributesIndex={props.filterAttributesIndex}
                      filterAttributesArray={props.filterAttributesArray}
                      setFilterAttributesArray={props.setFilterAttributesArray}
                      onAttributeChange={onAttributeChange}
                      isSubFilter={subfilter}
                    />
                  ) : (
                    <>
                      {"attribute" in props.filterAttribute && filterAttribute.label !== "Contact Engaged" && (
                        <Autocomplete
                          size="small"
                          value={props.filterAttribute.relation}
                          onChange={(event, newValue) => {
                            if (newValue === "includes" || newValue === "excludes") {
                              fetchAttributeValues();
                            }
                            onAttributeChange(newValue, "relation");
                          }}
                          options={attributeRelations}
                          // getOptionLabel={(option) => option.title}
                          popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
                          renderInput={(params) => <TextField {...params} placeholder="Relation" variant="outlined" />}
                        />
                      )}
                      <div
                        className="value-container"
                        style={{
                          display: "flex",
                          width: subfilter ? "100%" : "100%",
                        }}
                      >
                        {"attribute" in props.filterAttribute && "relation" in props.filterAttribute && (
                          <>
                            {filterAttribute?.filterType === "BOOLEAN" || filterAttribute?.relation === "is empty" || filterAttribute?.relation === "is not empty" ? (
                              <></>
                            ) : (
                              <>
                                <ValueComponent
                                  className="value-container"
                                  attributeData={filterAttribute}
                                  currentAttribute={currentAttribute}
                                  onAttributeChange={onAttributeChange}
                                  filtersConfig={relationArrConfig}
                                  fetchAttributeValues={fetchAttributeValues}
                                  attributeValues={attributeValues}
                                  selectAllValues={selectAllValues}
                                  inputText={inputText}
                                  setInputText={setInputText}
                                  getOPtionFilt={getOPtionFilt}
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <IconButton
          onClick={() => {
            if(props.filterAttributes.length > 1 || filterAttributeIndex?.toString()?.includes("-")){
              onClickClose(subfilter);
            }else {
              onClearAttributes();
            }
          }}
          style={{ marginRight: "5px" }}
        >
          <Tooltip title={"Remove Filter"}>
            <BinIcon />
          </Tooltip>
        </IconButton>
      </div>
    </ThemeProvider>
  ) : (
    <></>
  );
};

export default ExportFilterCriteriaSectionField;
