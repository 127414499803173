import React, { useState, useEffect, useRef, useCallback } from "react";
import { UNIFIED_URL } from "./Constant";
import KeySegmentTable from "./KeySegmentTable";
import styles from '../css/keysegment.module.css'

function Eng_vs_Intent_Segment(props) {
  const [compare, setCompare] = useState([]);
  const [engagementIntentBody, setEngagementIntentBody] = useState([]);
  const controllerRefEngIntent = useRef();

  const displayEngagementIntentData = useCallback(
    async (filBody) => {
      console.log("filBody in disp is", filBody);
      if (controllerRefEngIntent.current) {
        controllerRefEngIntent.current.abort();
      }
      const controller = new AbortController();
      controllerRefEngIntent.current = controller;

      await fetch(
        `${UNIFIED_URL}/analytics/keySegment/surge/${props.tenantId}?page=1&limit=5&icp=${props.icpSelected}&filterName=${props.segmentName}&sortby=&sortType=`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ filters: filBody }),
          signal: controllerRefEngIntent.current?.signal,
          credentials: "include",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true) {
            setCompare(data?.data?.result.slice(0, 5));
            props.setCount(data?.data?.filteredCount)
          } else {
            setCompare([]);
            props.setCount('0')
          }
        })
        .catch((err) => {
          if (err?.message !== "The user aborted a request.") {
            setCompare([]);
            props.setCount('0')
            console.warn(err);
          }
        });
    },
    [props.tenantId, props.icpSelected, props.segmentName]
  );

  useEffect(() => {
  

    console.log("request Body", props.requestBody);
    // setEngagementIntentBody(compareBody);

    if (props.requestBody && props.segmentName && props.icpSelected) {
      displayEngagementIntentData([...props.requestBody, ...props.specialFilter]);
    }
  }, [
    props.requestBody,
    props.segmentName,
    props.icpSelected,
    displayEngagementIntentData,
  ]);

  return (
  
      <div
        className={styles.keySegmentContainer}
      >
        <KeySegmentTable
          column2={"Intent & Engagement"}
          count={props?.count}
          rowsData={compare}
          tabVal={props.tabVal}
          icp={props.icpSelected}
          requestBody={props.requestBody}
          segmentName={props.segmentName}
          filterBodyToAH={engagementIntentBody}
          category={props?.category}
        />
      </div>

   
  );

}
export default Eng_vs_Intent_Segment;
