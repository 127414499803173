import { Typography, Autocomplete, TextField, InputAdornment } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { CategoryIcon } from "../../../assets/svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const Category = (props) => {
  const { selectedCategory, setSelectedCategory, categories } = props;

  return (
    <>
      <Typography>Insight Category</Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCategory}
        onChange={(event) => {
          setSelectedCategory(event.target.value);
        }}
        IconComponent={KeyboardArrowDownRoundedIcon}
        sx={{ width: 300 }}
        MenuProps={{
          classes: {
            paper: "custom-dropdown",
          },
        }}
      >
        {categories.map((item) => (
          <MenuItem value={item}>
            <CategoryIcon category={item} />
            {item}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
