import axios from "axios";

const getActionLabels = async (tenantId) => {
  const action_labels = {
    account: {},
    contact: {},
  };

  //api to get all action labels for tenant
  const api_url = `${window._env_.ACTIVATION_SERVICE_BASE_URL}/activator/get-all-actions?tenantId=${tenantId}`;
  await axios.get(api_url, { withCredentials: true }).then((result) => {
    console.log("result from api", result);
    for (let res of result.data) {
      action_labels[res.supportedType][res.actionLabel.toLowerCase().replace(/\s/g, "_")] = res.actionLabel;
    }
  });
  return action_labels;
};
export const getPermissionsForCards = async (permissionArray, tenantId) => {
  let permission_dict = {};

  console.log("calling getActionLabels-0224", permissionArray);
  const action_labels = await getActionLabels(tenantId);
  console.log("action_labels ===>", action_labels);

  if (permissionArray === "default")
    permission_dict = {
      account: Object.values(action_labels["account"]),
      contact: Object.values(action_labels["contact"]),
    };
  else
    for (let permission of permissionArray) {
      permission.resourceName = permission.resourceName.replace("_action_labels", "");
      permission_dict[permission.resourceName] = permission.operations.includes("All")
        ? Object.values(action_labels[permission.resourceName])
        : Object.values(permission.operations.map((perm) => action_labels[permission.resourceName][perm])).filter((val) => val !== undefined);
    }
  console.log("permission_dict ==>", permission_dict);
  sessionStorage.setItem("permissionsForCards", JSON.stringify(permission_dict));
};
export const getSessionPermissionsForCards = () => {
  try {
    return JSON.parse(sessionStorage.getItem("permissionsForCards"));
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const checkPermissionsForCards = (permissionArray,resourceArray)=>{
  try{
    console.log("checkPermissionsForCards",permissionArray,resourceArray)
    const userResources = permissionArray?.map(permission => permission?.resourceName?.toLowerCase());
    console.log(userResources)
    for(let sourceName of resourceArray)
    {
      if(userResources?.includes(sourceName?.toLowerCase()))
       {
        return true;
       }
    }
    return false;
  }
  catch(e){
    console.log("Error while checking the permissions for cards:",e);
    return false;
  }
}

function diffMinutes(dt2, dt1) 
 {

  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.ceil(diff));
  
 }

export function formateDateNew(refreshTime) {
  try {
      if(refreshTime === undefined || refreshTime === null) return ''
      const LastRefresh = new Date(refreshTime);
      const timeNow = new Date();
      const minute = diffMinutes(timeNow, LastRefresh);
      if (minute <= 1)
          return 'Now';
      else if(minute <=59)
          return ` ${minute} minutes ago`.toUpperCase();
      else if (minute > 59 && minute < 120)
          return ` ${Math.round(minute / 60)} hour ago`.toUpperCase();
      else if (minute >= 120 && minute < 480)
          return ` ${Math.round(minute / 60)} hours ago`.toUpperCase();
      else {
        const months = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        
        const date = new Date(refreshTime);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
      
        if (date.toDateString() === today.toDateString()) {
          return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
          return 'Yesterday';
        } else {
          const day = date.getDate();
          const month = months[date.getMonth()];
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
        }
      }
  }

  catch (error) {
      console.error("Error in DateDiffernce Function", error);
  }
}
