import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete as Autocomplete2 } from "@material-ui/lab";
import { Popper } from "@material-ui/core";
import { Autocomplete, Typography, InputAdornment } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ClearIcon from "@mui/icons-material/Clear";
import _ from "lodash";

const B2_INTENT_SETTING = window._env_.B2_INTENT_SETTING;

const PopperMy = function (props) {
  return <Popper {...props} style={{ maxWidth: "1500px", minWidth: "180px" }} placement="bottom-start" />;
};

const AttributeComponent = (props) => {
  const { subfilter, attributeOptions, filterAttribute, currentAttribute, typedValue, setTypedValue, onClickCheck, onClearAttributes } = props;
  const [attributeDropdownOpen, setAttributeDropdownOpen] = useState(false);

  const handleClearClick = (val) => {
    setTypedValue("");
    onClearAttributes(true);
  };
  const typedValueObj = { ...currentAttribute, ...typedValue };

  const getFilteredAttributes=(attributeOptions) =>{
    const filteredSubFilterAttribute = [
      ...new Map(
        attributeOptions.map(
          (currRow) => [
            (currRow?.attribute + currRow?.logo), currRow
          ]
        )
      ).values()
    ];
    return filteredSubFilterAttribute;
  }



  return (
    <>
      {subfilter ? (
        <Autocomplete2
          id="grouped-demo1"
          options={attributeOptions}
          filterOptions={(options) => getFilteredAttributes(options)}
          color="primary"
          getOptionLabel={(option) => {
            return option.label ?? option.attribute ?? "";
          }}
          getOptionSelected={(option, value) => option.label === value.label && option?.logo === value?.logo}
          style={{
            minWidth: "142px",
            height: "36px",
            display: "flex",
            "flex-direction": "column",
            "align-items": "flex-start",
            padding: "0px",
          }}
          size="small"
          value={typedValueObj}
          disableClearable={typedValueObj === "" || typedValueObj?.attribute === ""}
          popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
          renderInput={(params) => (
            <div
              style={{
                display: "flex",
                position: "relative",
                width: "100%",
              }}
            >
              <TextField
                {...params}
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "12px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "0.15px",
                  color: "rgba(3, 3, 3, 0.6)",
                  flex: "none",
                  order: 0,
                  alignSelf: "stretch",
                  paddingTop: "0.8px",
                  flexGrow: 0,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                placeholder="Sub-Attributes"
                variant="outlined"
                className="input subfilter-attr"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    marginLeft: (typedValueObj || filterAttribute)?.logo ? "-5px" : "6px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {(typedValueObj || filterAttribute)?.logo && (
                        <InputAdornment position="start">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "22px",
                              width: "22px",
                              marginLeft: "-10px"
                            }}
                          >
                            <img
                              style={{
                                position: "absolute",
                                marginLeft: "0px",
                                height: "22px",
                                width: "22px",
                                borderRadius: "50%",
                              }}
                              src={`${B2_INTENT_SETTING}/logos/${(typedValueObj || filterAttribute)?.logo.toLowerCase() ?? ""}.png`}
                              alt=""
                            />
                          </div>
                        </InputAdornment>
                      )}
                    </>
                  ),
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {typedValueObj === "" || typedValueObj?.attribute === "" ? (
                            <div style={{ height: "16px", width: "16px" }}></div>
                          ) : (
                            <ClearIcon
                              className="clear-icon"
                              style={{
                                color: "rgba(3, 3, 3, 0.6)",
                                height: "16px",
                                cursor: "pointer",
                              }}
                              onClick={handleClearClick}
                            />
                          )}
                          <KeyboardArrowDownRoundedIcon
                            style={{
                              color: "rgba(3, 3, 3, 0.6)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </div>
          )}
          renderOption={(option) => (
            <div
              key={option.groupLabel + option.label}
              style={{
                display: "flex",
                marginLeft: "-10px",
                width: "-webkit-fill-available",
                // gap: "10px",
              }}
              onClick={() => {
                onClickCheck(option);
              }}
            >
              {option.logo && (
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    height: "20px",
                    width: "20px",
                    borderRadius: "6px",
                    marginRight: "10px",
                    padding: "2px",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "6px",
                    }}
                    src={`${B2_INTENT_SETTING}/logos/${option?.logo ? option.logo.toLowerCase() : ""}.png`}
                    alt=""
                  />
                </div>
              )}
              <div
                style={{
                  // maxWidth: "130px",
                  // overflow: "hidden",
                  whiteSpace: "nowrap",
                  // textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
                {option.label}
              </div>
            </div>
          )}
        />
      ) : (
        <Autocomplete
          id="grouped-demo1"
          options={attributeOptions}
          groupBy={(option) => option.groupLabel}
          PopperComponent={PopperMy}
          color="primary"
          getOptionLabel={(option) => {
            return option.label ?? option.attribute ?? "";
          }}
          onChange={handleClearClick}
          style={{
            minWidth: "142px",
            height: "36px",
            display: "flex",
            "flex-direction": "column",
            "align-items": "flex-start",
            padding: "0px",
          }}
          size="small"
          value={typedValueObj}
          disableClearable={typedValueObj === "" || typedValueObj?.attribute === ""}
          popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
          open={attributeDropdownOpen}
          onOpen={() => setAttributeDropdownOpen(true)}
          onBlur={() => setAttributeDropdownOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "12px",
                display: "flex",
                alignItems: "center",
                letterSpacing: "0.15px",
                color: "rgba(3, 3, 3, 0.6)",
                flex: "none",
                order: 0,
                alignSelf: "stretch",
                flexGrow: 0,
              }}
              placeholder="Attributes"
              variant="outlined"
              className="input"
            />
          )}
          renderGroup={(option) => (
            <div className="render-group-container">
              <Typography variant="overline" component="Ag" className="groupcss" style={{ fontSize: "12px" }}>
                {option.group}
              </Typography>

              <div className="render-group-body">
                {option.children.map((topic, index) => (
                  <div
                    key={index}
                    className="render-group-list"
                    onClick={() => {
                      onClickCheck(topic.props.children); // topic.props.children => lable
                      setAttributeDropdownOpen(false);
                    }}
                  >
                    <div>
                      <p>{topic?.props.children}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        />
      )}
    </>
  );
};

export default AttributeComponent;
