import './assets/styles/App.css';
import DashBoardMain  from './components/DashboardMain';
import Card from './components/Layout/Cards/Card';
import Settings from './components/Settings/Settings.jsx'
import InsightForm from './components/insightForm';
import React from "react";
import {
  HashRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

function App() {
  return (
  
    <div>
       <Router>
            <Switch>
                <Route exact path="/" component={DashBoardMain} ></Route>
                <Route exact path="/filter/:id" component={DashBoardMain} ></Route>
                <Route exact path="/settings" component={Settings} ></Route>
                <Route exact path="/insightForm" component={InsightForm} ></Route>
                <Route exact path='/card' component={Card}></Route>
            </Switch>
        </Router>
    </div>
  );
}

export default App;



