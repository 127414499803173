import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import App from './App';
import { AuthProvider } from "@bamboobox/b2logincheck";
import { ResourceCheck } from '@bamboobox/b2resource-check/dist'
import reportWebVitals from './reportWebVitals';

const resources=["dashboard_dataquality",
"dashboard_insights",
"dashboard_keysegments",
"dashboard_journeyanalytics",
"dashboard_intent",
"dashboard_trends",
"account_dashboard"
]
ReactDOM.render(
  <AuthProvider loginURL={window._env_.LOGIN_URL} authcheckURL={window._env_.AUTH_URL}>
    <ResourceCheck component={<App />} resources={resources} default={true}/>
    {/* <App /> */}
  </AuthProvider>,
document.getElementById('root')
);

reportWebVitals();
