import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import Link from "@material-ui/core/Link";
import { UNIFIED_FRONTEND_URL } from "./Constant";

import Tooltip from "@mui/material/Tooltip";
import styles from '../css/keysegment.module.css'
import { NoResultFound } from "../../../../assets/icons/FallBackComponents";


const CustomTableCellData = styled(TableCell)({
  color: "#000",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "normal",
  borderBottom: "0px",
  minHeight: "2rem",
  padding: "8px"
});

const CustomTableCellHead = styled(TableCell)({
  color: "#000",
  fontSize: "11px",
  fontWeight: "400",
  lineHeight: "normal",
  borderBottom: "0px",
  paddingTop: "12px",
  paddingBottom: "12px",
});

const CustomTableRow = styled(TableRow)({
  "& > :not(:first-child)": {
    textAlign: "right",
    display: "flex",
    paddingRight: "0px",
    alignItems: "end",
    justifyContent: "right",
  },

  "&": {
    borderBottom: "1px solid #E8E8E8",
    verticalAlign: "baseline",
  },

  "& > :first-child": {
    paddingLeft: "0px",
  },

});

const CustomTableBody = styled(TableBody)({
  "& > :last-child":{
    borderBottom: "0px"
  }
});

const colors = ["#CD1515", "#5F41B7", "#E7C03A", "#016B40", "#3D70D4"];

export default function KeySegmentTable(props) {
  const rowsData = props.rowsData;

  console.log("props in keySegment", props);

  return (
    <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
      {
        Number(props?.count) ? (
          <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <CustomTableRow style={{maxHeight: "38px"}}>
                <CustomTableCellHead style={{paddingLeft: "6px"}}>Account</CustomTableCellHead>
  
                <CustomTableCellHead style={{paddingRight: "6px"}}>
                  {props.column2}
                </CustomTableCellHead>
              </CustomTableRow>
            </TableHead>
            {rowsData.length !== 0 ? (
              <CustomTableBody style={{ margin: "0px", padding: "0px" }}>
                {rowsData.map((row, index) => {
                  return (
                    //routing accountName
                    <CustomTableRow key={row.accountId}>
                      <CustomTableCellData 
                        scope="row"
                        sx={{ textOverflow: "ellipsis" }}
                      >
                        <Tooltip title={row.accountName} placement="bottom-start">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                width: "28px",
                                height: "28px",
                                marginRight: "0.5rem",
                                borderRadius: "50%",
                                backgroundColor: colors[index % colors.length],
                                color: "#FFFFFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {row.accountName.slice(0, 2).toUpperCase()}
                            </div>
                            <Link
                              style={{ color: "#000" }}
                              href={`${UNIFIED_FRONTEND_URL}/#/AccountDetail/${
                                row.accountId
                              }/0?previouspage="analytics_dashboard"&filter=${encodeURIComponent(
                                encodeURIComponent(
                                  JSON.stringify(props.requestBody)
                                )
                              )}&icp=${props.icp}&segmentName=${
                                props.segmentName
                              }&newDashboard=true&tabVal=${props.tabVal}&category=${props?.category}`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {row.accountName}
                              </span>
                            </Link>
                          </div>
                        </Tooltip>
                      </CustomTableCellData>
  
                      {props.column2 === "Engagement" && (
                        <CustomTableCellData>
                          <div style={{paddingRight: '2px'}}>{Math.ceil(row.engagementScore * 10) / 10}</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path d="M3.5 8.5L6 6L8.5 8.5H3.5Z" fill="#01AF45" />
                          </svg>
                          <div
                            style={{
                              color: "#01AF45",
                              fontSize: "9px",
                              fontWeight: "600",
                            }}
                          >
                            {Math.round(row.incremental)}%
                          </div>
                        </CustomTableCellData>
                      )}
  
                      {props.column2 === "Intent" && (
                        <CustomTableCellData>
                          <div style={{paddingRight: '2px'}}>
                            {Math.ceil(row.new_b2_intent_score * 10) / 10}
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path d="M3.5 8.5L6 6L8.5 8.5H3.5Z" fill="#01AF45" />
                          </svg>
                          <div
                            style={{
                              color: "#01AF45",
                              fontSize: "9px",
                              fontWeight: "600",
                            }}
                          >
                            {Math.round(row.b2_intent_percent_change)}%
                          </div>
                        </CustomTableCellData>
                      )}
  
                      {props.column2 === "Intent & Engagement" && (
                        <CustomTableCellData>
                          <span style={{paddingRight: '2px'}}>
                            {Math.ceil(row.new_b2_intent_score * 10) / 10}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path d="M3.5 8.5L6 6L8.5 8.5H3.5Z" fill="#01AF45" />
                          </svg>
                          <span
                            style={{
                              color: "#4CAF50",
                              fontSize: "9px"
                            }}
                          >
                            {Math.round(row.b2_intent_percent_change)}%
                          </span>
  
                          <span style={{ paddingLeft: "20px", paddingRight: "2px" }}>
                            {Math.ceil(row.engagementScore * 10) / 10}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path d="M3.5 8.5L6 6L8.5 8.5H3.5Z" fill="#01AF45" />
                          </svg>
                          <span
                            style={{
                              fontSize: "9px",
                              color: "#4CAF50",
                              alignSelf: "end",
                              fontWeight: "600",
                            }}
                          >
                            {Math.round(row.incremental)}%
                          </span>
                        </CustomTableCellData>
                      )}
                    </CustomTableRow>
                  );
                })}
              </CustomTableBody>
            ) : (
              <></>
            )}
          </Table>
        </TableContainer>
        ) : 
        <></>
      }

      {rowsData.length === 0 && (
        <NoResultFound />
      )}
    </div>
  );
}
