import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import { stringToColor } from "../../../../assets/icons/FallBackComponents";
import "../../../../components/childCardComponents/NetNewAccount/table.css";
import React from "react";
import { useEffect, useState, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  Loader,
  NoResultFound,
} from "../../../../assets/icons/FallBackComponents";
import Link from "@material-ui/core/Link";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.MuiTableCell-root`]: {
    fontFamily: "Inter",
    paddingLeft: "0px",
  },

  [`&.${tableCellClasses.head}`]: {

    fontSize: "11px",
    letterSpacing: `0.15px`,
    textTransform: `uppercase`,
  },

  [`&.${tableCellClasses.head}:first-child`]: {
    width: `280px`,
    paddingLeft: `20px`,
    height: `38px`,
  },

  [`&.${tableCellClasses.head}:nth-child(2)`]: {
    width: `280px`,
  },

  [`&.${tableCellClasses.body}`]: {
    margin: `0px`,
    // padding: `0px`,
    color: "#595959",
    fontSize: "14px",
    fontWeight: `400`,
    letterSpacing: "0.15px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // width: `180px`,
    padding: `0 2rem 0 0`,
    boxSizing: `border-box`,
    fontFamily: "'Nunito Sans', 'sans-serif'",
  },

  [`&.${tableCellClasses.body}:last-child`]: {
    paddingRight: `6rem`,
  },

  [`&.${tableCellClasses.body}:first-child`]: {
    width: `280px`,
  },

  [`&.${tableCellClasses.body}:nth-child(2)`]: {
    width: `280px`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    maxWidth: `280px`,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "45px",
}));

function Tabular(props) {
  const controllerRef = useRef();

  console.log("props tablular", props)
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const getTabularData = () => {
    setTableData(null);
    let columns =
      props?.cardDetails?.cardObject?.toLowerCase() === "contact"
        ? [
            {
              "type": "contacts",
              "attribute": "contacts|lastName",
              "sortable": true,
            },
            {
              "type": "contacts",
              "attribute": "contacts|contactId",
              "sortable": true,
            },
            {
              "type": "contacts",
              "attribute": "contacts|firstName",
              "sortable": true,
            },
            {
              "attribute": "contacts|rowId",
              "type": "contacts",
            },
            {
              "attribute": "contacts|lastName",
              "type": "contacts",
            },
            {
              "attribute": "contactName",
              "type": "contacts",
            },
            {
              "type": "accounts",
              "attribute": "accountName",
              "sortable": true,
            },
            {
              "attribute": "contacts|accountId",
              "type": "contact",
            },
          ]
        : [
            {
              attribute: "accountName",
              "type": "accounts",
              sortable: true,
            },
            {
              "attribute": "accounts|rowId",
              type: "accounts",
            },
          ];

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;
    let UNIFIED_URL = "";

    if (props.cardDetails.cardObject?.toLowerCase() === "contact")
      UNIFIED_URL = `${
        window._env_.UNIFIED_URL
      }/${props?.cardDetails?.cardObject?.toLowerCase()}/new-hub/${
        props.tenantId
      }/filter?page=1&limit=5&icp=${props.icpSelected}&filterName=${
        props.segmentName
      }&contSegId=${
        props?.cardDetails?.filterDetails?.filterId
      }&sortby=&sortType=&offset=0&dataQueryRequired=true&countQueryRequired=false&refresh=${
        props.refresh || props.softRefresh
      }&cards=true`;
    else {
      UNIFIED_URL = `${
        window._env_.UNIFIED_URL
      }/${props?.cardDetails?.cardObject?.toLowerCase()}/new-hub/${
        props.tenantId
      }/filter?page=1&limit=5&icp=${props.icpSelected}&filterName=${
        props.segmentName
      }&segId=${
        props?.cardDetails?.filterDetails?.filterId
      }&sortby=&sortType=&offset=0&dataQueryRequired=true&countQueryRequired=false&refresh=${
        props.refresh || props.softRefresh
      }&cards=true`;
    }

    fetch(`${UNIFIED_URL}`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: [...props.reqBody], columns: columns }),
      signal: controllerRef.current?.signal,
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        setLoading(false);

        setTableData(data?.data || []);
      })
      .catch((error) => {
        console.log(error?.message);
        if(error?.message === "signal is aborted without reason"){
          return;
        }
        setTableData([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.icpSelected && props.tenantId) {
      getTabularData();
    }
  }, [
    props.icpSelected,
    props.segmentName,
    props.requestBody,
    props.refresh,
    props.softRefresh,
  ]);

  return (
    <>
      {tableData === null ? (
        <Loader />
      ) : (
        <>
          {tableData?.length > 0 ? (
            <>
              {props?.cardDetails?.cardObject?.toLowerCase() === "contact" && (
                <ContactTable {...props} tableData={tableData} />
              )}

              {props?.cardDetails?.cardObject?.toLowerCase() === "account" && (
                <AccountTable {...props} tableData={tableData} />
              )}
            </>
          ) : (
            <NoResultFound />
          )}
        </>
      )}
    </>
  );
}

function ContactTable(props) {
  return (
    <TableContainer>
      <Table sx={{}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ textTransform: "none" }}
              size="small"
              component="th"
            >
              Contact Name
            </StyledTableCell>
            <StyledTableCell
              style={{ textTransform: "none", textAlign: "end"}}
              size="small"
              component="th"
            >
              Account Name
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData?.map((row) => (
            <StyledTableRow key={row?.firstName + " " + row?.lastName}>
              <StyledTableCell
                size="small"
                component="th"
                scope="row"
                sx={{ textOverflow: "ellipsis" }}
              >
                <Tooltip title={row?.firstName + " " + row?.lastName}>
                  <div className="value">
                    <Avatar
                      sx={{
                        bgcolor: stringToColor(
                          row?.firstName + " " + row?.lastName
                        ),
                        width: 30,
                        height: 30,
                      }}
                    >
                      <span className="avatartext">
                        {row?.firstName?.slice(0, 2)?.toUpperCase()}
                      </span>
                    </Avatar>{" "}
                    <Link
                      style={{ color: "#000" }}
                      href={`${
                        window._env_.UNIFIED_FRONTEND_URL
                      }/#/AccountDetail/${
                        row.accountId
                      }/0?previouspage="analytics_dashboard"&filter=${encodeURIComponent(
                        encodeURIComponent(JSON.stringify(props.requestBody))
                      )}&icp=${props.icpSelected}&segmentName=${
                        props.segmentName
                      }&newDashboard=true&tabVal=${props.tabVal}&category=${
                        props?.category
                      }`}
                    >
                      <span
                        style={{
                          display: "block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                      {row?.firstName + " " + row?.lastName}
                      </span>
                    </Link>
                  </div>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell
                size="small"
                component="th"
                scope="row"
                sx={{ textOverflow: "ellipsis"}}
                style={{padding: "6px 16px"}}
              >
                <Tooltip title={row?.accountName}>
                  <div className="value" style={{justifyContent: "end",}}>
                    <p style={{maxWidth: "350px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{row?.accountName}</p></div>
                </Tooltip>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AccountTable(props) {
  return (
    <TableContainer>
      <Table sx={{}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ textTransform: "none" }}
              size="small"
              component="th"
            >
              Account Name
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData?.map((row) => (
            <StyledTableRow key={row.accountName}>
              <StyledTableCell
                size="small"
                component="th"
                scope="row"
                sx={{ textOverflow: "ellipsis" }}
              >
                <Tooltip title={row?.accountName}>
                  <div className="value">
                    <Avatar
                      sx={{
                        bgcolor: stringToColor(row?.accountName),
                        width: 30,
                        height: 30,
                      }}
                    >
                      <span className="avatartext">
                        {row?.accountName?.slice(0, 2)?.toUpperCase()}
                      </span>
                    </Avatar>{" "}
                    <Link
                      style={{ color: "#000" }}
                      href={`${
                        window._env_.UNIFIED_FRONTEND_URL
                      }/#/AccountDetail/${
                        row.accountId
                      }/0?previouspage="analytics_dashboard"&filter=${encodeURIComponent(
                        encodeURIComponent(JSON.stringify(props.requestBody))
                      )}&icp=${props.icpSelected}&segmentName=${
                        props.segmentName
                      }&tabVal=${props.tabVal}&newDashboard=true&category=${
                        props?.category
                      }`}
                    >
                      <span
                        style={{
                          display: "block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.accountName}
                      </span>
                    </Link>
                  </div>
                </Tooltip>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Tabular;
