import axios from "axios";
import { useEffect, useState } from "react";
import Card from "./Layout/Cards/Card";
import InfiniteScroll from "react-infinite-scroll-component";
import AddIcon from "@mui/icons-material/Add";
import { MainPageLoader, MainPageNoResultFound } from "../assets/icons/FallBackComponents";
import './DesignTab.css';

const DesignTab = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [element, setElement] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [cardsData, setCardsData] = useState([]);
  
  async function fetchCardDetails(refresh = false) {
    setCardsData();
    setIsLoading(true);
    try {
      console.log("calling fetchCardDetails");
      const response = await axios.get(
        `${window._env_.CARD_SERVICE}/card/v2/getCardsForUser/${props.tenantId}?icp=${props.icpSelected}&category=${props.category}`,
        { withCredentials: true }
      );
      if (response?.status === 200) {
        let cards = response.data.data.cards;
        setCardsData(cards);
        if (refresh) {
          setItems(cards.slice(0, 10));
          if (cards.length < 10) setHasMore(false);
          else {
            setHasMore(true);
            setElement(10);
          }
        } else {
          setItems(...items, cards.slice(element, element + 10));
          if (cards.length < 10) setHasMore(false);
          else setElement(element + 10);
        }
      } else setCardsData([]);
      setIsLoading(false);
    } catch (e) {
      console.log("error fetchCardDetails", e);
      setCardsData([]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCardDetails();
  }, []);

  console.log("Card Details", cardsData);
  
  const fetchData = async () => {
    try{
      let result = [...items,...cardsData.slice(element,element+10)]
       setItems(result)
      if(result.length===cardsData.length) setHasMore(false)
      else setElement(element+10)
    }
    catch(e){
      console.log("fetch data",e)
    }

  };

  console.log("Request body", props)

  return (
    <InfiniteScroll style={{ marginBottom: '32px' }} next={fetchData} hasMore={hasMore} dataLength={items?.length} //This is important field to render the next data
    >
    {isLoading ? <MainPageLoader/> :
    <>
    {items?.length?
    <div className="card-root">
      {items?.map((card) => (
        <Card 
        key={card?.cardUuid}
        cardDetails={card} 
        segmentName={props.segmentName}  tenantId={props.tenantId} 
        requestBody={props.requestBody} icpSelected={props.icpSelected}
        refresh = {props.refresh}
        tabVal={props.tabVal}
        category={props.category}
        />
      ))}
      <div style={{ height: 'auto' }}>
      <a href={`${window._env_.NEW_DASHBOARD_VIEW}/#/settings?icp=${props.icpSelected}&category=${props.category}`} style={{ textDecoration: 'none' }}>
        <div className="newInsight">
         <div className="newInsightText">
          <AddIcon/> New Insights
         </div>
        </div>
      </a>
      </div>
    </div>:<MainPageNoResultFound />}</>}
    </InfiniteScroll>
  );
};
export default DesignTab;
