import React, { useState,useRef,useEffect } from "react";
import ReactFusioncharts from "react-fusioncharts";
import { useAuthState } from "@bamboobox/b2logincheck";
import {Loader, NoResultFound} from '../../../assets/icons/FallBackComponents'
import CryptoJS from "crypto-js";
import FusionCharts from "fusioncharts";
import { LICENSE_STRING } from '../../Layout/Cards/Type/Constant.js'
import { useLocation } from "react-router-dom";

if (LICENSE_STRING != "") {
  var _0x49bc0e = _0x298e;
  function _0x298e(_0x7d727b, _0x38966f) {
    var _0x1b8773 = _0x1b87();
    return (
      (_0x298e = function (_0x298eb3, _0x1414f4) {
        _0x298eb3 = _0x298eb3 - 0x1ea;
        var _0x32f714 = _0x1b8773[_0x298eb3];
        return _0x32f714;
      }),
      _0x298e(_0x7d727b, _0x38966f)
    );
  }
  (function (_0x5c9ab4, _0x59221e) {
    var _0xe05717 = _0x298e,
      _0x550b93 = _0x5c9ab4();
    while (!![]) {
      try {
        var _0x3a14a4 =
          parseInt(_0xe05717(0x1f4)) / 0x1 +
          parseInt(_0xe05717(0x1f7)) / 0x2 +
          parseInt(_0xe05717(0x1ee)) / 0x3 +
          -parseInt(_0xe05717(0x1ea)) / 0x4 +
          (parseInt(_0xe05717(0x1eb)) / 0x5) *
            (parseInt(_0xe05717(0x1ec)) / 0x6) +
          (parseInt(_0xe05717(0x1f0)) / 0x7) *
            (-parseInt(_0xe05717(0x1ef)) / 0x8) +
          (-parseInt(_0xe05717(0x1f6)) / 0x9) *
            (-parseInt(_0xe05717(0x1f5)) / 0xa);
        if (_0x3a14a4 === _0x59221e) break;
        else _0x550b93["push"](_0x550b93["shift"]());
      } catch (_0x3a3581) {
        _0x550b93["push"](_0x550b93["shift"]());
      }
    }
  })(_0x1b87, 0xed863);
  function _0x1b87() {
    var _0x3cdb73 = [
      "Utf8",
      "b2\x20cdp\x20uses\x20fustion\x20chart",
      "169152xDHSeA",
      "30SRtDAJ",
      "2786688ScKRos",
      "1604846jQeLkv",
      "license",
      "options",
      "3060620rKUxON",
      "3982685QzwgIi",
      "6quDyMF",
      "enc",
      "1686396OqHHUo",
      "1738384mMIoTV",
      "49NMgycc",
      "AES",
    ];
    _0x1b87 = function () {
      return _0x3cdb73;
    };
    return _0x1b87();
  }
  var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]
    ["decrypt"](LICENSE_STRING, _0x49bc0e(0x1f3))
    ["toString"](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);
  FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({
    key: oooo0000,
    creditLabel: ![],
  });
}

const TrendView = (props) => {
  const [trend,setTrend] = useState({})
  const [loading,setLoading] = useState({});
  const userDetails = useAuthState();
  console.log("userDetails123",userDetails)
  const controllerRef = useRef();

  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const icp = query.get("icp") || "";
  console.log('ICP in trend', icp)
  
  const dataSource = {
    chart: {
      theme: "fusion",
      xAxisName: "Days",
      subCaption: "---- Trend",
      yAxisName: `No of ${props.cardDetails.cardObject.toLowerCase()}s`,
      lineThickness: "2",
      "subcaptionFont": "Open Sans",
      "subcaptionFontSize": "9px",
      "subcaptionFontColor": "#9C9C9C",
      lineColor:'#016B40',
      drawAnchors: "1",
      numDivLines:"3",
      xAxisNameFontColor : "#5E5454",
      xAxisNameFontSize:"9px",
      yAxisNameFontColor : "#5E5454",
      yAxisNameFontSize:"9px", // Optional: Set font color
      xAxisValueFontColor: '#5E5454', // Optional: Set font color
      yAxisValueFontColor: '#5E5454', // Optional: Set font color 
      xAxisNameFontBold:"0",
      yAxisNameFontBold:"0",
      "yAxisValueFont": "Open Sans",
      "yAxisValueFontSize": "9px",
      "yAxisValueFontColor": "#5F6B7A",
      "xAxisFont": "Inter",
      "xAxisFontSize": "10px",
      "xAxisFontColor": "#ff0000",
    "baseFont": "Open Sans",
    "baseFontSize": "9px",
    "baseFontColor": "#5F6B7A"
    },
    trendlines: [
      {
        line: [
          {
            startvalue: 15000,
            color: "#999",
            valueOnRight: "1",
            thickness: "2",
            dashed:"1"
          },
        ],
      },
    ],
  };

  const getTrendData = () => {
    setLoading(true);
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    let FILTER_CACHING=`${window._env_.Filter_CACHING_URL}/cache/SetOrGetToTrendsHistory/${props.tenantId}/${props.cardDetails.cardUuid}?userId=${userDetails.user.userUuid}&refresh=${props.refresh || props.softRefresh}` 
    fetch(
      `${FILTER_CACHING}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...props.cardDetails, cardType:props.cardDetails.cardObject, backup_icp: icp }),
        signal: controllerRef.current?.signal,
        credentials: "include",
      }
    )
      .then((data) => data.json())
      .then((data) => {
        setLoading(false);
        const parsedData = Array.isArray(data.data)?data.data:JSON.parse(data.data || [])
         console.log("parsedData",parsedData)
        let dataSourceForTrend = {...dataSource}
        const newData = []
        let avg = 0
        for(let i=0;i<parsedData.length;i++)
        {
         const singleData = parsedData[i]
         console.log("singleData",singleData)
         const dateParts = singleData.trendDate.split(' ');
         const month = dateParts[0];
        const day = dateParts[1].slice(0, -1);
         newData.push({label:`${day} ${month}`, value: singleData.trendValue})
        //  newData.push({
        //   label: "Sun",
        //   value: "19202",
        // })
         avg+=parseInt(singleData.trendValue);
     }

     let trendlines =[
      {
        line: [
          {
            startvalue: Math.abs(avg/parsedData?.length || 0),
            color: "#999",
            valueOnRight: "1",
            thickness: "2",
            dashed:"1"
          },
        ],
      },
    ]
        dataSourceForTrend = {...dataSourceForTrend, data:newData,trendlines:trendlines}
        setTrend(dataSourceForTrend || []);
      })
      .catch((error) => {
        console.log(error);
        if(error?.message === "signal is aborted without reason"){
          return;
        }
        setLoading(false);
        setTrend({});
      });
  };

  useEffect(() => {
    if (props.icpSelected && props.tenantId) {
      getTrendData();
    }
  }, [props.icpSelected,props.segmentName,props.requestBody,props.refresh,props.softRefresh]);


 console.log("Trend data",trend)
  return (
    loading?<Loader></Loader>:<>
    {Object.keys(trend)?.length<=0?<NoResultFound></NoResultFound>:<div 
    >
    <ReactFusioncharts
      type="line"
      width="98%"
      height="255"
      dataFormat="JSON"
      dataLoadStartMessage="Retrieving data Please wait"
      dataEmptyMessage="No data to display"
      dataSource={trend}
    />
    </div>}
    </>
  );
};

export default TrendView;

