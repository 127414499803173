import { GlobalStyles as GB } from "@mui/material";

export const GlobalStyles = () => (
  <GB
    styles={{
      fontSize: "12px",
      "& svg": {
        height: "16px",
        width: "16px",
      },
      ".card-form-root": {
        minHeight: "100vh",
        padding: "48px",
        display: "flex",
        flexDirection: "column !important",
        backgroundColor: "var(--background)",
        gap: "32px",
      },
      ".nav": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        color: "yellow",
        fontSize: "24px",
        a: {
          display: "flex",
        },
        svg: {
          height: "24px",
          width: "24px",
        },
        ".MuiTypography-root": {
          fontSize: "24px",
          fontWeight: "600",
          color: "#3D3D3B",
        },
      },
      ".card-form-body": {
        display: "grid",
        minWidth: "628px",
        width: "100%",
        maxWidth: "800px",
        gap: "24px",
        ".category, .header-2": {
          display: "grid",
          gap: "8px",
          ".MuiTypography-root": {
            fontSize: "12px",
            fontWeight: "600",
            color: "#3D3D3B",
            fontFamily: "Inter",
          },
        },
        "& .MuiAutocomplete-root": {
          height: "32px",
          "& .MuiOutlinedInput-root": {
            padding: "6px 14px",
            height: "32px",
            fontSize: "12px",
            backgroundColor: "#FFF",
            borderRadius: "8px",
            "& .MuiInputBase-input": {
              padding: "0",
              height: "15px",
            },
          },
          "& fieldset": {
            padding: "0",
            top: "-5px",
            height: "37px",
            border: "1px solid var(--border-color) !important",
            borderRadius: "8px",
          },
          "& .subfilter-attr": {
            "& .MuiOutlinedInput-root": {
              paddingRight: "8px !important",
            },
          },
        },
        "& .MuiInputBase-root": {
          borderRadius: "8px",
          fontSize: "12px",
          backgroundColor: "#FFF",
          "& .MuiSelect-select": {
            padding: "8px",
            paddingLeft: "1em",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          },
        },
        "& .share": {
          "& .MuiOutlinedInput-root": {
            minWidth: "628px",
            width: "100%",
            maxWidth: "800px",
          },
        },
        "& .MuiIconButton-root": {
          padding: "0",
        },
      },
      ".title": {
        "& .MuiAutocomplete-paper": {
          marginTop: "5px",
          marginLeft: "-12px",
          borderRadius: "4px !important",
        },
        "& .MuiInputBase-root": {
          border: "none",
          width: "100% !important",
          height: "30px !important",
          "& fieldset": {
            border: "none !important",
            paddingRight: "0 !important",
          },
          "& svg": {
            height: "16px",
            width: "16px",
          },
          "& .MuiSelect-icon": {
            marginTop: "3px",
          },
        },
        "& .title-option": {
          padding: "0",
          paddingLeft: "0px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          fontSize: "12px",
          "& svg": {
            height: "16px",
            width: "16px",
          },
          "&:hover": {
            backgroundColor: "var(--hover-bg) !important",
          },
        },
        "& .MuiFormHelperText-root": {
          position: "absolute",
          top: "2.5em",
          fontSize: "10px",
        },
      },
      ".title-autocomplete": {
        width: "200px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "15px",
        "& .MuiAutocomplete-inputRoot": {
          fontSize: "12px",
        },
        "& .MuiInputAdornment-positionStart": {
          "& svg": {
            height: "16px",
            width: "16px",
          },
        },
      },
      "& .title-textfield": {
        width: "100%",
        "& .MuiInputBase-root": {
          paddingRight: "10px",
        },
      },
      ".insight-description": {
        border: "none",
        "& .MuiOutlinedInput-root": {
          minWidth: "628px",
          maxWidth: "800px",
          width: "100%",
          height: "32px",
          borderRadius: "8px",
          backgroundColor: "#FFF",
          fontSize: "12px",
          fontFamily: "Inter",
          "& fieldset": {
            border: "1px solid var(--border-color) !important",
          },
        },
      },
      "& .segment": {
        position: "relative",
        "& > button": {
          display: "flex",
          alignItems: "center",
          width: "82px",
          height: "32px",
          fontWeight: "500",
          fontFamily: "Inter",
          borderRadius: "8px",
          padding: "8px 16px 8px 16px",
          border: "1px solid #DFE2DE",
          backgroundColor: "#FFF",
          cursor: "pointer",
        },
        "& .MuiAutocomplete-endAdornment": {
          top: "50%",
        },
        "& .includes-excludes": {
          "& .MuiAutocomplete-endAdornment": {
            top: "25%",
          },
        },
        "& svg": {
          height: "22px",
          width: "22px",
        },
        "& .MuiAutocomplete-inputRoot, .MuiAutocomplete-root": {
          height: "36px !important",
          "& .Mui-focused": {
            minHeight: "36px",
            height: "auto !important",
            gap: "4px",
          },
          "& fieldset": {
            height: "40px !important",
            top: "-4px",
            borderRadius: "4px !important",
            borderColor: "rgba(0, 0, 0, 0.23) !important",
          },
        },
        "& .input2": {
          paddingLeft: "10px",
          height: "100%",
          width: "100%",
          fontSize: "12px",
          outline: "none",
          borderRadius: "4px !important",
          border: "1px solid rgba(0, 0, 0, 0.23) !important",
        },
      },
      "& .share": {
        position: "relative",
        "& .MuiAutocomplete-root": {
          width: "auto !important",
        },
        "& .MuiAvatar-root": {
          height: "16px",
          width: "16px",
          fontSize: "8px",
          alignSelf: "center",
        },
        "& .tag-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
          "& .tag-item": {
            display: "flex",
            gap: "4px",
            padding: "2px 6px",
            fontSize: "11px",
            fontFamily: "Inter",
            borderRadius: "4px",
            backgroundColor: "var(--hover-bg)",
            "& .MuiAvatar-root": {
              height: "16px",
              width: "16px",
              fontSize: "8px",
              alignSelf: "center",
            },
            "& .MuiChip-root": {
              height: "16px !important",
              width: "16px",
              cursor: "pointer",
              backgroundColor: "var(--hover-bg) !important",
            },
          },
        },
      },
      "& .clear-all": {
        position: "absolute",
        top: "5px",
        right: "0",
        cursor: "pointer",
        color: "rgba(0, 0, 0, 0.35)",
        fontFamily: "Inter",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
      },
      ".insight-action-root": {
        display: "flex",
        gap: "20px",
        marginTop: "12px",
        paddingBottom: "20px",
        "& button.create-insight": {
          color: "#FFFFFF",
          background: "rgba(1, 107, 64, 1)",
        },
        "& button": {
          borderRadius: "12px",
          fontWeight: 600,
          fontSize: "12px",
          padding: "10px 16px 10px 16px",
          outline: "none",
          border: "none",
          cursor: "pointer",
          "&:disabled": {
            cursor: "auto",
            color: "var(--disable-button-color)",
            background: "var(--disable-button-bg)",
          },
        },
        "& .cancel": {
          backgroundColor: "#FFF",
        },
      },
      // "& .MuiAutocomplete-endAdornment": {
      //   top: "50% !important",
      // },
      "& .dropdownelements": {
        margin: "2px",
        padding: "2px",
        borderRadius: "4px",
        gap: "4px",
        "&:hover": {
          backgroundColor: "var(--hover-bg)",
        },
      },
      "& .export-filter-crieria-section-and-field": {
        "& .MuiChip-root": {
          height: "100% !important",
        },
      },
      "& .MuiAutocomplete-listbox": {
        fontSize: "12px",
        fontFamily: "Inter",
      },
      "& .MuiInput-input": {
        fontSize: "12px !important",
        fontFamily: "Inter",
        fontWeight: "400",
      },
      "& .MuiButtonBase-root": {
        gap: "4px",
        fontSize: "12px !important",
      },
      ".title-pop-up": {
        marginTop: "2px",
        "& .MuiButtonBase-root": {
          display: "flex",
          gap: "4px",
          alignItems: "center",
          "& svg": {
            height: "16px",
            width: "16px",
          },
        },
      },
      ".custom-dropdown": {
        marginTop: "2px",
        "& .MuiList-root": {
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          padding: "2px",
          "& li": {
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "4px 8px",
          },
        },
      },
    }}
  />
);
