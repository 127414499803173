import React, { useEffect, useState, useRef } from "react";
import "./chart.css";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Funnel from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useAuthState } from "@bamboobox/b2logincheck";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import {Loader} from '../../../assets/icons/FallBackComponents'

import { chartData,pastChartData } from "../../../constants/StageLevel";
import { Paper, Typography, withStyles, Tooltip, Box, List, CircularProgress } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
import CryptoJS from "crypto-js";

import TimeSpanComponentForFunnel from "./TimeSpanComponentForFunnel";
import axios from "axios";
import _ from "lodash";
import { flexbox } from "@material-ui/system";
import { LICENSE_STRING } from '../../Layout/Cards/Type/Constant.js'


if (LICENSE_STRING != "") {
  var _0x49bc0e = _0x298e;
  function _0x298e(_0x7d727b, _0x38966f) {
    var _0x1b8773 = _0x1b87();
    return (
      (_0x298e = function (_0x298eb3, _0x1414f4) {
        _0x298eb3 = _0x298eb3 - 0x1ea;
        var _0x32f714 = _0x1b8773[_0x298eb3];
        return _0x32f714;
      }),
      _0x298e(_0x7d727b, _0x38966f)
    );
  }
  (function (_0x5c9ab4, _0x59221e) {
    var _0xe05717 = _0x298e,
      _0x550b93 = _0x5c9ab4();
    while (!![]) {
      try {
        var _0x3a14a4 =
          parseInt(_0xe05717(0x1f4)) / 0x1 +
          parseInt(_0xe05717(0x1f7)) / 0x2 +
          parseInt(_0xe05717(0x1ee)) / 0x3 +
          -parseInt(_0xe05717(0x1ea)) / 0x4 +
          (parseInt(_0xe05717(0x1eb)) / 0x5) *
            (parseInt(_0xe05717(0x1ec)) / 0x6) +
          (parseInt(_0xe05717(0x1f0)) / 0x7) *
            (-parseInt(_0xe05717(0x1ef)) / 0x8) +
          (-parseInt(_0xe05717(0x1f6)) / 0x9) *
            (-parseInt(_0xe05717(0x1f5)) / 0xa);
        if (_0x3a14a4 === _0x59221e) break;
        else _0x550b93["push"](_0x550b93["shift"]());
      } catch (_0x3a3581) {
        _0x550b93["push"](_0x550b93["shift"]());
      }
    }
  })(_0x1b87, 0xed863);
  function _0x1b87() {
    var _0x3cdb73 = [
      "Utf8",
      "b2\x20cdp\x20uses\x20fustion\x20chart",
      "169152xDHSeA",
      "30SRtDAJ",
      "2786688ScKRos",
      "1604846jQeLkv",
      "license",
      "options",
      "3060620rKUxON",
      "3982685QzwgIi",
      "6quDyMF",
      "enc",
      "1686396OqHHUo",
      "1738384mMIoTV",
      "49NMgycc",
      "AES",
    ];
    _0x1b87 = function () {
      return _0x3cdb73;
    };
    return _0x1b87();
  }
  var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]
    ["decrypt"](LICENSE_STRING, _0x49bc0e(0x1f3))
    ["toString"](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);
  FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({
    key: oooo0000,
    creditLabel: ![],
  });
}

//import axios from "axios";

ReactFC.fcRoot(FusionCharts, Funnel, Widgets, FusionTheme);

const AttributeList = [
  "All",
  "Unreached",
  "Unengaged",
  "Aware",
  "Engaged",
  "Potential Opportunity",
  "Active Opportunity"
];

const AttributeLists = [
  "Unreached",
  "Unengaged",
  "Aware",
  "Engaged",
  "Potential Opportunity",
  "Active Opportunity"
]


const BoxIcon = (props) => {
  let colorObj = {
    'All': '#A0A0A0',
    'Unreached': '#A0A0A0',
    'Unengaged': '#A0A0A0',
    'Aware': '#A0A0A0',
    'Engaged': '#A0A0A0',
    'Potential Opportunity': '#A0A0A0',
    'Active Opportunity': '#A0A0A0'
  }
  let styObj = { width: 20, height: 20 };
  styObj['backgroundColor'] = colorObj[props.stage]
  return (
    <>
      <Box style={styObj}>
      </Box>
    </>
  )
}

const FunnelChart = (props) => {
  let location = useLocation();
  const controllerRefFunnel = useRef();
  const controllerRefPastFunnel = useRef();
  const controllerRefStack = useRef()

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  let GetAttributeValue = () => {
    const attribute = useQuery().get("attribute")
    if(attribute === '' || attribute === 'null' || !attribute || attribute === 'undefined') {
      return AttributeList[0]
    }
    return  attribute;
  }
  function GetDateFrom (){
    let date = sessionStorage.getItem("oldDate") || weeksOldDate 
    return new Date(date);
  }
  const [pastChart,setPastChart] = useState([])
  const [chartConfigs, setChartConfigs] = React.useState([]);
  const [stageValue, setStageValue] = React.useState(GetAttributeValue())
  const [stageData, setStageData] = React.useState([]);
  const [isLoadingPastFunnel,setIsLoadingPastFunnel] = React.useState(false);
  const [isLoading,setIsLoading] = React.useState(false);
  const [count,setCount] = React.useState(1);

  // const [stackDepartment,setStackDepartment] =React.useState([]);
  const userDetails = useAuthState();
  let weeksOldDate = new Date();
  weeksOldDate.setDate(weeksOldDate.getDate() - 7);
  const [selectedFrequency, setSelectedFrequency] = React.useState("Weekly")

  const [fromDate, setFromDate] = React.useState(GetDateFrom())
  
  console.log("Cookies for props",userDetails);
  const tenantId = userDetails.user.tenantId;
  const [iconUrl,setIconUrl]= useState('#');
  var funnelChartData = [];
  const funnelPastChartData = [];

  const Down = ()=>{
    return (
      <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 4L7.795 3.295L5 6.085L5 0H4L4 6.085L1.21 3.29L0.5 4L4.5 8L8.5 4Z" fill="#F37B6B"/>
</svg>

    )
  }
  const Up = ()=>{
    return (
      <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 4L1.205 4.705L4 1.915V8H5V1.915L7.79 4.71L8.5 4L4.5 0L0.5 4Z" fill="#21DA8C"/>
</svg>
    )
  }
  const updateFunnel = (stageValue) => {
    console.log("update funnel ??")
    let a = document?.getElementById('funnel-finder')?.getElementsByTagName("text");

    let length = document?.getElementById('funnel-finder')?.getElementsByTagName("text").length;

    let i = 0;
    while (i < length) {

      if (a[i].innerHTML === stageValue) {
        const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
        function simulateMouseClick(element) {
          mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
              new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
              })
            )
          );
        }
        if (a)
          simulateMouseClick(a[i].nextSibling.nextSibling);
        break;
      }
      i++;
    }
  }
  const [array, setArray] = useState([])
  const [pastArray,setPastArray] = useState([])
  const [percentageResults,setPercentageResults] = useState([])
  
  

  
  const [pastChartConfig, setPastChartConfig] = useState({})
  const calculatePercentage = (array,pastArray) =>{
    try{
      let percentageResult = []
      if(array.length === pastArray.length)
      for(let i=0;i<array.length;i++){
        if(array[i] === pastArray[i])  percentageResult.push(0)
        else if(pastArray[i]==0) percentageResult.push(array[i])
        else percentageResult.push(Math.round(((array[i]-pastArray[i])/pastArray[i])*100))
      }
      setPercentageResults(percentageResult);
      console.log("percentage result:",percentageResult);

    }
    catch(e){
      console.log("calculate percentage:",e);
    }
  }

  useEffect(()=>{
    calculatePercentage(array,pastArray)
  },[array,pastArray])
  
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  useEffect(async()=>{
    
    console.log("fromDate:",formatDate(fromDate));
    if (props.icpSelected !== null && props.segmentName !== '')
      await setPastChartData();
  },[fromDate,props.requestBody,props.segmentName,props.icpSelected,props.softRefresh,props.refresh])


  const setPastChartData = async() =>{
    setIsLoadingPastFunnel(true)
    const body = {"date":formatDate(fromDate),"filters": props.requestBody}
    if (controllerRefPastFunnel.current) {
      controllerRefPastFunnel.current.abort();
    }
    const controller = new AbortController();
    controllerRefPastFunnel.current = controller;
    
   fetch(`${window._env_.UNIFIED_URL}/analytics/funnel/past/funnelview/${tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,{
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    signal:controllerRefPastFunnel.current?.signal,
    credentials: "include"
  }).then(response => response.json())
    .then(data => {
      setIsLoadingPastFunnel(false)
      setPastChart(data)
      const arrayTOPush=[]
      let sumOfTotalPastAccount = 0;
      const arrayTOPushPast = []

      for(let obj of data) sumOfTotalPastAccount += parseInt(obj.count)

      pastChartData.forEach((chartDataStage) => {
        let result = data.find((obj) =>  obj.accountStage === chartDataStage.label);
        let funnelChartDataObject = {
          label: "",
          value: "",
          color: "",
          tooltext: "",
          displayValue:"",
        };
        if (result !== undefined) {
          funnelChartDataObject.label = chartDataStage.label;
          funnelChartDataObject.value = 1;
           funnelChartDataObject.color = chartDataStage.color;
           funnelChartDataObject.displayValue = result.count;
           arrayTOPush.push(parseInt(result.count))
         
          funnelChartDataObject.tooltext = `<div style="padding: 8px">${chartDataStage.label}<br><b>${result.count
            }</b><br><br>% of Total Accounts<br><b>${Math.round(
              (parseInt(result.count) / sumOfTotalPastAccount) * 100
            )}</div>`;
            // '${chartDataStage.label}<br><b style="font-size: 14px">$result.count</b><br><br>% of Total Accounts<br> <b style="font-size: 14px">${Math.round((parseInt(result.count) / sumOfTotalAccount) * 100</b><br><br>Average Stage Velocity<br> <b style="font-size: 14px">${result.velocity}</b></div>';

          funnelPastChartData.push(funnelChartDataObject);
          
        } else {
          funnelChartDataObject.label = chartDataStage.label;
          funnelChartDataObject.value = 1;
          funnelChartDataObject.displayValue = "0";
          arrayTOPush.push(0)
          funnelChartDataObject.color = chartDataStage.color;
        
          funnelChartDataObject.tooltext = `<div style="position:relative;z-index:1500">${chartDataStage.label}: 0 <br> 0 % of Total Accounts </div>`;
          funnelPastChartData.push(funnelChartDataObject);
        }
      })
      setPastArray(arrayTOPush)
    
    const newChartConfigs = {
      type: "funnel",
      width: "22%",
      height: "328",
      dataFormat: "json",
      dataSource: {
        chart: {
          alignCaptionWithCanvas: 0,
          //subcaption: "Click on any plot to see the days spent by the accounts in 'Stage Ageing' chart",
          theme: "fusion",
          showlabels: 0,
          showValues: 1,
          showColors:1,
          showLegend: 0,
          showLabelsAtCenter: "1",
          minPlotHeightForValue: 20,
          enableSmartLabels: 1,
          legendPosition: "absolute",
          showpercentvalues: "0",
          streamlineddata: "0",
          plottooltext: "$label: <b>$dataValue</b>",
          showPlotBorder: "1",
          plotBorderThickness: 2,
          plotBorderColor: "#ffffff",
          canvasPadding: '0',
          canvasMargin: '0',
          plotBorderAlpha: 100,
          "usePlotGradientColor": "1",
          "toolTipBorderRadius": "10",
          "toolTipBgColor": "#FFFFFF",
          "toolTipBgAlpha": "100",
          "showToolTipShadow": "1",
          //  "toolTipBgColor": "#000000",
          // toolTipAlpha:100   
          useEllipsesWhenOverflow:"1",
          labelFontColor:"#000000",
          labelFontSize:"16"
        },
        
        data: funnelPastChartData,
        // linkeddata: funnelChartLinkedDataArray
      },


    };

    setPastChartConfig(newChartConfigs)
  }).catch((error)=>{
    if(error?.message === "The user aborted a request.") return;
  });
  
}
   
  const setChartData = () => {
    setIsLoading(true)
    if (controllerRefFunnel.current) {
      controllerRefFunnel.current.abort();
    }
    const controller = new AbortController();
    controllerRefFunnel.current = controller;
    fetch(
      `${window._env_.UNIFIED_URL}/analytics/funnel/funnelview/${tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({"filters":props.requestBody}),
        signal:controllerRefFunnel.current?.signal,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false)
        
        setStageData(data);
        
        let sumOfTotalAccount = 0;
        const arrayTOPush = []

        Object.values(data).forEach((stage) => {
          sumOfTotalAccount += parseInt(stage.count);
        });
       
        chartData.forEach((chartDataStage) => {
          var result = data.find((obj) => {
            return obj.accountStage === chartDataStage.label;
          });
          var funnelChartDataObject = {
            label: "",
            value: "",
            color: "",
            link: "",
            tooltext: "",
            displayValue:"",
          };

          var stageVelocityDataObject = {
            label: "",
            velocity: "",
          };

          let dynamicFilterObject = [
            {
              attribute: `b2AccountStage`,
              relation: "includes",
              values: [],
              type: "ACCOUNTS"
            },
          ];
          dynamicFilterObject[0].values.push(chartDataStage.label);
          if (
            !(Array.isArray(props.requestBody)  && !props.requestBody.length)
          ) {
            props.requestBody.forEach((reqBody) => {
              dynamicFilterObject.push(reqBody);
            });
          }
          if (result !== undefined) {
            funnelChartDataObject.label = chartDataStage.label;
            funnelChartDataObject.value = 1;
             funnelChartDataObject.color = chartDataStage.color;
             funnelChartDataObject.displayValue = result.count;
             arrayTOPush.push(parseInt(result.count))

            funnelChartDataObject.link = `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&attribute=${stageValue}&category=${props.category}`;
            funnelChartDataObject.tooltext = `<div style="padding: 8px;">${chartDataStage.label}<br><b>${result.count
              }</b><br><br>% of Total Accounts<br><b>${Math.round(
                (parseInt(result.count) / sumOfTotalAccount) * 100
              )}</b><br><br> Average Stage Velocity<br><b>${result.velocity
              }</b> days</div>`;
              // '${chartDataStage.label}<br><b style="font-size: 14px">$result.count</b><br><br>% of Total Accounts<br> <b style="font-size: 14px">${Math.round((parseInt(result.count) / sumOfTotalAccount) * 100</b><br><br>Average Stage Velocity<br> <b style="font-size: 14px">${result.velocity}</b></div>';

            funnelChartData.push(funnelChartDataObject);
            
          } else {
            funnelChartDataObject.label = chartDataStage.label;
            funnelChartDataObject.value = 1;
            funnelChartDataObject.displayValue = "0";
            arrayTOPush.push(0)
            funnelChartDataObject.color = chartDataStage.color;
            funnelChartDataObject.link = `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&attribute=${stageValue}&category=${props.category}`;
            funnelChartDataObject.tooltext = `<div style="position:relative;z-index:1500">${chartDataStage.label}: 0 <br> 0 % of Total Accounts <br> Average Stage Velocity: <b>0</b> days</div>`;
            funnelChartData.push(funnelChartDataObject);

          }
         
          if(chartDataStage.label===stageValue)
          {
            setIconUrl(
              `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
                JSON.stringify(dynamicFilterObject)
              ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
                JSON.stringify(props.requestBody)
              ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&attribute=${stageValue}&category=${props.category}`
            )
          }

        });

        setArray(arrayTOPush)
        const newChartConfigs = {
          type: "funnel",
          width: "22%",
          height: "328",
          dataFormat: "json",
          dataSource: {
            chart: {
              alignCaptionWithCanvas: 0,
              //subcaption: "Click on any plot to see the days spent by the accounts in 'Stage Ageing' chart",
              theme: "fusion",
              showlabels: 0,
              showValues: 1,
              showColors:1,
              showLegend: 0,
              showLabelsAtCenter: "1",
              minPlotHeightForValue: 20,
              enableSmartLabels: 1,
              legendPosition: "absolute",
              showpercentvalues: "0",
              streamlineddata: "0",
              plottooltext: "$label: <b>$dataValue</b>",
              showPlotBorder: "1",
              plotBorderThickness: 2,
              canvasPadding: '0',
              canvasMargin: '0',
              plotBorderColor: "#ffffff",
              plotBorderAlpha: 100,
              "usePlotGradientColor": "1",
              "toolTipBorderRadius": "10",
              "toolTipBgColor": "#FFFFFF",
              "showToolTipShadow": "1",
              //  "toolTipBgColor": "#000000",
              // toolTipAlpha:100   
              useEllipsesWhenOverflow:"1",
              labelFontColor:"#FFFFFF",
              labelFontSize:"16"
            },
            
            data: funnelChartData,
            // linkeddata: funnelChartLinkedDataArray
          },
          events: {
            
    
            "rendered": function (eventObj, dataObj) {
              console.log('in here after render');
              updateFunnel(stageValue)
            },

            "ready": function (eventObj, dataObj) {
              console.log('in here after ready');
              updateFunnel(stageValue)
            },

            "dataUpdated": function (eventObj, dataObj) {
              console.log('in here after ready');
              updateFunnel(stageValue)
            },

          }
        };
        // legendUpdate("untouch");   
        console.log("stageval", stageValue, stageData)
        setChartConfigs(newChartConfigs);
      }).catch((error)=>{
        if(error?.message === "The user aborted a request.") return;
      });   
    // console.log(chartConfigs)
  };
  
  
  
 


  useEffect(() => {
    if (props.icpSelected !== null && props.segmentName !== '') {
    setChartData();
    }
  }, [props.requestBody, props.icpSelected, props.segmentName, fromDate,count,,props.softRefresh,props.refresh])
  
 

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(236, 247, 254)",
      color: UItheme.palette.primary.dark,
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);





  return (
    <div className="container-align">

        <div className="funnel-ageing-container">
          { isLoading? (
            <React.Fragment><Loader  /></React.Fragment>
          ) : (
        

            <div style={{ display: "flex", flexDirection: "column", width: "100%"}}>
              <div  style={{display:"flex",justifyContent:'flex-end', alignItems:'center', height: '38px', marginTop: "1px"}}> 

              <Typography className="textfont" variant="Ag" component={'Ag'}>                  
                  Compare with 
                  </Typography>
                  <TimeSpanComponentForFunnel frequencySelected={selectedFrequency} setFromDate={setFromDate} fromDate={fromDate} input='1'/>

              </div>

              <div style={{ display: "flex" ,gap:"2vw",justifyContent:"center" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", width:'55vw',justifyContent: "space-around"}}>
                    <div style={{borderRadius:"16px"}}>
                  
                  <div id='funnel-finder' style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-evenly',alignItems:"center"}}>

                  {/* <div> */}
                  <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-evenly'}}>
                      <div className="funnel-align-past"> 
                      <div className="funnelText">Previous Range</div>
                      <ReactFC {...pastChartConfig} />
                  
                      </div>
                      
                      <div style={{display:"flex"}}>
                       
                        
                        
                          <div className="attributeList">
                      {AttributeLists.map((element,index) => (
                      <List id={'attribute'+index} className={percentageResults[index]<0&&'negative'} style={{width:'max-content',
                      color: "#3D3D3B",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      }}> 
                      <p12 id={"percentage"+index} className={percentageResults[index]<0&&'negativeNumber'} > {percentageResults[index]<0?<Down/>:percentageResults[index]>0?<Up/>:''} {Math.abs(percentageResults[index])}% </p12>
                      {element}
                       </List>
                      
                      ))}
                     
                      </div>
                      </div>
                      
                   <div >
                      </div>
                      <div className="funnel-align">
                         <div className="funnelText">Current Range</div>
                          <ReactFC {...chartConfigs} />
                     
                      </div>
                      </div>
       
                    <div >
                    </div>
                  </div>
                  </div>
                </div>

        
              </div>
             
            </div>

          )}
        </div>
 
    </div >
  );
};

export default FunnelChart;
