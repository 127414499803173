import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NewAccountManager from "./NewAccountManager";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '149%',
        letterSpacing: '0.15px',
        fontFamily: "Nunito Sans, sans-serif",
        color: "#595959",
        textShadow: "none",
    },
    '& .MuiDialogTitle-root': {
        padding: '24px 24px 0px 49px',
    },
    '& .MuiDialog-paper': {
        maxWidth: '1300px',
        minWidth: '1300px',
        borderRadius: "24px",
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: "auto",
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function newAccountDialog(props) {

    const handleClose = () => {
        props.setOpenNewAccountDialog(false);
        // props.setIntentNewAccount(false);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.openNewAccountDialog}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Suggested Accounts based on Intent
                </BootstrapDialogTitle>
                <DialogContent>
                    <NewAccountManager tenantId={props.tenantId} icpSelected={props.icpSelected} setOpenNewAccountDialog={props.setOpenNewAccountDialog} openNewAccountDialog={props.openNewAccountDialog} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
