/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthState } from "@bamboobox/b2logincheck";
import "./InfoIndicatorCard.css";
import React, { useState, useEffect } from "react";
import { chartData, pastChartData } from "../../constants/StageLevel";
import { formatNumber,up , down } from "../../helpers/common.helper";
import Link from "@material-ui/core/Link";
import { Tooltip } from "@mui/material";
import { SmallLoader } from "../../assets/icons/FallBackComponents";
import { InformationIcon } from "../../assets/svg";

const InfoIndicatorCardComponent = (props) => {
  console.log("percentage Changes",props)
  let dynamicFilterObject = [
    {
      attribute: `b2AccountStage`,
      relation: "includes",
      values: [],
      type: "ACCOUNTS"
    },
  ];
  dynamicFilterObject[0].values.push(props?.obj?.label);
  if (
    !(Array.isArray(props.requestBody)  && !props.requestBody.length)
  ) {
    props.reqBody.forEach((reqBody) => {
      dynamicFilterObject.push(reqBody);
    });
  }


  const transferUrl = `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
    JSON.stringify(dynamicFilterObject)
  ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
    JSON.stringify(props.reqBody)
  ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&category=${props?.category}`
  return (
    <div className="indiCatorCardContainer">
      <Link style={{ textDecoration: "none" }} href={transferUrl}>
        <div className="InfoIndicatorCardStage">
          {props?.obj?.label}
          <Tooltip title={props?.obj?.description || ""} arrow>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
              <InformationIcon />
            </div>
          </Tooltip>
        </div>

        <div className="InfoIndicatorCardCount">{formatNumber(props?.obj?.count)}</div>
        <div className={props?.obj?.percentageChange < 0 ? "InfoIndicatorCardPercentageDown" : "InfoIndicatorCardPercentageUp"}>
          <div style={{ marginRight: "4px" }}>{props?.obj?.percentageChange < 0 ? down() : up()}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {Math.abs(props?.obj?.percentageChange)}%<span style={{ marginLeft: "4px", color: "#6C6E6B", fontSize: "10px", fontWeight: 400 }}>vs last 7 days</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const InfoIndicatorLoaderComponent = (props) => {

  return (
    <div className="indiCatorCardContainer">
      <div className="InfoIndicatorCardStage">
        {props?.obj?.label}
        <Tooltip title={props?.obj?.description || ""} arrow>
          <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <InformationIcon />
          </div>
        </Tooltip>
      </div>
      {props.isChartLoading ? <SmallLoader /> : <></>}
    </div>
  );
};
const InfoIndicatorCard = (props) => {
  const [array, setArray] = useState([]);
  const [pastArray, setPastArray] = useState([]);
  const [percentageResults, setPercentageResults] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isPastChartLoading, setIsLoadingPastFunnel] = useState(true);
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  console.log("userDetails",tenantId)
  console.log("Props in ", props);
  console.log(
    "Array of past and present data",
    array,
    pastArray,
    percentageResults
  );
  const calculatePercentage = async() => {
    try {
      const percentageResult = [];
      if (array?.length === pastArray?.length)
        for (let i = 0; i < array.length; i++) {
          const percentageChangeObj = { ...array[i], percentageChange: parseInt(0) };
          if (array[i].count === pastArray[i])
          {
            percentageChangeObj.percentageChange = 0;
        }
          else if (pastArray[i] === 0)
          {
            percentageChangeObj.percentageChange = array[i].count;
        }
          else
          {
            percentageChangeObj.percentageChange = Math.round(
              ((array[i].count - pastArray[i]) / pastArray[i]) * 100
            );
        }
          percentageResult.push(percentageChangeObj);
        }
      setPercentageResults(percentageResult);
      console.log("percentage result:", percentageResult);
    } catch (e) {
      console.log("calculate percentage:", e);
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const setPastChartData = async () => {
    setIsLoadingPastFunnel(true);
    const fromDate = new Date();
    const oneWeekBefore = new Date(
      fromDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );

    const body = {
      date: formatDate(oneWeekBefore.toDateString()),
      filters: [],
    };
    

    fetch(
      `${window._env_.UNIFIED_URL}/analytics/funnel/past/funnelview/${tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        // signal: controllerRefPastFunnel.current?.signal,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoadingPastFunnel(false);

        const arrayTOPush = [];
        let sumOfTotalPastAccount = 0;
        for (let obj of data) sumOfTotalPastAccount += parseInt(obj.count);

        pastChartData?.forEach((chartDataStage) => {
          let result = data.find(
            (obj) => obj.accountStage === chartDataStage.label
          );

          if (result !== undefined) {
            arrayTOPush.push(parseInt(result.count));
          } else {
            arrayTOPush.push(0);
          }
        });
        setPastArray(arrayTOPush);
      })
      .catch((error) => {
        if (error?.message === "The user aborted a request.") return;
      });
  };

  const setCurrentChartData = async () => {
    setIsChartLoading(true);
    const body = {
      filters: [],
    };
    fetch(
      `${window._env_.UNIFIED_URL}/analytics/funnel/funnelview/${tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        // signal: controllerRefFunnel.current?.signal,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsChartLoading(false);

        const arrayTOPush = [];
        let sumOfTotalPastAccount = 0;
        for (let obj of data) sumOfTotalPastAccount += parseInt(obj.count);

        chartData.forEach((chartDataStage) => {
          let result = data.find(
            (obj) => obj.accountStage === chartDataStage.label
          );
          const countObj = { ...chartDataStage, count: parseInt(0) };
          if (result !== undefined) {
            countObj.count = parseInt(result.count);
          }
          arrayTOPush.push(countObj);
        });
        setArray(arrayTOPush);
      })
      .catch((error) => {
        if (error?.message === "The user aborted a request.") return;
      });
  };

  useEffect(async () => {

    if (props.icpSelected !== null && props.segmentName !== "")
      await setPastChartData();
  }, [props.segmentName, props.icpSelected]);

  useEffect(async () => {
    if (props.icpSelected !== null && props.segmentName !== "")
      await setCurrentChartData();
  }, [props.segmentName, props.icpSelected]);

  useEffect(async() => {
   await calculatePercentage(array,pastArray);
  }, [array, pastArray,props.icpSelected]);

  return (
    <div className="InfoIndicatorCard">
      {isChartLoading? <>
       {chartData?.map((obj,index) => (
       <InfoIndicatorLoaderComponent isChartLoading={isChartLoading} obj={obj} key = {index} />
      ))}
      </>:<>
      {percentageResults?.map((obj,index) => (
        <InfoIndicatorCardComponent key = {index} obj={obj} 
        reqBody={props.reqBody} icpSelected={props.icpSelected} segmentName={props.segmentName}
        />
      ))}
      </>}
    </div>
  );
};

export default InfoIndicatorCard;
