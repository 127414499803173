import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Avatar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const ListUser = (params) => {
  const [isLoading, setIsLoading] = useState(params.isLoading);

  useEffect(() => {
    setIsLoading(params.isLoading);
  }, [params.isLoading]);

  const handleAllResult = (ele) => {
    try {
      const check = ele["target"]["checked"];
      params.setListUsers(
        params.listUsers.map((val) => {
          if (val.name.toLowerCase().includes(params.filterSearch.toLowerCase())) val["cardShared"] = check;
          return val;
        })
      );
      params.handleSingListCheck()
    } catch (error) {
      console.error("handleAllResult err:", error);
    }
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0]?.[0]?.toUpperCase() ?? ""}${name.split(" ")[1]?.[0]?.toUpperCase() ?? ""}`,
    };
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  // console.log("...........",params.listUsers,params.listUsers.filter(f =>
  //   {console.log("hell",f.name, f.name.includes(params.filterSearch),params.filterSearch);
  // } ),params.filterSearch);

  const all =
    params.listUsers.filter((f) => f.name.toLowerCase().includes(params.filterSearch.toLowerCase())).filter((e) => e["cardShared"] === true).length ===
    params.listUsers.filter((f) => f.name.toLowerCase().includes(params.filterSearch.toLowerCase())).length;

  return (
      <>
      {isLoading ? (
        <div style={{height: '370px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress style = {{ color: "#016B40" }} />
          </div>
      ) : (
        <>
        <List sx={{ display: "block", height: "264px", overflow: "auto" }}>
        <ListItem>
          <ListItemIcon style={{ minWidth: "16px" }}>
            <Checkbox color="success" edge="start" checked={all} onClick={handleAllResult} disableRipple inputProps={"aria-labelledby"} />
          </ListItemIcon>
          <ListItemText style={{ fontSize: "14px", fontWeight: "500", color: "#3d3d3b" }} id={"All"} primary="Select All" />
        </ListItem>
        {params.listUsers
          .filter((f) => f.name.toLowerCase().includes(params.filterSearch.toLowerCase()))
          .map((value) => (
            <ListItem key={value.userUuid} className={(value["cardShared"] && "highligt") || ""}>
              <ListItemIcon style={{ minWidth: "16px" }}>
                <Checkbox
                  edge="start"
                  checked={value["cardShared"]}
                  tabIndex={-1}
                  color="success"
                  onClick={(e) => params.handleSingListCheck(e, value.userUuid)}
                  disableRipple
                  inputProps={{ "aria-labelledby": value.userUuid }}
                />
              </ListItemIcon>
              <Avatar {...stringAvatar(value.name)} style={{ margin: "0 12px", height: "24px", width: "24px", fontSize: "10px" }} />
              <ListItemText style={{ fontSize: "14px", fontWeight: "500", color: "#3d3d3b" }} id={value.userUuid} primary={<>{value.name.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</>} />
            </ListItem>
          ))}
      </List>
      </>
      )}</>
  );
};
