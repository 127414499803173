import { useState } from "react";
import { Typography, Autocomplete, TextField, Box, InputAdornment } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import DomainIcon from "@mui/icons-material/Domain";
import PersonIcon from "@mui/icons-material/Person";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const camalize = (str) => {
  return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
    return chr.toUpperCase();
  });
};
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const Title = (props) => {
  const { expType, setExpType, titleOptions, title, setTitle } = props;
  const [lengthError, setLengthError] = useState(false);

  return (
    <>
      <Typography>Title</Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "140px 2px 1fr",
          gap: "8px",
          alignItems: "center",
          width: "100%",
          height: "32px",
          background: "#FFFFFF",
          borderRadius: "8px",
          border: "1px solid #DFE2DE",
          position: "relative",
        }}
      >
        <Box style={{ overflow: "hidden" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={expType}
            onChange={(event) => {
              setExpType(event.target.value);
            }}
            IconComponent={KeyboardArrowDownRoundedIcon}
            MenuProps={{
              classes: {
                paper: "title-pop-up custom-dropdown",
              },
            }}
          >
            {titleOptions.map((item) => (
              <MenuItem value={item}>
                {item === "Account" ? <DomainIcon /> : <PersonIcon />}
                <span>{item}</span>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <div
          style={{
            width: "1px",
            height: "60%",
            background: "#DFE2DE",
          }}
        />
        <Box>
          <TextField
            label=""
            className="title-textfield"
            value={title}
            onChange={(event) => {
              const value = event.target.value;
              setTitle(value);
              if (value.length > 128) {
                setLengthError(true);
              } else {
                setLengthError(false);
              }
            }}
            variant="standard"
            placeholder="Add title to your insight"
            autoComplete="off"
            error={lengthError}
            helperText={lengthError && "Title length exceeded 128 characters:"}
            InputProps={{
              disableUnderline: true,
              /* startAdornment: (
                <InputAdornment position="start">
                  <div style={{ fontWeight: 700, fontSize: "12px", color: "rgba(3, 3, 3, 0.6)" }}>X {camalize(expType)}</div>
                </InputAdornment>
              ), */
            }}
          />
        </Box>
      </div>
    </>
  );
};
