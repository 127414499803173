import { useState, useEffect } from "react";
import { Typography, Autocomplete, TextField } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getSessionPermissionsForCards } from "../../../helpers/permissionForCards.helper";

export const Action = (props) => {
  const { expType, selectedAction, setSelectedAction } = props;
  const [actionOption, setActionOption] = useState(["None"]);

  useEffect(() => {
    try {
      (async () => {
        let permissionsForCards = await getSessionPermissionsForCards();

        if (Object.keys(permissionsForCards)?.length > 0) {
          permissionsForCards = permissionsForCards?.[expType.toLowerCase()]?.filter((labels) => labels.toLowerCase() !== "download");
        }
        setActionOption(["None", ...permissionsForCards]);
      })();
    } catch (error) {
      console.error(error);
      setActionOption(["None"]);
    }
  }, [expType]);
  return (
    <>
      <Typography>Action</Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedAction}
        onChange={(event) => {
          setSelectedAction(event.target.value);
        }}
        IconComponent={KeyboardArrowDownRoundedIcon}
        sx={{
          width: "180px",
        }}
        MenuProps={{
          classes: {
            paper: "custom-dropdown",
          },
        }}
      >
        {actionOption.map((item) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </Select>
    </>
  );
};
