import React, { useState, useEffect } from 'react';
import FusionCharts from 'fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import Column2D from "fusioncharts/fusioncharts.charts";
import LegendWithMenu from '../../ChartLegend/chartLegendWithMenu'
import CryptoJS from "crypto-js";
import { LICENSE_STRING } from '../../Layout/Cards/Type/Constant.js'

if (LICENSE_STRING != "") {
  var _0x49bc0e = _0x298e;
  function _0x298e(_0x7d727b, _0x38966f) {
    var _0x1b8773 = _0x1b87();
    return (
      (_0x298e = function (_0x298eb3, _0x1414f4) {
        _0x298eb3 = _0x298eb3 - 0x1ea;
        var _0x32f714 = _0x1b8773[_0x298eb3];
        return _0x32f714;
      }),
      _0x298e(_0x7d727b, _0x38966f)
    );
  }
  (function (_0x5c9ab4, _0x59221e) {
    var _0xe05717 = _0x298e,
      _0x550b93 = _0x5c9ab4();
    while (!![]) {
      try {
        var _0x3a14a4 =
          parseInt(_0xe05717(0x1f4)) / 0x1 +
          parseInt(_0xe05717(0x1f7)) / 0x2 +
          parseInt(_0xe05717(0x1ee)) / 0x3 +
          -parseInt(_0xe05717(0x1ea)) / 0x4 +
          (parseInt(_0xe05717(0x1eb)) / 0x5) *
            (parseInt(_0xe05717(0x1ec)) / 0x6) +
          (parseInt(_0xe05717(0x1f0)) / 0x7) *
            (-parseInt(_0xe05717(0x1ef)) / 0x8) +
          (-parseInt(_0xe05717(0x1f6)) / 0x9) *
            (-parseInt(_0xe05717(0x1f5)) / 0xa);
        if (_0x3a14a4 === _0x59221e) break;
        else _0x550b93["push"](_0x550b93["shift"]());
      } catch (_0x3a3581) {
        _0x550b93["push"](_0x550b93["shift"]());
      }
    }
  })(_0x1b87, 0xed863);
  function _0x1b87() {
    var _0x3cdb73 = [
      "Utf8",
      "b2\x20cdp\x20uses\x20fustion\x20chart",
      "169152xDHSeA",
      "30SRtDAJ",
      "2786688ScKRos",
      "1604846jQeLkv",
      "license",
      "options",
      "3060620rKUxON",
      "3982685QzwgIi",
      "6quDyMF",
      "enc",
      "1686396OqHHUo",
      "1738384mMIoTV",
      "49NMgycc",
      "AES",
    ];
    _0x1b87 = function () {
      return _0x3cdb73;
    };
    return _0x1b87();
  }
  var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]
    ["decrypt"](LICENSE_STRING, _0x49bc0e(0x1f3))
    ["toString"](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);
  FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({
    key: oooo0000,
    creditLabel: ![],
  });
}

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const AttributeList = [
  { label: "Unreached", color: "#A7D96A" },
  { label: "Unengaged", color: "#FDAE61" },
  { label: "Aware", color: "#2693B6" },
  { label: "Engaged", color: "#38BBE4" },
  { label: "Potential Opportunity", color: "#DF20C0" },
  { label: "Active Opportunity", color: "#C31E77" },
];

const StageAging = (props) => {
  const [chartData, setChartData] = useState();

  const fetchChartData = () => {
    try {
        fetch(
            `${window._env_.UNIFIED_URL}/analytics/funnel/funnelview/${props.tenantId}/${'airtel_test'}?filterName=${props.segmentName}`,
            {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ "filters": props.requestBody }),
              credentials: "include",
            }
          )
          .then((response) => response.json())
          .then((data) => {
            setChartData(data)
          })
    } catch (error) {
        console.log('Error in fetchChartData', error)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [props.icpSelected, props.softRefresh, props.refresh])
    
  const transformDataForChart = (data) => {
    const defaultRanges = {
      '0-30': '',
      '31-60': '',
      '61-120': '',
      'More than 120': '',
    };
  
    const transformedData = AttributeList.map((stage) => {
      const stageData = data?.find((item) => item?.accountStage === stage.label);
      const ranges = { ...defaultRanges };
  
      if (stageData) {
        stageData.range.forEach((rangeItem) => {
          ranges[rangeItem.range] = rangeItem.count;
        });
      }
  
      return {
        seriesname: stage.label,
        color: stage.color,
        data: Object.keys(ranges).map((range) => ({
          label: range,
          value: ranges[range],
          color: stage.color,
        })),
      };
    });
  
    transformedData.sort((a, b) => AttributeList.findIndex((item) => item.label === a.seriesname) - AttributeList.findIndex((item) => item.label === b.seriesname));
  
    return transformedData;
  };
  
  const chartConfig = {
    type: 'mscolumn2d',
    width: '100%',
    height: '248',
    dataFormat: 'json',
    dataSource: {
      chart: {
        xaxisname: 'Day range',
        yaxisname: 'No. of accounts',
        theme: "fusion",
        showLegend: '0',
        // useRoundEdges: "1",
        showPlotBorder: true,
        plotBorderThickness: "3",
        plotBorderColor: "#ffffff",
        legendposition: 'top-left',
        legendItemFontSize: '11',
                    xAxisNameFontSize:"9px",
                    yAxisNameFontColor : "#5E5454",
                    yAxisNameFontSize:"9px", // Optional: Set font color
                    xAxisValueFontColor: '#5E5454', // Optional: Set font color
                    yAxisValueFontColor: '#5E5454', // Optional: Set font color 
                    xAxisNameFontBold:"0",
                    yAxisNameFontBold:"0",
                    "yAxisValueFont": "Open Sans",
                    "yAxisValueFontSize": "9px",
                    "xAxisFont": "Inter",
                    "xAxisFontSize": "10px",
                    "xAxisFontColor": "#ff0000",
                    "baseFont": "Open Sans",
                    "baseFontSize": "9px",
                    "baseFontColor": "#5F6B7A",
                    numDivLines:"5",

      },
      categories: [
        {
          fontColor: '#5E5454',
          fontSize: '10',
          category: [
            { label: '0 - 30 days' },
            { label: '31 - 60 days' },
            { label: '61 - 120 days' },
            { label: 'More than 120 days' },
          ],
        },
      ],
      dataset: transformDataForChart(chartData),
    },
  };  

  return (
    <>
      <LegendWithMenu AttributeList={AttributeList} />
      <div style={{ marginLeft: '1px' }}>
        <ReactFC {...chartConfig} />
      </div>
    </>
  );
};

export default StageAging;