import React, { useState, useEffect } from "react";
import "./Header.css";
import Typography from '@material-ui/core/Typography';
import { FormControl } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useAuthState } from '@bamboobox/b2logincheck';
import { updateSelectedSegments, getSelectedSegments, Segments } from "../apis/api.js"
import SegmentSelect from "./SegmentSelect";

const Header = (props) => {
    const [checked, setChecked] = React.useState(false);
    let { id } = useParams();
    const [body, setBody] = useState(id ? JSON.parse(decodeURIComponent(id)) : []);
    const [accountCount,SetAccountCount] = useState(null);
    const [totalAccount,setTotalAccount]= useState();

    const userDetails = useAuthState();
    const permissions = userDetails?.permissions;
    const resourceNames=['dashboard_insights'];
    const tenantId = userDetails.user.tenantId;
    const userId = userDetails.user.userUuid;    

    let location = useLocation();

    function useQuery() {
        return new URLSearchParams(location.search);
    }

    let query = useQuery();
    let params_url = query.get("icp") || "";
    const [icp, setIcp] = useState(params_url);
    
    useEffect(() => {
        if(props.accSegment && props.icpSelected && body){
            setTotalAccount('')
            accountFilterCount()
        }  
    },[body, props.icpSelected, props.accSegment])
    
    useEffect(() => { 
        totalCount();
    }, [])

    // const getFromSessionStorage = (key) => {    
    //     return JSON.parse(sessionStorage.getItem(`${tenantId}+${userId}+${key}`));
    // }
    
    // const setToSessionStorage = (key, value) => {
    //     sessionStorage.setItem(`${tenantId}+${userId}+${key}`, JSON.stringify(value));
    // }

    useEffect( () => {

        console.log("going in getSelectedSegmentsAPICall")
        if(props.icpSelected?.trim()){
            async function getSelectedSegmentsAPICall() {
                var segmentFromDb = await getSelectedSegments(tenantId, userId, props.icpSelected, "account");
                let listOfSegments = props.loglist.length <= 1 ? await Segments(tenantId, userId): props.loglist
                if(listOfSegments){
                    props.setAccSegment(listOfSegments?.filter(data => data.listTitle === segmentFromDb)[0]?.listTitle || "All Accounts")
                }
                else{
                    props.setAccSegment("All Accounts");
                }
              } 
            getSelectedSegmentsAPICall();
        }
        
    }, [props.icpSelected])

    const totalCount=()=>{
        console.log("inside")
        const requestOptions = {
          credentials: 'include',
          method: 'GET',
      };
        fetch(`${window._env_.UNIFIED_URL}/account/hub/${tenantId}/role-based/count?icp=${props.icpSelected}`,requestOptions)
        .then((result) => result.json())
        .then((data)=> SetAccountCount(data.totalCount))
        .catch(SetAccountCount(null))
      console.log("totalcount",accountCount)
    
    }

    const accountFilterCount = ()=>{
         fetch(
            `${window._env_.UNIFIED_URL}/analytics/totalAccounts/${tenantId}?attr1=industry&icp=${props.icpSelected}&filterName=${props.accSegment}`,
            {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({"filters": body}),
              credentials: "include",
            }
          )
            .then((response) => response.json())
            .then((data) => {
                setTotalAccount(data.totalCount)
                console.log("totalcount",data.totalCount, totalCount);
            })
    }

    function checkPermision(tenantId,permissions,resourceNames){
        for(var objIdx=0;objIdx<permissions.length;objIdx++){
        if(tenantId && permissions[objIdx]?.operations?.length>0 && resourceNames.includes(permissions[objIdx]?.resourceName))
           return true
         }
       return false
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const insightsTabFinder = () => {
        if(checkPermision(tenantId,permissions,resourceNames)){
            if(props.tabVal !== 0){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    }

    const handleSegmentChange = async (event, data) => {
        console.log("icp and chosen segment", props.icpSelected, event.target.value)
        const selectedSegment = event.target.value
        props.setAccSegment(selectedSegment);
        if(props?.icpSelected){
            await updateSelectedSegments(tenantId, userId, props.icpSelected, "account", selectedSegment);
        }
    }

    const accountCountHeader = () => {
        const textStyle = {
          color: '#6C6E6B',
          fontWeight: 500,
          fontSize: '12px',
        };
      
        if (props.accSegment === 'All Accounts' || (props.accSegment !== 'All Accounts' && totalAccount)) {
          if ((props.accSegment === 'All Accounts' && body.length === 0) || totalAccount === undefined || totalAccount === null) {
            return (
              <Typography variant="ag" component="ag" style={textStyle}>
                {accountCount ? `(${accountCount} Accounts)` : '-- Accounts'} &nbsp;
              </Typography>
            );
          } else {
            return (
              <Typography variant="ag" component="ag" style={textStyle}>
                ({totalAccount} of {accountCount} Accounts) &nbsp;
              </Typography>
            );
          }
        } else {
          return (
            <Typography variant="ag" component="ag" style={textStyle}>
              {'-- Accounts'} &nbsp;
            </Typography>
          );
        }
    };
      
      return (
        <>
           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '32px', width: '100%', marginTop: '29px' }}>
                <div style={{ height: '29px', display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{color: '#3D3D3B', fontWeight: 600, fontSize: '24px'}}>Dashboard Insights</div>
                    <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'end', marginBottom: '4px' }}>{accountCountHeader()}</div>
                </div>
                <div style={{ minWidth: '120px' }}>
                    <FormControl size="small" style={{ cursor: 'pointer' }}>
                        <SegmentSelect id={id} onChange={handleSegmentChange} loglist={props.loglist} value={props.accSegment} dataType="account" />
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default Header
