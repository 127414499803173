import "./fallbackcomponents.css";
import CircularProgress from "@mui/material/CircularProgress";
import { NoData } from "../svg";

export const Loader = () => {
  return (
    <div className="circular-progress-div">
      <CircularProgress style={{ color: "#016B40" }} />
    </div>
  );
};

export const MainPageLoader = () => {
  return (
    <div className="circular-progress-main-page-div">
      <CircularProgress style={{ color: "#016B40" }} />
    </div>
  );
};

export const SmallLoader = () => {
  return (
    <div className="circular-progress-div-small">
      <CircularProgress
        style={{
          color: "#016B40",
          width: "30px",
          height: "30px",
          opacity: "100%",
        }}
      />
    </div>
  );
};

export const NoResultFound = () => {
  return (
    <div className="no-data-svg-div">
      <NoData />
      <span className="no-data-text">No data found</span>
    </div>
  );
};

export const MainPageNoResultFound = () => {
  return (
    <div className="no-data-main-page-svg-div">
      <NoData />
      <span className="no-data-text">No data found</span>
    </div>
  );
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
  };
};