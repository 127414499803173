import { Typography, TextField } from "@mui/material";
import { useState } from "react";

export const Description = (props) => {
  const { description, setDescription } = props;
  const [lengthError, setLengthError] = useState(false);

  return (
    <>
      <Typography>Description</Typography>
      <TextField
        label=""
        className="description-textfield"
        value={description}
        onChange={(event) => {
          const value = event.target.value;
          setDescription(value);
          if (value.length > 256) {
            setLengthError(true);
          } else {
            setLengthError(false);
          }
        }}
        variant="outlined"
        error={lengthError}
        helperText={lengthError && "Description length exceeded 256 characters:"}
        placeholder="Add description to your insight"
        autoComplete="off"
      />
    </>
  );
};
