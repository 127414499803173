export const pastChartData = [
  {
    label: "Unreached",
    color: "#D9D7E3" ,
  },
  {
    label: "Unengaged",
    color: "#CECCDB",
  },
  {
    label: "Aware",
    color: "#BEBBCF ",
  },
  {
    label: "Engaged",
    color: "#B3B0C7",
  },
  {
    label: "Potential Opportunity",
    color: "#A39FBB",
  },
  {
    label: "Active Opportunity",
     color: "#938EAF",
  }  
]


export const chartData = [
    {
      label: "Unreached",
      color: "#016B40,#CF99F4",
      link: "newchart-json-Unreached",
      description : "These accounts have not been engaged with for a defined time period",
    },
    {
      label: "Unengaged",
      color: "#1ABF6E,#AFA3ED",
      link: "newchart-json-Unengaged",
      description : "These accounts have not shown enough engagement and need nurturing",
    },
    {
      label: "Aware",
      color: "#92CC38,#8CAEE6",
      link: "newchart-json-Aware",
      description : "These accounts have just entered the buying cycle and need further nurturing",
    },
    {
      label: "Engaged",
      color: "#FDC731,#6AB9DE",
      link: "newchart-json-Engaged",
      description : "These accounts are warm, further nurturing would qualify them to MQAs",
    },
    {
      label: "Potential Opportunity",
      color: "#FC693E,#48C4D6",
      link: "newchart-json-Potential Opportunity",
      description : "These accounts are MQAs and can be passed on to Inside Sales",
    },
    {
      label: "Active Opportunity",
       color: "#B0344C,#38C9D3",
      link: "newchart-json-Active Opportunity",
      description : "These accounts have open opportunities which might be at any of the Sales Stages",
    }
  ];

  export const AttributeList = [
    "All",
    "Unreached",
    "Unengaged",
    "Aware",
    "Engaged",
    "Potential Opportunity",
    "Active Opportunity"
  ];
  
  export const AttributeLists = [
    "Unreached",
    "Unengaged",
    "Aware",
    "Engaged",
    "Potential Opportunity",
    "Active Opportunity"
  ]
  