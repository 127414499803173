import React from "react";
import { MenuItem, Select, makeStyles } from "@material-ui/core/";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiSelect-select:focus": {
      background: 'transparent',
    },
    "& .MuiSvgIcon-root": {
      marginTop: "7px",
      marginRight: "5px",
      minWidth: "auto",
      minHeight: "auto",
      height: "12px",
      width: "12px"
    },
  },
}))

const SegmentSelect = (props) => {
  const classes = useStyles();
  console.log("props in SegmentSelect", props);

  return (
    <Select
      id={props.id}
      variant="outlined"
      style={{
        borderRadius: "8px",
        height: "32px",
        minWidth: "112px",
        backgroundColor: "#FFFFFF",
      }}
      value={props.value}
      className={classes.select}
      IconComponent={KeyboardArrowDownRoundedIcon}
      onChange={props.onChange}
      MenuProps={{
        PaperProps: {
          style: {
            borderRadius: "6px",
            width: '135px',
            backgroundColor: '#FFFFFF',
          },
        },
        getContentAnchorEl: () => null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      inputProps={{
        style: {
          fontSize: "12px",
          color: "#3D3D3B",
          fontWeight: 500,
        },
      }}
      renderValue={(value) => (
        <label style={{ fontSize: "12px", color: "#3D3D3B", fontWeight: 500 }}>
          {" "}
          {value}{" "}
        </label>
      )}
    >
      {props?.loglist
        ?.reverse()
        .filter((data) => data.type === props.dataType)
        .map((data) => (
          <MenuItem key={data.listTitle} value={data.listTitle}>
            {data.listTitle}
          </MenuItem>
        ))}
    </Select>
  );
};

export default SegmentSelect;
