import "../specialCardParentComponent/specialCardComponent.css";
import { NoResultFound } from "../../assets/icons/FallBackComponents"; 

const FallBackChildComponent = (props) => {
  
  return (
    <div className="fallbackchild">
     <NoResultFound />
    </div>
  );
};
export default FallBackChildComponent;
