import "./userCardComponent.css";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import { DefaultCardHeaderIcon, ContactCardHeaderIcon } from "../../assets/svg";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import RefreshIcon from "@mui/icons-material/Refresh";
import { BambooBoxIcon, InformationIcon, RefreshIcon, MoreVertIcon } from "../../assets/svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import "../../assets/styles/toast.css";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ListIcon from '@mui/icons-material/List';
import CardFooterAction from "./CardFooterAction";
import SharePopUp from '../Settings/ShareUserList/index'
import Link from "@material-ui/core/Link";
import {GlobalWithInfo} from '../../assets/svg'
import Tooltip from '@mui/material/Tooltip';
import {formateDateNew} from '../../helpers/permissionForCards.helper'
import { newUp , newDown, getTitle } from "../../helpers/common.helper";
import { useLocation } from "react-router-dom";

const UserCardBasedComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [percentageChange, setPercentageChange] = useState(0);
  const location = useLocation();
  const controllerRef = useRef();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const icp = query.get("icp") || "";

  const handleShareClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const maxWidthApply = {
    InfluencedTrend: {maxWidth:true},
    funnelchart: {maxWidth:true},
    ConversionTrend: {maxWidth:true},
    OpportunitiesTrend: {maxWidth:true},
    VelocityTrend: {maxWidth:true},
    netNewAccount:{maxWidth:true },
  };

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: true,
  });

  console.log("SpecialCardBasedComponent", props);
  
  const formatDate = useMemo(() => {
    return (dateString) => {
      if (!dateString || dateString.length === 0) {
        return '';
      }

      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      
      const date = new Date(dateString);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
    
      if (date.toDateString() === today.toDateString()) {
        return 'Today';
      } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
      } else {
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
    };
  }, []);

  const get30DaysPercentageChange = ()=>{
    try {
      fetch(
          `${window._env_.Filter_CACHING_URL}/trend/getlastThirtyDaysData/${props.tenantId}/${props.cardDetails.cardUuid}?icp=${icp}`,
          {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        )
        .then((response) => response.json())
        .then((data) => {
          setPercentageChange(data?.data?.trendValue ||  0)
        })
  } catch (error) {
    setPercentageChange(0)
      console.log('Error in fetchChartData', error)
  }
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHide = async () => {
    const body = { hidden: true };
    const response = await axios.post(
      `${window._env_.CARD_SERVICE}/card/v2/hide/${props.tenantId}/${props.cardDetails.cardUuid}`,
      body,
      {
        withCredentials: true,
      }
    );
    console.log("hidden response 3->",response);
    if (response.data.success) {
      const id = props.cardDetails.cardUuid;
      props.setRemovedCard(id);
      toast.success(<Alert severity="success">{"Insight hidden from the dashboard view successfully"}</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: <CloseIcon style={{ width:"18px"}}/>,
        newestOnTop: true,
      });
    } else {
      toast.error(<Alert severity="error">{"Card failed"}</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSoftRefresh=()=>{
    props.setSoftRefresh(refresh=>!refresh)
  }

  const handleListView = ()=>{
    props.setShowTrend(false)
  }

  const getPercentageChangeVal=(count,trendPercentage)=>{

    if (count === trendPercentage)
    {
      return 0;
  }
    else if (trendPercentage === 0)
    {
      return count;
  }
    else
    {
      return Math.round(
        ((count - trendPercentage) / trendPercentage) * 100
      );

  }
}

  const handleTrendView = ()=>{
    props.setShowTrend(true)
  }

  const handleDelete = async () => {
    const response =  await axios.post(`${window._env_.CARD_SERVICE}/card/v2/deleteCardFromDB/${props.tenantId}?cardUuid=${props.cardDetails.cardUuid}&filterName=${props.cardDetails.filterDetails.filterName}`,
    {},
    { withCredentials: true}
    )
    if(response.data.success){
      const id = props.cardDetails.cardUuid;
      props.setRemovedCard(id);
      toast.success(<Alert severity="success">{'Insight deleted successfully'}</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
    }
    else{
      toast.error(<Alert severity="error">Oops there was problem deleting the card. Try again in Some Time</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
    }
  }

  /* count query */
  useEffect(() => {
    let columns = [];

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;
    let UNIFIED_URL = "";

    if (props.cardDetails.cardObject?.toLowerCase() === "contact")
      UNIFIED_URL = `${window._env_.UNIFIED_URL}/${props?.cardDetails?.cardObject?.toLowerCase()}/new-hub/${props.tenantId}/filter?page=1&limit=5&icp=${props.icpSelected}&filterName=${
        props.segmentName
      }&contSegId=${props?.cardDetails?.filterDetails?.filterId}&sortby=&sortType=&offset=0&dataQueryRequired=false&countQueryRequired=true&refresh=${props.refresh || props.softRefresh}&cards=true`;
    else {
      UNIFIED_URL = `${window._env_.UNIFIED_URL}/${props?.cardDetails?.cardObject?.toLowerCase()}/new-hub/${props.tenantId}/filter?page=1&limit=5&icp=${props.icpSelected}&filterName=${
        props.segmentName
      }&segId=${props?.cardDetails?.filterDetails?.filterId}&sortby=&sortType=&offset=0&dataQueryRequired=false&countQueryRequired=true&refresh=${props.refresh || props.softRefresh}&cards=true`;
    }

    fetch(`${UNIFIED_URL}`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: [...props.requestBody], columns: columns }),
      signal: controllerRef.current?.signal,
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        props.setCount(data?.filteredCount || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.icpSelected, props.requestBody,  props.segmentName, props.refresh, props.softRefresh]);
  useEffect(()=>{
    get30DaysPercentageChange()
  },[])

  return (
    <div className={maxWidthApply[props.cardDetails.uxComponent]?.maxWidth ? "containerWidth" : "container"}>
      <div className="description">
        <div className="headerLeft">
          {props?.cardDetails.cardObject?.toLowerCase() === "account" ? <DefaultCardHeaderIcon /> : <ContactCardHeaderIcon />}
          <Link
            style={{ color: "#3D3D3B", textDecoration: "none" }}
            rel="noreferrer"
            href={
              props?.cardDetails.cardObject?.toLowerCase() === "account"
                ? `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(JSON.stringify([]))}/analytics_dashboard/${encodeURIComponent(JSON.stringify(props.requestBody))}?icp=${
                    props.icpSelected
                  }&segmentName=${props.segmentName}&cardUuid=${props.cardDetails.cardUuid}&newDashboard=true&tabVal=${props.tabVal}&category=${props.category}`
                : `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(JSON.stringify([]))}/analytics_dashboard/${encodeURIComponent(JSON.stringify(props.requestBody))}/?icp=${
                    props.icpSelected || ""
                  }&segmentName=${props.segmentName}&contCardUuid=${props.cardDetails.cardUuid}&newDashboard=true&tabVal=${props.tabVal}&attribute=&tab=contact&category=${props.category}`
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="headertextdec" style={{ marginRight: "8px", fontSize: "14px", fontWeight: 600, color: "#3D3D3B" }}>
                {getTitle(props)}
                {/* {props.cardDetails.count = props?.count || 0} */}
                <Tooltip title={props?.cardDetails.description || "Description not present"} arrow>
                  <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <InformationIcon />
                  </div>
                </Tooltip>
              </span>
            </div>
            {/* {<div style={{ display:'flex', alignItems:"baseline", gap:'5px' }}>
        <p>
        <div style={{color:'#9C9C9C',
          display:'flex',
          alignItems: "baseline",
      }}>
        <div style={{marginRight:'5px'}}>
        {getPercentageChangeVal(props?.count,percentageChange)<0? newDown(): newUp()}
        </div>
        <div
        style={{
          fontFamily: "Inter",
          fontSize: "10px",
          fontWeight: "700",
          lineHeight: "14.52px",
          textAlign: "left"

        }}
        
        >{Math.abs(getPercentageChangeVal(props?.count,percentageChange))}%</div>
      </div></p><p 
      style={{
      fontFamily: "Inter",
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "13.31px",
      color: "#9C9C9C"
}}
      >vs 30Days ago ({percentageChange})</p> </div>} */}
          </Link>
        </div>

        <div className="headerRight">
          {props.showTrend === true && (
            <Tooltip className="global-with-info" title="This is a global insight. Segment selection is not applicable for this view" arrow>
              <div>
                <GlobalWithInfo />
              </div>
            </Tooltip>
          )}

          <div>
            <div className="refresh-more-div">
              <div className="icon-container" onClick={handleSoftRefresh}>
                <Tooltip title="Refresh" arrow>
                  <div style={{ display: "flex" }}>
                    <RefreshIcon className="refresh-icon" />
                  </div>
                </Tooltip>
              </div>
              <div className="icon-container" onClick={handleOpenMenu}>
                <Tooltip title="More" arrow>
                  <div style={{ display: "flex" }}>
                    <MoreVertIcon className="more-icon" />
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="groupButton">
              <ListIcon onClick={handleListView} style={props.showTrend ? { borderRight: "1px solid #DFE2DE" } : { borderRight: "1px solid #DFE2DE", backgroundColor: "#F1F1F1" }} />
              <TrendingUpIcon style={props.showTrend ? { backgroundColor: "#F1F1F1" } : { borderRadius: "0px 8px 8px 0px" }} onClick={handleTrendView} />
            </div>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              className: "custom-dropdown",
            }}
          >
            <MenuItem style={{ width: "135px", fontSize: "14px", marginLeft: "4px", marginRight: "4px", borderRadius: "4px", marginTop: "-4px", paddingBottom: "8px" }} onClick={handleHide}>
              Hide
            </MenuItem>
            <MenuItem
              style={{ width: "135px", fontSize: "14px", marginLeft: "4px", marginRight: "4px", borderRadius: "4px", paddingTop: "8px", paddingBottom: "8px" }}
              onClick={() => {
                window.location.href = `${window._env_.NEW_DASHBOARD_VIEW}/#/insightForm?icp=${props.icpSelected}&cardUuid=${props.cardDetails?.cardUuid}&category=${props.cardDetails?.category}&copy=false`;
              }}
              disabled={props?.cardDetails?.createdBy === "me" ? false : true}
            >
              Edit
            </MenuItem>
            <MenuItem style={{ width: "135px", fontSize: "14px", marginLeft: "4px", marginRight: "4px", borderRadius: "4px", paddingTop: "8px", paddingBottom: "8px" }} onClick={handleDelete}>
              Delete
            </MenuItem>
            <MenuItem
              style={{ width: "135px", fontSize: "14px", marginLeft: "4px", marginRight: "4px", borderRadius: "4px", paddingTop: "8px", paddingBottom: "8px" }}
              onClick={() => {
                window.location.href = `${window._env_.NEW_DASHBOARD_VIEW}/#/insightForm?icp=${props.icpSelected}&cardUuid=${props.cardDetails?.cardUuid}&category=${props.cardDetails?.category}&copy=true`;
              }}
            >
              Copy
            </MenuItem>
            <MenuItem style={{ width: "135px", fontSize: "14px", marginLeft: "4px", marginRight: "4px", borderRadius: "4px", paddingTop: "8px", paddingBottom: "4px" }} onClick={handleShareClick}>
              Share
            </MenuItem>
          </Menu>
          {isPopupOpen && <SharePopUp open={isPopupOpen} tenantId={props.tenantId} data={[props.cardDetails]} onClose={handleClosePopup} />}
        </div>
      </div>
      <div className="card-body">{props.children}</div>
      <div className="footer">
        <div className="bambooboxIcon">
          <Tooltip
            title={
              <React.Fragment>
                <div style={{ lineHeight: "2" }}>
                  {`Created by: ${props?.cardDetails?.createdBy}`} <br />
                  {`Created at: ${formateDateNew(props?.cardDetails?.createdAt)}`}
                </div>
              </React.Fragment>
            }
            arrow
          >
            <div>
              <BambooBoxIcon />
            </div>
          </Tooltip>
          <div className="datetext">Last Updated {formateDateNew(Date.now())}</div>
        </div>
        <div className="footer-action">
          <CardFooterAction
            offset={props.offset}
            cardDetails={props.cardDetails}
            cardId={props.cardId}
            count={props.count}
            tenantId={props.tenantId}
            icp={icp || props.icpSelected}
            tenantConfigMapper={props.tenantConfigMapper}
          />
        </div>
      </div>
    </div>
  );
};

export default UserCardBasedComponent;