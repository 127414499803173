import IntentChart from "./Type/Pie";
import SpecialCardBasedComponent from "../../specialCardParentComponent/SpecialCardBaseComponent";
import { useState, memo } from "react";
import FallBackBaseComponent from "../../fallBack/FallBackBase";
import FallBackChildComponent from "../../fallBack/FallBackChild";
import HeatMapChart from "./Type/HeatMap";
import InfluencedTrend from "../../childCardComponents/TrendChart/InfluencedTrend";
import FunnelChart from "../../childCardComponents/Funnel/FunnelChart";
import OpportunitiesTrend from "../../childCardComponents/TrendChart/OpportunitiesTrend";
import Engagement_Segment from "./Type/Engagement_Segment";
import Intent_Segment from "./Type/Intent_Segment";
import Eng_vs_Intent_Segment from "./Type/Eng_vs_Intent_Segment";
import StageAging from "../../childCardComponents/StageAging/StageAging";
import { engagementSegmentBody, intentSegmentBody } from "./Type/Constant";
import UserCardBasedComponent from "../../userCardParentComponent/UserCardBaseComponent";
import NetNewAccount from "../../childCardComponents/NetNewAccount/NetNewAccount";
import Tabular from "./Type/Tablular";
import StackChart from '../../childCardComponents/StackChart/StackChart';
import TrendView from "../../childCardComponents/TrendChart/CardTrend";
import BarChart from "../../childCardComponents/BarChart/BarChart";
import { useAuthState } from "@bamboobox/b2logincheck";
import {checkPermissionsForCards} from '../../../helpers/permissionForCards.helper'
const CardType = {
  special: SpecialCardBasedComponent,
  user:UserCardBasedComponent
};

const uxComponent = {
  piechart: IntentChart,
  heatmapchart: HeatMapChart,
  InfluencedTrend: InfluencedTrend,
  funnelchart: FunnelChart,
  OpportunitiesTrend: OpportunitiesTrend,
  Intent_KeySegment: Intent_Segment,
  Engagement_KeySegment: Engagement_Segment,
  Engagement_vs_Intent_KeySegment: Eng_vs_Intent_Segment,
  netNewAccount: NetNewAccount,
  StageAging: StageAging,
  tabular:Tabular,
  stackedchart:StackChart,
  barchart:BarChart
};

const specialFilter = {
  Engagement_KeySegment: engagementSegmentBody,
  Intent_KeySegment: intentSegmentBody,
  Engagement_vs_Intent_KeySegment: [
    ...engagementSegmentBody,
    ...intentSegmentBody,
  ],
  netNewAccount:[{}]
};

const Card = memo(function Card(props) {
  const [openNewAccountDialog, setOpenNewAccountDialog] = useState(false);
  const userDetails = useAuthState();
  const [softRefresh,setSoftRefresh] = useState(false);
  const [removedCard,setRemovedCard] = useState(null)
  const [showTrend,setShowTrend] = useState(true)
  const ParentComponent = CardType[props.cardDetails.cardType] || FallBackBaseComponent
  const [count,setCount] = useState("--");


  // Determine which child component to render based on uxType prop
  const ChildComponent =
  showTrend && props.cardDetails.uxComponent === "tabular" ? TrendView: uxComponent[props.cardDetails.uxComponent] || FallBackChildComponent;

  console.log("Hi this is use user data and permission", userDetails);
  return (
    <>
    {((!props?.cardDetails?.check_resource) || ((props?.cardDetails?.check_resource===true || props?.cardDetails?.check_resource==="true") && checkPermissionsForCards(userDetails?.permissions || [],props?.cardDetails?.resource_names || [] )))?<>
      {removedCard !== props.cardDetails.cardUuid && (
          <ParentComponent
            cardDetails={props.cardDetails}
            setRemovedCard={setRemovedCard}
            tenantId={props.tenantId}
            icpSelected={props.icpSelected}
            segmentName={props.segmentName}
            requestBody={[
              ...props.requestBody
            ]}
            specialFilter={[...specialFilter[props?.cardDetails?.uxComponent] || []]}
            viewAll={specialFilter[props?.cardDetails?.uxComponent]?.length ? true : false}
            tabVal={props.tabVal}
            softRefresh = {softRefresh}
            refresh = {props.refresh}
            setSoftRefresh={setSoftRefresh}
            setOpenNewAccountDialog={setOpenNewAccountDialog}
            setShowTrend = {setShowTrend}
            showTrend={showTrend}
            count={count}
            setCount={setCount}
            category={props.category}
    
          >
            <ChildComponent
              cardDetails={props.cardDetails}
              segmentName={props.segmentName}
              tenantId={props.tenantId}
              requestBody={[
                ...props.requestBody,
              ]}
              specialFilter={[...specialFilter[props?.cardDetails?.uxComponent] || []]}
              icpSelected={props.icpSelected}
              reqBody={props.requestBody}
              softRefresh = {softRefresh}
              openNewAccountDialog={openNewAccountDialog}
              setOpenNewAccountDialog={setOpenNewAccountDialog}
              setCount = {setCount}
              count={count}
              tabVal={props.tabVal}
              refresh = {props.refresh}
              category={props.category}
            />
          </ParentComponent>
      )}
    </>  
 :<></> }
  </>
  );
})

export default Card;
