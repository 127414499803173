import React, { useState, useEffect } from 'react'
// import { DataGrid, useGridParamsApi } from '@material-ui/data-grid';
import { DataGrid} from '@mui/x-data-grid';
import { Button } from "@material-ui/core";
import moment from "moment"
import "./table.css"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useAuthState } from "@bamboobox/b2logincheck";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
// import "./toast.css"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { json2csv } from 'json-2-csv';
import { filter, isFunction } from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;


const NewAccountManager = (props) => {
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: true,
  });


  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [action, setAction] = useState("")
  const [listOfRowIds, setListOfRowIds] = useState([])
  const [intentData ,setIntentData]  = useState([]);


  const getTopicList = (topicsData) => {
    let topicArr = []
    if (topicsData) {
      let arrOfTopic = topicsData.split('""')
      for (let topicStr of arrOfTopic) {
        let topic = topicStr.split('\\"')[1]
        if (topic) {
          topic = topic.substring(0, topic.length - 1)
          topicArr.push(topic)
        }
      }
    }
    return topicArr.join()
  }

  const getDateFormat = (date) => {
    let correctDate = new Date(date).toISOString().slice(0, 10);
    return correctDate
  }

  let rowDataArr = []


  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true
    },
    {
      field: 'company_name',
      headerName: 'Company',
      width: 200,
    },
    {
      field: 'source',
      headerName: 'Sources',
      width: 200,
      renderCell: (params) => {
        // console.log("params page visits", params.row)
        let parsedSource = params?.row?.source?.replace(/[\[\]']+/g, '');
        return (
            <Tooltip title={parsedSource} arrow>
                <span className = "table-cell-trucate">{parsedSource}</span>
            </Tooltip>
        )
      }
    },
    {
      field: 'page_visits',
      headerName: 'Visited Webpages',
      width: 340,
      renderCell: (params) => {
        console.log("params page visits", params.row.page_visits)
        let valueForPageVisit = '';
        let count = 0;
        
        if(params.row?.page_visits){
          for(let ele of params.row?.page_visits){
            console.log("ele from page visits", ele.page_path, valueForPageVisit)
            if(count === 0){
              valueForPageVisit = valueForPageVisit.concat(`${ele.hostname}${ele.page_path}`)
              count = 1
            }
            else{
             valueForPageVisit = valueForPageVisit.concat(`, ${ele.hostname}${ele.page_path}`)
            }
          
        }
        }

        // console.log("valueForPageVisit", valueForPageVisit);

        return (
            <Tooltip title={valueForPageVisit} arrow>
                <span className="table-cell-trucate" key={valueForPageVisit}>{valueForPageVisit}</span>
            </Tooltip>
        )
      }
    },
    {
      field: 'company_website',
      headerName: 'Account Domain',
      width: 140,
    },
    {
      field: 'company_size_range',
      headerName: 'Company Size',
      width: 200,
      
    },
    {
      field: 'surging_country',
      headerName: 'Country',
      width: 200,
    },
    {
      field: 'company_industry',
      headerName: 'Company Industry',
      width: 200,
    },
    {
      field: 'topics_data',
      headerName: 'Topics',
      type: 'string',
      width: 340,
      renderCell: (params) => {
        //console.log("params", params.row)
        return (
          <Tooltip title={params.row.topics_data} arrow>
            <span className="table-cell-trucate">{params.row.topics_data}</span>
          </Tooltip>
        )
      }
    },

    // {
    //   field: 'b2_intent_level',
    //   headerName: 'Intent Level',
    //   type: 'string',
    //   width: 200
    // },
    {
      field: 'intent_creation_date',
      headerName: 'Last Intent date',
      width: 200,
    },

    
  ];

  const setTableData = () => {
    fetch(
      `${window._env_.UNIFIED_URL}/analytics/intent/get-new-Account/BOMBORA/${tenantId}/${props.icpSelected}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then(response => response.json())
      .then(res => {
        if (res?.msg === "EMPTY") {
          toast.warn(<Alert severity="warn">No pending requests available</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        } else {
          let counter=0;
          res?.data?.forEach(obj => {
            counter++;
            let rowDataObject = {
              id: "",
              company_name: "",
              company_website: "",
              b2_intent_level: "",
              intent_creation_date: "",
              company_industry:"",
              company_size_range:"",
              surging_country:"",
              page_visits: "",
              source: ""
            }
            rowDataObject.id = counter;
            rowDataObject.company_name = obj.company_name
            rowDataObject.company_website = obj.company_website
            rowDataObject.b2_intent_level = obj.b2_intent_level
            rowDataObject.company_industry = obj.company_industry
            rowDataObject.surging_country = obj.surging_country
            rowDataObject.company_size_range = obj.company_size_range
            rowDataObject.topics_data = getTopicList(obj.topics_data_aggr)
            rowDataObject.intent_creation_date = moment(obj.last_intent_date).format('DD-MM-YYYY')
            rowDataObject.page_visits = obj.page_visits
            rowDataObject.source = obj.source
            rowDataArr.push(rowDataObject)
          })
          setIntentData(rowDataArr)

          setLoading(false)

          console.log(`%c${JSON.stringify(rowDataArr)}`, 'background:black;color:white')
        }
      }).catch(error => {
        toast.error(<Alert severity="error">Error Occuredd while retreiving data</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      })

  }

  const handleClose = () => {
    props.setOpenNewAccountDialog(false)
  }

  const handleDownload = async() => {
    console.log("listOfRowIds", listOfRowIds);
    let setOfListId = new Set(listOfRowIds)
    let filteredRows = intentData.filter(obj => setOfListId.has(obj?.id));

    console.log("setOfListId", setOfListId);
    console.log("filteredRows", filteredRows);

    let fileNameDownload= "intentNewAccount.csv";
    let csvConverted = '';

    filteredRows = filteredRows.filter(row => {
      delete row['b2_intent_level'];
      return row;
    });

    if(filteredRows.length > 0) {
      csvConverted = await json2csv(filteredRows, (err, data) => {
        if(err) {
          console.error('Error occurred in jsonToCSV', err)
        }
        return data;
      }, [])
    }
    console.log("csvConverted ===>", csvConverted)
      if (csvConverted?.length) {
        console.log("csvConverted length ===>", csvConverted?.length);
        const url = window.URL.createObjectURL(new Blob([csvConverted]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileNameDownload);
        document.body.appendChild(link);
        // window.location.assign(link);
        link.click();
          toast.success(<Alert severity="success">{listOfRowIds?.length} Account(s) downloaded successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
      } else {
        toast.error(<Alert severity="error">Some of your requests have not been processed</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      }
    


  }


  function convertUTCDateToLocalDate(date) {
    date.setHours(0)
    date.setMinutes(0)
    let offset = date.getTimezoneOffset()*60*1000
    let timeInEpoch = date.getTime()
    if((timeInEpoch - offset) > 0)
      date = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
    return date;
  }
  
  const approveRejectFunction = (action) => {
    let reqBodyArr = []

    if (action === "REJECT") {
      listOfRowIds.forEach((rowId) => {
        let actionRequestObj = {
          rowId: "",
          action: "REJECT",
          intent_creation_date: "",
          icp_name: props.icpSelected
        }
        let requiredRow = intentData.find(item => item.rowId = rowId)
        let intentDate = convertUTCDateToLocalDate(new Date(moment(requiredRow.intent_creation_date, "DD-MM-YYYY")))
        actionRequestObj.intent_creation_date = intentDate.toISOString()
        actionRequestObj.rowId = rowId;
        reqBodyArr.push(actionRequestObj)
      })

    } else {

      listOfRowIds.forEach((rowId) => {
        let actionRequestObj = {
          rowId: "",
          action: "APPROVE",
          icp_name: props.icpSelected,
          intent_creation_date: ""
        }
        let requiredRow = intentData.find(item => item.rowId = rowId)
        let intentDate = convertUTCDateToLocalDate(new Date(moment(requiredRow.intent_creation_date, "DD-MM-YYYY")))
        actionRequestObj.intent_creation_date = intentDate.toISOString()
        actionRequestObj.rowId = rowId;
        reqBodyArr.push(actionRequestObj)
      })

      console.log(`%c${JSON.stringify(reqBodyArr)}`, 'background:black;color:white')
    }

    fetch(
      `${window._env_.B2_CDPAPI}/intent/add/account/approve-account-new/BOMBORA/${tenantId}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBodyArr),
        credentials: "include",
      }
    ).then(response => response.json())
      .then(data => {
        if (data?.msg && (data?.msg || "").trim().toLowerCase() === "added to database" || "success") {
            toast.success(<Alert severity="success">{listOfRowIds?.length} Accounts downloaded successfully</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          // removeColumns(listOfRowIds, (data.errors || []), setRows, rows)
          setTableData()
        } else if (data?.msg && (data?.msg || "").trim().toLowerCase().length > 0) {
          toast.error(<Alert severity="error">Some of your requests have not been processed</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
          // removeColumns(listOfRowIds, (data.errors || []), setRows, rows)
          setTableData()
        } else {
            toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }

      }).catch(error => {
        toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });

      })

  }

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [allPageRowsSelected, setAllPageRowsSelected] = useState(false);
  console.log("selectionModel343",selectionModel,selectAll)
  console.log("selectionMode347listOfRowIds",listOfRowIds)

  // const [rowCountState, setRowCountState] = React.useState(
  //   100
  // );

  // React.useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     100
  //   );
  // }, [setRowCountState]);

 
  useEffect(() => {
    // setLoading(true)


    setTableData()

    // setLoading(false);
  }, [])


  const [openUnselectAll, setOpenUnselectAll] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleCloseUnselectAll = (e) => {
    console.log("from handleCloseUnselectAll 1", e.target.innerText)
    console.log("from handleCloseUnselectAll", e)
    if(e.target.innerText === "PROCEED"){
      setListOfRowIds([])
      setAllPageRowsSelected(false)
      setSelectAll(false)
    }
    else{
      let allrowIds=intentData.map(obj=>obj.id)
      setListOfRowIds(allrowIds);
    }
    setOpenUnselectAll(false);
  };

 function loadServerRows (page, rows) {


      console.log("intentData", intentData, page, pageSize);
        return (intentData.slice(page  * pageSize, (page + 1) * pageSize));
       // simulate network latency
  
  }

  // useEffect(() => {

  //     setLoading(true);
  //     const newRows = loadServerRows(page, rows);

  //     setRows(newRows);

  //     if(newRows.length){
  //       setLoading(false);
  //     }
  // }, [page, pageSize, intentData]);

  console.log("page from new account", page);
  console.log("pageSize from new account", pageSize);


  return (
    <MuiThemeProvider theme={UItheme}>
      {openUnselectAll&&<div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={openUnselectAll}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='deselectiondialog'
      >
        <DialogTitle id="alert-dialog-title" className='selectdialog'>
        All {intentData.length} rows will be deselected
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" className="selecteddialogtext">
              Currently we do not support individual deselection when all rows are selected. Clicking proceed will deselect everything
          </DialogContentText>
        </DialogContent>
        <DialogActions  className="selectallactionbutton">
          <Button variant="outlined" textTransform="none" className="selectallback" style={{ marginRight: "0px!important", textTransform: "uppercase", borderRadius: "32px", height:"32px",border: "1px solid #21DA8C", color: "#21DA8C", fontWeight: "600" }} onClick={handleCloseUnselectAll}>Proceed</Button>
          <Button variant="contained" style={{ textTransform: "uppercase", background: "#21DA8C", borderRadius: "32px", height:"32px", color: "white", fontWeight: "600", marginRight: "15px"}}  onClick={handleCloseUnselectAll} autoFocus>
          
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>}
      <div className="container-align intentGrid">
        <Paper style={{ borderRadius: '18px', margin: allPageRowsSelected ? "2.5rem 8px 0": "1.5rem 8px 0", paddingTop: "4px", paddingBottom:"8px", boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`, }}>
          <div id="cmapaign-table" style={{ height: 450, width: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '1.8rem', marginRight: '1.8rem' }}>
          {
                  (allPageRowsSelected)  && 

                  <div className="selectAllIntent" >

                    You have selected  { (selectAll) ? "all" :  listOfRowIds.length } rows.

                    <b className="bolderSelectAll">
                        {
                        !selectAll && <span onClick={(e)=> {
                          setSelectAll(true);
                          // setSelectionModel(intentData)
                          let allrowIds=intentData.map(obj=>obj.id)
                          setListOfRowIds(allrowIds);
            
                          }}>
                              Select All {intentData.length}</span>    
                        }
                        {
                            selectAll && <span onClick={(e)=> {
                              // setClearSelection(false)
                              setSelectAll(false)
                              setAllPageRowsSelected(false);
                              setListOfRowIds([])
              
                          }}>
                              Clear Selection</span>
                        }
                        </b>
                  </div>
  }
              <DataGrid
                rows={intentData}
                columns={columns}
                allowResizing={true}
                checkboxSelection
                disableSelectionOnClick
                rowsPerPageOptions={[10, 50, 75, 100]}
                rowHeight={36}
                headerHeight={36}
                // rowCount={intentData.length}
                // pagination= { paginationModel: { pageSize: 5 } },
                // // rowCount={rowCountState}
                pagination
                pageSizeOptions={[10, 50, 75, 100]}
                // page={page}
                pageSize={pageSize}
                // paginationMode="server"
                // onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                loading={loading}
                onSelectionModelChange={(newSelectionModel) => {
                  console.log(newSelectionModel);
                  // setSelectionModel(newSelectionModel);
                  if(newSelectionModel.length === rows.length){
                    setAllPageRowsSelected(true);
                  }

                  if(!newSelectionModel.length){
                    setAllPageRowsSelected(false)
                  }

                    setListOfRowIds(newSelectionModel);
                  
                  //comment out below and related openUseselectAll functionality for deselection of any page or any row to Work. 
                  if(selectAll){
                    setOpenUnselectAll(true);

                  }   

                
                }}
                selectionModel={listOfRowIds}
                disableColumnSelector={true}
                keepNonExistentRowsSelected
                
              />
            
          </div>
        </Paper>
        <div style={{ display: "flex", width: 'inherit', paddingTop: "24px", justifyContent: "flex-end", marginLeft: '2em', marginRight: '2em' }}>
        <Button variant="outlined" disabled={Array.isArray(listOfRowIds) && !listOfRowIds.length ? true : false} textTransform="none" onClick={() => handleDownload()} style={{ marginLeft: "26px", textTransform: "none", borderRadius: "32px", color: (Array.isArray(listOfRowIds) && !listOfRowIds.length ? "#e0e0e0" : "#21DA8C"), fontWeight: "700", height: "32px", border: "1px solid #21DA8C"  }} startIcon={<AddIcon />}>Download</Button>
          {/* <Button disabled={Array.isArray(listOfRowIds) && !listOfRowIds.length ? true : false} variant="contained" onClick={() => approveRejectFunction("APPROVE")}  style={{ marginLeft: "15px", textTransform: "none",  borderRadius: "32px", background: (Array.isArray(listOfRowIds) && !listOfRowIds.length ? "#e0e0e0" : "#21DA8C"), color: "white", fontWeight: "700", height: "32px" }} startIcon={<AddIcon />}>Add Accounts</Button> */}
          
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default NewAccountManager

