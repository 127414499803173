import '../specialCardParentComponent/specialCardComponent.css'
import React, { useState,useMemo } from "react";
import { DefaultCardHeaderIcon, InformationIcon, ContactCardHeaderIcon } from "../../assets/svg";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import RefreshIcon from "@mui/icons-material/Refresh";
import { BambooBoxIcon, RefreshIcon, MoreVertIcon } from "../../assets/svg";
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

const FallBackBaseComponent = (props) => {
  console.log("SpecialCardBasedComponent", props.cardDetails.cardObject);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHide = () => {
    // Perform action 2
    props.setRemovedCard(props.cardDetails.cardId)
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const formatDate = useMemo(() => {
    return (dateString) => {
      if (!dateString || dateString.length === 0) {
        return '';
      }

      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      
      const date = new Date(dateString);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
    
      if (date.toDateString() === today.toDateString()) {
        return 'Today';
      } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
      } else {
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
    };
  }, []);

  return (
    <div className="container">
      <div className="description">
        <div className="headerLeft">
          {props?.cardDetails.cardObject?.toLowerCase() === "account" ? <DefaultCardHeaderIcon /> : <ContactCardHeaderIcon />}
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="headertextdec" style={{ marginRight: "8px", fontSize: "14px", fontWeight: 600, color: "#3D3D3B" }}>
              {props.cardDetails.title || "Something Went Wrong"}
              <Tooltip title={props?.cardDetails.description || "Description not present"} arrow>
                <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                  <InformationIcon />
                </div>
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="headerRight">
          <div className="refresh-more-div">
            <div className="icon-container">
              <Tooltip title="Refresh" arrow>
                <div style={{ display: "flex" }}>
                  <RefreshIcon className="refresh-icon" />
                </div>
              </Tooltip>
            </div>
            <div className="icon-container" onClick={handleOpenMenu}>
              <Tooltip title="More" arrow>
                <div style={{ display: "flex" }}>
                  <MoreVertIcon className="more-icon" />
                </div>
              </Tooltip>
            </div>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              style={{ width: "135px", fontSize: "14px", marginLeft: "4px", marginRight: "4px", borderRadius: "4px", marginTop: "-4px", marginBottom: "-4px", paddingBottom: "8px" }}
              onClick={handleHide}
            >
              Hide
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="card-body">{props.children}</div>
      <div className="footer">
        <div className="bambooboxIcon">
          <Tooltip
            title={
              <React.Fragment>
                <div style={{ lineHeight: "2" }}>
                  {`Created by: ${props?.cardDetails?.createdBy}`} <br />
                  {`Created at: ${formatDate(props?.cardDetails?.createdAt)}`}
                </div>
              </React.Fragment>
            }
            arrow
          >
            <div>
              <BambooBoxIcon />
            </div>
          </Tooltip>
          <div className="datetext">Last Updated {formatDate(props.cardDetails?.updatedAt)}</div>
        </div>
      </div>
    </div>
  );
};
export default FallBackBaseComponent;
