import React from "react";
import { TextField, Checkbox } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

function BuyerGroup(props) {
  try {
    console.log("props inside ValueComponent", props);

    const {
      attributeData,
      currentAttribute,
      onAttributeChange,
      shouldShowErrors,
      relationArr,
      fetchAttributeValues,
      attributeValues,
      inputText,
      setInputText,
      allSelected,
      allPersonaSelected,
      isSubFilter,
    } = props;

    const filters = createFilterOptions();
    const handleInput = (value) => {
      try {
        console.log("vallue", value);
        if (value) setInputText(value?.target?.value || "");
        else setInputText("");
      } catch (error) {
        setInputText("");
      }
    };

    function onBuyerGroupOnNumberAttributeChange(val, ind) {
      let numValues = [...attributeData["values"]];
      if (attributeData["values"].length < 1) {
        numValues.push({});
      }
      if (ind === 0) {
        numValues[0].value[0] = val;
      } else {
        numValues[0].value[1] = val;
      }
      onAttributeChange(numValues, "values");
    }

    return (
      <>
        <div style={{}}>
          {/* ****************************************************for relation_Department************************ */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isSubFilter ? "180px 224px" : "180px 254px",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "180px",
              }}
            >
              <Autocomplete
                disableClearable
                disableCloseOnSelect
                color="primary"
                popupIcon={
                  <KeyboardArrowDownRoundedIcon
                    style={{ color: "rgba(3, 3, 3, 0.6)" }}
                  />
                }
                onChange={(event, newValue) => {
                  onAttributeChange({ department: newValue }, "values");
                }}
                limitTags={1}
                style={{ width: "100%" }}
                value={attributeData?.values?.[0]?.department || []}
                inputValue={inputText}
                onOpen={() => {
                  fetchAttributeValues();
                }}
                id="controllable-states-demo1"
                options={attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "department")
                  .map((obj) => obj.department)}
                filterOptions={(options, params) => {
                  const filtered = filters(options, params);
                  return ["Select All", ...filtered];
                }}
                renderOption={(option, { selected }) => {
                  const selectAllProps =
                    option === "Select All" // To control the state of 'Select All' checkbox
                      ? { checked: allSelected }
                      : {};
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        {...selectAllProps}
                      />
                      {option}
                    </React.Fragment>
                  );
                }}
                multiple
                renderInput={(params) => (
                  <TextField
                    error={shouldShowErrors("department")}
                    onInput={handleInput}
                    {...params}
                    variant="outlined"
                    style={{
                      fontSize: "14px",
                      fontStyle: "italic",
                      borderColor: "red",
                    }}
                    placeholder={
                      currentAttribute?.placeholderDepartment || "Department"
                    }
                  />
                )}
              />
            </div>
            {/* ****************************************************for persona_Level************************ */}

            <div style={{ display: "flex", width: "100%" }}>
              <Autocomplete
                disableClearable
                color="primary"
                disableCloseOnSelect
                popupIcon={
                  <KeyboardArrowDownRoundedIcon
                    style={{ color: "rgba(3, 3, 3, 0.6)" }}
                  />
                }
                onChange={(event, newValue) => {
                  onAttributeChange({ personalevel: newValue }, "values");
                }}
                limitTags={1}
                // className={classes.root}
                filterOptions={(options, params) => {
                  const filtered = filters(options, params);
                  return ["Select All", ...filtered];
                }}
                value={attributeData?.values[0]?.personalevel || []}
                inputValue={inputText}
                onOpen={() => {
                  fetchAttributeValues();
                }}
                id="controllable-states-demo2"
                options={attributeValues
                  .filter((obj) => Object.keys(obj)[0] === "personalevel")
                  .map((obj) => obj.personalevel)}
                style={{ width: "100%" }}
                renderOption={(option, { selected }) => {
                  const selectAllProps =
                    option === "Select All" // To control the state of 'Select All' checkbox
                      ? { checked: allPersonaSelected }
                      : {};
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        {...selectAllProps}
                      />
                      {option}
                    </React.Fragment>
                  );
                }}
                multiple
                renderInput={(params) => (
                  <TextField
                    error={shouldShowErrors("persona")}
                    {...params}
                    variant="outlined"
                    style={{ fontSize: "14px", fontStyle: "italic" }}
                    placeholder={
                      currentAttribute?.placeholderPersona || "Persona Level"
                    }
                    onInput={handleInput}
                  />
                )}
              />
            </div>
          </div>
          {/* ****************************************************for relation************************ */}

          <div
            style={{
              display: "flex",
              marginLeft: "10px",
            }}
          >
            <Autocomplete
              size="sm"
              options={relationArr}
              color="primary"
              value={attributeData?.relation || ""}
              placeholder="relation"
              style={{ width: "180px" }}
              onChange={(event, newValue) => {
                onAttributeChange(newValue, "relation");
              }}
              error={shouldShowErrors("relation")}
              IconComponent={KeyboardArrowDownRoundedIcon}
              getOptionLabel={(option) => {
                console.log("option in auto complete", option);
                return option;
              }}
              renderInput={(params) => (
                <TextField
                  error={shouldShowErrors("relation")}
                  {...params}
                  variant="outlined"
                  style={{ fontSize: "14px", fontStyle: "italic" }}
                  placeholder="Relation"
                />
              )}
              renderOption={(option, state) => {
                console.log("data in render option is", option, state);
                return option;
              }}
            />
            {/* ****************************************************for number_value************************ */}
            <div style={{ display: "flex", flex: "1", marginLeft: "10px" }}>
              {attributeData.relation !== "between" ? (
                <input
                  margin="dense"
                  className="input"
                  style={{
                    borderRadius: "5px",
                    paddingLeft: 10,
                    width: "100%",
                    height: "40px",
                    marginTop: "8px",
                    border: "1px solid #ccc",
                    borderColor: shouldShowErrors("value") && "red",
                  }}
                  type={
                    attributeData?.filterType === "NUMERIC"
                      ? "number"
                      : attributeData?.filterType === "DATE"
                      ? "date"
                      : "text"
                  }
                  value={attributeData?.values[0]?.value}
                  onChange={(event) => {
                    onBuyerGroupOnNumberAttributeChange(event.target.value, 0);
                  }}
                  placeholder={currentAttribute?.placeholder}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    maxWidth: "242px",
                    height: "40px",
                  }}
                >
                  <input
                    margin="dense"
                    className="input"
                    style={{
                      borderRadius: "5px",
                      paddingLeft: 10,
                      width: "",
                      maxWidth: "",
                      height: "40px",
                      marginTop: "8px",
                      border: "1px solid #ccc",
                      borderColor: "rgba(3, 3, 3, 0.2)",
                    }}
                    type={
                      attributeData?.filterType === "NUMERIC"
                        ? "number"
                        : attributeData?.filterType === "DATE"
                        ? "date"
                        : "text"
                    }
                    value={[attributeData?.values[0]?.value[0]] || ""}
                    onChange={(event) => {
                      onBuyerGroupOnNumberAttributeChange(
                        event?.target?.value,
                        0
                      );
                    }}
                    placeholder={currentAttribute?.placeholder}
                  />
                  <span
                    style={{
                      width: "242px",
                      paddingTop: "18px",
                      textAlign: "center",
                    }}
                  >
                    {"-"}
                  </span>
                  <input
                    margin="dense"
                    className="input"
                    style={{
                      borderRadius: "5px",
                      paddingLeft: 10,
                      width: "",
                      maxWidth: "",
                      height: "40px",
                      marginTop: "8px",
                      border: "1px solid #ccc",
                      borderColor: "rgba(3, 3, 3, 0.2)",
                    }}
                    type={
                      attributeData?.filterType === "NUMERIC"
                        ? "number"
                        : attributeData?.filterType === "DATE"
                        ? "date"
                        : "text"
                    }
                    value={[attributeData?.values[0]?.value[1]] || ""}
                    onChange={(event) => {
                      onBuyerGroupOnNumberAttributeChange(
                        event?.target?.value,
                        1
                      );
                    }}
                    placeholder={currentAttribute?.placeholder}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } catch (e) {
    console.error("Error occurred while rendering value component.", e);
    return (
      {/* <input
        margin="dense"
        className="input"
        style={{
          borderRadius: "5px",
          paddingLeft: 10,
          width: "100%",
          height: "40px",
          marginTop: "8px",
          border: "1px solid ",
          borderColor: "rgba(3, 3, 3, 0.2)",
        }}
        type={
          props.attributeData?.filterType === "NUMERIC"
            ? "number"
            : attributeData?.filterType === "DATE"
            ? "date"
            : "text"
        }
        value={props?.attributeData?.values?.[0] || ""}
        onChange={(event) => {
          onAttributeChange(
            event?.target?.value ? [event?.target?.value] : [],
            "values"
          );
        }}
        placeholder={props?.currentAttribute?.placeholder}
      /> */}
    );
  }
}

export default BuyerGroup;
