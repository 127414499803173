import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { useEffect, useState, useRef } from "react";
import {Loader, NoResultFound} from '../../../assets/icons/FallBackComponents'
import LegendWithMenu from "../../ChartLegend/chartLegendWithMenu"


// Resolves charts dependancy
charts(FusionCharts);




const BarChart=(props)=>  {

  const controllerRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [dataSource,setDataSource] = useState([]);
  const [AttributeList,setAttributeList] = useState({})

  const chart = {
      plottooltext: '<div style="border-radius:8px"><b>$seriesName</b> $dataValue</div>',
      showPercentValues: '1',
      theme: 'fusion',
      plotSpacePercent: '90',
      showHoverEffect:0,
      canvasLeftPadding: 50,
      canvasRightPaddding: 40,
      canvasTopPadding:30,
      yAxisName: "yaxis",
      xAxisName: 'TIME',
      legendItemFontBold: 1,
      drawCustomLegendIcon: 1,
      legendIconSides: 4,
      legendIconStartAngle: 45,
      legendIconScale: 0,
      divLineDashed: 0,
      showZeroPlaneValue: 1,
      xAxisNameFontColor : "#5E5454",
      xAxisNameFontSize:"9px",
      yAxisNameFontColor : "#5E5454",
      yAxisNameFontSize:"9px", // Optional: Set font color
      xAxisValueFontColor: '#5E5454', // Optional: Set font color
      yAxisValueFontColor: '#5E5454', // Optional: Set font color 
      xAxisNameFontBold:"0",
      yAxisNameFontBold:"0",
      "yAxisValueFont": "Open Sans",
      "yAxisValueFontSize": "9px",
      "yAxisValueFontColor": "#5F6B7A",
      "xAxisFont": "Inter",
      "xAxisFontSize": "10px",
      "xAxisFontColor": "#ff0000",
    "baseFont": "Open Sans",
    "baseFontSize": "9px",
    "baseFontColor": "#5F6B7A",
      ...props.cardDetails.chartAttributes,
  }
  
  let colors = [
    "#016B40",
    "#1ABF6E",
    "#92CC38",
    "#C2AE46",
    "#FDC731",
    "#FAA544",
    "#FC693E",
    "#FDA6A5",
    "#D83545",
    "#DE2268",
    "#FD57BA",
    "#F899EC",
    "#9247D3",
    "#6D41C6",
];

function getColor(index) {
    // Ensure index is within bounds
    index = index % colors.length;
    return colors[index];
}


const getBarChartData = () => {
  setLoading(true);


  if (controllerRef.current) {
    controllerRef.current.abort();
  }
  const controller = new AbortController();
  controllerRef.current = controller;
  let UNIFIED_URL=`${window._env_.UNIFIED_URL}/tenant/analytics/${props.tenantId}?segId=${props?.cardDetails?.filterDetails?.filterId}&icp=${props.icpSelected}`
  fetch(
    `${UNIFIED_URL}`,
    {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({...props.cardDetails}),
      signal: controllerRef.current?.signal,
      credentials: "include",
    }
  )
    .then((data) => data.json())
    .then((data) => {
        setLoading(false);
        console.log("data for data stacked chart",data);
        const datasets = data.data;
  
        const attList = []
        for(let index=0; index<datasets.length; index++) {
          const dataset = datasets[index];
          dataset.color = getColor(index)
          attList.push({label:dataset.label,color: dataset.color})
        }
        setAttributeList(attList);
        console.log("data for data bar chart100",datasets);
        const newChartData = {
          chart:chart,
          data:datasets,
        }
  
        setDataSource(newChartData);
        

    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
      setDataSource({});
    });
};


useEffect(() => {
  if (props.icpSelected && props.tenantId) {
    getBarChartData();
  }
}, [props.icpSelected, props.softRefresh, props.refresh]);




    return (
      isLoading?<Loader></Loader>:<>
      {Object.keys(dataSource)?.length<=0?<NoResultFound></NoResultFound>:
      <div style={{margin:"1px"}}>
      <LegendWithMenu AttributeList={AttributeList}/>
      <ReactFusioncharts
      type="column2d"
      width="98%"
      height="248"
      dataFormat="JSON"
      dataLoadStartMessage="Retrieving data Please wait"
      dataEmptyMessage="No data to display"
      dataSource={dataSource}
      />
      </div>
      }
    </>
    );
}

export default BarChart
