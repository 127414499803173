import { Button, IconButton, Tooltip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import "./ExportFilterCriteriaSection.css";
import ExportFilterCriteriaSectionField from "./ExportFilterCriteriaSectionField";
import Close from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { useAuthState } from "@bamboobox/b2logincheck";
import { AddIcon } from "../../../assets/svg";

const ExportFilterCriteriaSection = (props) => {
  const tenantId = useAuthState().user.tenantId;
  const { subfiltersConfig } = props;

  console.log("ExportFilterCriteriaSection props:", props);
  const isDisabled = () => {
    if (props.filterAttributes.length === 0) {
      return true;
    } else {
      for (const filterAttribute of props.filterAttributes) {
        // if(filterAttribute.attribute === 'Buyer Group' && filterAttribute.values.length === '3' && filterAttribute.values['department']!==undefined &&filterAttribute.values['personalevel']!==undefined && filterAttribute.values['value']!==undefined) return false
        if (filterAttribute.attribute === "Buyer Group") {
          if (filterAttribute.relation === undefined) {
            return true;
          }
          if (filterAttribute.values === undefined) {
            return true;
          }
          if (filterAttribute.values[0]?.value === undefined) {
            return true;
          }
          //  ;
          if (filterAttribute.values[0].value[0] == null) {
            return true;
          }
        }
        if (!("values" in filterAttribute)) {
          return true;
        }
      }
      return false;
    }
  };
  
  const isAndDisabled = () => {
    if (props.filterAttributes.length === 0) {
      return true;
    } else {
      for (const filterAttribute of props.filterAttributes) {
        if (!filterAttribute || !filterAttribute.attribute) {
          return true;
        }
        if (filterAttribute.relation === "is empty" || filterAttribute.relation === "is not empty") {
          return false;
        }
        if (!filterAttribute.relation) {
          return true;
        }
        if (!filterAttribute.values || filterAttribute.values?.length === 0) {
          return true;
        }
      }
      return false;
    }
  };

  const isDisabledDelete = () => {
    if (props.filterAttributesArray.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  const onClickDelete = () => {
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray.splice(props.filterAttributesIndex, 1);
    props.setFilterAttributesArray(newFilterAttributesArray);
  };

  const onClickCopy = () => {
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray.splice(props.filterAttributesIndex + 1, 0, [...props.filterAttributes]);
    props.setFilterAttributesArray(newFilterAttributesArray);
  };

  const addFilterCriteria = (elementIndex, isSubfilter = false) => {
    if (isSubfilter) {
      const newFilterAtributes = [...props.filterAttributes];
      if (newFilterAtributes[elementIndex]?.subfilter?.length > 0) {
        newFilterAtributes[elementIndex].subfilter = [
          ...newFilterAtributes[elementIndex].subfilter,
          {
            values: [],
            attribute: "",
            type: "",
            relation: "",
            isValid: false,
          },
        ];
      } else {
        newFilterAtributes[elementIndex].subfilter = [
          {
            values: [],
            attribute: "",
            type: "",
            relation: "",
            isValid: false,
          },
        ];
      }
      const newFilterAttributesArray = [...props.filterAttributesArray];
      newFilterAttributesArray[props.filterAttributesIndex] = [...newFilterAtributes];
      props.setFilterAttributesArray(newFilterAttributesArray);
    } else {
      const newFilterAtributes = [...props.filterAttributes];
      newFilterAtributes.push({});
      const newFilterAttributesArray = [...props.filterAttributesArray];
      newFilterAttributesArray[props.filterAttributesIndex] = [...newFilterAtributes];
      props.setFilterAttributesArray(newFilterAttributesArray);
    }
  };

  const orFilterCriteria = () => {
    const newFilterAttributesArray = [...props.filterAttributesArray];
    newFilterAttributesArray.splice(props.filterAttributesIndex + 1, 0, [{}]);
    props.setFilterAttributesArray(newFilterAttributesArray);
  };

  const [accTenantConfigMapper, setAccTenantConfigMapper] = useState();
  const [contTenantConfigMapper, setContTenantConfigMapper] = useState();

  async function getTenantConfigMapper() {
    let response = await fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/unified-profile-ui`, { credentials: "include" });
    let oppresponse = await fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/opportunity-hub`, { credentials: "include" });
    let contresponse = await fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/ContactHub-creation`, { credentials: "include" });
    let mapper = await response.json();
    let oppmapper = await oppresponse.json();
    let contmapper = await contresponse.json();

    setAccTenantConfigMapper(
      new Map(
        Object.entries({ ...mapper, ...oppmapper }).sort(([key1, a], [key2, b]) => {
          if (a.groupLabel === b.groupLabel) {
            return a.label < b.label ? -1 : 1;
          } else {
            return a.groupLabel < b.groupLabel ? -1 : 1;
          }
        })
      )
    );

    setContTenantConfigMapper(
      new Map(
        Object.entries(contmapper).sort(([key1, a], [key2, b]) => {
          if (a.groupLabel === b.groupLabel) {
            return a.label < b.label ? -1 : 1;
          } else {
            return a.groupLabel < b.groupLabel ? -1 : 1;
          }
        })
      )
    );
  }
  /**commented --- tenant config mapper */

  /**commented --- tenant config mapper */
  const isSubfilterAvailable = (index) => {
    const restSubfilterArr = fetchAttributeArr(`${index}-`, true);

    if (restSubfilterArr?.length > 0) {
      return true;
    }
    return false;
  };

  const fetchAttributeArr = (filterAttributeIndex, subfilter) => {
    if (subfilter) {
      const parentFilter = props.filterAttributes[+filterAttributeIndex.split("-")[0]] ?? [];
      const subfilterArr = parentFilter?.subfilter;

      const { attribute, source, values: parentFilterValues } = parentFilter;

      const configObject = subfiltersConfig?.[attribute] || [];

      const resultArray = [];
      /* if extra field added */
      if (configObject?.extrafields) {
        const subfilterAttrArray = configObject.extrafields;
        resultArray.push(...calculateSubfilterAttributeArray(subfilterAttrArray));
      }
      parentFilterValues.map((currentValue) => {
        const subfilterAttrArray = configObject?.[currentValue?.source]?.[currentValue?.activityType];
        resultArray.push(...calculateSubfilterAttributeArray(subfilterAttrArray, currentValue?.source, currentValue?.activityType));
      });

      return resultArray.filter((obj) => !subfilterArr?.find((currObj) => currObj.attribute === obj.attribute && currObj.logo === obj.logo));
    }
    if (contTenantConfigMapper?.size && props.exportType === "Contact") {
      return Array.from(contTenantConfigMapper, ([key, value]) => ({
        ...value,
        attribute: key,
      })).filter((obj) => obj.isFilter)
      .sort((a, b) => {
        if (a.groupLabel === b.groupLabel) {
          return a.label < b.label ? -1 : 1;
        } else {
          return a.groupLabel < b.groupLabel ? -1 : 1;
        }
      });
    } else if (accTenantConfigMapper?.size && props.exportType === "Account") {
      return Array.from(accTenantConfigMapper, ([key, value]) => ({
        ...value,
        attribute: key,
      })).filter((obj) => obj.isFilter)
      .sort((a, b) => {
        if (a.groupLabel === b.groupLabel) {
          return a.label < b.label ? -1 : 1;
        } else {
          return a.groupLabel < b.groupLabel ? -1 : 1;
        }
      });
    }
  };

  function calculateSubfilterAttributeArray(values, source = false, activityType = false) {
    try {
      const finalSubfilterAttribute = Array.isArray(values) ? values : [];

      if (props.exportType === "Account") {
        return finalSubfilterAttribute
          .map((key) => {
            if (source) {
              if (accTenantConfigMapper.get(key)) {
                const obj = {
                  ...accTenantConfigMapper.get(key),
                  attribute: key,
                  logo: source,
                  activityType: activityType,
                };
                return obj;
              }
              return false;
            } else if (accTenantConfigMapper.get(key)) {
              return { ...accTenantConfigMapper.get(key), attribute: key };
            }
          })
          .filter((obj) => obj);
      } else if (props.exportType === "Contact") {
        return finalSubfilterAttribute
          .map((key) => {
            if (source) {
              if (contTenantConfigMapper.get(key)) {
                const obj = {
                  ...contTenantConfigMapper.get(key),
                  attribute: key,
                  logo: source,
                  activityType: activityType,
                };
                return obj;
              }
              return false;
            } else if (contTenantConfigMapper.get(key)) {
              return { ...contTenantConfigMapper.get(key), attribute: key };
            }
          })
          .filter((obj) => obj);
      }

      return [];
    } catch (e) {
      console.error("Error occured while calculating subfilter array", e);
      return [];
    }
  }

  function isSubfilterTrue(filterAttribute) {
    let isTrue = false;
    if (filterAttribute.values?.length > 0) {
      for (const itr of filterAttribute.values) {
        const { source, activityType } = itr;
        if (subfiltersConfig?.[filterAttribute?.attribute]?.[source]?.[activityType]) {
          isTrue = true;
          break;
        }
      }
    }
    if (!isTrue && ["includes", "excludes"].includes(filterAttribute?.relation) && subfiltersConfig?.[filterAttribute?.attribute]?.extrafields) {
      isTrue = true;
    }
    return (
      filterAttribute?.isValid &&
      (filterAttribute.subfilter === undefined || (filterAttribute.subfilter?.length === 0 ? true : filterAttribute.subfilter?.[filterAttribute.subfilter?.length - 1]?.isValid)) &&
      isTrue
    );
  }

  useEffect(async () => {
    if (!accTenantConfigMapper || !contTenantConfigMapper) {
      await getTenantConfigMapper();
    }
  }, []);

  return (
    <div className="export-filter-crieria-section">
      <div className="export-filter-crieria-section-and-section">
        {props.filterAttributes.map((filterAttribute, filterAttributeIndex) => (
          <>
            <div className="export-filter-crieria-section-and-fields">
              <ExportFilterCriteriaSectionField
                key={`filterAttributes.${filterAttribute.attribute}.${filterAttributeIndex}`}
                filterAttributes={props.filterAttributes}
                filterAttributeIndex={filterAttributeIndex}
                filterAttribute={filterAttribute}
                filterAttributesArray={props.filterAttributesArray}
                filterAttributesIndex={props.filterAttributesIndex}
                setFilterAttributesArray={props.setFilterAttributesArray}
                fetchAttributeArr={fetchAttributeArr}
                icp={props.icp}
                exportType={props.exportType}
                accTenantConfigMapper={accTenantConfigMapper}
                contTenantConfigMapper={contTenantConfigMapper}
                relationArrConfig={props.relationArrConfig}
                subfiltersConfig={subfiltersConfig}
              />
            </div>
            {filterAttribute?.subfilter &&
              filterAttribute.subfilter?.length > 0 &&
              filterAttribute.subfilter.map((subfilterAttribute, subfilterIndex) => (
                <ExportFilterCriteriaSectionField
                  key={`filterAttributes.${filterAttribute.attribute}.${filterAttributeIndex}.${subfilterIndex}`}
                  subfilter={true}
                  filterAttributes={props.filterAttributes}
                  filterAttributeIndex={`${filterAttributeIndex}-${subfilterIndex}`}
                  filterAttribute={subfilterAttribute}
                  filterAttributesArray={props.filterAttributesArray}
                  filterAttributesIndex={props.filterAttributesIndex}
                  setFilterAttributesArray={props.setFilterAttributesArray}
                  fetchAttributeArr={fetchAttributeArr}
                  icp={props.icp}
                  exportType={props.exportType}
                  accTenantConfigMapper={accTenantConfigMapper}
                  contTenantConfigMapper={contTenantConfigMapper}
                  relationArrConfig={props.relationArrConfig}
                  subfiltersConfig={subfiltersConfig}
                />
              ))}
            {subfiltersConfig?.[filterAttribute?.attribute] && isSubfilterAvailable(filterAttributeIndex) && (
              <>
                {isSubfilterTrue(filterAttribute) ? (
                  <button
                    onClick={() => {
                      addFilterCriteria(filterAttributeIndex, true);
                    }}
                    className="subfilterButton"
                  >
                    <AddIcon />
                    <div>Sub-Attribute</div>
                  </button>
                ) : (
                  <Tooltip title={"You can't add sub-filter yet. Fill the values above and try again."}>
                    <button disabled className="subfilterButton">
                      <AddIcon />
                      <div>Sub-Attribute</div>
                    </button>
                  </Tooltip>
                )}
              </>
            )}
            {accTenantConfigMapper && contTenantConfigMapper && (
              <div className="export-filter-crieria-section-and">
                {!isAndDisabled() &&
                filterAttributeIndex === props.filterAttributes.length - 1 &&
                (filterAttribute.subfilter === undefined || filterAttribute.subfilter?.length === 0 ? true : filterAttribute.subfilter?.[filterAttribute.subfilter?.length - 1]?.isValid) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      addFilterCriteria();
                    }}
                    style={{
                      backgroundColor: isDisabled() ? "#cccccc" : "rgba(1, 107, 64, 1)",
                      color: isDisabled() ? "rgba(0, 0, 0, 0.38)" : "#FFFFFF",
                      boxShadow: "none",
                    }}
                  >
                    AND
                  </Button>
                ) : (
                  <>
                    {filterAttributeIndex < props.filterAttributes.length - 1 ? (
                      <Button
                        variant="contained"
                        disabled
                        style={{
                          height: "36px",
                          color: "rgba(0, 0, 0, 0.38)",
                          border: "1px solid var(--action-disabledBg, #FFF)",
                        }}
                      >
                        AND
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled
                        style={{
                          height: "36px",
                          background: "white",
                          color: "rgba(0, 0, 0, 0.38)",
                          border: "1px solid rgba(0, 0, 0, 0.38)",
                        }}
                      >
                        AND
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        ))}
        <div
          style={{
            display: "flex",
            gap: "32px",
            justifyContent: "flex-end",
            position: "absolute",
            right: "16px",
            bottom: "16px",
          }}
        >
          <IconButton
            onClick={() => {
              onClickCopy();
            }}
            disabled={isDisabled()}
          >
            <Tooltip title={"Copy Filter"}>
              <FileCopyIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={() => {
              onClickDelete();
            }}
            disabled={isDisabledDelete()}
          >
            <Tooltip title={"Remove Filter"}>
              <Close />
            </Tooltip>
          </IconButton>
        </div>
      </div>
      <div className="export-filter-crieria-section-or">
        <Button
          variant="contained"
          onClick={() => {
            orFilterCriteria();
          }}
          disabled={isDisabled()}
          style={{
            backgroundColor: isDisabled() ? "#cccccc" : "rgba(1, 107, 64, 1)",
            color: isDisabled() ? "rgba(0, 0, 0, 0.38)" : "#FFFFFF",
            boxShadow: "none",
          }}
        >
          OR
        </Button>
      </div>
    </div>
  );
};

export default ExportFilterCriteriaSection;
