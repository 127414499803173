/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect,useRef } from "react";
import InfoIndicatorCard from './InfoIndicator/InfoIndicatorCard';
import HeaderCount from './HeaderCount';
import { useAuthState } from '@bamboobox/b2logincheck';
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import CreateTab from "./Tab"
import { Segments } from "../apis/api";
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './Header.css'
import config from "../config.json";
import { getPermissionsForCards, getSessionPermissionsForCards } from "../helpers/permissionForCards.helper";

const useStyles = makeStyles({
  button: {
    border: 'none',
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px !important",
    lineHeight: "15px",
    color: "#3D70D4",
    textTransform: "none !important",
    '&:hover': {
      textDecoration: 'underline',
    },
  },
},
);

const Header = (props) => {
    let location = useLocation();

    function useQuery() {
        return new URLSearchParams(location.search);
    }

    let GetSegment = () => {
        return useQuery().get("segmentName");
    }
    const containerRef = useRef(null);

    const handleScrollToCard = () => {
      const cardPosition = document.getElementsByClassName ('mainHeader').offsetTop;
      containerRef.current.scrollTo({
        top: cardPosition+100,
        behavior: 'smooth',
      });
    };
  
    let { id } = useParams();
    let query = useQuery();
    let params_url = query.get("icp") || "";
    let category = query.get("category") || "";
    const [loglist,setLoglist] = useState([{id:0 ,listTitle:"All Accounts",type:'account'}])
    const [tabVal,setTabVal] = useState(config.categories.indexOf(category) >=0 ? config.categories.indexOf(category): 0);
    console.log("TabVal70",tabVal)
    const [intentNewAccount, setIntentNewAccount] = useState(false);
    const[accSegment,setAccSegment]=useState("");
    const userDetails = useAuthState();
    const tenantId = userDetails.user.tenantId;
    console.log("tenantId59",tenantId)
    const userUuid = useAuthState().user.userUuid;
    const [body, setBody] = useState(id ? JSON.parse(decodeURIComponent(id)) : []);
    const [icp, setIcp] = useState(params_url);
    const classes = useStyles();

    const getPermissionsForCardsApi = async () => {
      const permissionsForCards = getSessionPermissionsForCards();
      
      if (!permissionsForCards || permissionsForCards === null)
        userDetails?.permissions?.length > 0
          ? await getPermissionsForCards(
              userDetails.permissions.filter((perm) => perm.resourceName === "account_action_labels" || perm.resourceName === "contact_action_labels"),
              userDetails.user.tenantId
            )
          : await getPermissionsForCards("default", userDetails.user.tenantId);
    };

    useEffect(() => {
        let segName = GetSegment();
          
    if(segName&&Array.isArray(loglist)&&loglist?.filter(fil => fil?.listTitle===segName&&fil?.type==='account').length){
      setAccSegment(segName)
    }
    }, [useQuery().get("segmentName"),loglist])

    useEffect(async () => {
        console.log("calling....")
        setLoglist(await Segments(tenantId, userUuid));
        getPermissionsForCardsApi()
    },[]);

    let GetIcp = () => {
      return useQuery().get("icp") || '';
  }

    useEffect(() => {
      console.log("test icp query changed")
      setIcp(GetIcp())
  }, [useQuery().get("icp")])

    const handleButtonClick = () => {
        setTabVal(1)
        handleScrollToCard()
      };

    console.log("header82", icp, accSegment)

    return (
     <div className="mainHeader" ref={containerRef} >
     <HeaderCount reqBody={body} icpSelected={icp} segmentName={accSegment} setIntentNewAccount={setIntentNewAccount} intentNewAccount={intentNewAccount} loglist={loglist} accSegment={accSegment} setAccSegment={setAccSegment} tabVal={tabVal}/> 
     <div className="compareButton">
      <Button className={classes.button} style={{ fontSize: '12px', fontWeight: 400, paddingRight: 0, color: '#3D70D4', backgroundColor: 'transparent', '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' }}} onClick={handleButtonClick}>
        View Funnel Comparison
      </Button>
     </div>
     <InfoIndicatorCard 
     reqBody={body} icpSelected={icp} segmentName={accSegment} setIntentNewAccount={setIntentNewAccount} intentNewAccount={intentNewAccount}
     loglist={loglist} accSegment={accSegment} setAccSegment={setAccSegment} tabVal={tabVal}/>
     <CreateTab
     tabVal={tabVal} setTabVal={setTabVal} segmentName={accSegment}  tenantId={tenantId} 
     requestBody={body} icpSelected={icp}
     setBody={setBody}
     body={body}
     ></CreateTab>
     </div>
    )
}
    
export default Header