import React, { useState, Suspense } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, CircularProgress,  withStyles } from "@material-ui/core";
import { useAuthState } from "@bamboobox/b2logincheck";
import DesignTab from "./DesignTab";
import FilterAndChips from './Filter';
import config from "../config.json"
import Divider from '@mui/material/Divider';
import { useEffect } from "react";

const CreateTab = ({ tabVal, requestBody, icpSelected, tenantId, segmentName, ...props }) => {
    // let location = useLocation();
    console.log("props from createTab", props)
    const userDetails = useAuthState();
    const [refresh, setRefresh] = useState(false);
  const [resourcesAllowed,setResourcesAllowed] = useState(["Data Quality and Coverage", "Engagement", "Intent", "Potential Opportunity", "Deal", "Customer"])

    const permissionsOfUser = userDetails?.permissions.map((obj)=>obj.resourceName.toLowerCase());
    const resources = new Map([["dashboard_insights","Data Quality and Coverage"],["dashboard_keysegments","Engagement"],["dashboard_journeyanalytics","Intent"],["dashboard_intent","Potential Opportunity"],["dashboard_trends","Deal"],["dashboard_dataquality","Customer"]]) 
 
    useEffect(()=>{
      let resourcesAllowedCheck=[]

      if (permissionsOfUser.includes('account_dashboard')) {
        resourcesAllowedCheck = Array.from(resources.values());
      } else {
        Array.from(resources.keys()).forEach(obj => {
          if (permissionsOfUser.includes(obj)) {
            resourcesAllowedCheck.push(resources.get(obj));
          }
        })
      }
  
      if (resourcesAllowedCheck.length === 0) {
        resourcesAllowedCheck=Array.from(resources.values());
      }
      setResourcesAllowed(resourcesAllowedCheck)
    },[])
   
    const CustomTab = withStyles({
        root: {
          textTransform: "none",
          // minWidth: "13.8vw",
          // width: "13.8vw",
          lineHeight: "15.83px",
          color: '#3D3D3B',
          fontSize: '16px',
          fontFamily:'Inter !important',
          fontWeight: 400
        },
    })(Tab);

    function handleChange(e, newValue) {
      props.setTabVal(newValue);
    }
    

    console.log("resourcesAllowed",resourcesAllowed);

    return (
        <div style={{ width: '100%', marginTop: '30px' }}>
          <Box sx={{ maxWidth: "fit-content", borderBottomWidth: '0.2px', borderBottomColor: "rgb(211, 211, 211)" }}>
              <div style = {{ position: 'relative' }}>
                <Tabs TabIndicatorProps = {{ 
                  style: {
                    backgroundColor: "#59CB59",
                    border: "2px solid #59CB59",
                    }
                  }}
                  value={(Number(tabVal) || 0)}
                  onChange={handleChange}
                  variant="standard"
                >
                {resourcesAllowed.map((label, index) => (
                    <CustomTab key={index} className="menu-tab" style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px", paddingBottom: '0px' }} label={label}/>
                  ))}
                </Tabs> 
                <div style={{ width: '100%', borderBottom: '1px solid #D9D9D9'}} />
              </div> 
          </Box>
          {/* <br></br> */}
          <FilterAndChips body={props.body} setBody={props.setBody} category={config?.categories[tabVal]} setRefresh={setRefresh}  icpSelected={icpSelected} segmentName={segmentName} />
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            {
              resourcesAllowed.map((label,index)=>{
                return (
                  (tabVal || 0) === index && <Suspense fallback= 
                        { 
                          <div style={{display:"flex", alignItems:"center", justifyContent:"center", height: '420px' }}>
                            <CircularProgress style={{ color: "#016B40" }}/>
                          </div>
                        }
                      >
                        <DesignTab category={label} segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={icpSelected} refresh={refresh} tabVal={tabVal}/>
                      </Suspense>
                )
              })
            }
          </Box>
        </div>
      )
    }
    
export default CreateTab