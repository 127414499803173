import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NewAccountDialog from "./NewAccountDialog";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import { toast } from "react-toastify";
// import { Skeleton } from '@mui/material';
import {Loader} from '../../../assets/icons/FallBackComponents'
import Avatar from '@mui/material/Avatar';
import { stringToColor,NoResultFound } from '../../../assets/icons/FallBackComponents'
import './table.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    
    [`&.MuiTableCell-root`]: {
        fontFamily: "Inter",
        paddingLeft: "0px",
      },

    [`&.${tableCellClasses.head}`]: {
        color: '#595959',
        // fontFamily: UItheme.typography.fontFamily,
        fontWeight: 'bold',
        fontSize: '11px',
        letterSpacing: `0.15px`,
        textTransform: `uppercase`,
    },
    
    [`&.${tableCellClasses.head}:first-child`]: {
        width: `280px`,
        paddingLeft: `20px`,
        height: `38px`,
    },

    [`&.${tableCellClasses.head}:nth-child(2)`]: {
        width: `280px`,

    },
    
    [`&.${tableCellClasses.body}`]: {
        margin: `0px`,
        // padding: `0px`,
        color: '#595959',
        fontSize: '14px',
        fontWeight: `400`,
        /* identical to box height, or 171% */
        letterSpacing: '0.15px',
        // fontFamily: UItheme.typography.fontFamily,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // width: `180px`,
        padding: `0 2rem 0 0`,
        boxSizing: `border-box`,
        fontFamily: "'Nunito Sans', 'sans-serif'",
    },
    // [`&.${tableCellClasses.body}:last-child`]: {
    //     paddingRight: `6rem`,
    // },

    [`&.${tableCellClasses.body}:first-child`]: {
        width: `280px`,
    },
      
    [`&.${tableCellClasses.body}:nth-child(2)`]: {
        width: `280px`,
    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& td, & th': {
        maxWidth: `280px`,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    height: '45px',
}));

export default function NetNewAccount(props) {
    console.log(props.rows, "from NewNewAcccount line 56");
    const [rows, setRows] = useState(undefined)
    const [isEmpty, setIsEmpty] = useState(true)

    const setTableData = () => {
        fetch(
            `${window._env_.UNIFIED_URL}/analytics/intent/get-new-account/BOMBORA/${props.tenantId}/${props.icpSelected}`,
            {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        )
            .then(response => response.json())
            .then(res => {
                if (res?.msg.toUpperCase() !== "SUCCESS" || res?.msg.toUpperCase() === "EMPTY") {
                    setIsEmpty(false)
                    toast.warn(<Alert severity="warn">No pending requests available</Alert>,{
                        closeOnClick: true,
                        autoClose: true,
                        closeButton: true,
                        newestOnTop: true
                      });
                    let rowDataArr = []
                    props.setCount(0);
                    setRows(rowDataArr)
                }
                else {
                    setIsEmpty(true)
                    console.log(res.data, "from line 80");
                    let rowDataArr = []
                    for(let i = 0; i < 5; i++) {
                        let obj = res.data[i]
                        let rowDataObject = {
                            company_name: "",
                            topics_data: "",
                            intent_level: "",
                            last_intent_date: "",
                            source: "",
                            company_industry: "",
                        }
                        rowDataObject.company_name = obj.company_name
                        rowDataObject.company_industry = obj.company_industry
                        // rowDataObject.topics_data = getTopicList(obj.topics_data_aggr)
                        // rowDataObject.intent_level = obj.b2_intent_level
                        rowDataObject.source = obj.source?.replace(/[\[\]']+/g, '');
                        rowDataObject.last_intent_date = moment(obj.last_intent_date).format('DD-MM-YYYY')

                        rowDataArr.push(rowDataObject)
                    }
                    props.setCount(50);
                    setRows(rowDataArr)
                }
            }).catch(error => {
                toast.error(<Alert severity="warn">Error Occurred while retrieving data</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
                console.log('error', error)
            })

    }

    useEffect(() => {
        setRows(undefined)
        if (props.icpSelected) setTableData();
    }, [props.icpSelected, props.softRefresh, props.refresh, props.segementName])

    return (
        <>
            {rows===undefined ? (
                <Loader />
            ):
            <>
            <NewAccountDialog tenantId={props.tenantId} icpSelected={props.icpSelected} openNewAccountDialog={props.openNewAccountDialog} setOpenNewAccountDialog={props.setOpenNewAccountDialog} setIntentNewAccount={props.setIntentNewAccount} />
            { isEmpty === false ?
                <NoResultFound /> :
                <TableContainer >
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ textTransform: 'none', width: '40%', color: '#000000', fontSize: '11px', fontWeight: 400 }} size="small" component="th">Account</StyledTableCell>
                                {/* <StyledTableCell style={{ textTransform: 'none', width: '15%', color: '#000000', fontSize: '11px', fontWeight: 400 }} size="small" component="th">Intent Topics</StyledTableCell> */}
                                <StyledTableCell style={{ textTransform: 'none', width: '15%', color: '#000000', fontSize: '11px', fontWeight: 400 }} size="small" component="th">Source</StyledTableCell>
                                <StyledTableCell style={{ textTransform: 'none', width: '15%', color: '#000000', fontSize: '11px', fontWeight: 400 }} size="small" component="th">Company Industry</StyledTableCell>
                                <StyledTableCell style={{ textTransform: 'none', width: '15%', textAlign: 'end', color: '#000000', fontSize: '11px', fontWeight: 400, paddingRight: '32px' }} size="small" component="th">Last Intent Date</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row) => (
                                <StyledTableRow key={row.company_name}>
                                    <StyledTableCell size="small" component="th" scope="row" sx={{ textOverflow: 'ellipsis' }}>
                                        <Tooltip title={row.company_name}>
                                            <div className="value">
                                            <Avatar sx={{ bgcolor: stringToColor(row?.company_name),width: 30, height: 30 }}>
                                                <span className="avatartext">{row?.company_name?.slice(0,2)?.toUpperCase()}</span>
                                            </Avatar> {row.company_name}
                                            </div>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell size="small">
                                        <Tooltip title={row.source}>
                                            <div className="value" style={{ paddingLeft: '0px'}}>
                                                {row.source}
                                            </div>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell size="small">
                                        <Tooltip title={row.company_industry}>
                                            <div className="value" style={{ paddingLeft: '0px'}}>
                                                {row.company_industry}
                                            </div>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell size="small">
                                        <Tooltip title={row.last_intent_date}>
                                            <div className="value" style={{ padding: '0px', justifyContent: 'end' }}>
                                                {row?.last_intent_date?.slice(0, 10)}
                                            </div>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                </>
        }
        </>
    );
}
