import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { useEffect, useState, useRef } from "react";
import {Loader, NoResultFound} from '../../../assets/icons/FallBackComponents'
import LegendWithMenu from "../../ChartLegend/chartLegendWithMenu"
import CryptoJS from "crypto-js";
import { LICENSE_STRING } from '../../Layout/Cards/Type/Constant.js'

if (LICENSE_STRING != "") {
  var _0x49bc0e = _0x298e;
  function _0x298e(_0x7d727b, _0x38966f) {
    var _0x1b8773 = _0x1b87();
    return (
      (_0x298e = function (_0x298eb3, _0x1414f4) {
        _0x298eb3 = _0x298eb3 - 0x1ea;
        var _0x32f714 = _0x1b8773[_0x298eb3];
        return _0x32f714;
      }),
      _0x298e(_0x7d727b, _0x38966f)
    );
  }
  (function (_0x5c9ab4, _0x59221e) {
    var _0xe05717 = _0x298e,
      _0x550b93 = _0x5c9ab4();
    while (!![]) {
      try {
        var _0x3a14a4 =
          parseInt(_0xe05717(0x1f4)) / 0x1 +
          parseInt(_0xe05717(0x1f7)) / 0x2 +
          parseInt(_0xe05717(0x1ee)) / 0x3 +
          -parseInt(_0xe05717(0x1ea)) / 0x4 +
          (parseInt(_0xe05717(0x1eb)) / 0x5) *
            (parseInt(_0xe05717(0x1ec)) / 0x6) +
          (parseInt(_0xe05717(0x1f0)) / 0x7) *
            (-parseInt(_0xe05717(0x1ef)) / 0x8) +
          (-parseInt(_0xe05717(0x1f6)) / 0x9) *
            (-parseInt(_0xe05717(0x1f5)) / 0xa);
        if (_0x3a14a4 === _0x59221e) break;
        else _0x550b93["push"](_0x550b93["shift"]());
      } catch (_0x3a3581) {
        _0x550b93["push"](_0x550b93["shift"]());
      }
    }
  })(_0x1b87, 0xed863);
  function _0x1b87() {
    var _0x3cdb73 = [
      "Utf8",
      "b2\x20cdp\x20uses\x20fustion\x20chart",
      "169152xDHSeA",
      "30SRtDAJ",
      "2786688ScKRos",
      "1604846jQeLkv",
      "license",
      "options",
      "3060620rKUxON",
      "3982685QzwgIi",
      "6quDyMF",
      "enc",
      "1686396OqHHUo",
      "1738384mMIoTV",
      "49NMgycc",
      "AES",
    ];
    _0x1b87 = function () {
      return _0x3cdb73;
    };
    return _0x1b87();
  }
  var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]
    ["decrypt"](LICENSE_STRING, _0x49bc0e(0x1f3))
    ["toString"](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);
  FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({
    key: oooo0000,
    creditLabel: ![],
  });
}
// Resolves charts dependancy
charts(FusionCharts);




const StackChart=(props)=>  {



  const controllerRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [dataSource,setDataSource] = useState({});
  const [AttributeList,setAttributeList] = useState([])
  const chart = {
     showsum: '1',
      plottooltext: '<div style="border-radius:8px"><b>$seriesName</b> $dataValue</div>',
      showPercentValues: '1',
      theme: 'fusion',
      showLegend: '0',
      plotSpacePercent: '65',
      canvasLeftPadding: 50,
      canvasRightPaddding: 40,
      canvasTopPadding:30,
      yAxisName: "yaxis",
      xAxisName: 'TIME',
      legendItemFontBold: 1,
      drawCustomLegendIcon: 1,
      drawCrossLine:0,
      legendIconSides: 4,
      legendIconStartAngle: 45,
      legendIconScale: 0,
      divLineDashed: 0,
      showZeroPlaneValue: 1,
      xAxisNameFontColor : "#5E5454",
                    xAxisNameFontSize:"9px",
                    yAxisNameFontColor : "#5E5454",
                    yAxisNameFontSize:"9px", // Optional: Set font color
                    xAxisValueFontColor: '#5E5454', // Optional: Set font color
                    yAxisValueFontColor: '#5E5454', // Optional: Set font color 
                    xAxisNameFontBold:"0",
                    yAxisNameFontBold:"0",
                    "yAxisValueFont": "Open Sans",
                    "yAxisValueFontSize": "9px",
                    "xAxisFont": "Inter",
                    "xAxisFontSize": "10px",
                    "xAxisFontColor": "#ff0000",
                    "baseFont": "Open Sans",
                    "baseFontSize": "9px",
                    "baseFontColor": "#5F6B7A",
                    numDivLines:"5",
      ...props.cardDetails.chartAttributes,
  }
  
  let colors = [
    "#016B40",
    "#1ABF6E",
    "#92CC38",
    "#C2AE46",
    "#FDC731",
    "#FAA544",
    "#FC693E",
    "#FDA6A5",
    "#D83545",
    "#DE2268",
    "#FD57BA",
    "#F899EC",
    "#9247D3",
    "#6D41C6",
];

const getColor=(index)=> {
    // Ensure index is within bounds
    index = index % colors.length;
    return colors[index];
}


const getStackedChartData = () => {
  setLoading(true);


  if (controllerRef.current) {
    controllerRef.current.abort();
  }
  const controller = new AbortController();
  controllerRef.current = controller;
  let UNIFIED_URL=`${window._env_.UNIFIED_URL}/tenant/analytics/${props.tenantId}?segId=${props?.cardDetails?.filterDetails?.filterId}&icp=${props.icpSelected}`
  fetch(
    `${UNIFIED_URL}`,
    {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({...props.cardDetails}),
      signal: controllerRef.current?.signal,
      credentials: "include",
    }
  )
    .then((data) => data.json())
    .then((data) => {
      setLoading(false);
      console.log("data for data stacked chart",data);
      const categories = data.data.categories;
      const datasets = data.data.dataset;

      const attList = []
      for(let index=0; index<datasets.length; index++) {
        const dataset = datasets[index];
        dataset.color = getColor(index)
        attList.push({label:dataset.seriesname,color: dataset.color})
      }
      setAttributeList(attList);
      console.log("data for data stacked chart100",datasets);
      const newChartData = {
        chart:chart,
        categories:categories,
        dataset:datasets,
      }

      setDataSource(newChartData);
      

    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
      setDataSource({});
   
    });
};


useEffect(() => {
  if (props.icpSelected && props.tenantId) {
    getStackedChartData();
  }
}, [props.icpSelected, props.softRefresh, props.refresh]);

   console.log("dataSource288",dataSource)
    return (
      isLoading?<Loader></Loader>:<>
      {Object.keys(dataSource)?.length<=0?<NoResultFound></NoResultFound>:
      <div style={{margin:"1px"}}>
      <LegendWithMenu AttributeList={AttributeList}/>
      <ReactFusioncharts
      type="scrollstackedcolumn2d"
      width="98%"
      height="248"
      dataFormat="JSON"
      dataLoadStartMessage="Retrieving data Please wait"
      dataEmptyMessage="No data to display"
      dataSource={dataSource}
      />
      </div>
      }
    </>
    );
}

export default StackChart
