import React from 'react';
import { useEffect, useState, useRef } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import HeatMap from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import { Skeleton } from '@mui/material';
import {Loader} from '../../../../assets/icons/FallBackComponents'
import _ from "lodash";
import {LICENSE_STRING} from "./Constant";
import CryptoJS from "crypto-js";

if (LICENSE_STRING != "") {
    var _0x49bc0e = _0x298e;
    function _0x298e(_0x7d727b, _0x38966f) {
      var _0x1b8773 = _0x1b87();
      return (
        (_0x298e = function (_0x298eb3, _0x1414f4) {
          _0x298eb3 = _0x298eb3 - 0x1ea;
          var _0x32f714 = _0x1b8773[_0x298eb3];
          return _0x32f714;
        }),
        _0x298e(_0x7d727b, _0x38966f)
      );
    }
    (function (_0x5c9ab4, _0x59221e) {
      var _0xe05717 = _0x298e,
        _0x550b93 = _0x5c9ab4();
      while (!![]) {
        try {
          var _0x3a14a4 =
            parseInt(_0xe05717(0x1f4)) / 0x1 +
            parseInt(_0xe05717(0x1f7)) / 0x2 +
            parseInt(_0xe05717(0x1ee)) / 0x3 +
            -parseInt(_0xe05717(0x1ea)) / 0x4 +
            (parseInt(_0xe05717(0x1eb)) / 0x5) *
              (parseInt(_0xe05717(0x1ec)) / 0x6) +
            (parseInt(_0xe05717(0x1f0)) / 0x7) *
              (-parseInt(_0xe05717(0x1ef)) / 0x8) +
            (-parseInt(_0xe05717(0x1f6)) / 0x9) *
              (-parseInt(_0xe05717(0x1f5)) / 0xa);
          if (_0x3a14a4 === _0x59221e) break;
          else _0x550b93["push"](_0x550b93["shift"]());
        } catch (_0x3a3581) {
          _0x550b93["push"](_0x550b93["shift"]());
        }
      }
    })(_0x1b87, 0xed863);
    function _0x1b87() {
      var _0x3cdb73 = [
        "Utf8",
        "b2\x20cdp\x20uses\x20fustion\x20chart",
        "169152xDHSeA",
        "30SRtDAJ",
        "2786688ScKRos",
        "1604846jQeLkv",
        "license",
        "options",
        "3060620rKUxON",
        "3982685QzwgIi",
        "6quDyMF",
        "enc",
        "1686396OqHHUo",
        "1738384mMIoTV",
        "49NMgycc",
        "AES",
      ];
      _0x1b87 = function () {
        return _0x3cdb73;
      };
      return _0x1b87();
    }
    var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]
      ["decrypt"](LICENSE_STRING, _0x49bc0e(0x1f3))
      ["toString"](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);
    FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({
      key: oooo0000,
      creditLabel: ![],
    });
  }



ReactFC.fcRoot(FusionCharts, HeatMap, Widgets, PowerCharts, FusionTheme);

const HeatMapChart = (props) => {
    const controllerRef = useRef();
    const [loading, setLoading] = useState(false)
    const [chartConfigs, setChartConfigs] = useState(undefined)
    const [count,setCount] = React.useState(1);
    const getIntentVsIcp = () => {
      setLoading(true)

      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      const controller = new AbortController();
      controllerRef.current = controller;
  
        fetch(
            `${window._env_.UNIFIED_URL}/analytics/intent/intent/intent-vs-icp/${props.tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,
            {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"filters": [...props.reqBody]}),
                signal:controllerRef.current?.signal,
                credentials: "include",
            }
        )
            .then((data) => data.json())
            .then((data) => {
                setLoading(false)
                let tempArray = [
                    {
                        rowid:"VERY HIGH",
                        columnid:"VERY HIGH",
                        colorrangelabel:"VeryVeryHigh"
                    },
                    {
                        rowid:"VERY HIGH",
                        columnid:"HIGH",
                        colorrangelabel:"VERYHIGH"
                    },
                    {
                        rowid:"VERY HIGH",
                        columnid:"MEDIUM",
                        colorrangelabel:"MEDIUM"
                    },
                    {
                        rowid: "HIGH",
                        columnid: "MEDIUM",
                        colorrangelabel: "Medium"
                    },
                    {
                        rowid: "HIGH",
                        columnid: "HIGH",
                        colorrangelabel: "High"
                    },
                    {
                        rowid: "HIGH",
                        columnid: "VERY HIGH",
                        colorrangelabel: "VeryHigh"
                    },
                    {
                        rowid: "MEDIUM",
                        columnid: "MEDIUM",
                        colorrangelabel: "Low"
                    },
                    {
                        rowid: "MEDIUM",
                        columnid: "HIGH",
                        colorrangelabel: "Medium"
                    },
                    {
                        rowid: "MEDIUM",
                        columnid: "VERY HIGH",
                        colorrangelabel: "High"
                    },
                    {
                        rowid: "LOW",
                        columnid: "MEDIUM",
                        colorrangelabel: "VeryLow"
                    },
                    {
                        rowid: "LOW",
                        columnid: "HIGH",
                        colorrangelabel: "Low"
                    },
                    {
                        rowid: "LOW",
                        columnid: "VERY HIGH",
                        colorrangelabel: "Medium"
                    }
                ]
                let intentvsicpData = []
                tempArray.forEach(obj => {
                    let tempResult = data.find(object => {
                        return object.icp_level === obj.rowid && object.b2_intent_level === obj.columnid
                    })


                    let intentIcpObj = {
                        "rowid": "",
                        "columnid": "",
                        "displayvalue": "",
                        "colorrangelabel": "",
                        "link": ""
                    }
                    let dynamicFilterObject = [
                        {
                        "attrId": "4sDVD7BPpJ",    
                        "attribute":"Icp Level",
                        "type":"ACCOUNT","relation":"includes",
                        "values":[obj.rowid]},
                       
                    { "attrId": "DVD7BPpJ","attribute": "Intent Level", "type": "INTENT", "relation": "includes", "values": [obj.columnid] },...props.reqBody]


                    intentIcpObj.rowid = obj.rowid
                    intentIcpObj.columnid = obj.columnid
                    intentIcpObj.displayvalue = tempResult.num_of_acc
                    intentIcpObj.colorrangelabel = obj.colorrangelabel
                    intentIcpObj.link = `${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
                        JSON.stringify(dynamicFilterObject,...props.reqBody)
                      ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
                        JSON.stringify(props.reqBody)
                      ))}/?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&attribute=${props.stageValue}&category=${props.category}`;


                    console.log("reqBody",`${window._env_.UNIFIED_FRONTEND_URL}/#/filter/${
                        JSON.stringify(dynamicFilterObject,...props.reqBody)
                      }/analytics_dashboard/${
                        JSON.stringify(props.reqBody)
                      }/?icp=${props.icpSelected}&segmentName=${props.segmentName}&newDashboard=true&tabVal=${props.tabVal}&attribute=${props.stageValue}`)
                    intentvsicpData.push(intentIcpObj)

                })
                let chartConfiguration = {
                    type: "heatmap",
                    width: "90%",
                    height: "262",
                    dataFormat: "JSON",
                    dataSource: {
                       
                        colorrange: {
                            
                            gradient: "0",
                            minvalue: "0",
                            code: "F28C8C",
                            startlabel: "VeryLow",
                            endlabel: "VerHigh",
                            color: [
                                {
                                    code: "117245",
                                    minvalue: "0",
                                    maxvalue: "50",
                                    label: "VeryLow"
                                },
                                {
                                    code: "92CC38",
                                    minvalue: "50",
                                    maxvalue: "70",
                                    label: "Low"
                                },
                                {
                                    code: "FDC731",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "Medium"
                                },
                                {
                                    code: "FDC731",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "High"

                                },
                                {
                                    code: "FAA544",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "VeryHigh"
                                },{
                                    code: "D83545",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "VeryVeryHigh"
                                }
                            ]
                        },
                        dataset: [
                            {
                                data: intentvsicpData
                            }
                        ],
                        columns: {
                            column: [
                                {
                                    id: "MEDIUM",
                                    label: "MEDIUM"
                                },
                                {
                                    id: "HIGH",
                                    label: "HIGH"
                                },
                                {
                                    id: "VERY HIGH",
                                    label: "VERY HIGH"
                                }
                            ]
                        },
                        rows: {
                            row: [
                                {
                                    id: "VERY HIGH",
                                    label:"VERY HIGH"
                                },
                                {
                                    id: "HIGH",
                                    label: "HIGH"
                                },
                                {
                                    id: "MEDIUM",
                                    label: "MEDIUM"
                                },
                                {
                                    id: "LOW",
                                    label: "LOW"
                                }
                            ]
                        },
                        chart: {
                            theme: "fusion",
                            showLegend: "0",
                            showvalues: "1",
                            xAxisName: "Intent Level",
                            yAxisName: "ICP Range",
                            mapByCategory: "1",
                            cellWidth: "200",
                            plottooltext:
                            '<div style="padding: 5px">Number of Accounts<br><b style="font-size: 14px">$displayvalue</b> <br><br>ICP Level<br> <b style="font-size: 14px">$rowlabel</b><br><br>Intent Level<br> <b style="font-size: 14px">$columnlabel</b></div>',
                                // "$rowlabel's average temperature in $columnlabel is $displayvalue °F"   
                            "toolTipBorderRadius": "10",
                            "toolTipBgColor": "#FFFFFF",
                            "toolTipBgAlpha": "100",
                            "showToolTipShadow": "1",
                            valueFontColor:"#FFFFFF",
                            valueFontSize:"16",
                            xAxisNameFontColor : "#5E5454",
                            xAxisNameFontSize:"9px",
                            yAxisNameFontColor : "#5E5454",
                            yAxisNameFontSize:"9px", // Optional: Set font color
                            xAxisValueFontColor: '#5E5454', // Optional: Set font color
                            yAxisValueFontColor: '#5E5454', // Optional: Set font color 
                            xAxisNameFontBold:"0",
                            yAxisNameFontBold:"0",
                            "yAxisValueFont": "Open Sans",
                            "yAxisValueFontSize": "9px",
                            "yAxisValueFontColor": "#5F6B7A",
                            "xAxisFont": "Inter",
                            "xAxisFontSize": "10px",
                            "xAxisFontColor": "#ff0000",
                            "baseFont": "Open Sans",
                            "baseFontSize": "9px",
                            "baseFontColor": "#5F6B7A",
                            "labelFont": "Open Sans",
                            "labelFontSize": "9px",
                            "labelFontColor": "#5F6B7A",
                            
        
                        }
                    }
                }
                console.log("chartConfiguration", chartConfiguration)
                setChartConfigs(chartConfiguration)

            }).catch((error) => {
                
                if(error?.message !== "The user aborted a request."){
                    const chartConfiguration = {
                        type: "heatmap",
                        width: "90%",
                        height: "370",
                        dataFormat: "JSON",
                        dataSource: {
                            colorrange: {
                                gradient: "0",
                                minvalue: "0",
                                code: "F28C8C",
                                startlabel: "VeryLow",
                                endlabel: "VerHigh",
                                color: [
                                    {
                                        code: "117245",
                                        minvalue: "0",
                                        maxvalue: "50",
                                        label: "VeryLow"
                                    },
                                    {
                                        code: "92CC38",
                                        minvalue: "50",
                                        maxvalue: "70",
                                        label: "Low"
                                    },
                                    {
                                        code: "FDC731",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "Medium"
                                    },
                                    {
                                        code: "D74848",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "High"
    
                                    },
                                    {
                                        code: "FAA544",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "VeryHigh"
                                    },{
                                        code: "D83545",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "VeryVeryHigh"
                                    }
                                ]
                            },
                            dataset: [
                                {
                                    data: [
                                        {
                                            rowid: "VERY HIGH",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "High"
                                        }, {
                                            rowid: "VERY HIGH",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryHigh"
                                        }, {
                                            rowid: "VERY HIGH",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryVeryHigh"
                                        },
                                        {
                                            rowid: "HIGH",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "Medium"
                                        },
                                        {
                                            rowid: "HIGH",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "High"
                                        },
                                        {
                                            rowid: "HIGH",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryHigh"
                                        },
                                        {
                                            rowid: "MEDIUM",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "Low"
                                        },
                                        {
                                            rowid: "MEDIUM",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "Medium"
                                        },
                                        {
                                            rowid: "MEDIUM",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "High"
                                        },
                                        {
                                            rowid: "LOW",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryLow"
                                        },
                                        {
                                            rowid: "LOW",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "Low"
                                        },
                                        {
                                            rowid: "LOW",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "Medium"
                                        }
                                    ]
                                }
                            ],
                            columns: {
                                column: [
                                    {
                                        id: "MEDIUM",
                                        label: "MEDIUM"
                                    },
                                    {
                                        id: "HIGH",
                                        label: "HIGH"
                                    },
                                    {
                                        id: "VERY HIGH",
                                        label: "VERY HIGH"
                                    }
                                ]
                            },
                            rows: {
                                row: [
                                    {
                                        id: "VERY HIGH",
                                        label: "VERY High"
                                    },
                                    {
                                        id: "HIGH",
                                        label: "HIGH"
                                    },
                                    {
                                        id: "MEDIUM",
                                        label: "MEDIUM"
                                    },
                                    {
                                        id: "LOW",
                                        label: "LOW"
                                    }
                                ]
                            },
                            chart: {
                                theme: "fusion",
                                showLegend: "0",
                                showvalues: "1",
                                xAxisName: "INTENT",
                                yAxisName: "ICP",
                                mapByCategory: "1",
                                plottooltext:
                                "Number of Accounts: $displayvalue</br> ICP Level: $rowlabel</br> Intent Level: $columnlabel",
                                xAxisNameFontColor : "#5E5454",
                                xAxisNameFontSize:"9px",
                                yAxisNameFontColor : "#5E5454",
                                yAxisNameFontSize:"9px", // Optional: Set font color
                                xAxisValueFontColor: '#5E5454', // Optional: Set font color
                                yAxisValueFontColor: '#5E5454', // Optional: Set font color 
                                xAxisNameFontBold:"0",
                                yAxisNameFontBold:"0",
                                "yAxisValueFont": "Open Sans",
                                "yAxisValueFontSize": "9px",
                                "yAxisValueFontColor": "#5F6B7A",
                                "xAxisFont": "Inter",
                                "xAxisFontSize": "10px",
                                "xAxisFontColor": "#ff0000",
                                "baseFont": "Open Sans",
                                "baseFontSize": "9px",
                                "baseFontColor": "#5F6B7A",
                                "labelFont": "Open Sans",
                                "labelFontSize": "9px",
                                "labelFontColor": "#5F6B7A",
            
                            }
                        }
                    }
                    setChartConfigs(chartConfiguration)
                }
            })
    }

    useEffect(() => {
        if (props.icpSelected !== null && props.segmentName !== '') {
        console.log("stageValue",props.stageValue)
        getIntentVsIcp();
        }

    }, [
        props.icpSelected,
        props.segmentName,
        props.reqBody, 
        props.stageValue,
        props.count,
        props.softRefresh,
        props.refresh
    ]);

    window.addEventListener('resize',()=> setCount(count+1))
    return (
        
         loading ? (<Loader />):(
            <div style={{marginLeft:"40px",marginTop:'2px'}}>
                <ReactFC {...chartConfigs} />
            </div>
    )) 
}

export default HeatMapChart

