import { Autocomplete } from "@material-ui/lab";
import { ArrowRightAlt } from "@material-ui/icons";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  customTextField: {
    "& input::label": {
      fontStyle: "italic",
    },
  },
});

const PersonaLevel = (props) => {
  const classes = useStyles();

  const { isSubFilter } = props;

  const getFieldValue = (index) => {
    if (props.filterAttribute["values"][1][1] === null) {
      props.filterAttribute["values"][1][1] =
        props.filterAttribute["values"][1][0] + 1;
    }
    return props.filterAttribute["values"][1][index];
  };

  const fieldsOnchange = (value, index) => {
    const newFilterAttribute = { ...props.filterAttribute };

    if (props.filterAttribute["relation"] !== "between") {
      newFilterAttribute["values"][1][1] = null;
    }
    newFilterAttribute["values"][1][index] = parseInt(value);

    props.onAttributeChange(newFilterAttribute, "others");
  };

  const onChangeRelationPersonaLvl = (valueObj, option) => {
    const newFilterAttribute = { ...props.filterAttribute };
    if (option === 0) {
      newFilterAttribute["relationMain"] = valueObj.value;
    } else {
      if ("values" in newFilterAttribute) {
        if (option === 1) {
          newFilterAttribute["values"][0] = valueObj.value;
        } else if (option === 2) {
          newFilterAttribute["relation"] = valueObj.value;
        }
      }
    }

    props.onAttributeChange(newFilterAttribute, "others");
  };

  const getDropdownOptions = (option) => {
    const dropdown0 = [
      { title: "includes", value: "includes" },
      { title: "excludes", value: "excludes" },
    ];
    const dropdown1 = [
      { title: "Very High", value: 4 },
      { title: "High", value: 3 },
      { title: "Medium", value: 2 },
      { title: "Low", value: 1 },
      { title: "Very Low", value: 0 },
    ];
    const dropdown2 = [
      { title: "is equal (=)", value: "isEqual (=)" },
      { title: "greater than (>)", value: "greater than(>)" },
      { title: "less than (<)", value: "less than(<)" },
      { title: "between", value: "between" },
    ];
    if (option === 0) {
      return dropdown0;
    } else if (option === 1) {
      return dropdown1;
    } else if (option === 2) {
      return dropdown2;
    }
  };
  const getDropdownValues = (val, option) => {
    console.log("dropdown option", option, val);
    const dropdown = getDropdownOptions(option);
    console.log("dropdown", dropdown);
    for (const obj of dropdown) {
      if (obj.value === val) {
        console.log("dropdown obj", obj);
        return obj;
      }
    }
  };

  return (
    <div
      style={{
        display: "grid",
        gap: "10px",
        marginTop: "1px",
        width: isSubFilter ? "584px" : "618px",
      }}
    >
      <div
        style={{ display: "grid", gridTemplateColumns: "50% 50%", gap: "10px" }}
      >
        {"attribute" in props.filterAttribute && (
          <Autocomplete
            popupIcon={
              <KeyboardArrowDownRoundedIcon
                style={{ color: "rgba(3, 3, 3, 0.6)" }}
              />
            }
            value={getDropdownValues(props?.filterAttribute?.relationMain, 0)}
            disableClearable
            onChange={(event, newValue) => {
              onChangeRelationPersonaLvl(newValue, 0);
            }}
            options={getDropdownOptions(0)}
            getOptionLabel={(option) => option.title}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                placeholder="Relation"
                {...params}
                variant="outlined"
              />
            )}
          />
        )}

        {"relationMain" in props.filterAttribute && (
          <Autocomplete
            popupIcon={
              <KeyboardArrowDownRoundedIcon
                style={{ color: "rgba(3, 3, 3, 0.6)" }}
              />
            }
            defaultValue={{ title: "Very High", value: "Very High" }}
            value={getDropdownValues(props?.filterAttribute?.values[0], 1)}
            disableClearable
            onChange={(event, newValue) => {
              onChangeRelationPersonaLvl(newValue, 1);
            }}
            options={getDropdownOptions(1)}
            getOptionLabel={(option) => option.title}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                placeholder="Level"
                {...params}
                variant="outlined"
              />
            )}
          />
        )}
      </div>

      {"relationMain" in props.filterAttribute ? (
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            marginLeft: "10px",
          }}
        >
          {"values" in props.filterAttribute && (
            <Autocomplete
              popupIcon={
                <KeyboardArrowDownRoundedIcon
                  style={{ color: "rgba(3, 3, 3, 0.6)" }}
                />
              }
              defaultValue={{
                title: "between",
                value: "between",
              }}
              value={getDropdownValues(props?.filterAttribute?.relation, 2)}
              disableClearable
              onChange={(event, newValue) => {
                onChangeRelationPersonaLvl(newValue, 2);
              }}
              options={getDropdownOptions(2)}
              getOptionLabel={(option) => option.title}
              style={{ width: "50%" }}
              renderInput={(params) => (
                <TextField
                  classes={{ root: classes.customTextField }}
                  placeholder="Relation"
                  {...params}
                  variant="outlined"
                />
              )}
            />
          )}

          {"values" in props.filterAttribute && (
            <>
              {props.filterAttribute["relation"] !== "between" ? (
                <TextField
                  variant="outlined"
                  placeholder="Value"
                  style={{
                    width: "100%",
                  }}
                  type="number"
                  value={getFieldValue(0)}
                  onChange={(event) => {
                    fieldsOnchange(event.target.value, 0);
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    style: {
                      fontSize: 16,
                      height: 40,
                    },
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    style={{ minwidth: "100px", width: "190px" }}
                    placeholder="Value"
                    type="number"
                    value={getFieldValue(0)}
                    onChange={(event) => {
                      fieldsOnchange(event.target.value, 0);
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: props.filterAttribute?.values[1][1] - 1,
                      },
                      style: {
                        fontSize: 16,
                        height: 40,
                      },
                    }}
                  />
                  <div
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px",
                    }}
                  >
                    <ArrowRightAlt />
                  </div>
                  <TextField
                    variant="outlined"
                    style={{ minwidth: "100px", width: "190px" }}
                    placeholder="Value"
                    type="number"
                    value={getFieldValue(1)}
                    onChange={(event) => {
                      fieldsOnchange(event.target.value, 1);
                    }}
                    InputProps={{
                      inputProps: {
                        min: props.filterAttribute?.values[1][0] + 1,
                      },
                      style: {
                        fontSize: 16,
                        height: 40,
                      },
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PersonaLevel;
