
// import { ThemeProvider } from "@mui/styles";
import Header from "./Header"



const  DashBoardMain =(props)=>{
    
return (
 
 <div className="root" style={{backgroundColor: '#f9f9f9'}}>
  <Header></Header>
  
 </div>

)
}

export default DashBoardMain
