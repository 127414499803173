import React, { useState, useEffect } from "react";
import Filters from "./new-filers";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useAuthState } from "@bamboobox/b2logincheck";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { DATE_FIELDS, CATEGORIES } from "./new-filers/attributeitem/constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import PublicIcon from '@mui/icons-material/Public';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import "./Filter.css";
import FilterChips from "./FilterChips";

import {
  saveFilters,
  getFilterNames,
  getFilterDataByName,
  deleteFilters,
  getIcpListName,
  Segments,
} from "../apis/api";

const ui = "unified_profile";

const useStyles = makeStyles((theme) => ({
  filterButton: {
    backgroundColor: '#FFFFFF',
    padding: "0px",
    width: "100px", 
    height: "160px",
  }
}));

const FilterAndChips = (props) => {
  console.log("props in FilterAndChips",props,props?.body?.length,props?.body?.length>0 || props.segmentName!=='All Accounts'?true:false)
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const [action, setAction] = useState("");
  const [attributeData, setAttributeData] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [chipData, setChipData] = useState([]);
  const [appliedFilterAttr, setAppliedFilterAttr] = useState([]);
  const [filterNamesArr, setFilterNamesArr] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [chipClicked, setChipClicked] = useState(false);
  const [chipToFocus, setChipToFocus] = useState({});
  const [tenantConfigMapper, setTenantConfigMapper] = useState();
  const [contTenantConfigMapper, setContTenantConfigMapper] = useState();
  const [icpList, setIcpList] = useState([]);
  const [clearClicked,setClearClicked] = useState(true); 

  const classes = useStyles();

  async function getTenantConfigMapper() {
    let response = await fetch(
      `${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/unified-profile-ui`,
      { credentials: "include" }
    );
    let mapper = await response.json();

    Object.keys(mapper).forEach((key) => {
      if (mapper[key] === undefined || mapper[key] === null) {
        delete mapper[key];
      }
    });
    setTenantConfigMapper(mapper);
    let contResponse = await fetch(
      `${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/ContactHub-creation`,
      { credentials: "include" }
    );
    let contMapper = await contResponse.json();

    Object.keys(contMapper).forEach((key) => {
      if (contMapper[key] === undefined || contMapper[key] === null) {
        delete contMapper[key];
      }
    });
    console.log(contMapper);
    setContTenantConfigMapper(contMapper);
  }

  function saveData(mode = "save", attrList, filterName) {
    saveFilters(mode, attrList, filterName, tenantId, ui)
      .then((json) => {
        getFilterNames(tenantId, ui).then((res) => {
          setFilterOptions(res?.data || []);
        });
        if (json?.success) {
          // setSeverity("success");
          toast.success(<Alert severity="success">{json?.message}</Alert>, {
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true,
          });
          if (mode === "save") {
            applyFilters(attrList);
          } else {
            setShowModal(false);
            setFilterName("");
          }
        } else {
          // setSeverity("error");
          toast.error(<Alert severity="error">{json?.message}</Alert>, {
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true,
          });
        }
      })
      .catch((e) => {
        console.error("savedata error", e);
      });
  }

  function applyFilters(attrList) {
    setAppliedFilterAttr(attrList);
    const attributeArray = attrList.map((object) => {
      const obj = { ...object };
      delete obj.isValid;
      delete obj.index;
      return obj;
    });

    props.setBody(attributeArray);
    setShowModal(false);
    console.log("Attribute Filter List", attrList);
    const chipArray = attrList.map((element, index) => {
      console.log("Attribute Value", element);
      let fieldName = element?.attribute;
      let chipLabel = tenantConfigMapper[fieldName]?.label || fieldName;
      if (DATE_FIELDS.includes(element.attribute)) {
        let fromDate = new Date(element.values[0]);
        let toDate = new Date(element.values[1]);
        if (
          element.relation.toLowerCase() === "is empty" ||
          element.relation.toLowerCase() === "is not empty"
        ) {
          return {
            key: element.attrId,
            // label: `${chipLabel} (${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()})`,
            label: `${chipLabel}`,
            value: `${element.relation}`,
          };
        }

        if (element.relation.toLowerCase() !== "between".toLowerCase()) {
          return {
            key: element.attrId,
            // label: `${chipLabel} (${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()})`,
            label: `${chipLabel}`,
            value: `${element.relation.toLowerCase()} ${fromDate.getDate()}-${
              fromDate.getMonth() + 1
            }-${fromDate.getFullYear()}`,
          };
        } else {
          return {
            key: element.attrId,
            // label: `${chipLabel}(${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()} to ${toDate.getDate()}-${(toDate.getMonth() + 1)}-${toDate.getFullYear()})`,
            label: `${chipLabel}`,
            value: `${element.relation.toLowerCase()} ${fromDate.getDate()}-${
              fromDate.getMonth() + 1
            }-${fromDate.getFullYear()} to ${toDate.getDate()}-${
              toDate.getMonth() + 1
            }-${toDate.getFullYear()}`,
          };
        }
      } else if (
        element.relation.toLowerCase() === "Is Empty".toLowerCase() ||
        element.relation.toLowerCase() === "is not empty".toLowerCase()
      ) {
        const valuesArr = element.relation.toLowerCase();
        return {
          key: element.attrId,
          // label: `${chipLabel}(${valuesArr})`,
          label: `${chipLabel}`,
          value: `${valuesArr}`,
        };
      }
      var objArray = [];
      if (typeof element.values[0] === "object") {
        if (
          element.attribute === "Campaign Name" ||
          element.attribute === "Contact Campaign Name"
        ) {
          element.values.forEach((obj) => {
            objArray.push(
              `${obj.programName}-${
                obj.source == null || obj.source === "" ? "" : obj.source
              }`
            );
          });
        } else if (element.attribute === "Buyer Group") {
          let depts = element.values[0].department.join(",");
          let personas = element.values[0].personalevel.join(",");
          let count = element.values[0].value.join(",");
          objArray.push(
            `${count} in verticals of ${depts} and for ${personas} roles`
          );
        } else if (element.attribute === "Account Labels") {
          objArray.push(...element.values.map((item) => item?.labelName));
        } else {
          element.values.forEach((obj) => {
            objArray.push(
              `${obj.activityType}-${
                obj.source == null || obj.source === "" ? "" : obj.source
              }`
            );
          });
        }
        console.log("element", objArray);
      }

      console.log("element", JSON.stringify(element.values), objArray);
      // element.values !== 'object'?element.values.join(","):element.values;
      const valuesArr =
        typeof element.values[0] !== "object"
          ? element.values.join(",")
          : objArray.join(",");
      return {
        key: element.attrId,
        // label: `${chipLabel}(${valuesArr})`,
        label: `${chipLabel}`,
        value: `${element.relation.toLowerCase()} ${valuesArr}`,
      };
    });
    setChipData(chipArray);
    setFilterName("");
  }

  useEffect(() => {
    getIcpListName(tenantId).then((json) => {
      const icpKeys = json?.map((icpObject) => {
        return icpObject?.id?.icpName;
      });
      setIcpList(icpKeys);
    });

    getFilterNames(tenantId, ui)
      .then((json) => {
        setFilterOptions(json.data);
      })
      .catch((e) => {
        console.error("getFilterNames error occur", e);
      });

    if (!tenantConfigMapper) {
      getTenantConfigMapper();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    props.setRefresh(refresh=>!refresh)

  };

  const handleClearClick=()=>{
    setClearClicked(false)

  }

  return (
    <div className="filterContainer">
      <div className="container1">
        <a href={`${window._env_.NEW_DASHBOARD_VIEW}/#/settings?icp=${props.icpSelected}&category=${props.category}`}>
        <Button
          style={{
            width: "146px",
            height: "36px",
            backgroundColor: "#016B40",
            borderRadius: "12px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#FFFFFF",
            textTransform: 'none'
          }}
          startIcon={<AddIcon />}
          onClick={handleButtonClick}
        >
          New Insights
        </Button>
        </a>
        <div className="flexContainer">
        <div style={{ width: '150px', height: '36px', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '14px' }}>
        <Button className="refreshButton"
          startIcon={<RefreshIcon sx={{fontSize: "small"}} />}
          onClick={handleButtonClick}
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            textTransform: "none",
            color: "#3D3D3B",
            display: "flex",
            alignItems: "center",
          }}
        >Refresh All</Button>
        </div>

          <Filters
            className={classes.filterButton}
            getFilterData={(data) => {
              setAction(data.actionType);
              if (data.isValid) {
                setAttributeData(data.attrList);
                setFilterName(data.filterName);

                if (
                  data.actionType === "APPLY" ||
                  data.actionType === "ONLY_APPLY"
                ) {
                  applyFilters(data.attrList);
                } else if (data.actionType === "SAVE") {
                  saveData("edit", data.attrList, data.filterName);
                } else {
                  if (data.actionType === "SAVE_APPLY") {
                    if (filterNamesArr.includes(data.filterName)) {
                      // setMessage("FilterName already exists!");
                      toast.error(
                        <Alert severity="error">
                          FilterName already exists!
                        </Alert>,
                        {
                          closeOnClick: true,
                          autoClose: true,
                          closeButton: true,
                          newestOnTop: true,
                        }
                      );
                      // setSeverity("error");
                      // setOpenSnackbar(true);
                    } else {
                      saveData("save", data.attrList, data.filterName);
                    }
                  }
                }
              }
            }}
            customStyle={{ width: 150, background: "#fff" }}
            onClickFilter={(filter, type) => {
              if (filter !== "none") {
                if (type === "edit") {
                  console.log(type);
                  getFilterDataByName(filter, tenantId, ui).then((json) => {
                    setAttributeData(json);
                    setShowModal(true);
                  });
                } else if (type === "delete") {
                  console.log("delete");
                  deleteFilters(filter, tenantId, ui)
                    .then((res) => {
                      getFilterNames(tenantId, ui)
                        .then((json) => {
                          setFilterOptions(json.data);
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                } else if (type === "add") {
                  setShowModal(true);
                } else {
                  getFilterDataByName(filter, tenantId, ui).then((json) => {
                    applyFilters(json);
                  });
                }
              } else {
                props.setBody([]);
                setChipData([]);
              }
            }}
            onModalClose={(e) => {
              setShowModal(false);
              setAttributeData([]);
              setFilterName("");
              setChipClicked(false);
            }}
            typoLabel="Account Hub"
            showModal={showModal}
            attributeData={attributeData}
            options={filterOptions}
            attributesValueURL={`${window._env_.ATTRIBUTE_URL}v2/getAttributeData?tenantId=${tenantId}`}
            icp={props.icp}
            chipClicked={chipClicked}
            setChipClicked={setChipClicked}
            chipToFocus={chipToFocus}
            accChipArray={chipData}
            tenantConfigMapper={tenantConfigMapper}
            body={props.body}
            screen="isAccountHub"
          />
        </div>
      </div>
      <div className="container2">
        <FilterChips
          chipData={chipData}
          setChipData={setChipData}
          setShowModal={setShowModal}
          setChipClicked={setChipClicked}
          chipToFocus={chipToFocus}
          setChipToFocus={setChipToFocus}
          setBody={props.setBody}
          setAppliedFilterAttr={setAppliedFilterAttr}
          appliedFilterAttr={appliedFilterAttr}
          setAttributeData={setAttributeData}
        />
      </div>
      {clearClicked&&(props.segmentName!=="")&&(props?.body?.length>0 || props.segmentName!=='All Accounts')?<div className="globalindicator">
      <div style={{display:'flex',alignItems:'center'}}>
      <PublicIcon style={{marginLeft:'10px'}}/>
      <p
      style={{ fontSize: "10px",
      color: "#3D3D3B",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      marginLeft: "5px",
      lineHeight: "12.1px",}}
      >
        There are global insights that does not support your segmentation
        </p>
        </div>
        <div style={{marginRight:'15px'}}>
        <CloseRoundedIcon
                    style={{
                      color: "#3D3D3D",
                      fontSize: "larger",
                      fontWeight: "700",
                      marginLeft: "0px",
                      paddingLeft: "5px",
                      cursor:'pointer'
                    }}
                    onClick={handleClearClick}
                  />        </div>
      </div>:<></>}
    </div>
  );
};

export default FilterAndChips;
